import noop from 'lodash/noop'
import mixpanel from 'mixpanel-browser'
import { AnyObject } from '@voltus/types'
import { isTestEnv } from '@voltus/utils'

const mpStub = {
  track: noop,
  identify: noop,
  people: {
    set: noop,
    set_once: noop,
    increment: noop,
  },
  register: noop,
  unregister: noop,
  reset: noop,
}
export type Mixpanel = {
  track: (event: string, properties: AnyObject) => void
  people: {
    set: (properties: AnyObject) => void
    increment: (property: string) => void
  }
  register: (properties: AnyObject) => void
  unregister: (property: string) => void
  identify: (id: string | number) => void
  reset: () => void
}

export default () => {
  // Never track mixpanel events during tests
  if (isTestEnv()) {
    return mpStub
  }

  // In prod, we get the token as a global
  if (window.MIXPANEL_TOKEN) {
    mixpanel.init(window.MIXPANEL_TOKEN)
    return mixpanel
  }

  // In DEV, track events if a token is found
  // Useful for capturing new events
  if (__DEV__ && process.env.MIXPANEL_TOKEN) {
    mixpanel.init(process.env.MIXPANEL_TOKEN)
    return mixpanel
  }

  return mpStub
}
