import { cloneDeep } from 'lodash'

/**
 * Deep clone an object.
 *
 * First use native function structuredClone, if it fails, use lodash's cloneDeep.
 * Some users may have issues with structuredClone due to level of browser updates,
 * so we provide a fallback.
 * @returns deep clone of obj
 */
export function safeClone<TObj>(obj: TObj) {
  try {
    return structuredClone(obj)
  } catch (_) {
    return cloneDeep(obj)
  }
}
