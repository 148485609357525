import { isAfter, isBefore } from 'date-fns'
import produce from 'immer'
import get from 'lodash/get'
import setWith from 'lodash/setWith'
import { createActions, createReducer } from 'reduxsauce'

export const {
  Types: SiteAssignmentsTypes,
  Creators: SiteAssignmentsCreators,
} = createActions(
  {
    fetchSiteAssignments: ['dispatchId'],
    receiveSiteAssignments: ['assignments'],
    assignSites: ['payload'],
    unassignSites: ['payload'],
    assignmentsInFlight: ['assignments'],
    loadingState: ['dispatchId', 'loadingState'],
  },
  {
    prefix: 'DISPATCHES/SITE_ASSIGNMENTS/',
  }
)

const INITIAL_STATE = {
  assignments: {},
  loadingState: {},
  inFlight: {},
}

const handleAssignmentsInFlight = (state, { assignments }) => {
  return produce(state, (draftState) => {
    assignments.forEach((assignment) => {
      draftState.inFlight[assignment.siteId] = assignment.inFlight
    })
  })
}

const handleReceiveAssignments = (state, { assignments }) => {
  return produce(state, (draftState) => {
    assignments.forEach((assignment) => {
      const { scheduledEventId, facilityId } = assignment
      const valuePath = ['assignments', scheduledEventId, facilityId]
      const oldAssignment = get(draftState, valuePath)
      if (oldAssignment) {
        if (
          isAfter(
            new Date(assignment.createdAt),
            new Date(oldAssignment.createdAt)
          )
        ) {
          setWith(draftState, valuePath, assignment, Object)
        } else if (
          isBefore(
            new Date(assignment.createdAt),
            new Date(oldAssignment.createdAt)
          )
        ) {
          setWith(draftState, valuePath, oldAssignment, Object)
        } else {
          // timestamps are equal, compare the db id to find the "newer" one
          setWith(
            draftState,
            valuePath,
            assignment.id > oldAssignment.id ? assignment : oldAssignment,
            Object
          )
        }
      } else {
        setWith(draftState, valuePath, assignment, Object)
      }
    })
  })
}

const handleLoadingState = (state, { dispatchId, loadingState }) => {
  return produce(state, (draftState) => {
    draftState.loadingState[dispatchId] = loadingState
  })
}

export const siteAssignmentsReducer = createReducer(INITIAL_STATE, {
  [SiteAssignmentsTypes.RECEIVE_SITE_ASSIGNMENTS]: handleReceiveAssignments,
  [SiteAssignmentsTypes.LOADING_STATE]: handleLoadingState,
  [SiteAssignmentsTypes.ASSIGNMENTS_IN_FLIGHT]: handleAssignmentsInFlight,
})
