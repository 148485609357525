export const selectSitesFilter = (state) => state.dispatches.ui.sitesFilter
export const selectSitesSortBy = (state) => state.dispatches.ui.sitesSortBy
export const selectSitesSortOrder = (state) =>
  state.dispatches.ui.sitesSortOrder
export const selectAdminSitesListViewFilter = (state) =>
  state.dispatches.ui.adminSitesListViewFilter
export const selectAdminSitesAssignmentFilter = (state) =>
  state.dispatches.ui.adminSitesAssignmentFilter
export const selectAdminSitesControlsFilter = (state) =>
  state.dispatches.ui.adminSitesControlsFilter
export const selectAdminSitesStatusFilter = (state) =>
  state.dispatches.ui.adminSitesStatusFilter
export const selectAdminSitesSortBy = (state) =>
  state.dispatches.ui.adminSitesSortBy
export const selectAdminSitesSortOrder = (state) =>
  state.dispatches.ui.adminSitesSortOrder
export const selectIsMobile = (state) => state.dispatches.ui.isMobile
export const selectFilterByPerformanceDataAvailable = (state) =>
  state.dispatches.ui.filterByPerformanceDataAvailable
