import fuzzy from 'fuzzy'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import memoize from 'memoize-one'
import { Profile } from '@voltus/types'
import { sitePropertyResolvers } from '../components/SiteList/sitePropertyResolvers'
import {
  DISPATCH_ROLE,
  PARTICIPATION_STATUS,
  ADMIN_SORT,
  ADMIN_CONTROLS_FILTER,
  ADMIN_ASSIGNMENT_FILTER,
  ADMIN_LIST_VIEW_FILTER,
  ADMIN_STATUS_FILTER,
} from '../constants'
import { Assignments } from '../types/assignments'
import { DispatchSnapshotSite, Ack } from '../types/dispatch'
import { Site } from '../types/dispatch'
import {
  AdminAssignmentFilterOption,
  AdminControlsFilterOption,
  AdminStatusFilterOption,
  AdminListViewFilterOption,
} from '../types/filters'

/**
 * Similar to getSnapshotSitesByParticipationStatus,
 * except operates on a list of sites, and includes the option
 * to return all sites
 */
export const filterSitesByParticipation = (
  sites: Array<Site>,
  filter: string
) => {
  return sites.filter((site) => {
    if (filter === 'all') {
      return true
    }
    if (filter === PARTICIPATION_STATUS.CONFIRMED) {
      return filterByConfirmed(site)
    }
    if (filter === PARTICIPATION_STATUS.REJECTED) {
      return filterByRejected(site)
    }

    if (filter === PARTICIPATION_STATUS.NOT_RESPONDED) {
      return filterByNotResponded(site)
    }

    return true
  })
}

/**
 * Consider the site to be "confirmed" if a primary contact
 * has confirmed and no one has declined
 */
export const filterByConfirmed = (site: DispatchSnapshotSite) => {
  return (
    site.acknowledgments.every(
      (ack: Ack) => ack.acknowledgmentStatus !== PARTICIPATION_STATUS.REJECTED
    ) &&
    site.acknowledgments?.some(
      (ack: Ack) =>
        ack.dispatchRole === DISPATCH_ROLE.PRIMARY &&
        ack.acknowledgmentStatus === PARTICIPATION_STATUS.CONFIRMED
    )
  )
}

/**
 * Consider the site to be rejected if at any
 * user has rejected
 */
export const filterByRejected = (site: DispatchSnapshotSite) => {
  return site.acknowledgments?.some(
    (ack) => ack.acknowledgmentStatus === PARTICIPATION_STATUS.REJECTED
  )
}

/**
 * If no users have confirmed or rejected, then
 * a site is tagged notResponded
 */
export const filterByNotResponded = (site: DispatchSnapshotSite) => {
  return !filterByConfirmed(site) && !filterByRejected(site)
}

export const aggregateSitesByParticipationStatus = (
  sites: Array<DispatchSnapshotSite> = []
) => ({
  confirmedSites: sites.filter(filterByConfirmed) ?? [],
  rejectedSites: sites.filter(filterByRejected) ?? [],
  notRespondedSites: sites.filter(filterByNotResponded) ?? [],
})

/**
 * Returns the participation status for a given site.
 * The rules are:
 * 1. If any contact declines, site is tagged as rejected
 * 2. If a primary contact (and only a primary contact) is confirmed, site is tagged as confirmed
 * 3. Otherwise no response
 *
 * @param userAcks - A list of user acknowledgements for a given site
 * @returns Enum - participation status of a given site.
 */
export const getAckStatusForSite = (userAcks: Array<Ack>) => {
  // 1. If any contact declines, site is tagged as rejected
  if (
    userAcks.some(
      (ack) => ack.acknowledgmentStatus === PARTICIPATION_STATUS.REJECTED
    )
  ) {
    return PARTICIPATION_STATUS.REJECTED
  }

  // 2. If a primary contact is confirmed, site is tagged as confirmed
  if (
    userAcks.some(
      (ack) =>
        ack.dispatchRole === DISPATCH_ROLE.PRIMARY &&
        ack.acknowledgmentStatus === PARTICIPATION_STATUS.CONFIRMED
    )
  ) {
    return PARTICIPATION_STATUS.CONFIRMED
  }

  // 3. Otherwise, consider the site "not-responded"
  // Meaning we ignore confirmations from secondary and or notification only contacts
  return PARTICIPATION_STATUS.NOT_RESPONDED
}

/**
 * Helpers to check ack status for a site
 */
export const isSiteConfirmed = (site) =>
  getAckStatusForSite(site.acknowledgments) === PARTICIPATION_STATUS.CONFIRMED
export const isSiteRejected = (site) =>
  getAckStatusForSite(site.acknowledgments) === PARTICIPATION_STATUS.REJECTED
export const isSiteNotResponded = (site) =>
  getAckStatusForSite(site.acknowledgments) ===
  PARTICIPATION_STATUS.NOT_RESPONDED

// Duplicate of the above, to work with newer rollup functionality
export const sortSitesByAssignmentGroup = (sites, siteAssignments) => {
  const [unassignedSites, assignedSites] = sites.reduce(
    (res, site) => {
      if (siteAssignments[site.id]?.[site.dispatchId]?.isAssignment) {
        res[1].push(site)
      } else {
        res[0].push(site)
      }
      return res
    },
    [[], []]
  )

  return unassignedSites.concat(assignedSites)
}

/**
 * Sort sites based on a property defined in sitePropertyResolvers.
 * This allows us to perform calcuations and/or transformations
 * on site properties prior to sorting.
 *
 * @param sites - list of sites from a given snapshot
 * @param sortBy - property to sort by, must be a key in sitePropertyResolvers
 * @param sortOrder - either 1, -1, or 0
 */
export const sortSitesByProperty = (sites, sortBy, sortOrder) => {
  return sites.slice().sort((site1, site2) => {
    const col = sitePropertyResolvers[sortBy]
    const site1Val = col.valueResolver(site1)
    const site2Val = col.valueResolver(site2)
    // Because of how JS sorts strings vs numbers,
    // we want to reverse the sorting if the value is a string
    // so that ascending goes from a -> z in the same
    // way that ascending for integers goes from lower -> higher
    const stringReverse = typeof site1Val === 'string' ? -1 : 1
    if (site1Val > site2Val) {
      return 1 * sortOrder * stringReverse
    }
    if (site1Val < site2Val) {
      return -1 * sortOrder * stringReverse
    }

    return 0
  })
}

// Duplicated version of the above, but handles newer grouped sites funcitonality
export const sortAdminGroupSites = (
  sites,
  sortBy,
  sortOrder,
  siteAssignments
) => {
  switch (sortBy) {
    case ADMIN_SORT.ACK_STATUS: {
      const { confirmedSites, rejectedSites, notRespondedSites } =
        aggregateSitesByParticipationStatus(sites)
      return [
        ...sortSitesByProperty(rejectedSites, 'commitmentKw', -1),
        ...sortSitesByProperty(notRespondedSites, 'commitmentKw', -1),
        ...sortSitesByProperty(confirmedSites, 'commitmentKw', -1),
      ]
    }
    case ADMIN_SORT.MEGAWATTS_MISSING:
      return sortSitesByProperty(sites, ADMIN_SORT.MEGAWATTS_MISSING, -1)
    case ADMIN_SORT.ASSIGNMENT:
      return sortSitesByAssignmentGroup(
        sortSitesByProperty(sites, 'commitmentKw', -1),
        siteAssignments
      )
    default:
      return sites
  }

  // return sortedSites
}

const filterByControls = (
  sites: Array<Site>,
  controlsFilter: AdminControlsFilterOption
) => {
  return sites.filter((site) => {
    if (controlsFilter.value === ADMIN_CONTROLS_FILTER.ALL_TYPES) {
      return sites
    }

    if (controlsFilter.value === ADMIN_CONTROLS_FILTER.MANUAL) {
      return (
        site.responseType === '' ||
        site.responseType === ADMIN_CONTROLS_FILTER.MANUAL
      )
    }

    return site.responseType === controlsFilter.value
  })
}

const filterByAssignment = (
  sites: Array<Site>,
  profile: Profile,
  siteAssignments: Assignments,
  assignmentFilter: AdminAssignmentFilterOption
) => {
  let newSites = sites
  let assignment
  switch (assignmentFilter.value) {
    case ADMIN_ASSIGNMENT_FILTER.ASSIGNED:
      newSites = sites.filter((site) => {
        assignment = siteAssignments[site.id]?.[site.dispatchId]
        return (
          !isNil(assignment) &&
          assignment.userId === profile?.id &&
          assignment.isAssignment === true
        )
      })
      break
    case ADMIN_ASSIGNMENT_FILTER.UNASSIGNED:
      newSites = sites.filter((site) => {
        assignment = siteAssignments[site.id]?.[site.dispatchId]
        return isNil(assignment) || assignment.isAssignment === false
      })
      break
    default:
      break
  }
  return newSites
}

const filterByStatus = (
  sites: Array<Site>,
  statusFilter: AdminStatusFilterOption
) => {
  let newSites = sites
  switch (statusFilter.value) {
    case ADMIN_STATUS_FILTER.PARTICIPATING:
      newSites = sites.filter(
        (site) =>
          getAckStatusForSite(site.acknowledgments) ===
          PARTICIPATION_STATUS.CONFIRMED
      )
      break
    case ADMIN_STATUS_FILTER.DECLINED:
      newSites = sites.filter(
        (site) =>
          getAckStatusForSite(site.acknowledgments) ===
          PARTICIPATION_STATUS.REJECTED
      )
      break
    case ADMIN_STATUS_FILTER.NOT_RESPONDED:
      newSites = sites.filter(
        (site) =>
          getAckStatusForSite(site.acknowledgments) ===
          PARTICIPATION_STATUS.NOT_RESPONDED
      )
      break
    default:
      break
  }
  return newSites
}

const filterByListView = (
  sites: Array<Site>,
  listViewFilter: AdminListViewFilterOption
) => {
  /**
   * Filtering by account results in "Grouped" sites that render
   * inside a dropdown keyed by organization. This is tightly bound
   * to the rendering logic of the AdminSiteList, that expects
   * "sections" to have a certain shape
   */
  if (
    listViewFilter.value === ADMIN_LIST_VIEW_FILTER.ACCOUNTS ||
    listViewFilter.value === ADMIN_LIST_VIEW_FILTER.ACCOUNTS_EXPANDED
  ) {
    const newSites: Array<Site> = Object.values(
      sites.reduce((groups, site) => {
        if (groups[site.organization.name]) {
          groups[site.organization.name].children.push(site)
        } else {
          groups[site.organization.name] = {
            children: [site],
            header: site.organization.name,
            type: 'section',
          }
        }

        return groups
      }, {})
    )
    return newSites
  }

  return sites
}

/**
 * Filter and/or group sites based on some filter value
 * Sites can be "grouped" so that they are rendered in
 * an accordion dropdown, or sites can be a flat list
 * that just renders each row
 */
export const filterAndGroupAdminSitesGroup = memoize(
  ({
    rawSites,
    profile,
    siteAssignments,
    textFilter,
    filterByPerformanceDataAvailable,
    listViewFilter,
    controlsFilter,
    assignmentFilter,
    statusFilter,
  }: {
    rawSites: Array<Site>
    profile: Profile
    siteAssignments: Assignments
    textFilter: string
    filterByPerformanceDataAvailable: boolean
    listViewFilter: AdminListViewFilterOption
    controlsFilter: AdminControlsFilterOption
    assignmentFilter: AdminAssignmentFilterOption
    statusFilter: AdminStatusFilterOption
  }) => {
    // We fuzzy filter the sites by concatening their name, org name, and account managers name
    // then use a fuzzy filter lib to find sites that match the input text `textFilter`
    let sites = textFilter
      ? fuzzy
          .filter(textFilter, rawSites.slice(), {
            extract: (el) =>
              el.name +
              ' ' +
              el.organization.name +
              ' ' +
              el.accountManager.fullName,
          })
          .map(({ original }) => original)
      : rawSites.slice()

    if (filterByPerformanceDataAvailable) {
      sites = filterSitesByTargetData(sites)
    }

    // Controls
    sites = filterByControls(sites, controlsFilter)
    // Assignment
    sites = filterByAssignment(
      sites,
      profile,
      siteAssignments,
      assignmentFilter
    )
    // Status
    sites = filterByStatus(sites, statusFilter)
    // View
    sites = filterByListView(sites, listViewFilter)

    return sites
  }
)

export const sortAcknowledgmentsByAckStatus = (
  acks: DispatchSnapshotSite['acknowledgments']
) => {
  const sorted = acks.reduce(
    (
      res: {
        confirmed: Array<Ack>
        notResponded: Array<Ack>
        rejected: Array<Ack>
      },
      ack: Ack
    ) => {
      if (ack.acknowledgmentStatus === PARTICIPATION_STATUS.CONFIRMED) {
        res.confirmed.push(ack)
      }
      if (ack.acknowledgmentStatus === PARTICIPATION_STATUS.NOT_RESPONDED) {
        res.notResponded.push(ack)
      }
      if (ack.acknowledgmentStatus === PARTICIPATION_STATUS.REJECTED) {
        res.rejected.push(ack)
      }
      return res
    },
    { confirmed: [], rejected: [], notResponded: [] }
  )

  return [sorted.confirmed, sorted.rejected, sorted.notResponded]
}

export const getNamesOfSitesInDispatch = (sitesInDispatch, allSites) => {
  if (sitesInDispatch && !isEmpty(allSites)) {
    return sitesInDispatch.map(
      (dispatchSite) => allSites[dispatchSite.id]?.name
    )
  }
  return []
}

export const hasTargetsData = (site) => site?.targets?.length !== 0

export const filterSitesByTargetData = (sites) =>
  sites.filter((site) => hasTargetsData(site))

export const hasSparklinesData = (site) => site?.sparklines?.length !== 0
