import * as React from 'react'
import { matchPath } from 'react-router-dom'
import { testSelectors as SELECTORS } from '@voltus/constants'
import {
  Navbar as NavbarBase,
  Flex,
  Box,
  StyledIcons,
  Badge,
} from '@voltus/core-components'
import {
  EVENTS,
  PROPERTIES,
  TrackedLink,
  eventTracker,
} from '@voltus/event-tracking'
import { getCurrentURL, getResolvedPath, isNotNull } from '@voltus/utils'
import { ROUTES, PATHS } from '../../constants/routes'
import { useIsBoardsEnabled } from '../../utils/useIsBoardsEnabled'
import HelpMenu from '../HelpMenu'
import { CASH_DASH_TOUR_SELECTORS } from '../HelpMenu/constants'

export const Navbar = ({
  profile,
  portfolioId,
  permissions,
  navbarRef,
  isChatMenuOpen,
  onChatMenuClick,
}) => {
  const isBoardsEnabled = useIsBoardsEnabled()
  const getCashDashHref = () => {
    if (portfolioId) {
      return getResolvedPath(PATHS.CASHDASH, [portfolioId])
    }
    return PATHS.CASHDASH_HOME
  }

  const getPortfolioDashHref = () =>
    portfolioId
      ? getResolvedPath(PATHS.PORTFOLIO, [portfolioId])
      : PATHS.PORTFOLIO_HOME

  // Big hack - we're filtering out the nav items that should not be visible
  // in the "cashdashOnly" scenario that we're currently applying to residential
  // customers.
  const cashdashOnlyItems = ['cashdash', 'profile']
  const navItems = [
    {
      key: 'admin',
      Component: (
        <NavbarBase.Item
          data-testid={SELECTORS.ADMIN_NAV_ITEM}
          hidden={permissions.viewAdmin === false}
          active={!!matchPath({ path: `${PATHS.ADMIN}/*` }, location.pathname)}
          is="a"
          onClick={() => {
            eventTracker.track(EVENTS.CLICK_ADMIN, {
              [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
              [PROPERTIES.NAVIGATE_TO]: PATHS.ADMIN,
            })
          }}
          to={PATHS.ADMIN}
        >
          {ROUTES.ADMIN.name}
        </NavbarBase.Item>
      ),
    },
    isBoardsEnabled
      ? {
          key: 'boards',
          Component: (
            <NavbarBase.Item
              data-testid={SELECTORS.BOARDS_NAV_ITEM}
              hidden={permissions.viewEarnings === false}
              active={
                !!matchPath(
                  {
                    path: `${PATHS.BOARDS}/*`,
                  },
                  location.pathname
                )
              }
              is={TrackedLink}
              to={PATHS.BOARDS}
              eventName={EVENTS.CLICK_BOARDS_NAV}
            >
              <Flex.Row alignItems="center">
                Boards
                <Badge.Primary fontSize={0} px={1} py={0} m={0} ml={1}>
                  Beta
                </Badge.Primary>
              </Flex.Row>
            </NavbarBase.Item>
          ),
        }
      : null,
    {
      key: 'rted',
      Component: (
        <NavbarBase.Item
          data-testid={SELECTORS.REALTIME_DASH_NAV_ITEM}
          hidden={permissions.viewRealtimeDash === false}
          active={
            !!matchPath(
              {
                path: `${PATHS.REALTIME_ENERGY}/*`,
              },
              location.pathname
            )
          }
          is={TrackedLink}
          to={PATHS.REALTIME_ENERGY}
          eventName={EVENTS.CLICK_RT_DASH}
          eventProps={{
            [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
            [PROPERTIES.NAVIGATE_TO]: PATHS.HOME,
          }}
        >
          Real-Time Energy Data
        </NavbarBase.Item>
      ),
    },
    {
      key: 'cashdash',
      Component: (
        <NavbarBase.Item
          data-testid={SELECTORS.CASHDASH_NAV_ITEM}
          data-tour={CASH_DASH_TOUR_SELECTORS.CASH_DASH_NAV}
          hidden={permissions.viewEarnings === false}
          active={
            !!matchPath(
              {
                path: `${PATHS.CASHDASH_HOME}/*`,
              },
              location.pathname
            )
          }
          is={TrackedLink}
          to={getCashDashHref()}
          eventName={EVENTS.CLICK_CASHDASH}
          eventProps={{
            [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
            [PROPERTIES.NAVIGATE_TO]: getCashDashHref(),
          }}
        >
          {ROUTES.EARNINGS.name}
        </NavbarBase.Item>
      ),
    },
    {
      key: 'dispatches',
      Component: (
        <NavbarBase.DropdownNavItem
          data-testid={SELECTORS.DISPATCHES_NAV_ITEM}
          label="Dispatches"
          hidden={permissions.viewVoc === false}
          active={
            !!matchPath(
              {
                path: `${PATHS.DISPATCHES}/*`,
              },
              location.pathname
            )
          }
        >
          <Box p={1}>
            <NavbarBase.Item
              data-testid={SELECTORS.ACTIVE_DISPATCHES_NAV_ITEM}
              active={
                !!matchPath(
                  {
                    path: `${PATHS.ACTIVE_DISPATCHES}/*`,
                  },
                  location.pathname
                )
              }
              is={TrackedLink}
              to={PATHS.ACTIVE_DISPATCHES}
              eventName={EVENTS.CLICK_VOC}
              eventProps={{
                [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
                [PROPERTIES.NAVIGATE_TO]: PATHS.ACTIVE_DISPATCHES,
              }}
            >
              {ROUTES.ACTIVE_DISPATCHES.name}
            </NavbarBase.Item>
            <NavbarBase.Item
              data-testid={SELECTORS.ACTIVE_DISPATCHES_NAV_ITEM}
              active={
                !!matchPath(
                  {
                    path: `${PATHS.PAST_PERFORMANCE}/*`,
                  },
                  location.pathname
                )
              }
              is={TrackedLink}
              to={PATHS.PAST_PERFORMANCE}
              eventName={EVENTS.CLICK_VOC}
              eventProps={{
                [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
                [PROPERTIES.NAVIGATE_TO]: PATHS.PAST_PERFORMANCE,
              }}
            >
              {ROUTES.PAST_PERFORMANCE.name}
            </NavbarBase.Item>
          </Box>
        </NavbarBase.DropdownNavItem>
      ),
    },
    {
      key: 'portfolio',
      Component: (
        <NavbarBase.Item
          data-testid={SELECTORS.SITES_DASH_NAV_ITEM}
          hidden={permissions.viewEarnings === false}
          active={
            !!matchPath(
              { path: `${PATHS.PORTFOLIO_HOME}/*` },
              location.pathname
            )
          }
          is={TrackedLink}
          eventName={EVENTS.CLICK_HOME}
          eventProps={{
            [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
            [PROPERTIES.NAVIGATE_TO]: getPortfolioDashHref(),
          }}
          to={getPortfolioDashHref()}
        >
          {ROUTES.PORTFOLIO.name}
        </NavbarBase.Item>
      ),
    },
    {
      key: 'profile',
      Component: (
        <>
          <NavbarBase.Icon
            icon="Profile"
            data-testid={SELECTORS.PROFILE_NAV_ITEM}
            active={
              !!matchPath(
                {
                  path: `${PATHS.ACCOUNT_MANAGEMENT_ROOT}/*`,
                },
                location.pathname
              )
            }
            is={TrackedLink}
            eventName={EVENTS.CLICK_PROFILE}
            eventProps={{
              [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
              [PROPERTIES.NAVIGATE_TO]: PATHS.ACCOUNT_MANAGEMENT_PROFILE,
            }}
            to={PATHS.ACCOUNT_MANAGEMENT_ROOT}
          />
          {permissions.viewAdmin && (
            <NavbarBase.Icon
              active={isChatMenuOpen}
              is="button"
              onClick={onChatMenuClick}
              iconWidth={22}
              iconHeight={22}
              icon="Message"
            />
          )}
          <NavbarBase.DropdownNavItem
            isIcon
            label={
              <StyledIcons.QuestionMark
                cursor="pointer"
                color="slates.50"
                width={18}
                height={18}
              />
            }
          >
            <HelpMenu />
          </NavbarBase.DropdownNavItem>
        </>
      ),
    },
  ]
    .filter(isNotNull)
    .filter(({ key }) => {
      if (!profile?.cashdashOnly) {
        return true
      }

      return cashdashOnlyItems.includes(key)
    })

  return (
    <>
      <NavbarBase ref={navbarRef}>
        {navItems.map(({ key, Component }) => (
          <React.Fragment key={key}>{Component}</React.Fragment>
        ))}
      </NavbarBase>
    </>
  )
}
