import type { RequireExactlyOne } from 'type-fest'
import { Any } from '@voltus/types'

// This structure assumes we will have at most one of the following properties
type ResponseJSONKeys = {
  msg: string
  message: string
  detail: Any
}

type ResponseJSON = RequireExactlyOne<
  ResponseJSONKeys,
  'msg' | 'message' | 'detail'
>

type HandleApiResponseReturnType =
  | ArrayBuffer
  | Blob
  | FormData
  | string
  | unknown

// restrict to Response object keys which point to
// methods which return Promise<ReturnType>
type MethodKey = keyof {
  [K in keyof Response as Response[K] extends () => Promise<HandleApiResponseReturnType>
    ? K
    : never]: Any
}

// Custom Error class
export class NetworkError<Detail = Any> extends Error {
  statusCode: number
  detail: Detail
  constructor(message, detail, statusCode) {
    super(message)
    this.name = 'NetworkError'
    this.detail = detail
    this.statusCode = statusCode
  }
}

/*
This function allows us to massage a variety of API responses into a consistent format.

In particular, for cases where we get a non-ok response from the server, this allows us
to extract the error message from one of four possible origins. Furthermore, the entire
json object from the response, if any, is attached to the custom Error under the detail
key, allowing APIs to pass arbitrary metadata along with any error responses.

If the response is ok, this function will call one of the responses native methods and
pass on the return value.
 */
const handleApiResponse = async (res: Response, type: MethodKey = 'text') => {
  if (!res.ok) {
    const responseJSON: ResponseJSON = await res.clone().json()
    const message =
      responseJSON.msg ||
      responseJSON.message ||
      (typeof responseJSON.detail === 'string' && responseJSON.detail) ||
      res.statusText

    throw new NetworkError(message, responseJSON, res.status)
  }
  return res[type]()
}

export { handleApiResponse }
