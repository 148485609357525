import { grpc } from '@improbable-eng/grpc-web'
import noop from 'lodash/noop'
import auth from '@voltus/auth'
import { getVoltusPortfolioName } from '@voltus/constants'
import Proto from '@voltus/grpc-clients/voc-admin/voc_admin_pb'
import { VocAdmin } from '@voltus/grpc-clients/voc-admin/voc_admin_pb_service'
import logger, { debugLogger } from '@voltus/logger'
import { network, endpoints } from '@voltus/network'
import { ACK_STATUS_MAP } from '../constants'
import { VOC_ADMIN_API_PATH } from './constants'
import { unary, makegRPCHost, sanitizeMessageForDemo } from './gRPCUtils'

const makeVOCAdminHost = () => makegRPCHost(VOC_ADMIN_API_PATH)

const eventStreamsByDispatchId = {}
export const initializeEventsStream = ({
  onEnd = noop,
  onMessage,
  dispatchId,
}) => {
  debugLogger.info('Initializing VOC admin event stream', dispatchId)
  if (eventStreamsByDispatchId[dispatchId]) {
    return eventStreamsByDispatchId[dispatchId]
  }

  const host = makeVOCAdminHost()
  const transport = grpc.WebsocketTransport()
  const grpcClient = grpc.client(VocAdmin.EventStream, {
    host,
    transport,
  })

  grpcClient.onMessage(async (message) => {
    if (message.toObject) {
      logger.info('gRPC Message:', { message: message.toObject() })
      onMessage(
        await sanitizeMessageForDemo(VocAdmin.EventStream, host, message)
      )
    }
  })

  grpcClient.onEnd((...args) => {
    logger.warn('Event stream disconnected')
    onEnd(...args)
    eventStreamsByDispatchId[dispatchId] = null
  })

  grpcClient.start()
  grpcClient.clear = () => {
    eventStreamsByDispatchId[dispatchId] = null
  }

  const eventStreamRequest = new Proto.EventStreamRequest()
  eventStreamRequest.setAccessToken(auth.accessToken)
  eventStreamRequest.setScheduledEventId(dispatchId)
  grpcClient.send(eventStreamRequest)

  eventStreamsByDispatchId[dispatchId] = grpcClient
  return grpcClient
}

export const outboundCall = ({
  scheduledEventId,
  userIdCaller,
  userIdCallee,
  phoneNumber,
  facilityIds,
}) => {
  const request = new Proto.OutboundCallRequest()
  request.setScheduledEventId(scheduledEventId)
  request.setUserIdCaller(userIdCaller)
  request.setUserIdCallee(userIdCallee)
  request.setFacilityIdsList(facilityIds)
  request.setPhoneNumberCallee(phoneNumber)
  return unary(makeVOCAdminHost(), request, VocAdmin.OutboundCall)
}

export const getAssignments = (scheduledEventId) => {
  const request = new Proto.GetAssignmentsRequest()
  request.setScheduledEventId(scheduledEventId)
  return unary(makeVOCAdminHost(), request, VocAdmin.GetAssignments)
}

export const checkIn = (dispatchId, userId) => {
  const request = new Proto.CheckInRequest()
  request.setUserId(userId)
  request.setScheduledEventId(dispatchId)
  return unary(makeVOCAdminHost(), request, VocAdmin.CheckIn)
}

export const checkOut = (dispatchId, userId) => {
  const request = new Proto.CheckOutRequest()
  request.setUserId(userId)
  request.setScheduledEventId(dispatchId)
  return unary(makeVOCAdminHost(), request, VocAdmin.CheckOut)
}

export const getCheckIns = (dispatchId) => {
  const request = new Proto.GetCheckInsRequest()
  request.setScheduledEventId(dispatchId)
  return unary(makeVOCAdminHost(), request, VocAdmin.GetCheckIns)
}

export const assignSites = ({
  isEscalation = false,
  reason,
  reasonBody,
  scheduledEventId,
  sites,
  userId,
}) => {
  const promises = sites.map((site) => {
    const request = new Proto.AssignRequest()
    request.setScheduledEventId(scheduledEventId)
    request.setIsEscalation(isEscalation)
    request.setUserId(userId)
    request.setFacilityId(site.id)
    request.setReason(reason)
    request.setReasonBody(reasonBody)
    return unary(makeVOCAdminHost(), request, VocAdmin.Assign)
  })

  return Promise.all(promises)
}

export const unassignSites = ({ scheduledEventId, sites, userId }) => {
  const promises = sites.map((site) => {
    const request = new Proto.UnassignRequest()
    request.setScheduledEventId(scheduledEventId)
    request.setUserId(userId)
    request.setFacilityId(site.facilityId)
    return unary(makeVOCAdminHost(), request, VocAdmin.Unassign)
  })

  return Promise.all(promises)
}

export const getSiteNotes = ({ siteId, pageLimit, pageKey }) => {
  const request = new Proto.GetFacilityNotesRequest()
  request.setFacilityId(siteId)
  if (pageLimit) {
    request.setPageLimit(pageLimit)
  }
  if (pageKey) {
    request.setPageKey(pageKey)
  }
  return unary(makeVOCAdminHost(), request, VocAdmin.GetFacilityNotes)
}
export const getSiteContactNotes = ({ scheduledEventId, userId }) => {
  const request = new Proto.GetSiteContactNotesRequest()
  request.setScheduledEventId(scheduledEventId)
  request.setSiteContactUserId(userId)
  return unary(makeVOCAdminHost(), request, VocAdmin.GetSiteContactNotes)
}

export const createTextNote = (note) => {
  const { userId, dispatchId, siteContactUserId, siteIds, body, caseTypes } =
    note
  const request = new Proto.CreateTextNoteRequest()
  const newNote = new Proto.Note()
  newNote.setUserId(userId)
  newNote.setScheduledEventId(dispatchId)
  newNote.setSiteContactUserId(siteContactUserId)
  newNote.setFacilityIdsList(siteIds)
  newNote.setBody(body)
  newNote.setCaseTypesList(caseTypes)
  request.setNote(newNote)
  return unary(makeVOCAdminHost(), request, VocAdmin.CreateTextNote)
}

export const getVoltusUsers = () => {
  return network.get(
    endpoints.markets.organizations(
      getVoltusPortfolioName({ ignoreDemo: true }),
      'members'
    )
  )
}

export const getUser = (id) => {
  return network.get(endpoints.markets.users(id))
}

export const getDispatchDetail = (scheduledEventId, portfolioId) => {
  return network.get(
    endpoints.markets.voc.scheduledEvents(scheduledEventId, portfolioId)
  )
}

export const setUserAckStatus = ({
  eventId,
  userId,
  acknowledgmentType,
  ackStatus,
}) => {
  return network.post(
    endpoints.markets.dispatches.acknowledge({
      event_id: eventId,
      user_id: userId,
      acknowledgment_type: acknowledgmentType,
      acknowledgment_status: ACK_STATUS_MAP[ackStatus],
    })
  )
}
