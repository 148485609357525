import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'
import { PatchTypes } from '../../constants'
import { selectAllUsers } from '../checkin'
import { selectDispatchId } from '../routeParams'

const rootPath = ['dispatches', 'notes']

export const selectNotes = (state) => get(state, rootPath).notes
export const selectNotesLoadingStates = (state) =>
  get(state, rootPath).loadingStates
export const selectNotesHasNextPage = (state) =>
  get(state, rootPath).hasNextPage
export const selectNotesPageKey = (state) => get(state, rootPath).pageKey
export const selectAddModalSiteData = (state) =>
  get(state, rootPath).addNoteModalData
export const selectAddModalOpen = (state) =>
  get(state, rootPath).addNoteModalOpen
export const selectActiveDispatchId = (state) =>
  get(state, rootPath).activeDispatchId

const selectSiteId = (_, props) => props.siteId
const selectUserId = (_, props) => props.userId
export const makeSelectNotes = () => {
  return createSelector(
    [
      selectNotes,
      selectAllUsers,
      selectDispatchId,
      selectActiveDispatchId,
      selectSiteId,
      selectUserId,
    ],
    (notes, users, dispatchId, activeDispatchId, siteId, userId) => {
      let selectedNotes = []

      if (!isNil(userId)) {
        selectedNotes = selectedNotes.concat(
          notes
            .filter(
              ({ data }) =>
                (data.scheduledEventId === +dispatchId ||
                  data.scheduledEventId === +activeDispatchId) &&
                data.siteContactUserId === +userId
            )
            .map((note) => ({
              ...note,
              user: users[note.data.userId],
            }))
        )
      }

      if (!isNil(siteId)) {
        selectedNotes = selectedNotes.concat(
          notes.reduce((filteredNotes, note) => {
            const { type, data } = note
            if (type === PatchTypes.ASSIGNMENT) {
              if (data.facilityId === +siteId) {
                filteredNotes.push({
                  ...note,
                  user: users[data.userId],
                  assigningUser: users[data.assigningUserId],
                })
              }
            } else if (type === PatchTypes.TEXT_NOTE) {
              if (data.facilityIdsList.includes(+siteId)) {
                filteredNotes.push({
                  ...note,
                  user: users[data.userId],
                })
              }
            } else if (type === PatchTypes.USER_DISPATCH_STATE) {
              // TODO: Not currently doing anything with the user dispatch state notes.
              // So ignore them for the moment
            } else if (type === PatchTypes.CALL) {
              if (data.facilityIdsList.includes(+siteId)) {
                const caller = data.participantsList.find(
                  ({ isPrimary }) => isPrimary === false
                )
                filteredNotes.push({
                  ...note,
                  user: users[caller.voltappId],
                })
              }
            }
            return filteredNotes
          }, [])
        )
      }

      return selectedNotes
    }
  )
}
