import { camelCase } from 'lodash'
import { AnyObject } from '@voltus/types'

export default function camelCaseJsonBody(body: string): AnyObject {
  if (!body) {
    return {}
  }
  return JSON.parse(body, function (k, v) {
    // Make sure key is a string, not sure
    // but somehow it can be a number?
    if (('' + k).includes('_')) {
      this[camelCase(k)] = v
      return
    }

    return v
  })
}
