import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'
import { LOADING_STATES } from '../../constants'
import { dedupeAndSort, transformEventStreamToNoteTypes } from '../../utils'

export const { Types: NotesTypes, Creators: NotesCreators } = createActions(
  {
    getNotesBySite: ['siteId', 'pageKey'],
    getNotesByUser: ['dispatchId', 'userId'],

    getSiteNotesSuccess: ['siteId', 'notes', 'hasNextPage', 'pageKey'],
    getSiteNotesFailure: ['dispatchId', 'siteId'],

    getUserNotesSuccess: ['userId', 'notes'],
    getUserNotesFailure: ['userId'],

    createTextNote: ['dispatchId', 'payload'],
    receiveNote: ['event'],

    openAddNoteModal: null,
    closeAddNoteModal: null,
    setAddNoteModalSite: ['site'],
    setAddNoteModalUser: ['user'],
    setActiveDispatchId: ['activeDispatchId'],
  },
  {
    prefix: 'DISPATCHES/NOTES/',
  }
)

const INITIAL_STATE = {
  notes: [],
  hasNextPage: null,
  pageKey: null,
  loadingStates: {
    sites: {},
    users: {},
  },
  addNoteModalOpen: false,
  addNoteModalData: {},
  activeDispatchId: undefined,
}

const handleOpen = produce((draft) => {
  draft.addNoteModalOpen = true
})

const handleReceiveNote = produce((draft, { event }) => {
  draft.notes = dedupeAndSort([
    // The order of this spread is important
    // this ensures that when we dedupe the notes list
    // the incoming event will be picked since it's first in the array,
    // rather than an old event that already exists
    ...transformEventStreamToNoteTypes(event),
    ...draft.notes,
  ])
})

const handleClose = produce((draft) => {
  draft.addNoteModalOpen = false
})

const handleAddNoteModalSite = produce((draft, { site }) => {
  draft.addNoteModalData = {
    site,
    user: null,
  }
})

const handleAddNoteModalUser = produce((draft, { user }) => {
  draft.addNoteModalData = {
    site: null,
    user,
  }
})

const handleGetNotesBySite = produce((draft, { siteId }) => {
  if (draft.loadingStates.sites[siteId] === LOADING_STATES.READY) {
    draft.loadingStates.sites[siteId] = LOADING_STATES.FETCHING
    return
  }

  draft.loadingStates.sites[siteId] = LOADING_STATES.LOADING
})

const handleGetNotesByUser = produce((draft, { userId }) => {
  if (draft.loadingStates.users[userId] === LOADING_STATES.READY) {
    return
  }

  draft.loadingStates.users[userId] = LOADING_STATES.LOADING
})

const handleGetSiteNotesSuccess = produce(
  (draft, { siteId, notes, hasNextPage, pageKey }) => {
    draft.notes = dedupeAndSort([...notes, ...draft.notes])
    draft.hasNextPage = hasNextPage
    draft.pageKey = pageKey
    draft.loadingStates.sites[siteId] = LOADING_STATES.READY
  }
)

const handleGetSiteNotesFailure = produce((draft, { siteId }) => {
  draft.loadingStates.sites[siteId] = LOADING_STATES.ERROR
})

const handleGetUserNotesSuccess = produce((draft, { userId, notes }) => {
  draft.notes = dedupeAndSort([...draft.notes, ...notes])
  draft.loadingStates.users[userId] = LOADING_STATES.READY
})

const handleGetUserNotesFailure = produce((draft, { userId }) => {
  draft.loadingStates.users[userId] = LOADING_STATES.ERROR
})

const handleSetActiveDispatchId = produce((draft, { activeDispatchId }) => {
  draft.activeDispatchId = activeDispatchId
})

export const notesReducer = createReducer(INITIAL_STATE, {
  [NotesTypes.GET_NOTES_BY_SITE]: handleGetNotesBySite,
  [NotesTypes.GET_NOTES_BY_USER]: handleGetNotesByUser,
  [NotesTypes.GET_SITE_NOTES_SUCCESS]: handleGetSiteNotesSuccess,
  [NotesTypes.GET_SITE_NOTES_FAILURE]: handleGetSiteNotesFailure,
  [NotesTypes.GET_USER_NOTES_SUCCESS]: handleGetUserNotesSuccess,
  [NotesTypes.GET_USER_NOTES_FAILURE]: handleGetUserNotesFailure,
  [NotesTypes.OPEN_ADD_NOTE_MODAL]: handleOpen,
  [NotesTypes.CLOSE_ADD_NOTE_MODAL]: handleClose,
  [NotesTypes.SET_ADD_NOTE_MODAL_SITE]: handleAddNoteModalSite,
  [NotesTypes.SET_ADD_NOTE_MODAL_USER]: handleAddNoteModalUser,
  [NotesTypes.RECEIVE_NOTE]: handleReceiveNote,
  [NotesTypes.SET_ACTIVE_DISPATCH_ID]: handleSetActiveDispatchId,
})
