import { flatten, sortBy, uniqBy, merge, isEqual, isNil } from 'lodash'
import * as React from 'react'
import {
  Box,
  Flex,
  SiteSwitcher,
  Accordion,
  Text,
  Badge,
  Dropdown,
  ActivityIndicator,
  Button,
  colors,
} from '@voltus/core-components'
import { Conversation } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { usePrevious } from '@voltus/utils'
import { UnreadMessageNotification } from '../../../../../../components/SiteContactChatMenu/types'
import { Contact, Dispatch, Facility, Assignment } from '../../types'
import { ExportConversationRecordsModal } from './ExportConversationRecordsModal'
import { DISPATCH_DROPDOWN_OPTIONS } from './constants'
import {
  getNumNotificationsForFacility,
  getNumNotificationsForContact,
  filterFacilitiesFromDispatches,
  filterContactsWithoutMobileNumberFromFacilities,
} from './utils'

interface Props {
  dispatches: Array<Dispatch>
  assignments: Record<number, Record<number, Assignment>>
  viewingUserId: number
  selectedContactUserId?: number
  handleSelectContact?: (contact: Contact) => void
  primaryNotifications?: Array<UnreadMessageNotification>
  secondaryNotifications?: Array<UnreadMessageNotification>
  recentConversationsOutsideDispatches?: Array<Conversation.AsObject>
  facilityIdToRecentMessageTimestampMap: { [key: number]: string }
  isLoading?: boolean
}

interface OpenedState {
  [key: number]: boolean
}

function DispatchContactMenu({
  dispatches,
  assignments,
  viewingUserId,
  selectedContactUserId,
  handleSelectContact,
  primaryNotifications = [],
  secondaryNotifications = [],
  recentConversationsOutsideDispatches = [],
  facilityIdToRecentMessageTimestampMap = {},
  isLoading,
}: Props): JSX.Element {
  const [opened, setOpened] = React.useState<OpenedState>({})
  const [selectedDispatch, setSelectedDispatch] = React.useState<{
    label: string
    value: number | string
  }>(DISPATCH_DROPDOWN_OPTIONS.ALL_DISPATCHES)

  const [
    isExportConversationRecordsModalOpen,
    setIsExportConversationRecordsModalOpen,
  ] = React.useState(false)

  const [selectedFacility, setSelectedFacility] = React.useState()

  const facilityHeaderRefs = React.useRef({})

  const dispatchOptions = React.useMemo(() => {
    return [
      DISPATCH_DROPDOWN_OPTIONS.ALL_DISPATCHES,
      DISPATCH_DROPDOWN_OPTIONS.ACTIVE_DISPATCHES,
      DISPATCH_DROPDOWN_OPTIONS.PAST_DISPATCHES,
      DISPATCH_DROPDOWN_OPTIONS.CLAIMED_SITES,
      ...dispatches.map((d) => ({
        value: d.id,
        label: d.programName,
      })),
    ]
  }, [dispatches])

  const allNotifications = React.useMemo(
    () => [...primaryNotifications, ...secondaryNotifications],
    [primaryNotifications, secondaryNotifications]
  )

  const facilities = React.useMemo((): Array<Facility> => {
    const filteredFacilities = filterFacilitiesFromDispatches(
      selectedDispatch,
      dispatches,
      assignments,
      viewingUserId
    )

    // Needed because it's possible for multiple dispatches to contain the same facility.
    const consolidatedFacilities: Array<Facility> = Object.values(
      filteredFacilities.reduce((result, curFacility) => {
        if (result[curFacility.id]) {
          result[curFacility.id] = merge(curFacility, result[curFacility.id])
        } else {
          result[curFacility.id] = curFacility
        }
        return result
      }, {})
    )

    const facilitiesWithPastMessages = consolidatedFacilities
      .filter((facility) => facilityIdToRecentMessageTimestampMap[facility.id])
      .sort(
        (facilityA, facilityB) =>
          new Date(
            facilityIdToRecentMessageTimestampMap[facilityB.id]
          ).getTime() -
          new Date(
            facilityIdToRecentMessageTimestampMap[facilityA.id]
          ).getTime()
      )

    const facilitiesWithoutPastMessages = sortBy(
      consolidatedFacilities.filter(
        (facility) => !facilityIdToRecentMessageTimestampMap[facility.id]
      ),
      'name'
    )

    const allFacilities = facilitiesWithPastMessages.concat(
      facilitiesWithoutPastMessages
    )

    const sortedAndFilteredFacilities =
      filterContactsWithoutMobileNumberFromFacilities(allFacilities)

    // This is an object that resembles a facility, but stores all the conversations from when
    // customers text us 24 hours after their dispatch has ended.
    const recentConversationsOutsideDispatchesFacilityObject = {
      id: -1,
      name: 'Recent Conversations Outside Dispatches',
      acknowledgments: recentConversationsOutsideDispatches.map(
        (conversation) => ({
          fullName: conversation.userFullName,
          userId: conversation.userId,
        })
      ),
    }

    // Only display the "Recent Conversations Outside Dispatches" section if there are any
    // recent conversations outside of dispatches.
    if (recentConversationsOutsideDispatches.length > 0) {
      sortedAndFilteredFacilities.unshift(
        recentConversationsOutsideDispatchesFacilityObject as Facility
      )
    }

    return sortedAndFilteredFacilities
  }, [
    assignments,
    dispatches,
    facilityIdToRecentMessageTimestampMap,
    selectedDispatch,
    recentConversationsOutsideDispatches,
    viewingUserId,
  ])

  const currentFacilityIds = facilities.map((facility) => facility.id)
  const prevFacilityIds = usePrevious(currentFacilityIds)
  // Ensures that if the list of facilities changes and we have a facility opened:
  // We keep that facility opened instead of allowing the indices to shift and the incorrect facility to be opened.
  React.useEffect(() => {
    if (!isEqual(currentFacilityIds, prevFacilityIds)) {
      const prevOpenedIndex = Object.entries(opened).find(
        ([, isOpen]) => isOpen
      )?.[0]
      if (!isNil(prevOpenedIndex)) {
        const openedFacilityId = prevFacilityIds?.[prevOpenedIndex]
        const newOpenedIndex = currentFacilityIds.indexOf(openedFacilityId)
        setOpened((state) => ({
          ...state,
          [newOpenedIndex]: true,
          [prevOpenedIndex]: false,
        }))
      }
    }
  }, [currentFacilityIds, opened, prevFacilityIds])

  const contactOptions = React.useMemo(
    () =>
      uniqBy(
        flatten(
          flatten(dispatches.map((d) => d.facilities)).map(
            (f) => f.acknowledgments
          )
        ).map((c) => ({ ...c, label: c.fullName, value: c.userId })),
        (option) => option.value
      ),
    [dispatches]
  )

  const selectedContact = contactOptions.find(
    (c) => c.userId === selectedContactUserId
  )

  const handleSelectedContactChange = (
    contact: Contact,
    isFromDropdown?: boolean
  ): void => {
    if (handleSelectContact) {
      handleSelectContact(contact)
    }
    if (contact) {
      const facilityIndex = facilities.findIndex((facility) =>
        facility.acknowledgments.some((c) => c.userId === contact.userId)
      )
      const facility = facilities[facilityIndex]
      // Only open the facility and scroll to that facility if user selected contact from dropdown.
      if (isFromDropdown && facility) {
        setOpened((state) => ({
          ...state,
          [facilityIndex]: true,
        }))
        setTimeout(() => {
          facilityHeaderRefs.current[facility.id].scrollIntoView()
        }, 500)
      }
    }
  }

  const handleSelectedFacilityChange = (facility) => {
    if (facility) {
      setSelectedFacility(facility)
      const facilityIndex = facilities.findIndex(
        (fac) => fac.id === facility.value
      )
      setOpened((state) => ({
        ...state,
        [facilityIndex]: true,
      }))

      setTimeout(() => {
        facilityHeaderRefs.current[facility.value].scrollIntoView()
      }, 500)
    } else {
      setSelectedFacility(undefined)
    }
  }

  return (
    <>
      <Flex.Column position="relative" width="100%" height="100%">
        <Box position="absolute" width="100%" top={0} px={3} py={2}>
          <SiteSwitcher
            firstValue={selectedDispatch}
            firstOptions={dispatchOptions}
            onFirstValueChange={(val) => setSelectedDispatch(val)}
            isSearchable
          />
          <Box mt={3}>
            <Dropdown
              background="light"
              isClearable
              isSearchable
              placeholder="Filter Contacts"
              options={sortBy(contactOptions, 'label')}
              onChange={(contact) => {
                if (contact) {
                  handleSelectedContactChange(contact, true)
                }
              }}
              value={selectedContact}
            />
          </Box>
          <Box mt={2}>
            <Dropdown
              background="light"
              isClearable
              isSearchable
              placeholder="Filter Sites"
              options={facilities.map((facility) => ({
                label: facility.name,
                value: facility.id,
              }))}
              onChange={handleSelectedFacilityChange}
              value={selectedFacility}
            />
          </Box>
          <Box mt={2}>
            <Button
              width="100%"
              onClick={() => setIsExportConversationRecordsModalOpen(true)}
            >
              Export Conversation Records
            </Button>
          </Box>
        </Box>
        {isLoading ? (
          <Flex.FullyCentered width="100%" height="100%" flex={1}>
            <ActivityIndicator.Large />
          </Flex.FullyCentered>
        ) : (
          <Box mt={206} overflowY="scroll">
            <Accordion
              opened={opened}
              onChange={(item, index) => {
                setOpened((state) => ({
                  [index]: !state[index],
                }))
              }}
              keyPath="id"
              data={facilities}
              renderHeader={(facility) => {
                const numPrimaryNotifications = getNumNotificationsForFacility(
                  primaryNotifications,
                  facility
                )
                const numSecondaryNotifications =
                  getNumNotificationsForFacility(
                    secondaryNotifications,
                    facility
                  )
                const isPrimary = numPrimaryNotifications > 0
                const totalNotifications = isPrimary
                  ? numPrimaryNotifications + numSecondaryNotifications
                  : numSecondaryNotifications

                return (
                  <Flex.Row
                    ref={(el) => (facilityHeaderRefs.current[facility.id] = el)}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Text fontWeight="bold">{facility.name}</Text>
                    {totalNotifications > 0 && (
                      <Badge
                        bg={isPrimary ? 'reds.40' : 'golds.20'}
                        mr={2}
                        borderRadius="10px"
                      >
                        {totalNotifications}
                      </Badge>
                    )}
                  </Flex.Row>
                )
              }}
              renderContent={(facility) => {
                if (facility.acknowledgments.length < 1) {
                  return (
                    <Box p={3}>
                      <Text>
                        No contacts with mobile phone numbers available for this
                        site.
                      </Text>
                    </Box>
                  )
                }
                return facility.acknowledgments.map((contact) => {
                  const hasPrimaryNotification = primaryNotifications?.find(
                    (notification) => notification.userId === contact.userId
                  )
                  const hasSecondaryNotification = secondaryNotifications?.find(
                    (notification) => notification.userId === contact.userId
                  )

                  return (
                    <Button
                      key={contact.userId}
                      onClick={() => handleSelectedContactChange(contact)}
                      bg={
                        contact.userId === selectedContactUserId
                          ? 'grays.10'
                          : 'transparent'
                      }
                      width="100%"
                      border="none"
                      borderRadius={0}
                      css={{
                        '&:active': { background: colors.grays['10'] },
                        '&:focus': { background: colors.grays['10'] },
                        '&:hover': { background: colors.grays['10'] },
                      }}
                    >
                      <Flex.Row
                        px={3}
                        py={2}
                        cursor="pointer"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Text>{contact.fullName}</Text>
                        {(hasPrimaryNotification ||
                          hasSecondaryNotification) && (
                          <Badge
                            bg={hasPrimaryNotification ? 'reds.40' : 'golds.20'}
                            borderRadius="10px"
                          >
                            {getNumNotificationsForContact(
                              allNotifications,
                              contact
                            )}
                          </Badge>
                        )}
                      </Flex.Row>
                    </Button>
                  )
                })
              }}
            />
          </Box>
        )}
      </Flex.Column>
      <ExportConversationRecordsModal
        isOpen={isExportConversationRecordsModalOpen}
        onRequestClose={() => setIsExportConversationRecordsModalOpen(false)}
      />
    </>
  )
}

export { DispatchContactMenu }
