/**
 * Networking utility that wraps \@voltus/auth to perform
 * authenticated network requests that respond with JSON
 *
 * Defaults to converting JSON responses to camelCase
 *
 * Throws an error if we encounter an error response from the api
 * @packageDocumentation
 */

import auth from '@voltus/auth'
import { Any, AnyObject } from '@voltus/types'
import { camelCaseJsonBody, isDemoEnabled } from '@voltus/utils'
import { checkUrlInDemoConfig } from './checkUrlInDemoConfig'
import { FETCH_METHODS } from './constants'
import { config as demoConfig } from './demo-cfg'
import { handleApiResponse } from './handleApiResponse'
import { parseAndSanitizeData } from './parseAndSanitizeData'
import { FetchMethods } from './types'

/**
 * Extends native fetch options
 */
export interface NetworkOpts extends Omit<RequestInit, 'body'> {
  /**
   * Request body
   */
  body?: RequestInit['body'] | AnyObject | null
  /**
   * Don't attempt to automatically parse response data as JSON
   * Useful if you're expecting a non-json response, like plain text or a csv string
   */
  dontParse?: boolean
  /**
   * Don't automatically camelCase the response object's keys
   */
  dontCamelize?: boolean
  /**
   * Removes the automaticall applied Content-Type: application/json header
   * Useful for when you're dealing with non-json data and need to post or put
   * data with a different content type
   * For example posting a file with a multipart/form-data content type:
   * ```
   * network.post(url, { method: 'POST', body: fileData, raw: true })
   * ```
   */
  raw?: boolean
}

export type MockedEntries = {
  [key: string]: Any
}

const mocks = {}

const mock = <DataType = unknown>(uri: string): Promise<DataType> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mocks[uri])
    }, 2000)
  })
}

const makeRawFetch = (method: FetchMethods) => {
  return (url: string, opts?: RequestInit): Response => {
    return auth.fetchParity(url, {
      method,
      ...opts,
    })
  }
}

const makeFetch = (method: FetchMethods) => {
  /**
   * A wrapper around fetch that handles authentication
   *
   * Takes 1 Generic argument, which is the data type of the response
   * e.g.
   * ```
   * network.get<MyDataType>('/api/endpoint',
   *   { body: JSON.stringify(data },
   * )
   * ```
   *
   * @param uri - the uri to fetch
   * @param opts - the options to pass to fetch
   * @returns a promise that resolves to the response
   */
  return <DataType>(uri: string, opts: NetworkOpts = {}): Promise<DataType> => {
    if (__DEV__) {
      // NEVER mock a call in prod, only in dev
      // eslint-disable-next-line
      if (mocks.hasOwnProperty(uri.split('?')[0])) {
        return mock<DataType>(uri.split('?')[0])
      }

      // If we are in dev, check that the request would be allowed by demo mode.
      // If not, flag to the developer that they need to add their endpoint to
      // the demo-cfg
      checkUrlInDemoConfig(uri, window.location.pathname)
    }

    let demoHandlerCtx: AnyObject = {}
    if (isDemoEnabled()) {
      let allowed = true

      // Search for request handlers for the given demo url config
      for (const [urlRegEx, config] of Object.entries(demoConfig.api)) {
        if (new RegExp(urlRegEx).test(uri)) {
          // matched a url, now check if the method has a handler
          const handler = config[method]
          // Disallow the request if:
          // 1. there's no handler
          // 2. the handler is explicitly blocked
          // 3. there a request handler, but no response handler
          // 4. there's no response handler

          // no handle or blocked
          if (!handler || handler === 'block') {
            allowed = false
            break
          }

          if (handler === 'pass') {
            allowed = true
            break
          }

          const reqHandler = handler.req
          const resHandler = handler.res

          // no response handler, block request
          if (!resHandler) {
            allowed = false
            break
          }

          // if we have a request handler, override the uri and opts
          // from the incoming request
          if (typeof reqHandler === 'function') {
            allowed = true
            const resp = reqHandler(uri, opts)
            uri = resp.uri
            opts = resp.opts
            demoHandlerCtx = resp.ctx
          }
        }
      }

      if (!allowed) {
        return Promise.reject('This request is not allowed in demo mode.')
      }
    }

    return auth
      .fetchParity(uri, {
        method,
        ...opts,
      })
      .then((res) => handleApiResponse(res))
      .then((data) => {
        if (isDemoEnabled()) {
          return parseAndSanitizeData(uri, method, data, demoHandlerCtx)
        }
        return data
      })
      .then((data) => {
        if (opts.dontParse) {
          return data
        }
        return opts.dontCamelize ? JSON.parse(data) : camelCaseJsonBody(data)
      })
  }
}

/**
 * Networking utility that wraps \@voltus/auth to perform
 * authenticated network requests that respond with JSON
 *
 * Defaults to converting JSON responses to camelCase
 *
 * Throws an error if we encounter an error response from the api
 */
const network = {
  get: makeFetch(FETCH_METHODS.GET),
  put: makeFetch(FETCH_METHODS.PUT),
  post: makeFetch(FETCH_METHODS.POST),
  patch: makeFetch(FETCH_METHODS.PATCH),
  delete: makeFetch(FETCH_METHODS.DELETE),
  /**
   * Raw methods are available for the very rare cases where you
   * do not want any of the default behavior of the network utils
   * Raw methods do not camelize responses, do not handle errors,
   * do not parse responses to objects.
   */
  raw: {
    get: makeRawFetch(FETCH_METHODS.GET),
    put: makeRawFetch(FETCH_METHODS.PUT),
    post: makeRawFetch(FETCH_METHODS.POST),
    patch: makeRawFetch(FETCH_METHODS.PATCH),
    delete: makeRawFetch(FETCH_METHODS.DELETE),
  },
  /**
   * Useful for testing. Inject a mock response into all network
   * calls matching the given route.
   *
   * **Example**
   * ```
   * network.mockPaths({ '/api/profile/user': { some: 'fakeData' } })
   * ```
   *
   * Now if we call `network.get('/api/profile/user')` we get the mocked
   * response, and no fetch request is made.
   *
   * **Important**: mocks are NEVER respected in production builds...only during
   * during development builds. They are useful to temporarily stub
   * out network requests that are not yet fully implemented in our backend
   */
  mockPaths: (mock: MockedEntries): void => {
    Object.entries(mock).forEach(([uri, mockedResponse]) => {
      mocks[uri] = mockedResponse
    })
  },
}

export { network }
