import PropTypes from 'prop-types'

export const requireOneOf =
  (requiredProps, types) => (props, propName, componentName) => {
    const existingProps = requiredProps.filter(
      (name) => props[name] !== undefined
    )
    if (existingProps.length !== 1) {
      const givenProps = !existingProps.length
        ? 'None'
        : `['${existingProps.join("', '")}']`
      return new Error(
        `Only one of ['${requiredProps.join(
          "', '"
        )}'] is required by '${componentName}' component. ${givenProps} given.`
      )
    }

    if (props[propName] !== undefined) {
      return PropTypes.checkPropTypes(
        { [propName]: types },
        props,
        propName,
        componentName
      )
    }
    return null
  }
export const DispatchesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    authorized: PropTypes.bool.isRequired,
    dispatchId: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
    endTime: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    eventType: PropTypes.oneOf([
      'voltus_acceptance_test',
      'market_dispatch',
      'market_voluntary_dispatch',
      'market_curtailment_test',
    ]),
    program: PropTypes.shape({
      name: PropTypes.string.isRequired,
      phoneNumber: PropTypes.string,
      programId: PropTypes.number,
      programMarket: PropTypes.string,
      programType: PropTypes.string,
      smsSender: PropTypes.string,
      timezone: PropTypes.string,
    }),
    portfolios: PropTypes.arrayOf(
      PropTypes.shape({
        acknowledgmentsByFacilityMap: PropTypes.array,
        commitmentKw: PropTypes.number,
        commitmentKwh: PropTypes.number,
        id: PropTypes.number,
        name: PropTypes.string,
        potentialEarnings: PropTypes.number,
        performanceKw: PropTypes.number,
        performanceKwh: PropTypes.number,
      })
    ),
  })
)
