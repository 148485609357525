// Implementation adapted from:
// https://stackoverflow.com/questions/51086688/mutex-in-javascript-does-this-look-like-a-correct-implementation

/*
  Implements a mutex in plain JavaScript using promises.
*/
export class Mutex {
  constructor() {
    // Keeps track of the previous requester in the lock
    // Default to a resolved state so the next requester can come in
    this._previous = Promise.resolve()
  }

  // Returns a key that must be called after synchronized code is complete
  lock() {
    // Private variable that will be a "key" to free the current requester
    let _unlockNewPromise

    // Create a promise for the current requester
    const newPromise = new Promise((resolve) => {
      _unlockNewPromise = () => resolve()
    })

    // Create a promise for the current requester that gives it the key to
    // its spot. Attach it to the previous requester, so the current one
    // does not get their key until the previous requester is done (previous
    // is resolved)
    const getKeyPromise = this._previous.then(() => _unlockNewPromise)

    // Update the previous requester to be the current requester
    this._previous = newPromise

    // Return the promise that will give the key once the previous requester
    // is complete (lock is free)
    return getKeyPromise
  }
}
