/**
 * A piece of global state that can be used to trigger refreshes of data downstream
 * by refetching (or acting in some way) based on the key stored in state here.
 *
 * The state value is just a timestamp that represents the last time the state was
 * updated. When you call the setter returned from `useRefreshDispatch()`, the
 * timestamp is updated to the value of Date.now()
 *
 * This allows for things like some nested button to update the refresh value, which
 * will can then get picked up by queries that rely on this refresh key as a trigger
 * to re-fetch the data.
 *
 * You must wrap your application with the provider `<RefreshKeyProvider>`
 *
 * Then in your app you can use:
 * ```tsx
 * import { useRefreshKey } from '@voltus/context'
 *
 * const MyComponent = () => {
 *   const [refreshKey, setRefreshKey] = useRefreshKey()
 *
 *   return (
 *     <>
 *       <Button onClick={() => setRefreshKey()}>Set Refresh Key</Button>
 *       <Text>Refresh Key: {refreshKey}</Text>
 *     </>
 *   )
 * }
 * ```
 *
 * @packageDocumentation
 */

import * as React from 'react'

type setRefreshKey = React.Dispatch<React.SetStateAction<number>>
const setStateNoop: setRefreshKey = () => null

const RefreshKeyStateContext = React.createContext(Date.now())
const RefreshKeyDispatchContext = React.createContext(setStateNoop)

type RefreshStateProviderProps = {
  children: React.ReactNode
}

/**
 * Context Provider.
 *
 * To use the refresh key you must wrap your app with this provider.
 *
 * ```tsx
 * import { RefreshKeyProvider } from '@voltus/context'
 *
 * const MyApp = () => {
 *   return (
 *     <RefreshKeyProvider>
 *       <App/>
 *     </RefreshKeyProvider>
 *   )
 * }
 * ```
 *
 */
export const RefreshKeyProvider = ({
  children,
}: RefreshStateProviderProps): JSX.Element => {
  const [refreshKey, setRefreshKey] = React.useState(Date.now())
  return (
    <RefreshKeyStateContext.Provider value={refreshKey}>
      <RefreshKeyDispatchContext.Provider value={setRefreshKey}>
        {children}
      </RefreshKeyDispatchContext.Provider>
    </RefreshKeyStateContext.Provider>
  )
}

/**
 * A custom hook to access the refresh state directly.
 * @returns number - the current refresh key state
 */
export const useRefreshState = (): number =>
  React.useContext(RefreshKeyStateContext)

/**
 * A custom hook to update the refresh key state
 *
 * When called, this will set the refresh key state to the curren time - i.e. Date.now()
 *
 * @returns setRefreshKey - a function that when called, updates the refresh key to Date.now()
 */
export const useRefreshDispatch = (): (() => void) => {
  const setter = React.useContext(RefreshKeyDispatchContext)
  return () => setter(Date.now())
}

/**
 * A convenience export to access both the refresh key, and the setter function
 * in one hook
 *
 * Use like
 * ```tsx
 * import { useRefreshKey } from '@voltus/context'
 *
 * const MyComponent = () => {
 *   const [refreshKey, setRefreshKey] = useRefreshKey()
 *   // use the values below
 * }
 * ```
 *
 * @returns [number, setRefreshKey] - the current refresh key state, and a function to update it
 */
export const useRefreshKey = (): [number, () => void] => [
  useRefreshState(),
  useRefreshDispatch(),
]
