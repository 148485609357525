import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import createSagaInjector from './createSagaInjector'

/**
 *
 * Create a redux store during the bootstrapping of an application.
 * This should only ever be called once at the start of an app.
 * It also adds two methods to the store to allow for async loading of reducers and sagas:
 *  1. store.injectReducer
 *  2. store.injectSaga
 *
 * @param {Object} reducers - the static reducers requried for application startup
 * @param {Function|null} rootSaga - the root saga for an application
 * @param {*} initialState - the initial state to pass to the store
 * @returns {Object} store - Redux store with additional methods
 */
export function configureStore(reducers, rootSaga = null, initialState) {
  const sagaMiddleware = createSagaMiddleware()
  function createReducer(asyncReducers) {
    return combineReducers({
      ...reducers,
      ...asyncReducers,
    })
  }
  const middlewares = [sagaMiddleware]

  const options = {
    serialize: true,
  }
  const composeEnhancers = composeWithDevTools(options)

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  )

  if (rootSaga) {
    // Add injectSaga method to our store
    store.injectSaga = createSagaInjector(sagaMiddleware.run)

    // Inject the root saga
    store.injectSaga('root', rootSaga)
  }

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {}

  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))
  }

  return store
}
