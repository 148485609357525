// source: voc_admin.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

goog.exportSymbol('proto.voc_admin.AssignRequest', null, global)
goog.exportSymbol('proto.voc_admin.AssignResponse', null, global)
goog.exportSymbol('proto.voc_admin.Assignment', null, global)
goog.exportSymbol('proto.voc_admin.Call', null, global)
goog.exportSymbol('proto.voc_admin.CallParticipant', null, global)
goog.exportSymbol('proto.voc_admin.CheckInRequest', null, global)
goog.exportSymbol('proto.voc_admin.CheckInResponse', null, global)
goog.exportSymbol('proto.voc_admin.CheckOutRequest', null, global)
goog.exportSymbol('proto.voc_admin.CheckOutResponse', null, global)
goog.exportSymbol('proto.voc_admin.CreateTextNoteRequest', null, global)
goog.exportSymbol('proto.voc_admin.CreateTextNoteResponse', null, global)
goog.exportSymbol('proto.voc_admin.Event', null, global)
goog.exportSymbol('proto.voc_admin.EventStreamRequest', null, global)
goog.exportSymbol('proto.voc_admin.EventStreamResponse', null, global)
goog.exportSymbol('proto.voc_admin.GetAssignmentsRequest', null, global)
goog.exportSymbol('proto.voc_admin.GetAssignmentsResponse', null, global)
goog.exportSymbol('proto.voc_admin.GetCheckInsRequest', null, global)
goog.exportSymbol('proto.voc_admin.GetCheckInsResponse', null, global)
goog.exportSymbol('proto.voc_admin.GetDispatchCallsRequest', null, global)
goog.exportSymbol('proto.voc_admin.GetDispatchCallsResponse', null, global)
goog.exportSymbol('proto.voc_admin.GetFacilityNotesRequest', null, global)
goog.exportSymbol('proto.voc_admin.GetFacilityNotesResponse', null, global)
goog.exportSymbol('proto.voc_admin.GetSiteContactNotesRequest', null, global)
goog.exportSymbol('proto.voc_admin.GetSiteContactNotesResponse', null, global)
goog.exportSymbol('proto.voc_admin.Note', null, global)
goog.exportSymbol('proto.voc_admin.OutboundCallRequest', null, global)
goog.exportSymbol('proto.voc_admin.OutboundCallResponse', null, global)
goog.exportSymbol('proto.voc_admin.Pagination', null, global)
goog.exportSymbol('proto.voc_admin.UnassignRequest', null, global)
goog.exportSymbol('proto.voc_admin.UnassignResponse', null, global)
goog.exportSymbol('proto.voc_admin.UserDispatchState', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CheckInRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CheckInRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CheckInRequest.displayName = 'proto.voc_admin.CheckInRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CheckInResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CheckInResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CheckInResponse.displayName =
    'proto.voc_admin.CheckInResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.UserDispatchState = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.UserDispatchState, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.UserDispatchState.displayName =
    'proto.voc_admin.UserDispatchState'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CheckOutRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CheckOutRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CheckOutRequest.displayName =
    'proto.voc_admin.CheckOutRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CheckOutResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CheckOutResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CheckOutResponse.displayName =
    'proto.voc_admin.CheckOutResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.AssignRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.AssignRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.AssignRequest.displayName = 'proto.voc_admin.AssignRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.AssignResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.AssignResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.AssignResponse.displayName = 'proto.voc_admin.AssignResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.Assignment = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.Assignment, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.Assignment.displayName = 'proto.voc_admin.Assignment'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.UnassignRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.UnassignRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.UnassignRequest.displayName =
    'proto.voc_admin.UnassignRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.UnassignResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.UnassignResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.UnassignResponse.displayName =
    'proto.voc_admin.UnassignResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetAssignmentsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.GetAssignmentsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetAssignmentsRequest.displayName =
    'proto.voc_admin.GetAssignmentsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetAssignmentsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.GetAssignmentsResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.GetAssignmentsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetAssignmentsResponse.displayName =
    'proto.voc_admin.GetAssignmentsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetCheckInsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.GetCheckInsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetCheckInsRequest.displayName =
    'proto.voc_admin.GetCheckInsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetCheckInsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.GetCheckInsResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.GetCheckInsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetCheckInsResponse.displayName =
    'proto.voc_admin.GetCheckInsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CreateTextNoteRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CreateTextNoteRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CreateTextNoteRequest.displayName =
    'proto.voc_admin.CreateTextNoteRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.Note = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.Note.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.Note, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.Note.displayName = 'proto.voc_admin.Note'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CreateTextNoteResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CreateTextNoteResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CreateTextNoteResponse.displayName =
    'proto.voc_admin.CreateTextNoteResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetFacilityNotesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.GetFacilityNotesRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetFacilityNotesRequest.displayName =
    'proto.voc_admin.GetFacilityNotesRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetFacilityNotesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.GetFacilityNotesResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.GetFacilityNotesResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetFacilityNotesResponse.displayName =
    'proto.voc_admin.GetFacilityNotesResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.Event = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.Event, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.Event.displayName = 'proto.voc_admin.Event'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.Call = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.Call.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.Call, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.Call.displayName = 'proto.voc_admin.Call'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.CallParticipant = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.CallParticipant, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.CallParticipant.displayName =
    'proto.voc_admin.CallParticipant'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.Pagination = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.Pagination, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.Pagination.displayName = 'proto.voc_admin.Pagination'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetSiteContactNotesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.GetSiteContactNotesRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetSiteContactNotesRequest.displayName =
    'proto.voc_admin.GetSiteContactNotesRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetSiteContactNotesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.GetSiteContactNotesResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.GetSiteContactNotesResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetSiteContactNotesResponse.displayName =
    'proto.voc_admin.GetSiteContactNotesResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.EventStreamRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.EventStreamRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.EventStreamRequest.displayName =
    'proto.voc_admin.EventStreamRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.EventStreamResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.EventStreamResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.EventStreamResponse.displayName =
    'proto.voc_admin.EventStreamResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.OutboundCallRequest = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.OutboundCallRequest.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.OutboundCallRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.OutboundCallRequest.displayName =
    'proto.voc_admin.OutboundCallRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.OutboundCallResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.OutboundCallResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.OutboundCallResponse.displayName =
    'proto.voc_admin.OutboundCallResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetDispatchCallsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.voc_admin.GetDispatchCallsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetDispatchCallsRequest.displayName =
    'proto.voc_admin.GetDispatchCallsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.voc_admin.GetDispatchCallsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.voc_admin.GetDispatchCallsResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.voc_admin.GetDispatchCallsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.voc_admin.GetDispatchCallsResponse.displayName =
    'proto.voc_admin.GetDispatchCallsResponse'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CheckInRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CheckInRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CheckInRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CheckInRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CheckInRequest}
 */
proto.voc_admin.CheckInRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CheckInRequest()
  return proto.voc_admin.CheckInRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CheckInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CheckInRequest}
 */
proto.voc_admin.CheckInRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CheckInRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CheckInRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CheckInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CheckInRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.CheckInRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CheckInRequest} returns this
 */
proto.voc_admin.CheckInRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.voc_admin.CheckInRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CheckInRequest} returns this
 */
proto.voc_admin.CheckInRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 scheduled_event_id = 3;
 * @return {number}
 */
proto.voc_admin.CheckInRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CheckInRequest} returns this
 */
proto.voc_admin.CheckInRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CheckInResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CheckInResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CheckInResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CheckInResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userDispatchState:
          (f = msg.getUserDispatchState()) &&
          proto.voc_admin.UserDispatchState.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CheckInResponse}
 */
proto.voc_admin.CheckInResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CheckInResponse()
  return proto.voc_admin.CheckInResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CheckInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CheckInResponse}
 */
proto.voc_admin.CheckInResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.UserDispatchState()
        reader.readMessage(
          value,
          proto.voc_admin.UserDispatchState.deserializeBinaryFromReader
        )
        msg.setUserDispatchState(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CheckInResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CheckInResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CheckInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CheckInResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserDispatchState()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.voc_admin.UserDispatchState.serializeBinaryToWriter
    )
  }
}

/**
 * optional UserDispatchState user_dispatch_state = 1;
 * @return {?proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.CheckInResponse.prototype.getUserDispatchState = function () {
  return /** @type{?proto.voc_admin.UserDispatchState} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.UserDispatchState, 1)
  )
}

/**
 * @param {?proto.voc_admin.UserDispatchState|undefined} value
 * @return {!proto.voc_admin.CheckInResponse} returns this
 */
proto.voc_admin.CheckInResponse.prototype.setUserDispatchState = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.CheckInResponse} returns this
 */
proto.voc_admin.CheckInResponse.prototype.clearUserDispatchState = function () {
  return this.setUserDispatchState(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.CheckInResponse.prototype.hasUserDispatchState = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.UserDispatchState.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.UserDispatchState.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.UserDispatchState} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.UserDispatchState.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 5, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        isCheckIn: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        createdAt: jspb.Message.getFieldWithDefault(msg, 4, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.UserDispatchState.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.UserDispatchState()
  return proto.voc_admin.UserDispatchState.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.UserDispatchState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.UserDispatchState.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 5:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 3:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsCheckIn(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.UserDispatchState.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.UserDispatchState.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.UserDispatchState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.UserDispatchState.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(5, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getIsCheckIn()
  if (f) {
    writer.writeBool(3, f)
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional sint32 id = 5;
 * @return {number}
 */
proto.voc_admin.UserDispatchState.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UserDispatchState} returns this
 */
proto.voc_admin.UserDispatchState.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value)
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.voc_admin.UserDispatchState.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UserDispatchState} returns this
 */
proto.voc_admin.UserDispatchState.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.UserDispatchState.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UserDispatchState} returns this
 */
proto.voc_admin.UserDispatchState.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional bool is_check_in = 3;
 * @return {boolean}
 */
proto.voc_admin.UserDispatchState.prototype.getIsCheckIn = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.UserDispatchState} returns this
 */
proto.voc_admin.UserDispatchState.prototype.setIsCheckIn = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value)
}

/**
 * optional string created_at = 4;
 * @return {string}
 */
proto.voc_admin.UserDispatchState.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.UserDispatchState} returns this
 */
proto.voc_admin.UserDispatchState.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CheckOutRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CheckOutRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CheckOutRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CheckOutRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CheckOutRequest}
 */
proto.voc_admin.CheckOutRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CheckOutRequest()
  return proto.voc_admin.CheckOutRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CheckOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CheckOutRequest}
 */
proto.voc_admin.CheckOutRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CheckOutRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CheckOutRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CheckOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CheckOutRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.CheckOutRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CheckOutRequest} returns this
 */
proto.voc_admin.CheckOutRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.voc_admin.CheckOutRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CheckOutRequest} returns this
 */
proto.voc_admin.CheckOutRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 scheduled_event_id = 3;
 * @return {number}
 */
proto.voc_admin.CheckOutRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CheckOutRequest} returns this
 */
proto.voc_admin.CheckOutRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CheckOutResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CheckOutResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CheckOutResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CheckOutResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userDispatchState:
          (f = msg.getUserDispatchState()) &&
          proto.voc_admin.UserDispatchState.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CheckOutResponse}
 */
proto.voc_admin.CheckOutResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CheckOutResponse()
  return proto.voc_admin.CheckOutResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CheckOutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CheckOutResponse}
 */
proto.voc_admin.CheckOutResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.UserDispatchState()
        reader.readMessage(
          value,
          proto.voc_admin.UserDispatchState.deserializeBinaryFromReader
        )
        msg.setUserDispatchState(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CheckOutResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CheckOutResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CheckOutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CheckOutResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserDispatchState()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.voc_admin.UserDispatchState.serializeBinaryToWriter
    )
  }
}

/**
 * optional UserDispatchState user_dispatch_state = 1;
 * @return {?proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.CheckOutResponse.prototype.getUserDispatchState = function () {
  return /** @type{?proto.voc_admin.UserDispatchState} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.UserDispatchState, 1)
  )
}

/**
 * @param {?proto.voc_admin.UserDispatchState|undefined} value
 * @return {!proto.voc_admin.CheckOutResponse} returns this
 */
proto.voc_admin.CheckOutResponse.prototype.setUserDispatchState = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.CheckOutResponse} returns this
 */
proto.voc_admin.CheckOutResponse.prototype.clearUserDispatchState =
  function () {
    return this.setUserDispatchState(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.CheckOutResponse.prototype.hasUserDispatchState = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.AssignRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.AssignRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.AssignRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.AssignRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
        isEscalation: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
        reasonBody: jspb.Message.getFieldWithDefault(msg, 5, ''),
        reason: jspb.Message.getFieldWithDefault(msg, 6, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.AssignRequest}
 */
proto.voc_admin.AssignRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.AssignRequest()
  return proto.voc_admin.AssignRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.AssignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.AssignRequest}
 */
proto.voc_admin.AssignRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 7:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsEscalation(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setReasonBody(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setReason(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.AssignRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.AssignRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.AssignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.AssignRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(4, f)
  }
  f = message.getIsEscalation()
  if (f) {
    writer.writeBool(7, f)
  }
  f = message.getReasonBody()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getReason()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.AssignRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.voc_admin.AssignRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 facility_id = 3;
 * @return {number}
 */
proto.voc_admin.AssignRequest.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional sint32 scheduled_event_id = 4;
 * @return {number}
 */
proto.voc_admin.AssignRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setScheduledEventId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional bool is_escalation = 7;
 * @return {boolean}
 */
proto.voc_admin.AssignRequest.prototype.getIsEscalation = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 7, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setIsEscalation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value)
}

/**
 * optional string reason_body = 5;
 * @return {string}
 */
proto.voc_admin.AssignRequest.prototype.getReasonBody = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setReasonBody = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string reason = 6;
 * @return {string}
 */
proto.voc_admin.AssignRequest.prototype.getReason = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.AssignRequest} returns this
 */
proto.voc_admin.AssignRequest.prototype.setReason = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.AssignResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.AssignResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.AssignResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.AssignResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        assignment:
          (f = msg.getAssignment()) &&
          proto.voc_admin.Assignment.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.AssignResponse}
 */
proto.voc_admin.AssignResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.AssignResponse()
  return proto.voc_admin.AssignResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.AssignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.AssignResponse}
 */
proto.voc_admin.AssignResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Assignment()
        reader.readMessage(
          value,
          proto.voc_admin.Assignment.deserializeBinaryFromReader
        )
        msg.setAssignment(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.AssignResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.AssignResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.AssignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.AssignResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAssignment()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.voc_admin.Assignment.serializeBinaryToWriter
    )
  }
}

/**
 * optional Assignment assignment = 1;
 * @return {?proto.voc_admin.Assignment}
 */
proto.voc_admin.AssignResponse.prototype.getAssignment = function () {
  return /** @type{?proto.voc_admin.Assignment} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Assignment, 1)
  )
}

/**
 * @param {?proto.voc_admin.Assignment|undefined} value
 * @return {!proto.voc_admin.AssignResponse} returns this
 */
proto.voc_admin.AssignResponse.prototype.setAssignment = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.AssignResponse} returns this
 */
proto.voc_admin.AssignResponse.prototype.clearAssignment = function () {
  return this.setAssignment(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.AssignResponse.prototype.hasAssignment = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.Assignment.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.Assignment.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.Assignment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.Assignment.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
        isAssignment: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        isEscalation: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
        reason: jspb.Message.getFieldWithDefault(msg, 7, ''),
        reasonBody: jspb.Message.getFieldWithDefault(msg, 6, ''),
        createdAt: jspb.Message.getFieldWithDefault(msg, 8, ''),
        assigningUserId: jspb.Message.getFieldWithDefault(msg, 10, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.Assignment}
 */
proto.voc_admin.Assignment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.Assignment()
  return proto.voc_admin.Assignment.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.Assignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.Assignment}
 */
proto.voc_admin.Assignment.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 5:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsAssignment(value)
        break
      case 9:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsEscalation(value)
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.setReason(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setReasonBody(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      case 10:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setAssigningUserId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.Assignment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.Assignment.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.Assignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.Assignment.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(4, f)
  }
  f = message.getIsAssignment()
  if (f) {
    writer.writeBool(5, f)
  }
  f = message.getIsEscalation()
  if (f) {
    writer.writeBool(9, f)
  }
  f = message.getReason()
  if (f.length > 0) {
    writer.writeString(7, f)
  }
  f = message.getReasonBody()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
  f = message.getAssigningUserId()
  if (f !== 0) {
    writer.writeSint32(10, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.voc_admin.Assignment.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.voc_admin.Assignment.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 facility_id = 3;
 * @return {number}
 */
proto.voc_admin.Assignment.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional sint32 scheduled_event_id = 4;
 * @return {number}
 */
proto.voc_admin.Assignment.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setScheduledEventId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional bool is_assignment = 5;
 * @return {boolean}
 */
proto.voc_admin.Assignment.prototype.getIsAssignment = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setIsAssignment = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value)
}

/**
 * optional bool is_escalation = 9;
 * @return {boolean}
 */
proto.voc_admin.Assignment.prototype.getIsEscalation = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 9, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setIsEscalation = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value)
}

/**
 * optional string reason = 7;
 * @return {string}
 */
proto.voc_admin.Assignment.prototype.getReason = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setReason = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional string reason_body = 6;
 * @return {string}
 */
proto.voc_admin.Assignment.prototype.getReasonBody = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setReasonBody = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string created_at = 8;
 * @return {string}
 */
proto.voc_admin.Assignment.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional sint32 assigning_user_id = 10;
 * @return {number}
 */
proto.voc_admin.Assignment.prototype.getAssigningUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Assignment} returns this
 */
proto.voc_admin.Assignment.prototype.setAssigningUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 10, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.UnassignRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.UnassignRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.UnassignRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.UnassignRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 4, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.UnassignRequest}
 */
proto.voc_admin.UnassignRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.UnassignRequest()
  return proto.voc_admin.UnassignRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.UnassignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.UnassignRequest}
 */
proto.voc_admin.UnassignRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.UnassignRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.UnassignRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.UnassignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.UnassignRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(4, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.UnassignRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.UnassignRequest} returns this
 */
proto.voc_admin.UnassignRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.voc_admin.UnassignRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UnassignRequest} returns this
 */
proto.voc_admin.UnassignRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 facility_id = 3;
 * @return {number}
 */
proto.voc_admin.UnassignRequest.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UnassignRequest} returns this
 */
proto.voc_admin.UnassignRequest.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional sint32 scheduled_event_id = 4;
 * @return {number}
 */
proto.voc_admin.UnassignRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.UnassignRequest} returns this
 */
proto.voc_admin.UnassignRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.UnassignResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.UnassignResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.UnassignResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.UnassignResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        assignment:
          (f = msg.getAssignment()) &&
          proto.voc_admin.Assignment.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.UnassignResponse}
 */
proto.voc_admin.UnassignResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.UnassignResponse()
  return proto.voc_admin.UnassignResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.UnassignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.UnassignResponse}
 */
proto.voc_admin.UnassignResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Assignment()
        reader.readMessage(
          value,
          proto.voc_admin.Assignment.deserializeBinaryFromReader
        )
        msg.setAssignment(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.UnassignResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.UnassignResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.UnassignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.UnassignResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAssignment()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.voc_admin.Assignment.serializeBinaryToWriter
    )
  }
}

/**
 * optional Assignment assignment = 1;
 * @return {?proto.voc_admin.Assignment}
 */
proto.voc_admin.UnassignResponse.prototype.getAssignment = function () {
  return /** @type{?proto.voc_admin.Assignment} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Assignment, 1)
  )
}

/**
 * @param {?proto.voc_admin.Assignment|undefined} value
 * @return {!proto.voc_admin.UnassignResponse} returns this
 */
proto.voc_admin.UnassignResponse.prototype.setAssignment = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.UnassignResponse} returns this
 */
proto.voc_admin.UnassignResponse.prototype.clearAssignment = function () {
  return this.setAssignment(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.UnassignResponse.prototype.hasAssignment = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetAssignmentsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetAssignmentsRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetAssignmentsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetAssignmentsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetAssignmentsRequest}
 */
proto.voc_admin.GetAssignmentsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetAssignmentsRequest()
  return proto.voc_admin.GetAssignmentsRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetAssignmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetAssignmentsRequest}
 */
proto.voc_admin.GetAssignmentsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetAssignmentsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.GetAssignmentsRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetAssignmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetAssignmentsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.GetAssignmentsRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetAssignmentsRequest} returns this
 */
proto.voc_admin.GetAssignmentsRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.GetAssignmentsRequest.prototype.getScheduledEventId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetAssignmentsRequest} returns this
 */
proto.voc_admin.GetAssignmentsRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.GetAssignmentsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetAssignmentsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetAssignmentsResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetAssignmentsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetAssignmentsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        assignmentsList: jspb.Message.toObjectList(
          msg.getAssignmentsList(),
          proto.voc_admin.Assignment.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetAssignmentsResponse}
 */
proto.voc_admin.GetAssignmentsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetAssignmentsResponse()
  return proto.voc_admin.GetAssignmentsResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetAssignmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetAssignmentsResponse}
 */
proto.voc_admin.GetAssignmentsResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Assignment()
        reader.readMessage(
          value,
          proto.voc_admin.Assignment.deserializeBinaryFromReader
        )
        msg.addAssignments(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetAssignmentsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.GetAssignmentsResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetAssignmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetAssignmentsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAssignmentsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.voc_admin.Assignment.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Assignment assignments = 1;
 * @return {!Array<!proto.voc_admin.Assignment>}
 */
proto.voc_admin.GetAssignmentsResponse.prototype.getAssignmentsList =
  function () {
    return /** @type{!Array<!proto.voc_admin.Assignment>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.voc_admin.Assignment, 1)
    )
  }

/**
 * @param {!Array<!proto.voc_admin.Assignment>} value
 * @return {!proto.voc_admin.GetAssignmentsResponse} returns this
 */
proto.voc_admin.GetAssignmentsResponse.prototype.setAssignmentsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.voc_admin.Assignment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Assignment}
 */
proto.voc_admin.GetAssignmentsResponse.prototype.addAssignments = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.voc_admin.Assignment,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.GetAssignmentsResponse} returns this
 */
proto.voc_admin.GetAssignmentsResponse.prototype.clearAssignmentsList =
  function () {
    return this.setAssignmentsList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetCheckInsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetCheckInsRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetCheckInsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetCheckInsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetCheckInsRequest}
 */
proto.voc_admin.GetCheckInsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetCheckInsRequest()
  return proto.voc_admin.GetCheckInsRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetCheckInsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetCheckInsRequest}
 */
proto.voc_admin.GetCheckInsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetCheckInsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.GetCheckInsRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetCheckInsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetCheckInsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.GetCheckInsRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetCheckInsRequest} returns this
 */
proto.voc_admin.GetCheckInsRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.GetCheckInsRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetCheckInsRequest} returns this
 */
proto.voc_admin.GetCheckInsRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.GetCheckInsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetCheckInsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetCheckInsResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetCheckInsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetCheckInsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userDispatchStatesList: jspb.Message.toObjectList(
          msg.getUserDispatchStatesList(),
          proto.voc_admin.UserDispatchState.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetCheckInsResponse}
 */
proto.voc_admin.GetCheckInsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetCheckInsResponse()
  return proto.voc_admin.GetCheckInsResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetCheckInsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetCheckInsResponse}
 */
proto.voc_admin.GetCheckInsResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.UserDispatchState()
        reader.readMessage(
          value,
          proto.voc_admin.UserDispatchState.deserializeBinaryFromReader
        )
        msg.addUserDispatchStates(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetCheckInsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.GetCheckInsResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetCheckInsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetCheckInsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserDispatchStatesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.voc_admin.UserDispatchState.serializeBinaryToWriter
    )
  }
}

/**
 * repeated UserDispatchState user_dispatch_states = 1;
 * @return {!Array<!proto.voc_admin.UserDispatchState>}
 */
proto.voc_admin.GetCheckInsResponse.prototype.getUserDispatchStatesList =
  function () {
    return /** @type{!Array<!proto.voc_admin.UserDispatchState>} */ (
      jspb.Message.getRepeatedWrapperField(
        this,
        proto.voc_admin.UserDispatchState,
        1
      )
    )
  }

/**
 * @param {!Array<!proto.voc_admin.UserDispatchState>} value
 * @return {!proto.voc_admin.GetCheckInsResponse} returns this
 */
proto.voc_admin.GetCheckInsResponse.prototype.setUserDispatchStatesList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value)
  }

/**
 * @param {!proto.voc_admin.UserDispatchState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.GetCheckInsResponse.prototype.addUserDispatchStates = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.voc_admin.UserDispatchState,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.GetCheckInsResponse} returns this
 */
proto.voc_admin.GetCheckInsResponse.prototype.clearUserDispatchStatesList =
  function () {
    return this.setUserDispatchStatesList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CreateTextNoteRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CreateTextNoteRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CreateTextNoteRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CreateTextNoteRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        note:
          (f = msg.getNote()) &&
          proto.voc_admin.Note.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CreateTextNoteRequest}
 */
proto.voc_admin.CreateTextNoteRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CreateTextNoteRequest()
  return proto.voc_admin.CreateTextNoteRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CreateTextNoteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CreateTextNoteRequest}
 */
proto.voc_admin.CreateTextNoteRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = new proto.voc_admin.Note()
        reader.readMessage(
          value,
          proto.voc_admin.Note.deserializeBinaryFromReader
        )
        msg.setNote(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CreateTextNoteRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CreateTextNoteRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CreateTextNoteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CreateTextNoteRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getNote()
  if (f != null) {
    writer.writeMessage(2, f, proto.voc_admin.Note.serializeBinaryToWriter)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.CreateTextNoteRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CreateTextNoteRequest} returns this
 */
proto.voc_admin.CreateTextNoteRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional Note note = 2;
 * @return {?proto.voc_admin.Note}
 */
proto.voc_admin.CreateTextNoteRequest.prototype.getNote = function () {
  return /** @type{?proto.voc_admin.Note} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Note, 2)
  )
}

/**
 * @param {?proto.voc_admin.Note|undefined} value
 * @return {!proto.voc_admin.CreateTextNoteRequest} returns this
 */
proto.voc_admin.CreateTextNoteRequest.prototype.setNote = function (value) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.CreateTextNoteRequest} returns this
 */
proto.voc_admin.CreateTextNoteRequest.prototype.clearNote = function () {
  return this.setNote(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.CreateTextNoteRequest.prototype.hasNote = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.Note.repeatedFields_ = [4, 8]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.Note.prototype.toObject = function (opt_includeInstance) {
    return proto.voc_admin.Note.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.Note} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.Note.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 7, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        siteContactUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        facilityIdsList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        body: jspb.Message.getFieldWithDefault(msg, 5, ''),
        createdAt: jspb.Message.getFieldWithDefault(msg, 6, ''),
        caseTypesList:
          (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.Note}
 */
proto.voc_admin.Note.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.Note()
  return proto.voc_admin.Note.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.Note} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.Note}
 */
proto.voc_admin.Note.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 7:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setSiteContactUserId(value)
        break
      case 4:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited()
            ? reader.readPackedSint32()
            : [reader.readSint32()]
        )
        for (var i = 0; i < values.length; i++) {
          msg.addFacilityIds(values[i])
        }
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setBody(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.addCaseTypes(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.Note.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.Note.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.Note} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.Note.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(7, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getSiteContactUserId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getFacilityIdsList()
  if (f.length > 0) {
    writer.writePackedSint32(4, f)
  }
  f = message.getBody()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getCaseTypesList()
  if (f.length > 0) {
    writer.writeRepeatedString(8, f)
  }
}

/**
 * optional sint32 id = 7;
 * @return {number}
 */
proto.voc_admin.Note.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value)
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.voc_admin.Note.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.Note.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setScheduledEventId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 site_contact_user_id = 3;
 * @return {number}
 */
proto.voc_admin.Note.prototype.getSiteContactUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setSiteContactUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * repeated sint32 facility_ids = 4;
 * @return {!Array<number>}
 */
proto.voc_admin.Note.prototype.getFacilityIdsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4))
}

/**
 * @param {!Array<number>} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setFacilityIdsList = function (value) {
  return jspb.Message.setField(this, 4, value || [])
}

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.addFacilityIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.clearFacilityIdsList = function () {
  return this.setFacilityIdsList([])
}

/**
 * optional string body = 5;
 * @return {string}
 */
proto.voc_admin.Note.prototype.getBody = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setBody = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.voc_admin.Note.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * repeated string case_types = 8;
 * @return {!Array<string>}
 */
proto.voc_admin.Note.prototype.getCaseTypesList = function () {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8))
}

/**
 * @param {!Array<string>} value
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.setCaseTypesList = function (value) {
  return jspb.Message.setField(this, 8, value || [])
}

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.addCaseTypes = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.Note} returns this
 */
proto.voc_admin.Note.prototype.clearCaseTypesList = function () {
  return this.setCaseTypesList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CreateTextNoteResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CreateTextNoteResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CreateTextNoteResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CreateTextNoteResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        note:
          (f = msg.getNote()) &&
          proto.voc_admin.Note.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CreateTextNoteResponse}
 */
proto.voc_admin.CreateTextNoteResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CreateTextNoteResponse()
  return proto.voc_admin.CreateTextNoteResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CreateTextNoteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CreateTextNoteResponse}
 */
proto.voc_admin.CreateTextNoteResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Note()
        reader.readMessage(
          value,
          proto.voc_admin.Note.deserializeBinaryFromReader
        )
        msg.setNote(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CreateTextNoteResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CreateTextNoteResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CreateTextNoteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CreateTextNoteResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getNote()
  if (f != null) {
    writer.writeMessage(1, f, proto.voc_admin.Note.serializeBinaryToWriter)
  }
}

/**
 * optional Note note = 1;
 * @return {?proto.voc_admin.Note}
 */
proto.voc_admin.CreateTextNoteResponse.prototype.getNote = function () {
  return /** @type{?proto.voc_admin.Note} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Note, 1)
  )
}

/**
 * @param {?proto.voc_admin.Note|undefined} value
 * @return {!proto.voc_admin.CreateTextNoteResponse} returns this
 */
proto.voc_admin.CreateTextNoteResponse.prototype.setNote = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.CreateTextNoteResponse} returns this
 */
proto.voc_admin.CreateTextNoteResponse.prototype.clearNote = function () {
  return this.setNote(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.CreateTextNoteResponse.prototype.hasNote = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetFacilityNotesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetFacilityNotesRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetFacilityNotesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetFacilityNotesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        facilityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        pageKey: jspb.Message.getFieldWithDefault(msg, 4, ''),
        pageLimit: jspb.Message.getFieldWithDefault(msg, 5, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetFacilityNotesRequest}
 */
proto.voc_admin.GetFacilityNotesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetFacilityNotesRequest()
  return proto.voc_admin.GetFacilityNotesRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetFacilityNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetFacilityNotesRequest}
 */
proto.voc_admin.GetFacilityNotesRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setPageKey(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPageLimit(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetFacilityNotesRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetFacilityNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetFacilityNotesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getPageKey()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getPageLimit()
  if (f !== 0) {
    writer.writeSint32(5, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetFacilityNotesRequest} returns this
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 facility_id = 2;
 * @return {number}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetFacilityNotesRequest} returns this
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.setFacilityId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 scheduled_event_id = 3;
 * @return {number}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.getScheduledEventId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetFacilityNotesRequest} returns this
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.setScheduledEventId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 3, value)
  }

/**
 * optional string page_key = 4;
 * @return {string}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.getPageKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetFacilityNotesRequest} returns this
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.setPageKey = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional sint32 page_limit = 5;
 * @return {number}
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.getPageLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetFacilityNotesRequest} returns this
 */
proto.voc_admin.GetFacilityNotesRequest.prototype.setPageLimit = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 5, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.GetFacilityNotesResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetFacilityNotesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetFacilityNotesResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetFacilityNotesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetFacilityNotesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.voc_admin.Event.toObject,
          includeInstance
        ),
        pagination:
          (f = msg.getPagination()) &&
          proto.voc_admin.Pagination.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetFacilityNotesResponse}
 */
proto.voc_admin.GetFacilityNotesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetFacilityNotesResponse()
  return proto.voc_admin.GetFacilityNotesResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetFacilityNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetFacilityNotesResponse}
 */
proto.voc_admin.GetFacilityNotesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.voc_admin.Event()
          reader.readMessage(
            value,
            proto.voc_admin.Event.deserializeBinaryFromReader
          )
          msg.addEvents(value)
          break
        case 2:
          var value = new proto.voc_admin.Pagination()
          reader.readMessage(
            value,
            proto.voc_admin.Pagination.deserializeBinaryFromReader
          )
          msg.setPagination(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetFacilityNotesResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetFacilityNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetFacilityNotesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.voc_admin.Event.serializeBinaryToWriter
    )
  }
  f = message.getPagination()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.voc_admin.Pagination.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Event events = 1;
 * @return {!Array<!proto.voc_admin.Event>}
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.getEventsList = function () {
  return /** @type{!Array<!proto.voc_admin.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.voc_admin.Event, 1)
  )
}

/**
 * @param {!Array<!proto.voc_admin.Event>} value
 * @return {!proto.voc_admin.GetFacilityNotesResponse} returns this
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.setEventsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.voc_admin.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Event}
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.addEvents = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.voc_admin.Event,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.GetFacilityNotesResponse} returns this
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.clearEventsList =
  function () {
    return this.setEventsList([])
  }

/**
 * optional Pagination pagination = 2;
 * @return {?proto.voc_admin.Pagination}
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.getPagination = function () {
  return /** @type{?proto.voc_admin.Pagination} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Pagination, 2)
  )
}

/**
 * @param {?proto.voc_admin.Pagination|undefined} value
 * @return {!proto.voc_admin.GetFacilityNotesResponse} returns this
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.setPagination = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.GetFacilityNotesResponse} returns this
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.clearPagination =
  function () {
    return this.setPagination(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.GetFacilityNotesResponse.prototype.hasPagination = function () {
  return jspb.Message.getField(this, 2) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.Event.prototype.toObject = function (opt_includeInstance) {
    return proto.voc_admin.Event.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.Event} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.Event.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        note:
          (f = msg.getNote()) &&
          proto.voc_admin.Note.toObject(includeInstance, f),
        assignment:
          (f = msg.getAssignment()) &&
          proto.voc_admin.Assignment.toObject(includeInstance, f),
        userDispatchState:
          (f = msg.getUserDispatchState()) &&
          proto.voc_admin.UserDispatchState.toObject(includeInstance, f),
        call:
          (f = msg.getCall()) &&
          proto.voc_admin.Call.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.Event}
 */
proto.voc_admin.Event.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.Event()
  return proto.voc_admin.Event.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.Event}
 */
proto.voc_admin.Event.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Note()
        reader.readMessage(
          value,
          proto.voc_admin.Note.deserializeBinaryFromReader
        )
        msg.setNote(value)
        break
      case 2:
        var value = new proto.voc_admin.Assignment()
        reader.readMessage(
          value,
          proto.voc_admin.Assignment.deserializeBinaryFromReader
        )
        msg.setAssignment(value)
        break
      case 3:
        var value = new proto.voc_admin.UserDispatchState()
        reader.readMessage(
          value,
          proto.voc_admin.UserDispatchState.deserializeBinaryFromReader
        )
        msg.setUserDispatchState(value)
        break
      case 4:
        var value = new proto.voc_admin.Call()
        reader.readMessage(
          value,
          proto.voc_admin.Call.deserializeBinaryFromReader
        )
        msg.setCall(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.Event.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.Event.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.Event.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getNote()
  if (f != null) {
    writer.writeMessage(1, f, proto.voc_admin.Note.serializeBinaryToWriter)
  }
  f = message.getAssignment()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.voc_admin.Assignment.serializeBinaryToWriter
    )
  }
  f = message.getUserDispatchState()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.voc_admin.UserDispatchState.serializeBinaryToWriter
    )
  }
  f = message.getCall()
  if (f != null) {
    writer.writeMessage(4, f, proto.voc_admin.Call.serializeBinaryToWriter)
  }
}

/**
 * optional Note note = 1;
 * @return {?proto.voc_admin.Note}
 */
proto.voc_admin.Event.prototype.getNote = function () {
  return /** @type{?proto.voc_admin.Note} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Note, 1)
  )
}

/**
 * @param {?proto.voc_admin.Note|undefined} value
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.setNote = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.clearNote = function () {
  return this.setNote(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.Event.prototype.hasNote = function () {
  return jspb.Message.getField(this, 1) != null
}

/**
 * optional Assignment assignment = 2;
 * @return {?proto.voc_admin.Assignment}
 */
proto.voc_admin.Event.prototype.getAssignment = function () {
  return /** @type{?proto.voc_admin.Assignment} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Assignment, 2)
  )
}

/**
 * @param {?proto.voc_admin.Assignment|undefined} value
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.setAssignment = function (value) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.clearAssignment = function () {
  return this.setAssignment(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.Event.prototype.hasAssignment = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * optional UserDispatchState user_dispatch_state = 3;
 * @return {?proto.voc_admin.UserDispatchState}
 */
proto.voc_admin.Event.prototype.getUserDispatchState = function () {
  return /** @type{?proto.voc_admin.UserDispatchState} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.UserDispatchState, 3)
  )
}

/**
 * @param {?proto.voc_admin.UserDispatchState|undefined} value
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.setUserDispatchState = function (value) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.clearUserDispatchState = function () {
  return this.setUserDispatchState(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.Event.prototype.hasUserDispatchState = function () {
  return jspb.Message.getField(this, 3) != null
}

/**
 * optional Call call = 4;
 * @return {?proto.voc_admin.Call}
 */
proto.voc_admin.Event.prototype.getCall = function () {
  return /** @type{?proto.voc_admin.Call} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Call, 4)
  )
}

/**
 * @param {?proto.voc_admin.Call|undefined} value
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.setCall = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.Event} returns this
 */
proto.voc_admin.Event.prototype.clearCall = function () {
  return this.setCall(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.Event.prototype.hasCall = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.Call.repeatedFields_ = [5, 9]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.Call.prototype.toObject = function (opt_includeInstance) {
    return proto.voc_admin.Call.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.Call} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.Call.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        conferenceSid: jspb.Message.getFieldWithDefault(msg, 2, ''),
        note: jspb.Message.getFieldWithDefault(msg, 4, ''),
        participantsList: jspb.Message.toObjectList(
          msg.getParticipantsList(),
          proto.voc_admin.CallParticipant.toObject,
          includeInstance
        ),
        createdAt: jspb.Message.getFieldWithDefault(msg, 6, ''),
        endedAt: jspb.Message.getFieldWithDefault(msg, 7, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 8, 0),
        facilityIdsList:
          (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.Call}
 */
proto.voc_admin.Call.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.Call()
  return proto.voc_admin.Call.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.Call} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.Call}
 */
proto.voc_admin.Call.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setConferenceSid(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setNote(value)
        break
      case 5:
        var value = new proto.voc_admin.CallParticipant()
        reader.readMessage(
          value,
          proto.voc_admin.CallParticipant.deserializeBinaryFromReader
        )
        msg.addParticipants(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.setEndedAt(value)
        break
      case 8:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 9:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited()
            ? reader.readPackedSint32()
            : [reader.readSint32()]
        )
        for (var i = 0; i < values.length; i++) {
          msg.addFacilityIds(values[i])
        }
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.Call.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.Call.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.Call} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.Call.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getConferenceSid()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getNote()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getParticipantsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.voc_admin.CallParticipant.serializeBinaryToWriter
    )
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getEndedAt()
  if (f.length > 0) {
    writer.writeString(7, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(8, f)
  }
  f = message.getFacilityIdsList()
  if (f.length > 0) {
    writer.writePackedSint32(9, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.voc_admin.Call.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string conference_sid = 2;
 * @return {string}
 */
proto.voc_admin.Call.prototype.getConferenceSid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setConferenceSid = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional string note = 4;
 * @return {string}
 */
proto.voc_admin.Call.prototype.getNote = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setNote = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * repeated CallParticipant participants = 5;
 * @return {!Array<!proto.voc_admin.CallParticipant>}
 */
proto.voc_admin.Call.prototype.getParticipantsList = function () {
  return /** @type{!Array<!proto.voc_admin.CallParticipant>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.voc_admin.CallParticipant,
      5
    )
  )
}

/**
 * @param {!Array<!proto.voc_admin.CallParticipant>} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setParticipantsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value)
}

/**
 * @param {!proto.voc_admin.CallParticipant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.CallParticipant}
 */
proto.voc_admin.Call.prototype.addParticipants = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    5,
    opt_value,
    proto.voc_admin.CallParticipant,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.clearParticipantsList = function () {
  return this.setParticipantsList([])
}

/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.voc_admin.Call.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string ended_at = 7;
 * @return {string}
 */
proto.voc_admin.Call.prototype.getEndedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setEndedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional sint32 scheduled_event_id = 8;
 * @return {number}
 */
proto.voc_admin.Call.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setScheduledEventId = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value)
}

/**
 * repeated sint32 facility_ids = 9;
 * @return {!Array<number>}
 */
proto.voc_admin.Call.prototype.getFacilityIdsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9))
}

/**
 * @param {!Array<number>} value
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.setFacilityIdsList = function (value) {
  return jspb.Message.setField(this, 9, value || [])
}

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.addFacilityIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.Call} returns this
 */
proto.voc_admin.Call.prototype.clearFacilityIdsList = function () {
  return this.setFacilityIdsList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.CallParticipant.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.CallParticipant.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.CallParticipant} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.CallParticipant.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        callId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        callSid: jspb.Message.getFieldWithDefault(msg, 3, ''),
        isPrimary: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
        onHold: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
        fullName: jspb.Message.getFieldWithDefault(msg, 6, ''),
        phoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ''),
        voltappId: jspb.Message.getFieldWithDefault(msg, 8, 0),
        salesforceId: jspb.Message.getFieldWithDefault(msg, 9, ''),
        createdAt: jspb.Message.getFieldWithDefault(msg, 10, ''),
        endedAt: jspb.Message.getFieldWithDefault(msg, 11, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.CallParticipant}
 */
proto.voc_admin.CallParticipant.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.CallParticipant()
  return proto.voc_admin.CallParticipant.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.CallParticipant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.CallParticipant}
 */
proto.voc_admin.CallParticipant.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setCallId(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setCallSid(value)
        break
      case 4:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsPrimary(value)
        break
      case 5:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setOnHold(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setFullName(value)
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.setPhoneNumber(value)
        break
      case 8:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setVoltappId(value)
        break
      case 9:
        var value = /** @type {string} */ (reader.readString())
        msg.setSalesforceId(value)
        break
      case 10:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      case 11:
        var value = /** @type {string} */ (reader.readString())
        msg.setEndedAt(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.CallParticipant.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.CallParticipant.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.CallParticipant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.CallParticipant.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getCallId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getCallSid()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getIsPrimary()
  if (f) {
    writer.writeBool(4, f)
  }
  f = message.getOnHold()
  if (f) {
    writer.writeBool(5, f)
  }
  f = message.getFullName()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getPhoneNumber()
  if (f.length > 0) {
    writer.writeString(7, f)
  }
  f = message.getVoltappId()
  if (f !== 0) {
    writer.writeSint32(8, f)
  }
  f = message.getSalesforceId()
  if (f.length > 0) {
    writer.writeString(9, f)
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(10, f)
  }
  f = message.getEndedAt()
  if (f.length > 0) {
    writer.writeString(11, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.voc_admin.CallParticipant.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 call_id = 2;
 * @return {number}
 */
proto.voc_admin.CallParticipant.prototype.getCallId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setCallId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string call_sid = 3;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getCallSid = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setCallSid = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional bool is_primary = 4;
 * @return {boolean}
 */
proto.voc_admin.CallParticipant.prototype.getIsPrimary = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 4, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setIsPrimary = function (value) {
  return jspb.Message.setProto3BooleanField(this, 4, value)
}

/**
 * optional bool on_hold = 5;
 * @return {boolean}
 */
proto.voc_admin.CallParticipant.prototype.getOnHold = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 5, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setOnHold = function (value) {
  return jspb.Message.setProto3BooleanField(this, 5, value)
}

/**
 * optional string full_name = 6;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getFullName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setFullName = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string phone_number = 7;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getPhoneNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setPhoneNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional sint32 voltapp_id = 8;
 * @return {number}
 */
proto.voc_admin.CallParticipant.prototype.getVoltappId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setVoltappId = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value)
}

/**
 * optional string salesforce_id = 9;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getSalesforceId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setSalesforceId = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * optional string created_at = 10;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value)
}

/**
 * optional string ended_at = 11;
 * @return {string}
 */
proto.voc_admin.CallParticipant.prototype.getEndedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.CallParticipant} returns this
 */
proto.voc_admin.CallParticipant.prototype.setEndedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 11, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.Pagination.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.Pagination.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.Pagination} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.Pagination.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        totalItems: jspb.Message.getFieldWithDefault(msg, 1, 0),
        hasNextPage: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
        perPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
        pageKey: jspb.Message.getFieldWithDefault(msg, 4, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.Pagination}
 */
proto.voc_admin.Pagination.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.Pagination()
  return proto.voc_admin.Pagination.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.Pagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.Pagination}
 */
proto.voc_admin.Pagination.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setTotalItems(value)
        break
      case 2:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setHasNextPage(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPerPage(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setPageKey(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.Pagination.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.Pagination.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.Pagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.Pagination.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getTotalItems()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getHasNextPage()
  if (f) {
    writer.writeBool(2, f)
  }
  f = message.getPerPage()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getPageKey()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
}

/**
 * optional sint32 total_items = 1;
 * @return {number}
 */
proto.voc_admin.Pagination.prototype.getTotalItems = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Pagination} returns this
 */
proto.voc_admin.Pagination.prototype.setTotalItems = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional bool has_next_page = 2;
 * @return {boolean}
 */
proto.voc_admin.Pagination.prototype.getHasNextPage = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.voc_admin.Pagination} returns this
 */
proto.voc_admin.Pagination.prototype.setHasNextPage = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value)
}

/**
 * optional sint32 per_page = 3;
 * @return {number}
 */
proto.voc_admin.Pagination.prototype.getPerPage = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.Pagination} returns this
 */
proto.voc_admin.Pagination.prototype.setPerPage = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string page_key = 4;
 * @return {string}
 */
proto.voc_admin.Pagination.prototype.getPageKey = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.Pagination} returns this
 */
proto.voc_admin.Pagination.prototype.setPageKey = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetSiteContactNotesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetSiteContactNotesRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetSiteContactNotesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetSiteContactNotesRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        siteContactUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetSiteContactNotesRequest}
 */
proto.voc_admin.GetSiteContactNotesRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetSiteContactNotesRequest()
  return proto.voc_admin.GetSiteContactNotesRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetSiteContactNotesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetSiteContactNotesRequest}
 */
proto.voc_admin.GetSiteContactNotesRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setAccessToken(value)
          break
        case 2:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setScheduledEventId(value)
          break
        case 3:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setSiteContactUserId(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetSiteContactNotesRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetSiteContactNotesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetSiteContactNotesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getSiteContactUserId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.getAccessToken =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetSiteContactNotesRequest} returns this
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.getScheduledEventId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetSiteContactNotesRequest} returns this
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.setScheduledEventId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value)
  }

/**
 * optional sint32 site_contact_user_id = 3;
 * @return {number}
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.getSiteContactUserId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetSiteContactNotesRequest} returns this
 */
proto.voc_admin.GetSiteContactNotesRequest.prototype.setSiteContactUserId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 3, value)
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.GetSiteContactNotesResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetSiteContactNotesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetSiteContactNotesResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetSiteContactNotesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetSiteContactNotesResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        eventsList: jspb.Message.toObjectList(
          msg.getEventsList(),
          proto.voc_admin.Event.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetSiteContactNotesResponse}
 */
proto.voc_admin.GetSiteContactNotesResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetSiteContactNotesResponse()
  return proto.voc_admin.GetSiteContactNotesResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetSiteContactNotesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetSiteContactNotesResponse}
 */
proto.voc_admin.GetSiteContactNotesResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.voc_admin.Event()
          reader.readMessage(
            value,
            proto.voc_admin.Event.deserializeBinaryFromReader
          )
          msg.addEvents(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetSiteContactNotesResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetSiteContactNotesResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetSiteContactNotesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetSiteContactNotesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEventsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.voc_admin.Event.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Event events = 1;
 * @return {!Array<!proto.voc_admin.Event>}
 */
proto.voc_admin.GetSiteContactNotesResponse.prototype.getEventsList =
  function () {
    return /** @type{!Array<!proto.voc_admin.Event>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.voc_admin.Event, 1)
    )
  }

/**
 * @param {!Array<!proto.voc_admin.Event>} value
 * @return {!proto.voc_admin.GetSiteContactNotesResponse} returns this
 */
proto.voc_admin.GetSiteContactNotesResponse.prototype.setEventsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.voc_admin.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Event}
 */
proto.voc_admin.GetSiteContactNotesResponse.prototype.addEvents = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.voc_admin.Event,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.GetSiteContactNotesResponse} returns this
 */
proto.voc_admin.GetSiteContactNotesResponse.prototype.clearEventsList =
  function () {
    return this.setEventsList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.EventStreamRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.EventStreamRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.EventStreamRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.EventStreamRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.EventStreamRequest}
 */
proto.voc_admin.EventStreamRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.EventStreamRequest()
  return proto.voc_admin.EventStreamRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.EventStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.EventStreamRequest}
 */
proto.voc_admin.EventStreamRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.EventStreamRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.EventStreamRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.EventStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.EventStreamRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.EventStreamRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.EventStreamRequest} returns this
 */
proto.voc_admin.EventStreamRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.EventStreamRequest.prototype.getScheduledEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.EventStreamRequest} returns this
 */
proto.voc_admin.EventStreamRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.EventStreamResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.EventStreamResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.EventStreamResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.EventStreamResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        event:
          (f = msg.getEvent()) &&
          proto.voc_admin.Event.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.EventStreamResponse}
 */
proto.voc_admin.EventStreamResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.EventStreamResponse()
  return proto.voc_admin.EventStreamResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.EventStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.EventStreamResponse}
 */
proto.voc_admin.EventStreamResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Event()
        reader.readMessage(
          value,
          proto.voc_admin.Event.deserializeBinaryFromReader
        )
        msg.setEvent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.EventStreamResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.EventStreamResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.EventStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.EventStreamResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getEvent()
  if (f != null) {
    writer.writeMessage(1, f, proto.voc_admin.Event.serializeBinaryToWriter)
  }
}

/**
 * optional Event event = 1;
 * @return {?proto.voc_admin.Event}
 */
proto.voc_admin.EventStreamResponse.prototype.getEvent = function () {
  return /** @type{?proto.voc_admin.Event} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Event, 1)
  )
}

/**
 * @param {?proto.voc_admin.Event|undefined} value
 * @return {!proto.voc_admin.EventStreamResponse} returns this
 */
proto.voc_admin.EventStreamResponse.prototype.setEvent = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.EventStreamResponse} returns this
 */
proto.voc_admin.EventStreamResponse.prototype.clearEvent = function () {
  return this.setEvent(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.EventStreamResponse.prototype.hasEvent = function () {
  return jspb.Message.getField(this, 1) != null
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.OutboundCallRequest.repeatedFields_ = [4]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.OutboundCallRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.OutboundCallRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.OutboundCallRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.OutboundCallRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        userIdCaller: jspb.Message.getFieldWithDefault(msg, 2, 0),
        userIdCallee: jspb.Message.getFieldWithDefault(msg, 3, 0),
        facilityIdsList:
          (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 5, 0),
        phoneNumberCallee: jspb.Message.getFieldWithDefault(msg, 6, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.OutboundCallRequest}
 */
proto.voc_admin.OutboundCallRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.OutboundCallRequest()
  return proto.voc_admin.OutboundCallRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.OutboundCallRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.OutboundCallRequest}
 */
proto.voc_admin.OutboundCallRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserIdCaller(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserIdCallee(value)
        break
      case 4:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited()
            ? reader.readPackedSint32()
            : [reader.readSint32()]
        )
        for (var i = 0; i < values.length; i++) {
          msg.addFacilityIds(values[i])
        }
        break
      case 5:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setPhoneNumberCallee(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.OutboundCallRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.OutboundCallRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.OutboundCallRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.OutboundCallRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getUserIdCaller()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getUserIdCallee()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getFacilityIdsList()
  if (f.length > 0) {
    writer.writePackedSint32(4, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(5, f)
  }
  f = message.getPhoneNumberCallee()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.OutboundCallRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 user_id_caller = 2;
 * @return {number}
 */
proto.voc_admin.OutboundCallRequest.prototype.getUserIdCaller = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setUserIdCaller = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 user_id_callee = 3;
 * @return {number}
 */
proto.voc_admin.OutboundCallRequest.prototype.getUserIdCallee = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setUserIdCallee = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * repeated sint32 facility_ids = 4;
 * @return {!Array<number>}
 */
proto.voc_admin.OutboundCallRequest.prototype.getFacilityIdsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4))
}

/**
 * @param {!Array<number>} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setFacilityIdsList = function (
  value
) {
  return jspb.Message.setField(this, 4, value || [])
}

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.addFacilityIds = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.clearFacilityIdsList =
  function () {
    return this.setFacilityIdsList([])
  }

/**
 * optional sint32 scheduled_event_id = 5;
 * @return {number}
 */
proto.voc_admin.OutboundCallRequest.prototype.getScheduledEventId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setScheduledEventId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 5, value)
}

/**
 * optional string phone_number_callee = 6;
 * @return {string}
 */
proto.voc_admin.OutboundCallRequest.prototype.getPhoneNumberCallee =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
  }

/**
 * @param {string} value
 * @return {!proto.voc_admin.OutboundCallRequest} returns this
 */
proto.voc_admin.OutboundCallRequest.prototype.setPhoneNumberCallee = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.OutboundCallResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.OutboundCallResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.OutboundCallResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.OutboundCallResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        call:
          (f = msg.getCall()) &&
          proto.voc_admin.Call.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.OutboundCallResponse}
 */
proto.voc_admin.OutboundCallResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.OutboundCallResponse()
  return proto.voc_admin.OutboundCallResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.OutboundCallResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.OutboundCallResponse}
 */
proto.voc_admin.OutboundCallResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.voc_admin.Call()
        reader.readMessage(
          value,
          proto.voc_admin.Call.deserializeBinaryFromReader
        )
        msg.setCall(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.OutboundCallResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.voc_admin.OutboundCallResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.OutboundCallResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.OutboundCallResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getCall()
  if (f != null) {
    writer.writeMessage(1, f, proto.voc_admin.Call.serializeBinaryToWriter)
  }
}

/**
 * optional Call call = 1;
 * @return {?proto.voc_admin.Call}
 */
proto.voc_admin.OutboundCallResponse.prototype.getCall = function () {
  return /** @type{?proto.voc_admin.Call} */ (
    jspb.Message.getWrapperField(this, proto.voc_admin.Call, 1)
  )
}

/**
 * @param {?proto.voc_admin.Call|undefined} value
 * @return {!proto.voc_admin.OutboundCallResponse} returns this
 */
proto.voc_admin.OutboundCallResponse.prototype.setCall = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.voc_admin.OutboundCallResponse} returns this
 */
proto.voc_admin.OutboundCallResponse.prototype.clearCall = function () {
  return this.setCall(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.voc_admin.OutboundCallResponse.prototype.hasCall = function () {
  return jspb.Message.getField(this, 1) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetDispatchCallsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetDispatchCallsRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetDispatchCallsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetDispatchCallsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
        scheduledEventId: jspb.Message.getFieldWithDefault(msg, 2, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetDispatchCallsRequest}
 */
proto.voc_admin.GetDispatchCallsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetDispatchCallsRequest()
  return proto.voc_admin.GetDispatchCallsRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetDispatchCallsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetDispatchCallsRequest}
 */
proto.voc_admin.GetDispatchCallsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setScheduledEventId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetDispatchCallsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetDispatchCallsRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetDispatchCallsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetDispatchCallsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getScheduledEventId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
}

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.voc_admin.GetDispatchCallsRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.voc_admin.GetDispatchCallsRequest} returns this
 */
proto.voc_admin.GetDispatchCallsRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional sint32 scheduled_event_id = 2;
 * @return {number}
 */
proto.voc_admin.GetDispatchCallsRequest.prototype.getScheduledEventId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
  }

/**
 * @param {number} value
 * @return {!proto.voc_admin.GetDispatchCallsRequest} returns this
 */
proto.voc_admin.GetDispatchCallsRequest.prototype.setScheduledEventId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value)
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.voc_admin.GetDispatchCallsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.voc_admin.GetDispatchCallsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.voc_admin.GetDispatchCallsResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.voc_admin.GetDispatchCallsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.voc_admin.GetDispatchCallsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        callsList: jspb.Message.toObjectList(
          msg.getCallsList(),
          proto.voc_admin.Call.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.voc_admin.GetDispatchCallsResponse}
 */
proto.voc_admin.GetDispatchCallsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.voc_admin.GetDispatchCallsResponse()
  return proto.voc_admin.GetDispatchCallsResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.voc_admin.GetDispatchCallsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.voc_admin.GetDispatchCallsResponse}
 */
proto.voc_admin.GetDispatchCallsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.voc_admin.Call()
          reader.readMessage(
            value,
            proto.voc_admin.Call.deserializeBinaryFromReader
          )
          msg.addCalls(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.voc_admin.GetDispatchCallsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.voc_admin.GetDispatchCallsResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.voc_admin.GetDispatchCallsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.voc_admin.GetDispatchCallsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getCallsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.voc_admin.Call.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Call calls = 1;
 * @return {!Array<!proto.voc_admin.Call>}
 */
proto.voc_admin.GetDispatchCallsResponse.prototype.getCallsList = function () {
  return /** @type{!Array<!proto.voc_admin.Call>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.voc_admin.Call, 1)
  )
}

/**
 * @param {!Array<!proto.voc_admin.Call>} value
 * @return {!proto.voc_admin.GetDispatchCallsResponse} returns this
 */
proto.voc_admin.GetDispatchCallsResponse.prototype.setCallsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.voc_admin.Call=} opt_value
 * @param {number=} opt_index
 * @return {!proto.voc_admin.Call}
 */
proto.voc_admin.GetDispatchCallsResponse.prototype.addCalls = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.voc_admin.Call,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.voc_admin.GetDispatchCallsResponse} returns this
 */
proto.voc_admin.GetDispatchCallsResponse.prototype.clearCallsList =
  function () {
    return this.setCallsList([])
  }

goog.object.extend(exports, proto.voc_admin)
