import noop from 'lodash/noop'
import { put, call, all, takeLatest } from 'redux-saga/effects'
import logger from '@voltus/logger'
import { outboundCall } from '../../api/vocAdminClient'
import { CALL_STATUSES } from '../../constants'
import { CallTypes, CallCreators } from './calling.redux'

function* handleOutboundCall({ payload }) {
  const {
    scheduledEventId,
    userIdCaller,
    userIdCallee,
    phoneNumber,
    facilityIds,
    onSuccess = noop,
    onFailure = noop,
  } = payload
  try {
    yield put(
      CallCreators.setCallStatus({ ...payload, status: CALL_STATUSES.DIALING })
    )
    const res = yield call(outboundCall, {
      scheduledEventId,
      userIdCaller,
      userIdCallee,
      phoneNumber,
      facilityIds,
    })
    yield put(
      CallCreators.setCallStatus({
        ...payload,
        status: CALL_STATUSES.CONNECTED,
      })
    )
    yield put(CallCreators.setCall(res.call))
    onSuccess()
  } catch (e) {
    logger.error(e)
    yield put(
      CallCreators.setCallStatus({
        ...payload,
        status: CALL_STATUSES.FAILED,
      })
    )
    onFailure()
  }
}

export function* watchCalling() {
  yield all([takeLatest(CallTypes.INITIATE_OUTBOUND_CALL, handleOutboundCall)])
}
