import { combineReducers } from 'redux'

// TODO: This past performance module really shouldn't load up here...
import pastPerformance from '../../../routes/pastPerformance/modules/reducers'

import { bootstrapReducer } from './bootstrapAdmin'
import { callingReducer } from './calling'
import { checkInReducer } from './checkin'
import { dispatchReducer } from './dispatch'
import { notesReducer } from './notes'
import { routeParamsReducer } from './routeParams'
import { siteAssignmentsReducer } from './siteAssignments'
import { smsReducer } from './sms'
import { uiReducer } from './ui'

export const reducer = combineReducers({
  pastPerformance: pastPerformance,
  dispatch: dispatchReducer,
  notes: notesReducer,
  siteAssignments: siteAssignmentsReducer,
  checkIns: checkInReducer,
  routeParams: routeParamsReducer,
  bootstrapAdmin: bootstrapReducer,
  ui: uiReducer,
  calling: callingReducer,
  sms: smsReducer,
})
