import { useMutation, useQuery } from '@tanstack/react-query'
import {
  featureFlagsManagerAllFlags,
  featureFlagsManagerUpdateContextFlags,
} from '@voltus/api/featureFlagsManager/featureFlagsManager'
import { Variation } from '@voltus/api/featureFlagsManager/featureFlagsManager.schemas'
import { queryKeys } from '@voltus/network'

/**
 * Fetches the list of available feature flag defintions and their
 * metadata, like their available variations, descriptions, and
 * other useful information.
 *
 * *Important!* These flags DO NOT represent
 * the user values for a given flag, rather they are just
 * flag definitions that we use to create custom UIs
 * to toggle feature flags on/off
 */
export const useFeatureFlagDefinitionQuery = ({
  enabled,
}: {
  enabled: boolean
}) => {
  return useQuery({
    queryKey: queryKeys.allFeatureFlagsQueryKey(),
    queryFn: featureFlagsManagerAllFlags,
    refetchOnWindowFocus: false,
    enabled: enabled,
  })
}

/**
 * Mutation to update a set of flag value for a given user
 */
export const useUpdateUserFeatureFlag = () => {
  return useMutation({
    mutationFn: ({
      email,
      flags,
    }: {
      email: string
      flags: { [key: string]: Variation['value'] }
    }) => {
      return featureFlagsManagerUpdateContextFlags({
        context: {
          kind: 'user',
          key: email,
        },
        flags: flags,
      })
    },
  })
}
