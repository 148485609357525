import produce from 'immer'
import setWith from 'lodash/setWith'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: CallTypes, Creators: CallCreators } = createActions(
  {
    initiateOutboundCall: ['payload'],
    setCallStatus: ['payload'],
    setCall: ['payload'],
  },
  {
    prefix: 'DISPATCHES/CALLING/',
  }
)

const INITIAL_STATE = {
  calls: {},
  callStatuses: {},
}

const handleSetCall = (state, { payload }) => {
  const { scheduledEventId, id } = payload
  return produce(state, (draft) => {
    draft.calls
    setWith(draft.calls, [scheduledEventId, id], payload, Object)
  })
}

const handleSetCallStatus = (state, { payload }) => {
  const { scheduledEventId, userIdCaller, userIdCallee, status } = payload
  return produce(state, (draft) => {
    setWith(
      draft.callStatuses,
      [scheduledEventId, `${userIdCaller}-${userIdCallee}`],
      status,
      Object
    )
  })
}

export const callingReducer = createReducer(INITIAL_STATE, {
  [CallTypes.SET_CALL_STATUS]: handleSetCallStatus,
  [CallTypes.SET_CALL]: handleSetCall,
})
