import { isDemoEnabled } from '@voltus/utils'

/**
 * Returns the Voltus portfolio name. Typically this will be "Voltus",
 * but if the user is in Demo Mode it will be "DEMO All".
 * @example
 * ```
 * getVoltusPortfolioName() // 'Voltus' | 'DEMO All'
 * ```
 *
 * You may force this function to return 'Voltus' using the `ignoreDemo` flag.
 * This can be useful in situations where you need to use the 'Voltus' name
 * to make a network request.
 * @example
 * ```
 * network.get(endpoints.markets.organizations(getVoltusPortfolioName({ ignoreDemo: true }), 'members'))
 * ```
 */
export const getVoltusPortfolioName = ({ ignoreDemo = false } = {}) => {
  if (ignoreDemo) {
    return 'Voltus'
  }

  return isDemoEnabled() ? 'DEMO All' : 'Voltus'
}
