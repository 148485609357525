import classnames from 'classnames'
import * as React from 'react'
import { Any, AnyObject } from '@voltus/types'
import { usePrevious } from '@voltus/utils'
import { Box } from '../Box'
import { makeHandleNodeChange } from '../Dropdown/helpers'
import { OptionsTree, flattenTree, Node } from '../Dropdown/optionsTree'
import { SiteSwitcherDropdown } from './components/SiteSwitcherDropdown'
import * as SELECTORS from './siteSwitcher.testSelectors'
import { SiteSwitcherOption, OptionConfig } from './types'

/**
 * Tree mode requires array values, so throw if we are in tree mode
 * and we don't have an array value
 */
const raiseIfInvalidValue = (
  optionConfig: `${OptionConfig}`,
  // We really don't care, or need to know what the value type is here, we just want to know if it's an array or not
  value: Any | Array<Any>
): void => {
  if (optionConfig === OptionConfig.Tree && value && !Array.isArray(value)) {
    throw new Error(
      `when options are in "tree" mode, value must be an array but instead received: ${value}`
    )
  }
}

/**
 * We want to sort the options by bringing the selected option to the top
 * If the passed in options change for any reason, we should re-run the sort
 */
function useSortedOptions<ValueType>(
  optionsTree: OptionsTree<ValueType>,
  foundValue: Node<ValueType> | Array<Node<ValueType>> | undefined | null
): [
  Array<Node<ValueType>>,
  React.Dispatch<React.SetStateAction<Array<Node<ValueType>>>>,
] {
  const prevTree = usePrevious(optionsTree)
  const [sortedOptions, setSortedOptions] = React.useState(
    flattenTree(optionsTree.sortBySelected(foundValue))
  )

  // Compare prev tree and current tree to identify a change in the selections
  React.useEffect(() => {
    if (
      optionsTree &&
      prevTree &&
      optionsTree?.flattenedOptions.length !== prevTree?.flattenedOptions.length
    ) {
      setSortedOptions(flattenTree(optionsTree.sortBySelected(foundValue)))
    }
  }, [prevTree, optionsTree, setSortedOptions, foundValue])

  return [sortedOptions, setSortedOptions]
}

interface SiteSwitcherProps<
  FirstValueType = Any,
  SecondValueType = Any,
  isFirstMulti = false,
  isSecondMulti = false,
> {
  isFetching?: boolean
  className?: string
  firstOptions: Array<SiteSwitcherOption<FirstValueType>> | null
  firstValue?:
    | SiteSwitcherOption<FirstValueType>
    | Array<SiteSwitcherOption<FirstValueType>>
    | Array<FirstValueType>
    | FirstValueType
  secondOptions?: Array<SiteSwitcherOption<SecondValueType>> | null
  secondValue?: isSecondMulti extends true
    ? Array<SiteSwitcherOption<SecondValueType>> | Array<SecondValueType>
    : SiteSwitcherOption<SecondValueType> | SecondValueType
  firstProps?: AnyObject
  secondProps?: AnyObject
  firstOptionConfig?: `${OptionConfig}`
  secondOptionConfig?: `${OptionConfig}`
  onFirstValueChange: (
    value: isFirstMulti extends true
      ? Array<Node<FirstValueType>>
      : Node<FirstValueType>
  ) => void
  onSecondValueChange?: (
    value: isSecondMulti extends true
      ? Array<Node<SecondValueType>>
      : Node<SecondValueType>
  ) => void
  isSearchable?: boolean
}

export function SiteSwitcher<
  FirstValueType = AnyObject,
  SecondValueType = AnyObject,
  isFirstMulti = false,
  isSecondMulti = false,
>({
  isFetching,
  className,
  firstOptions = [],
  firstProps = {},
  secondOptions = [],
  secondProps = {},
  firstValue,
  secondValue,
  firstOptionConfig = 'flat',
  secondOptionConfig = 'flat',
  onSecondValueChange,
  onFirstValueChange,
  isSearchable = false,
}: SiteSwitcherProps<
  FirstValueType,
  SecondValueType,
  isFirstMulti,
  isSecondMulti
>): JSX.Element {
  const isFirstTreeSelect = firstOptionConfig === 'tree'
  const isSecondTreeSelect = secondOptionConfig === 'tree'

  // Tree mode requires array values, so throw if we are in tree mode
  // and the value isn't an array (or empty)
  raiseIfInvalidValue(firstOptionConfig, firstValue)
  raiseIfInvalidValue(secondOptionConfig, secondValue)

  // The `useMemo` calls may not do anything for the majority of cases
  // but for large lists, it allows for consumer optimizations by passing
  // in memoized option and values
  const firstOptionsTree = React.useMemo(
    () =>
      OptionsTree<FirstValueType>(firstOptions ?? [], firstValue, {
        includeAllOption: firstProps.includeAllOption ?? isFirstTreeSelect,
      }),
    [firstProps, firstOptions, firstValue, isFirstTreeSelect]
  )
  const secondOptionsTree = React.useMemo(
    () =>
      OptionsTree<SecondValueType>(secondOptions ?? [], secondValue, {
        includeAllOption: secondProps.includeAllOption ?? isSecondTreeSelect,
      }),
    [secondProps, secondOptions, secondValue, isSecondTreeSelect]
  )

  const foundFirstValue = firstOptionsTree.findValue(firstValue)
  const foundSecondValue = secondOptionsTree.findValue(secondValue)

  // When the menu closes, we want to sort the options so that the selected
  // items move to the top of the list. This means we need to store the options
  // locally on state, and re-sort them when the menu closes
  // Because we support a tree structure, we can't just sort a flat list of items,
  // instead we have to sort the tree roots based on if a child item within a given
  // branch is selected.
  const [sortedFirstOptions, setSortedFirstOptions] = useSortedOptions(
    firstOptionsTree,
    foundFirstValue
  )
  const [sortedSecondOptions, setSortedSecondOptions] = useSortedOptions(
    secondOptionsTree,
    foundSecondValue
  )

  return (
    <div
      className={classnames('site-switcher', className)}
      data-testid={SELECTORS.SELECT}
    >
      <div
        className="site-switcher__dropdown-container"
        data-testid={SELECTORS.SELECT_FIRST_DROPDOWN}
      >
        <SiteSwitcherDropdown
          {...firstProps}
          className="site-switcher__first-dropdown"
          isFetching={isFetching}
          isSearchable={isSearchable}
          options={sortedFirstOptions}
          value={foundFirstValue}
          isTreeSelect={isFirstTreeSelect}
          // When the dropdown closes, we move the selected items up to the top
          onOpen={(_, value) => {
            setSortedFirstOptions(
              flattenTree(firstOptionsTree.sortBySelected(value))
            )
          }}
          onChange={(...selectArgs) => {
            makeHandleNodeChange<FirstValueType>(
              onFirstValueChange,
              isFirstTreeSelect,
              firstOptionsTree
            )(...selectArgs)
          }}
        />
      </div>
      {!secondOptionsTree.flattenedOptions ||
      !secondOptionsTree.flattenedOptions.length ? null : (
        <>
          <Box className="site-switcher__dropdown-separator" />
          <Box
            data-testid={SELECTORS.SELECT_SECOND_DROPDOWN}
            className="site-switcher__dropdown-container"
          >
            <SiteSwitcherDropdown
              {...secondProps}
              className="site-switcher__second-dropdown"
              isFetching={isFetching}
              isSearchable={isSearchable}
              options={sortedSecondOptions}
              value={foundSecondValue}
              isTreeSelect={isSecondTreeSelect}
              // When the dropdown closes, we move the selected items up to the top
              onOpen={(_, value) => {
                setSortedSecondOptions(
                  flattenTree(secondOptionsTree.sortBySelected(value))
                )
              }}
              onChange={(...selectArgs) => {
                if (onSecondValueChange) {
                  makeHandleNodeChange<SecondValueType>(
                    onSecondValueChange,
                    isSecondTreeSelect,
                    secondOptionsTree
                  )(...selectArgs)
                }
              }}
            />
          </Box>
        </>
      )}
    </div>
  )
}
