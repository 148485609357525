import * as React from 'react'
import logger from '@voltus/logger'
import isTestEnv from '../isTestEnv'

type ZendeskWidgetHandlers = {
  isShowingWidget: boolean
  setShowingWidget: React.Dispatch<React.SetStateAction<boolean>>
  toggleWidget: () => void
  hideWidget: () => void
  showWidget: () => void
}

export const useZendeskWidget = (): ZendeskWidgetHandlers => {
  // eslint-disable-next-line
  // @ts-ignore
  const zE = window?.zE

  if (!zE && !isTestEnv()) {
    logger.once.warn(
      'window.zE not found! Unable to interact with the Zendesk Web Widget. Make sure the appropriate script tag is included in your index.html'
    )
  }

  const [isShowingWidget, setShowingWidget] = React.useState(false)

  React.useEffect(() => {
    zE?.('webWidget:on', 'close', () => {
      setShowingWidget(false)
      zE?.('webWidget', 'hide')
    })
  }, [zE, setShowingWidget])

  const showWidget = () => {
    zE?.('webWidget', 'show')
    zE?.('webWidget', 'open')
    setShowingWidget(true)
  }
  const hideWidget = () => {
    zE?.('webWidget', 'close')
    zE?.('webWidget', 'hide')
    setShowingWidget(false)
  }

  const toggleWidget = () => {
    if (!isShowingWidget) {
      showWidget()
    } else {
      hideWidget()
    }
  }

  return {
    isShowingWidget,
    setShowingWidget,
    toggleWidget,
    hideWidget,
    showWidget,
  }
}
