import { isBefore } from 'date-fns'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import numeral from 'numeral'
import { DISPATCH_DETAIL_TABS, LIST_MODE, ADMIN_SORT } from '../../constants'
import {
  getPerformancePercentage,
  isSiteConfirmed,
  isSiteRejected,
  isDispatchOver,
} from '../../utils'

const performanceDisplayResolver = (formatter) => (site) => {
  if (isBefore(new Date(), new Date(site.dispatch.startTime))) {
    return '--'
  }
  return site.targets?.length ? formatter(site) : '--'
}

const sumValues = (obj) =>
  Object.values(obj).reduce((acc, curr) => acc + curr, 0)

/**
 * Attempts to normalize and centralize
 * the display and actual values for a given
 * property on a site within the snapshot data
 *
 * These rows are also used to construct the
 * "List" and "Grid" views of the site list.
 * Allowing us to pick which components are used
 * or grouped together in a given view
 */
export const sitePropertyResolvers = {
  // Common row
  siteName: {
    filters: {
      tab: [
        DISPATCH_DETAIL_TABS.PARTICIPATION,
        DISPATCH_DETAIL_TABS.PERFORMANCE,
      ],
    },
    label: 'Name',
    value: 'siteName',
    // A selector for the raw value from the facility
    valueResolver: (site) => site.name,
    // A selector used to figure out what should be rendered
    // base on the raw value. For some sites,
    // we want to show different display labels based on some
    // data checks
    valueDisplayResolver: (site) => site.name,
  },

  // Participation Rows
  potentialEarnings: {
    filters: {
      tab: DISPATCH_DETAIL_TABS.PARTICIPATION,
    },
    label: 'Potential Earnings',
    value: 'potentialEarnings',
    valueResolver: (site) => sumValues(site.potentialEarnings),
    valueDisplayResolver: (site) =>
      numeral(sumValues(site.potentialEarnings)).format('$0,0'),
  },
  commitmentKw: {
    filters: { tab: DISPATCH_DETAIL_TABS.PARTICIPATION },
    label: 'Commitment',
    valueResolver: (site) => site.commitmentKw,
    value: 'commitmentKw',
    valueDisplayResolver: (site) =>
      `${numeral(site.commitmentKw).format('0,0')} kW`,
  },
  acknowledgments: {
    filters: { tab: DISPATCH_DETAIL_TABS.PARTICIPATION },
    label: 'Acknowledgments',
    value: 'acknowledgments',
    valueResolver: (site) => {
      // there's no real concept of "value" for the ack list,
      // so instead the value represents the desired sort order
      if (isSiteConfirmed(site)) {
        return 1
      }
      if (isSiteRejected(site)) {
        return -1
      }
      return 0
    },
    // Acknowledgements use their own component, they don't
    // just return a simple value for rendering
    valueDisplayResolver: () => null,
  },

  // Performance common rows
  load: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE },
    label: 'Load',
    value: 'load',
    valueResolver: (site) => site.load,
    // If no sparklines are available, it means
    // we don't have load data, so don't show anything
    valueDisplayResolver: (site) => {
      if (isDispatchOver(site.dispatch)) {
        return '--'
      }

      // Don't show load if no sparklines.
      if (site.sparklines?.length === 0 || isNil(site.sparklines)) {
        return '--'
      }

      return !isNil(site.load) ? `${numeral(site.load).format('0,0')} kW` : '--'
    },
  },

  // Performance rows that change by sub filters
  currentCommitment: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE, mode: 'current' },
    label: 'Commitment',
    value: 'currentCommitment',
    valueResolver: (site) => site.commitmentKw,
    valueDisplayResolver: (site) =>
      `${numeral(site.commitmentKw).format('0,0')} kW`,
  },
  currentPerformanceKw: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE, mode: 'current' },
    label: 'Current Performance (kW)',
    value: 'currentPerformanceKw',
    valueResolver: (site) => site.performanceKw,
    valueDisplayResolver: performanceDisplayResolver(
      (site) => `${numeral(site.performanceKw).format('0,0')} kW`
    ),
  },
  currentPerformancePct: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE, mode: 'current' },
    label: 'Current Performance (%)',
    value: 'currentPerformancePct',
    valueResolver: (site) =>
      getPerformancePercentage(site.performanceKw, site.commitmentKw),
    valueDisplayResolver: performanceDisplayResolver(
      (site) =>
        `${numeral(
          getPerformancePercentage(site.performanceKw, site.commitmentKw)
        ).format('0')}%`
    ),
  },

  [ADMIN_SORT.MEGAWATTS_MISSING]: {
    filters: { tab: null },
    label: 'Missing Megawatts',
    value: ADMIN_SORT.MEGAWATTS_MISSING,
    valueResolver: (site) => site.commitmentKw - site.performanceKw,
    valueDisplayResolver: noop,
  },

  dispatchCommitment: {
    filters: { mode: 'dispatch' },
    label: 'Dispatch Commitment',
    value: 'dispatchCommitment',
    valueResolver: (site) => site.commitmentKwh,
    valueDisplayResolver: (site) =>
      `${numeral(site.commitmentKwh).format('0,0')} kWh`,
  },
  dispatchPerformanceKwh: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE, mode: 'dispatch' },
    label: 'Dispatch Performance (kWh)',
    value: 'dispatchPerformanceKwh',
    valueResolver: (site) => site.performanceKwh,
    valueDisplayResolver: performanceDisplayResolver(
      (site) => `${numeral(site.performanceKwh).format('0,0')} kWh`
    ),
  },
  dispatchPerformancePct: {
    filters: { tab: DISPATCH_DETAIL_TABS.PERFORMANCE, mode: 'dispatch' },
    label: 'Dispatch Performance (%)',
    value: 'dispatchPerformancePct',
    valueResolver: (site) =>
      getPerformancePercentage(site.performanceKwh, site.commitmentKwh),
    valueDisplayResolver: performanceDisplayResolver(
      (site) =>
        `${numeral(
          getPerformancePercentage(site.performanceKwh, site.commitmentKwh)
        ).format('0')}%`
    ),
  },
}

/**
 * Definte the columsn visible in the "List"
 * view of the detail page
 *
 * @param {string} tab - Enum of type DISPATCH_DETAIL_TABS
 * @param {string} mode - Enum of type LIST_MODE
 */
export const getListColumns = (tab, mode) => {
  if (tab === DISPATCH_DETAIL_TABS.PARTICIPATION) {
    return [
      sitePropertyResolvers.siteName,
      sitePropertyResolvers.potentialEarnings,
      sitePropertyResolvers.currentCommitment,
      sitePropertyResolvers.acknowledgments,
    ]
  }

  return [
    sitePropertyResolvers.siteName,
    sitePropertyResolvers.load,
    mode === LIST_MODE.CURRENT
      ? sitePropertyResolvers.currentCommitment
      : sitePropertyResolvers.dispatchCommitment,
    mode === LIST_MODE.CURRENT
      ? sitePropertyResolvers.currentPerformanceKw
      : sitePropertyResolvers.dispatchPerformanceKwh,
    mode === LIST_MODE.CURRENT
      ? sitePropertyResolvers.currentPerformancePct
      : sitePropertyResolvers.dispatchPerformancePct,
  ]
}

/**
 * Definte the rows visible in the "Grid"
 * view of the detail page
 *
 * @param {string} tab - Enum of type DISPATCH_DETAIL_TABS
 * @param {string} mode - Enum of type LIST_MODE
 */
export const getGridRows = (tab, mode) => {
  if (tab === DISPATCH_DETAIL_TABS.PARTICIPATION) {
    return [
      sitePropertyResolvers.siteName,
      {
        // Group key is used to flag
        // that these two properties are visually
        // grouped together
        type: 'group',
        children: [
          sitePropertyResolvers.potentialEarnings,
          sitePropertyResolvers.currentCommitment,
        ],
      },
      sitePropertyResolvers.acknowledgments,
    ]
  }

  // Performance Tab
  return [
    sitePropertyResolvers.siteName,
    mode === LIST_MODE.CURRENT
      ? sitePropertyResolvers.currentPerformancePct
      : sitePropertyResolvers.dispatchPerformancePct,
    {
      type: 'group',
      children: [
        mode === LIST_MODE.CURRENT
          ? sitePropertyResolvers.currentPerformanceKw
          : sitePropertyResolvers.dispatchPerformanceKwh,
        mode === LIST_MODE.CURRENT
          ? sitePropertyResolvers.currentCommitment
          : sitePropertyResolvers.dispatchCommitment,
      ],
    },
  ]
}

export const filterColumns = (tab, viewMode) =>
  Object.values(sitePropertyResolvers).filter((resolver) => {
    // No filters, never show
    if (!resolver.filters) {
      return false
    }

    if (
      Array.isArray(resolver.filters.tab) &&
      resolver.filters.tab.includes(tab)
    ) {
      if (resolver.filters.mode === viewMode) {
        return resolver.filters.mode === viewMode
      }

      return true
    }

    if (tab === resolver.filters.tab) {
      if (resolver.filters.mode) {
        return resolver.filters.mode === viewMode
      }

      return true
    }

    return false
  })
