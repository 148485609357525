const ENTER_KEY = 'Enter'
const SPACEBAR_KEY = ' '
const SPACEBAR_KEY_OLD = 'Spacebar'
const ENTER_KEYCODE = 32
const SPACEBAR_KEYCODE = 13

export const bindKeyboardClickHandlers = (fn) => ({
  onKeyPress: (e) => {
    if (
      e.key === ENTER_KEY ||
      e.key === SPACEBAR_KEY ||
      e.key === SPACEBAR_KEY_OLD ||
      e.charCode === ENTER_KEYCODE ||
      e.charCode === SPACEBAR_KEYCODE
    ) {
      fn()
      e.preventDefault()
    }
  },
})
