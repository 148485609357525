import { grpc } from '@improbable-eng/grpc-web'
import noop from 'lodash/noop'
import auth from '@voltus/auth'
import StreamsPB from '@voltus/grpc-clients/voc/streams/streams_pb'
import { Streams } from '@voltus/grpc-clients/voc/streams/streams_pb_service'
import logger from '@voltus/logger'
import { AnyObject } from '@voltus/types'

import { VOC_STREAMS_API_PATH, VOC_SNAPSHOTS_API_PATH } from './constants'
import { makegRPCHost } from './gRPCUtils'

const makeVOCStreamsHost = () => makegRPCHost(VOC_STREAMS_API_PATH)

type InitializeTelemetryStreamOpts = {
  onEnd: (code: grpc.Code, message: string, trailers: grpc.Metadata) => void
  onMessage: (data: AnyObject) => void
  portfolioId: number
}

interface SocketClient
  extends grpc.Client<grpc.ProtobufMessage, grpc.ProtobufMessage> {
  clear: () => void
}

let telemetryStream: SocketClient | null = null

export const intializeTelemetryStream = ({
  onEnd = noop,
  onMessage,
  portfolioId,
}: InitializeTelemetryStreamOpts): SocketClient => {
  if (telemetryStream) {
    return telemetryStream
  }

  const transport = grpc.WebsocketTransport()
  const grpcClient = grpc.client(Streams.StreamPortfolioData, {
    host: makeVOCStreamsHost(),
    transport,
  }) as SocketClient // cast so we can add the `clear` method later

  grpcClient.onMessage((message) => {
    try {
      if (message.toObject) {
        onMessage(message.toObject())
      }
    } catch (_) {
      logger.error('couldnt deserialize message')
    }
  })

  grpcClient.onEnd((...args) => {
    onEnd(...args)
    telemetryStream = null
  })

  // We know we have an access token if we're here, so cast to string
  grpcClient.start(
    new grpc.Metadata({ authorization: auth.accessToken as string })
  )

  // We add our own clear method onto the grpc client to make it easier
  // to re-initialize the telemetry stream
  grpcClient.clear = () => {
    telemetryStream = null
  }

  const request = new StreamsPB.StreamPortfolioDataRequest()
  request.setPortfolioId(portfolioId)
  grpcClient.send(request)

  telemetryStream = grpcClient
  return grpcClient
}
