import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
  InfiniteData,
} from '@tanstack/react-query'
import {
  GetUserMessagesRequest,
  GetUserMessagesResponse,
  SMSMessage,
} from '@voltus/grpc-clients/voc-sms/sms_pb'
import { getUserMessages } from '../../../routes/dispatches/routes/ActiveDispatches/api/smsClient'

type GetUserMessagesRequestParams = {
  userId: number
  limit?: number
  afterId?: number
  beforeId?: number
  enabled: boolean
}

export const useGetUserMessages = (
  requestBody: GetUserMessagesRequestParams
) => {
  return useInfiniteQuery({
    queryKey: [
      'getUserMessages',
      requestBody.userId,
      requestBody.afterId,
      requestBody.beforeId,
      requestBody.limit,
    ],
    staleTime: 0,
    initialPageParam: undefined,
    refetchOnWindowFocus: false,
    enabled: requestBody.enabled,
    getNextPageParam: (lastPage: GetUserMessagesResponse.AsObject) => {
      if (lastPage.hasMore) {
        const oldestMessage = lastPage.resultsList.slice(-1)[0]
        return oldestMessage.id
      }
    },
    queryFn: ({ pageParam = '' }) =>
      getUserMessages({
        ...requestBody,
        beforeId: pageParam,
      } as unknown as GetUserMessagesRequest.AsObject),
  })
}

export const flattenPaginatedMessages = (
  data?: InfiniteData<GetUserMessagesResponse.AsObject>
): Array<SMSMessage.AsObject> => {
  let allMessages: Array<SMSMessage.AsObject> = []

  if (data?.pages) {
    for (const page of data.pages) {
      allMessages = allMessages.concat(page.resultsList)
    }
  }
  return allMessages
}

export const useGetAllUserMessages = ({
  userId,
  enabled,
}: {
  userId: number
  enabled: boolean
}): UseQueryResult<GetUserMessagesResponse.AsObject> =>
  useQuery<GetUserMessagesResponse.AsObject>({
    queryKey: ['getAllUserMessages', userId],
    queryFn: () =>
      getUserMessages(<GetUserMessagesRequest.AsObject>{
        userId,
        // TODO: Update GetUserMessages endpoint to allow no limit on how many messages are returned.
        // In the meantime, this is the maximum limit that can be passed and it is sufficient to request all messages.
        limit: 9999999,
      }),
    enabled,
  })
