import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'
import {
  ACK_FILTER_OPTIONS,
  ADMIN_ASSIGNMENT_FILTER_OPTIONS,
  ADMIN_CONTROLS_FILTER_OPTIONS,
  ADMIN_STATUS_FILTER_OPTIONS,
  ADMIN_LIST_VIEW_FILTER_OPTIONS,
} from '../../constants'

export const { Types: UITypes, Creators: UICreators } = createActions({
  setSitesFilter: ['filter'],
  setSitesSortBy: ['sortBy'],
  setSitesSortOrder: ['sortOrder'],

  // filterByPerformanceDataAvailable is shared by the sites and admin pages
  setFilterByPerformanceDataAvailable: ['filterByPerformanceDataAvailable'],

  setIsMobile: ['isMobile'],

  setAdminSitesListViewFilter: ['listViewFilter'],
  setAdminSitesAssignmentFilter: ['assignmentFilter'],
  setAdminSitesControlsFilter: ['controlsFilter'],
  setAdminSitesStatusFilter: ['statusFilter'],
  setAdminSitesSortBy: ['sortBy'],
  setAdminSitesSortOrder: ['sortOrder'],
})

const INITIAL_STATE = {
  sitesSortBy: null,
  sitesSortOrder: -1,
  sitesFilter: ACK_FILTER_OPTIONS[0],

  // filterByPerformanceDataAvailable is shared by the sites and admin pages
  filterByPerformanceDataAvailable: false,

  adminSitesListViewFilter: ADMIN_LIST_VIEW_FILTER_OPTIONS[0],
  adminSitesAssignmentFilter: ADMIN_ASSIGNMENT_FILTER_OPTIONS[0],
  adminSitesControlsFilter: ADMIN_CONTROLS_FILTER_OPTIONS[0],
  adminSitesStatusFilter: ADMIN_STATUS_FILTER_OPTIONS[0],
  adminSitesSortBy: null,
  adminSitesSortOrder: -1,
}

const handleSetIsMobile = (state, { isMobile }) => {
  return produce(state, (draft) => {
    draft.isMobile = isMobile
  })
}

const handleSetSitesFilter = (state, { filter }) => {
  return produce(state, (draft) => {
    draft.sitesFilter = filter
  })
}

const handleSetSitesSortBy = (state, { sortBy }) => {
  return produce(state, (draft) => {
    draft.sitesSortBy = sortBy
  })
}
const handleSetSitesSortOrder = (state, { sortOrder }) => {
  return produce(state, (draft) => {
    draft.sitesSortOrder = sortOrder
  })
}

const handleSetAdminListViewFilter = (state, { listViewFilter }) => {
  return produce(state, (draft) => {
    draft.adminSitesListViewFilter = listViewFilter
  })
}
const handleSetAdminAssignmentFilter = (state, { assignmentFilter }) => {
  return produce(state, (draft) => {
    draft.adminSitesAssignmentFilter = assignmentFilter
  })
}
const handleSetAdminControlsFilter = (state, { controlsFilter }) => {
  return produce(state, (draft) => {
    draft.adminSitesControlsFilter = controlsFilter
  })
}
const handleSetAdminSitesStatusFilter = (state, { statusFilter }) => {
  return produce(state, (draft) => {
    draft.adminSitesStatusFilter = statusFilter
  })
}
const handleSetAdminSitesSortBy = (state, { sortBy }) => {
  return produce(state, (draft) => {
    draft.adminSitesSortBy = sortBy
  })
}
const handleSetAdminSitesSortOrder = (state, { sortOrder }) => {
  return produce(state, (draft) => {
    draft.adminSitesSortOrder = sortOrder
  })
}

const handleSetFilterByPerformanceDataAvailable = (
  state,
  { filterByPerformanceDataAvailable }
) => {
  return produce(state, (draft) => {
    draft.filterByPerformanceDataAvailable = filterByPerformanceDataAvailable
  })
}

export const uiReducer = createReducer(INITIAL_STATE, {
  [UITypes.SET_SITES_FILTER]: handleSetSitesFilter,
  [UITypes.SET_SITES_SORT_BY]: handleSetSitesSortBy,
  [UITypes.SET_SITES_SORT_ORDER]: handleSetSitesSortOrder,
  [UITypes.SET_FILTER_BY_PERFORMANCE_DATA_AVAILABLE]:
    handleSetFilterByPerformanceDataAvailable,

  [UITypes.SET_IS_MOBILE]: handleSetIsMobile,

  [UITypes.SET_ADMIN_SITES_LIST_VIEW_FILTER]: handleSetAdminListViewFilter,
  [UITypes.SET_ADMIN_SITES_ASSIGNMENT_FILTER]: handleSetAdminAssignmentFilter,
  [UITypes.SET_ADMIN_SITES_CONTROLS_FILTER]: handleSetAdminControlsFilter,
  [UITypes.SET_ADMIN_SITES_STATUS_FILTER]: handleSetAdminSitesStatusFilter,
  [UITypes.SET_ADMIN_SITES_SORT_BY]: handleSetAdminSitesSortBy,
  [UITypes.SET_ADMIN_SITES_SORT_ORDER]: handleSetAdminSitesSortOrder,
})
