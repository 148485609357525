import {
  KBarProviderProps,
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  KBarResults,
  useMatches,
  Action,
  useRegisterActions,
} from 'kbar'
import * as React from 'react'
import { featureFlagsManagerUpdateContextFlags } from '@voltus/api/featureFlagsManager/featureFlagsManager'
import { Flagresultitem } from '@voltus/api/featureFlagsManager/featureFlagsManager.schemas'
import { PATHS } from '@voltus/constants'
import { useFeatureFlagContext } from '@voltus/feature-flags'
import { useFeatureFlagDefinitionQuery, useProfileQuery } from '@voltus/queries'
import { Permissions } from '@voltus/types'
import { disableDemo, enableDemo, isNotNull, isProdHost } from '@voltus/utils'
import theme from '../../constants/theme'
import { Badge } from '../Badge'
import { Box } from '../Box'
import { Flex } from '../Flex'
import { Text } from '../Text'

const colors = theme.colors
const FEATURE_FLAG_PREFIX = 'feature-flag__'

export const commandBarActions = ({
  paths,
}: {
  paths: { [key: string]: string }
}) => {
  const actions: Array<Action> = [
    {
      id: 'manage-portfolios',
      name: 'Manage Portfolios',
      subtitle: 'Go to Portfolio Management',
      keywords: 'manage portfolios',
      section: 'Key Pages',
      perform: () => (window.location.href = '/admin/portfolios'),
    },
    {
      id: 'view-edit-users',
      name: 'View/Edit Users',
      subtitle: 'Go to list of VoltApp users',
      keywords: 'view edit users',
      section: 'Key Pages',
      perform: () => (window.location.href = '/admin/users/all'),
    },
    {
      id: 'view-edit-applications',
      name: 'View/Edit Applications',
      subtitle: 'Go to list of VoltApp applications',
      keywords: 'view edit users',
      section: 'Key Pages',
      perform: () => (window.location.href = '/admin/applications'),
    },
    {
      id: 'schedule-dispatch',
      name: 'Create a Dispatch',
      keywords: 'schedule dispatch',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.SCHEDULE_DISPATCH),
    },
    {
      id: 'schedule-test-dispatch',
      name: 'Schedule Test Dispatch',
      subtitle: 'Dispatch Verifications, comms tests, integration tests',
      keywords: 'schedule test dispatch',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.SCHEDULE_TEST_DISPATCH),
    },
    {
      id: 'view-events',
      name: 'View Dispatches',
      subtitle: 'View and manage Dispatches',
      keywords: 'view events',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.VIEW_EVENTS),
    },
    {
      id: 'flask-api-docs',
      name: 'Voltapp-flask API Docs',
      subtitle: '',
      keywords: 'api docs voltapp flask',
      section: 'Resources',
      perform: () => (window.location.href = '/internal/api-docs#'),
    },
    {
      id: 'redash',
      name: 'Redash',
      subtitle: 'Go to Redash',
      keywords: 'redash',
      section: 'Resources',
      perform: () =>
        (window.location.href = 'https://redash.cluster.voltus.co'),
    },
    {
      id: 'past-performance',
      name: 'Past Performance',
      subtitle: '',
      keywords: 'past performancej',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.PAST_PERFORMANCE),
    },
    {
      id: 'scheduler',
      name: 'Scheduler',
      keywords: 'scheduler',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.SCHEDULER),
    },
    {
      id: 'rted',
      name: 'Real-time Energy Data',
      subtitle: 'Go to RTED',
      keywords: 'realtime energy data',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.REALTIME_ENERGY),
    },
    {
      id: 'help-center',
      name: 'Voltan Help Center',
      subtitle: '',
      keywords: 'voltan help center',
      section: 'Key Pages',
      perform: () => window.open(PATHS.INTERNAL_HELP_CENTER),
    },
    {
      id: 'help-center-external',
      name: 'Customer Help Center',
      subtitle: '',
      keywords: 'customer help center',
      section: 'Key Pages',
      perform: () => window.open(PATHS.EXTERNAL_HELP_CENTER),
    },
    {
      id: 'active-dispatches',
      name: 'Active Dispatches',
      subtitle: '',
      keywords: 'active dispatches',
      section: 'Key Pages',
      perform: () => (window.location.href = PATHS.ACTIVE_DISPATCHES),
    },
    {
      id: 'logout',
      name: 'Logout',
      subtitle: '',
      keywords: 'logout',
      section: 'Actions',
      perform: () => (window.location.href = PATHS.LOGOUT),
    },
    {
      id: 'enable-demo',
      name: 'Enable Demo Mode',
      keywords: 'enable demo',
      section: 'Demo',
      perform: enableDemo,
    },
    {
      id: 'disable-demo',
      name: 'Disable Demo Mode',
      keywords: 'disable demo',
      section: 'Demo',
      perform: disableDemo,
    },
    ...Object.entries(paths ?? {})
      .map(([key, value]) => {
        // Don't bring in dynamic paths - we can't really fill these out with params without some magic
        if (value.includes(':')) {
          return null
        }

        return {
          id: key,
          name: key.toLowerCase().split('_').join(' '),
          section: 'All Paths',
          perform: () => (window.location.href = value),
        }
      })
      .filter(isNotNull),
  ]
  return actions
}

const getFlagEnvironment = (flag: Flagresultitem) =>
  isProdHost() ? flag.environments.production : flag.environments.development

const HIDDEN_TAGS = ['secret', 'hidden']
const shouldHideFlag = (flag: Flagresultitem) =>
  HIDDEN_TAGS.some((tag) => flag.tags?.includes(tag))

const useFeatureFlagActions = ({ enabled }: { enabled: boolean }) => {
  const { data: profileData } = useProfileQuery()
  const { data: flagDefinitions } = useFeatureFlagDefinitionQuery({
    enabled: enabled,
  })
  const [actions, setActions] = React.useState<Array<Action>>([])

  React.useEffect(() => {
    const ffActions: Array<Action> = []
    if (profileData?.email && flagDefinitions) {
      ffActions.push({
        id: 'feature-flags',
        name: 'Feature Flags',
        subtitle: 'Manage my feature flags',
        keywords: 'feature flags',
        section: 'Feature Flags',
      })
      ffActions.push({
        id: 'feature-flags-page-nav',
        parent: 'feature-flags',
        name: 'Go To Feature Flags Management Page',
        subtitle: 'Manage my feature flags',
        keywords: 'feature flags',
        section: 'Feature Flags',
        perform: () => (window.location.href = PATHS.INTERNAL_FLAGS),
      })

      for (const def of flagDefinitions.items) {
        if (shouldHideFlag(def) || getFlagEnvironment(def).on === false) {
          continue
        }
        if (def.variations.every(({ value }) => typeof value === 'boolean')) {
          const actionId = `${FEATURE_FLAG_PREFIX}${def.key}`
          ffActions.push({
            id: actionId,
            parent: 'feature-flags',
            name: `${def.name} Feature Flag`,
            section: 'Feature Flags',
          })
          ffActions.push({
            id: `enable-${actionId}`,
            parent: actionId,
            name: `Enable ${def.name} Feature Flag`,
            section: 'Feature Flags',
            perform: () => {
              featureFlagsManagerUpdateContextFlags({
                context: {
                  kind: 'user',
                  key: profileData.email,
                },
                flags: {
                  [def.key]: true,
                },
              })
            },
          })
          ffActions.push({
            id: `disabled-${actionId}`,
            parent: actionId,
            section: 'Feature Flags',
            name: `Disable ${def.name} Feature Flag`,
            perform: () => {
              featureFlagsManagerUpdateContextFlags({
                context: {
                  kind: 'user',
                  key: profileData.email,
                },
                flags: {
                  [def.key]: false,
                },
              })
            },
          })
        }
      }
      setActions(ffActions)
    }
  }, [profileData?.email, flagDefinitions])

  useRegisterActions(actions, [actions])
}

export const CommandBarProvider = ({
  children,
  actions,
  ...props
}: KBarProviderProps & { children: React.ReactNode }) => {
  const mergedOptions = {
    toggleShortcut: '$mod+Shift+k',
    disableScrollbarManagement: true,
    enableHistory: true,
    disableDocumentLock: true,
  }

  if (props.options) {
    Object.assign(mergedOptions, props.options)
  }

  return (
    <KBarProvider {...props} actions={actions} options={mergedOptions}>
      {children}
    </KBarProvider>
  )
}

export { KBarProvider }

type CommandBarProps = {
  permissions: Partial<Permissions>
}

export const CommandBar = ({ permissions }: CommandBarProps) => {
  const { results } = useMatches()
  useFeatureFlagActions({ enabled: permissions?.viewAdmin ?? false })
  const { variation } = useFeatureFlagContext()

  return permissions?.viewAdmin ? (
    <>
      <KBarPortal>
        <KBarPositioner style={{ zIndex: 99999 }}>
          <KBarAnimator
            style={{
              maxWidth: '600px',
              width: '100%',
              background: colors.background.primary,
              overflow: 'hidden',
              boxShadow: 'rgb(0 0 0 / 45%) 0px 0 20px 0px',
            }}
          >
            <KBarSearch
              style={{
                padding: '12px 16px',
                fontSize: '16px',
                width: '100%',
                boxSizing: 'border-box' as React.CSSProperties['boxSizing'],
                outline: 'none',
                border: 'none',
                borderBottom: '1px solid #eee',
              }}
            />
            <KBarResults
              items={results}
              onRender={({ item, active }) => {
                if (typeof item === 'string') {
                  return (
                    <Box display="flex" alignItems="center" borderBottom={1}>
                      <Text.Helper py={2} px={12} color="textColor.muted">
                        {item}
                      </Text.Helper>
                    </Box>
                  )
                }

                const isFeatureFlag = item.id.startsWith(FEATURE_FLAG_PREFIX)
                const featureFlagKey = isFeatureFlag
                  ? item.id.slice(FEATURE_FLAG_PREFIX.length)
                  : null
                return (
                  <Flex.Row
                    bg={active ? 'grays.5' : 'white'}
                    cursor="pointer"
                    px={12}
                    py={16}
                    minHeight={44}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Text.P>{item.name}</Text.P>
                      {item.subtitle ? (
                        <Text.Helper display="block">
                          {item.subtitle}
                        </Text.Helper>
                      ) : null}
                    </Box>
                    {isFeatureFlag && featureFlagKey ? (
                      <>
                        {variation(featureFlagKey) ? (
                          <Badge.Primary>on</Badge.Primary>
                        ) : (
                          <Badge.Muted>off</Badge.Muted>
                        )}
                      </>
                    ) : null}
                  </Flex.Row>
                )
              }}
            />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
    </>
  ) : null
}
