import { useQuery } from '@tanstack/react-query'
import { network, endpoints, makeQueryFetcher } from '@voltus/network'
import { Portfolios } from '@voltus/types'

export const useUserPortfoliosQuery = ({
  include,
}: {
  include?: Array<string>
} = {}) => {
  return useQuery({
    queryKey: ['user-portfolios', include],
    queryFn: makeQueryFetcher<Portfolios>(() =>
      network.get(endpoints.portfolio.user({ include }))
    ),
  })
}
