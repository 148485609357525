import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { UpdateMessageResponse } from '@voltus/grpc-clients/voc-sms/sms_pb'
import {
  updateMessage,
  UpdateMessageParams,
} from '../../../routes/dispatches/routes/ActiveDispatches/api/smsClient'

export const useUpdateMessage =
  (): UseMutationResult<UpdateMessageResponse.AsObject> => {
    return useMutation({
      mutationFn: (updateMessageRequest: UpdateMessageParams) =>
        updateMessage(updateMessageRequest),
    })
  }
