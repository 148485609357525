import { toString } from 'lodash'
import qs from 'query-string'
import { AnyObject } from '@voltus/types'
/**
 * Function to fill path string with a set of params in order to create URN.
 */

type Opts = {
  search?: AnyObject | string
  hasTrailingSlash?: boolean
}

const addSearch = (url: string, searchParams?: AnyObject | string): string => {
  if (!searchParams) {
    return url
  }

  if (typeof searchParams === 'string') {
    return `${url}?${searchParams}`
  }

  return `${url}?${qs.stringify(searchParams)}`
}

const getResolvedPath = (
  path: string,
  params?: Array<string | number>,
  opts?: Opts
): string => {
  const defaultOpts: Opts = {
    hasTrailingSlash: true,
  }
  const mergedOpts = Object.assign({}, defaultOpts, opts)
  if (!params) {
    return addSearch(path, opts?.search)
  }

  const paramsAsStrings = params.map((param) => toString(param))

  return addSearch(
    paramsAsStrings.reduce((tempUrn, param) => {
      const replace = mergedOpts?.hasTrailingSlash ? `${param}/` : param
      return tempUrn.replace(/(:.*?\/)|(:.*)/, replace)
    }, path),
    opts?.search
  )
}

export default getResolvedPath
