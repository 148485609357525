/* eslint-disable */
/**
 * Generated by orval 🍺
 * Do not edit manually.
 * Feature Flag Service
 * HTTP Service for interacting with Launch Darkly
 * OpenAPI spec version: 1.0
 */
import type {
  Flagresult,
  UpdateContextFlagsRequestBody,
  UpdateUserFlagsRequestBody,
} from './featureFlagsManager.schemas'
import { httpClient } from '../../network/src/httpClient'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * Fetches all available feature flags
 * @summary all-flags feature-flags-manager
 */
export const featureFlagsManagerAllFlags = (
  options?: SecondParameter<typeof httpClient>
) => {
  return httpClient<Flagresult>(
    { url: `/api/feature-flags/v0.1/all-flags`, method: 'GET' },
    options
  )
}

/**
 * Healthcheck for Feature Flags service
 * @summary health feature-flags-manager
 */
export const featureFlagsManagerHealth = (
  options?: SecondParameter<typeof httpClient>
) => {
  return httpClient<void>(
    { url: `/api/feature-flags/v0.1/health`, method: 'GET' },
    options
  )
}

/**
 * Updates a flags status for a context
 * @summary update-context-flags feature-flags-manager
 */
export const featureFlagsManagerUpdateContextFlags = (
  updateContextFlagsRequestBody: UpdateContextFlagsRequestBody,
  options?: SecondParameter<typeof httpClient>
) => {
  return httpClient<void>(
    {
      url: `/api/feature-flags/v0.1/update-context-flags`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: updateContextFlagsRequestBody,
    },
    options
  )
}

/**
 * Updates a flag status for a single user
 * @summary update-user-flags feature-flags-manager
 */
export const featureFlagsManagerUpdateUserFlags = (
  updateUserFlagsRequestBody: UpdateUserFlagsRequestBody,
  options?: SecondParameter<typeof httpClient>
) => {
  return httpClient<void>(
    {
      url: `/api/feature-flags/v0.1/update-user-flags`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserFlagsRequestBody,
    },
    options
  )
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export type FeatureFlagsManagerAllFlagsResult = NonNullable<
  Awaited<ReturnType<typeof featureFlagsManagerAllFlags>>
>
export type FeatureFlagsManagerHealthResult = NonNullable<
  Awaited<ReturnType<typeof featureFlagsManagerHealth>>
>
export type FeatureFlagsManagerUpdateContextFlagsResult = NonNullable<
  Awaited<ReturnType<typeof featureFlagsManagerUpdateContextFlags>>
>
export type FeatureFlagsManagerUpdateUserFlagsResult = NonNullable<
  Awaited<ReturnType<typeof featureFlagsManagerUpdateUserFlags>>
>
