import isNil from 'lodash/isNil'
import * as React from 'react'
import { usePrevious } from '@voltus/utils'

const defaultBreakpoints = {
  mobile: 600,
  tablet: 800,
}

export const useBreakpointsArray = (
  breakpoints: Array<number>
): Array<boolean> => {
  const previousBreakpoints = usePrevious(breakpoints)
  const memoizedBreakpoints = React.useMemo(() => {
    if (breakpoints?.length !== previousBreakpoints?.length) {
      return breakpoints
    }

    if (
      // One of the breakpoints is different
      breakpoints.some((breakpoint, i) => {
        return breakpoint !== previousBreakpoints?.[i]
      })
    ) {
      return breakpoints
    }

    return previousBreakpoints
  }, [previousBreakpoints, breakpoints])

  const initialWidth =
    isNil(document.documentElement.clientWidth) ||
    document.documentElement.clientWidth === 0
      ? window.innerWidth
      : document.documentElement.clientWidth
  const [res, setRes] = React.useState<Array<boolean>>(
    memoizedBreakpoints.map((breakpoint) => initialWidth < breakpoint)
  )
  React.useLayoutEffect(() => {
    const listener = (e: Event) => {
      const clientWidth = (e.target as Window)?.document.documentElement
        ?.clientWidth
      setRes(
        memoizedBreakpoints.map((breakpoint) => {
          return clientWidth < breakpoint
        })
      )
    }

    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [memoizedBreakpoints, setRes])

  return res
}

export const useBreakpoints = (breakpoints: {
  mobile?: number
  tablet?: number
}): { isMobile: boolean; isTablet: boolean } => {
  const mergedBreakpoints = {
    ...defaultBreakpoints,
    ...breakpoints,
  }

  const { mobile, tablet } = mergedBreakpoints
  const width =
    isNil(document.documentElement.clientWidth) ||
    document.documentElement.clientWidth === 0
      ? window.innerWidth
      : document.documentElement.clientWidth

  const [isMobile, setIsMobile] = React.useState(width < mobile)
  const [isTablet, setIsTablet] = React.useState(width < tablet)

  React.useLayoutEffect(() => {
    const listener = (e) => {
      const { clientWidth } = e.target.document.documentElement
      if (clientWidth < mobile) {
        setIsMobile(true)
        setIsTablet(false)
      } else if (clientWidth < tablet) {
        setIsMobile(false)
        setIsTablet(true)
      } else {
        setIsMobile(false)
        setIsTablet(false)
      }
    }

    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, Object.values(mergedBreakpoints))

  return { isMobile, isTablet }
}
