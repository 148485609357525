import noop from 'lodash/noop'
import * as React from 'react'

type GlobalSidebarProviderProps = {
  children: React.ReactNode
}

type GlobalSidebarState = {
  isPrimarySidebarOpen: boolean
  isSecondarySidebarOpen: boolean
  primaryChild?: () => JSX.Element | null
  secondaryChild?: () => JSX.Element | null
}

export enum GlobalSidebarActionType {
  setPrimarySidebarOpen = 'setPrimarySidebarOpen',
  setSecondarySidebarOpen = 'setSecondarySidebarOpen',
  togglePrimarySidebar = 'togglePrimarySidebar',
  toggleSecondarySidebar = 'toggleSecondarySidebar',
  toggleEntireSidebar = 'toggleEntireSidebar',
  setPrimaryChild = 'setPrimaryChild',
  setSecondaryChild = 'setSecondaryChild',
}

type GlobalSidebarAction =
  | { type: GlobalSidebarActionType.setPrimarySidebarOpen; payload: boolean }
  | { type: GlobalSidebarActionType.setSecondarySidebarOpen; payload: boolean }
  | { type: GlobalSidebarActionType.togglePrimarySidebar }
  | { type: GlobalSidebarActionType.toggleSecondarySidebar }
  | { type: GlobalSidebarActionType.toggleEntireSidebar }
  | {
      type: GlobalSidebarActionType.setPrimaryChild
      payload: () => JSX.Element | null
    }
  | {
      type: GlobalSidebarActionType.setSecondaryChild
      payload: () => JSX.Element | null
    }

type GlobalSidebarDispatch = (action: GlobalSidebarAction) => void

const defaultState: GlobalSidebarState = {
  isPrimarySidebarOpen: false,
  isSecondarySidebarOpen: false,
  primaryChild: undefined,
  secondaryChild: undefined,
}

const GlobalSidebarContext =
  React.createContext<GlobalSidebarState>(defaultState)
const GlobalSidebarDispatchContext =
  React.createContext<GlobalSidebarDispatch>(noop)

const toggleEntireSidebar = (state: GlobalSidebarState): GlobalSidebarState => {
  const isOpen = state.isPrimarySidebarOpen || state.isSecondarySidebarOpen

  if (isOpen) {
    return {
      ...state,
      isPrimarySidebarOpen: false,
      isSecondarySidebarOpen: false,
    }
  }
  return {
    ...state,
    isPrimarySidebarOpen: true,
  }
}

const reducer = (state: GlobalSidebarState, action: GlobalSidebarAction) => {
  switch (action.type) {
    case GlobalSidebarActionType.setPrimarySidebarOpen:
      return {
        ...state,
        isPrimarySidebarOpen: action.payload,
      }
    case GlobalSidebarActionType.setSecondarySidebarOpen:
      return {
        ...state,
        isSecondarySidebarOpen: action.payload,
      }
    case GlobalSidebarActionType.togglePrimarySidebar:
      return {
        ...state,
        isPrimarySidebarOpen: !state.isPrimarySidebarOpen,
      }
    case GlobalSidebarActionType.toggleSecondarySidebar:
      return {
        ...state,
        isSecondarySidebarOpen: !state.isSecondarySidebarOpen,
      }
    case GlobalSidebarActionType.toggleEntireSidebar:
      return toggleEntireSidebar(state)
    case GlobalSidebarActionType.setPrimaryChild:
      return {
        ...state,
        primaryChild: action.payload,
      }
    case GlobalSidebarActionType.setSecondaryChild:
      return {
        ...state,
        secondaryChild: action.payload,
      }
    default:
      return state
  }
}

function GlobalSidebarProvider({
  children,
}: GlobalSidebarProviderProps): JSX.Element {
  const [globalSidebarState, dispatch] = React.useReducer(reducer, defaultState)

  return (
    <GlobalSidebarContext.Provider value={globalSidebarState}>
      <GlobalSidebarDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalSidebarDispatchContext.Provider>
    </GlobalSidebarContext.Provider>
  )
}

function useGlobalSidebarContext(): [
  GlobalSidebarState,
  GlobalSidebarDispatch,
] {
  return [
    React.useContext(GlobalSidebarContext),
    React.useContext(GlobalSidebarDispatchContext),
  ]
}

export { GlobalSidebarProvider, useGlobalSidebarContext }
