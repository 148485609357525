import produce from 'immer'
import { setAutoFreeze } from 'immer'
import { setWith, set, get as lodashGet, isNil } from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import logger from '@voltus/logger'

// This is dumb - but we're coming from a _really_ old version of immer,
// that didn't, by default, freeze produced objects, so after updating
// immer we started seeing an error related to trying to extend non-extensible
// objects. This sets an immer default to get it back to how it used to behave
// TODO: @jcharry update downstream usages so we can remove this hack, eventually
setAutoFreeze(false)

import {
  getFacilityDispatchPath,
  getFacilityTargetsPath,
  getPortfolioDispatchPath,
  getFacilitySparklinesPath,
} from './dispatch.helpers'

/*
 * This is a bit annoying, but lodash `get` only returns the
 * fallback value if that resolved value is `undefined`. If the resolved
 * value is `null`, lodash will return null, not the fallback value.
 * I guess this is desired behavior, but for this module, I need
 * the fallback to also return if the value is null.
 */
const get = (data, path, fallback) => {
  const res = lodashGet(data, path)
  if (isNil(res)) {
    return fallback
  }

  return res
}

export const { Types: DispatchTypes, Creators: DispatchActions } =
  createActions(
    {
      // These fire off fetches
      getSnapshot: ['portfolioId', 'dispatchId', 'isInitial'],

      // Reducers called by sagas
      getSnapshotSuccess: ['portfolioId', 'dispatchId', 'data'],
      getSnapshotFailure: ['portfolioId', 'dispatchId', 'error'],

      closeAllSockets: null,

      receiveDispatchDetails: ['portfolioId', 'dispatchId', 'data'],

      pinSite: ['site', 'isPinned'],
      setUserAckStatus: ['user', 'status', 'cb'],

      pushRealtimeUserAck: ['ack'],
      dismissRealtimeAcks: [],

      // Websocket event reducer
      updatePortfolioDispatch: ['data'],
      updateUserAcknowledgments: ['data'],
      updateFacilityLoad: ['batch'],
      updateFacilityTarget: ['batch'],
      updateFacilityPerformanceKw: ['batch'],
      updateFacilityPerformanceKwh: ['batch'],
      updatePortfolioPerformanceKw: ['batch'],
      updatePortfolioPerformanceKwh: ['batch'],

      // Handle applying batched actions from websocket data
      propagateBatchData: ['batch'],
      enqueueBatchUpdate: [],
      updateIsPropagatingBatchUpdates: ['isPropagatingBatchUpdates'],
    },
    {
      prefix: 'DISPATCHES/DISPATCH/',
    }
  )

export const INITIAL_STATE = {
  list: [],
  snapshots: {},
  activeDispatches: {},
  isFetchingSnapshot: false,
  pinned: {},
  details: {},
  realtimeAcks: [],
  // Only propagate batch updates when the document is visible.
  // This is a performance enhancement for when users have multiple tabs open.
  isPropagatingBatchUpdates: false,
  errors: {
    activeDispatches: null,
    snapshot: null,
  },
}

/**
 * Initial reducer for getting a snapshot
 * Triggers saga that performs the fetch
 */
const getSnapshot = produce((draft, { isInitial }) => {
  draft.isFetchingSnapshot = isInitial
})

/**
 * Success reducer for snapshot data from BE
 * @param {Object} state - redux state
 * @param {Object} data
 * @param {number} data.portfolioId - snapshot data
 * @param {number} data.dispatchId - snapshot data
 * @param {Object} data.data - snapshot data
 */
const getSnapshotSuccess = produce(
  (draft, { portfolioId, dispatchId, data }) => {
    draft.isFetchingSnapshot = false
    setWith(draft.snapshots, [dispatchId, portfolioId], data, Object)
  }
)

/**
 * Failure reducer for fetching snapshots
 */
const getSnapshotFailure = produce((draft) => {
  draft.isFetchingSnapshot = false
}, {})

/**
 * Reducer for websocket event of type `portfolio_dispatch`
 * within a given snapshot
 *
 * @param {Object} state - redux state
 * @param {Object} data
 * @param {number} data.portfolioId
 * @param {number} data.dispatchId
 * @param {Object} data.data - snapshot data
 */
const updatePortfolioDispatch = produce((draft, { data }) => {
  const portfolioDispatchPath = getPortfolioDispatchPath(
    data.portfolioId,
    data.dispatchId
  )
  setWith(draft, portfolioDispatchPath, data, Object)
})

/**
 * Reducer for websocket event of type `facility_acknowledgment`
 * within a given snapshot
 *
 * @param {Object} state - redux state
 * @param {Object} data
 * @param {number} data.portfolioId
 * @param {number} data.dispatchId
 * @param {Object} data.data - snapshot data
 */
const updateUserAcknowledgments = produce((draft, { data }) => {
  const { portfolioId, dispatchId, userId, facilityId } = data
  const facilityDispatchPath = getFacilityDispatchPath(portfolioId, dispatchId)
  const facilities = get(draft, facilityDispatchPath, [])
  const facility = facilities.find((fac) => fac.id === facilityId)
  if (!facility) {
    return
  }

  // Update list of facilities with the newly updated user acknowledgment
  const userAcks = facility.acknowledgments
  const newFacilities = facilities.map((fac) => {
    if (+facilityId === +fac.id) {
      return {
        ...fac,
        acknowledgments: userAcks.map((user) => {
          if (user.userId === userId) {
            return data
          }
          return user
        }),
      }
    }

    // Make sure not to update facilities that aren't relevant, to ensure
    // we keep the same object references to the facs that haven't changed
    return fac
  })

  set(draft, facilityDispatchPath, newFacilities)
})

const updateFacilityLoad = produce((draft, { batch }) => {
  batch.forEach((data) => {
    const { value, facilityId, portfolioId, dispatchId } = data
    const facilityDispatchPath = getFacilityDispatchPath(
      portfolioId,
      dispatchId
    )
    const facilitySparklinesPath = [
      ...getFacilitySparklinesPath(portfolioId, dispatchId),
      facilityId,
    ]
    const newFacilities = get(draft, facilityDispatchPath, []).map((fac) => {
      return fac.facilityId === facilityId
        ? {
            ...fac,
            load: value,
          }
        : fac
    })
    set(draft, facilityDispatchPath, newFacilities)

    const newSparklines = [...get(draft, facilitySparklinesPath, []), data]
    set(draft, facilitySparklinesPath, newSparklines)
  })
})

/**
 * Reducer for websocket event `facility_load_kw`
 * Pushes new target data into the faciltyTarget array
 * within a given snapshot
 *
 * @param {Object} state = Redux state
 * @param {Object} data - websocket event data
 */
const updateFacilityTarget = produce((draft, { batch }) => {
  batch.forEach((data) => {
    const {
      facilityId,
      portfolioId,
      dispatchId,
      target,
      timestamp,
      baselineType,
    } = data
    const facilityTargetsPath = [
      ...getFacilityTargetsPath(portfolioId, dispatchId),
      facilityId,
    ]
    const facilityTargets = get(draft, facilityTargetsPath)
    facilityTargets.push({
      timestamp: timestamp,
      value: target,
      baselineType: baselineType,
    })
  })
})

const makeUpdateFacilityPerformance = (keys) =>
  produce((draft, { batch }) => {
    batch.forEach((data) => {
      const { facilityId, portfolioId, dispatchId } = data
      const facilityDispatchPath = getFacilityDispatchPath(
        portfolioId,
        dispatchId
      )
      const newFacilities = get(draft, facilityDispatchPath, []).map((fac) => {
        if (fac.facilityId === facilityId) {
          const newProps = {}
          keys.forEach(({ targetKey, dataKey }) => {
            newProps[targetKey] = data[dataKey]
          })
          return {
            ...fac,
            ...newProps,
          }
        }

        return fac
      })
      set(draft, facilityDispatchPath, newFacilities)
    })
  })

const makeUpdatePortfolioPerformance = (keys) =>
  produce((draft, { batch }) => {
    batch.forEach((data) => {
      const { portfolioId, dispatchId } = data
      const portfolioDispatchPath = getPortfolioDispatchPath(
        portfolioId,
        dispatchId
      )

      const portfolioDispatchData = get(draft, portfolioDispatchPath, [])
      if (portfolioDispatchData.baselineType === data.baselineType) {
        keys.forEach(({ targetKey, dataKey }) => {
          set(draft, [...portfolioDispatchPath, targetKey], data[dataKey])
        })
      }
    })
  })

const pinSite = (state, { site, isPinned }) => {
  return produce(state, (draft) => {
    draft.pinned[site.id] = isNil(isPinned)
      ? !state?.pinned?.[site.id]
      : isPinned
  })
}

const pushRealtimeUserAck = produce((draft, { ack }) => {
  draft.realtimeAcks.push(ack)
})

const dismissRealtimeAcks = produce((draft) => {
  draft.realtimeAcks = []
}, {})

/**
 * Group the reducers that can be applied in batches
 */
const batchReducers = {
  [DispatchTypes.UPDATE_FACILITY_LOAD]: updateFacilityLoad,
  [DispatchTypes.UPDATE_FACILITY_TARGET]: updateFacilityTarget,
  [DispatchTypes.UPDATE_FACILITY_PERFORMANCE_KW]: makeUpdateFacilityPerformance(
    [{ targetKey: 'performanceKw', dataKey: 'kw' }]
  ),
  [DispatchTypes.UPDATE_FACILITY_PERFORMANCE_KWH]:
    makeUpdateFacilityPerformance([
      { targetKey: 'performanceKwh', dataKey: 'kwh' },
    ]),
  [DispatchTypes.UPDATE_PORTFOLIO_PERFORMANCE_KW]:
    makeUpdatePortfolioPerformance([
      { targetKey: 'performanceKw', dataKey: 'kw' },
    ]),
  [DispatchTypes.UPDATE_PORTFOLIO_PERFORMANCE_KWH]:
    makeUpdatePortfolioPerformance([
      { targetKey: 'performanceKwh', dataKey: 'kwh' },
    ]),
}

/**
 * Reducer to handle batched events
 * Runs through all the batchReducers defined
 * above, applies their reducer, and returns the collated state
 *
 */
const propagateBatchData = (state, { batch }) => {
  try {
    return produce(state, (draftState) => {
      Object.entries(batchReducers).reduce(
        (resultingState, [type, reducer]) => {
          return reducer(resultingState, { batch: batch[type] || [] })
        },
        draftState
      )
    })
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    return state
  }
}

const updateIsPropagatingBatchUpdates = produce(
  (draft, { isPropagatingBatchUpdates }) => {
    draft.isPropagatingBatchUpdates = isPropagatingBatchUpdates
  }
)

export const dispatchReducer = createReducer(INITIAL_STATE, {
  // Basic actions for fetching dispatch data
  [DispatchTypes.GET_SNAPSHOT]: getSnapshot,
  [DispatchTypes.GET_SNAPSHOT_SUCCESS]: getSnapshotSuccess,
  [DispatchTypes.GET_SNAPSHOT_FAILURE]: getSnapshotFailure,

  [DispatchTypes.PIN_SITE]: pinSite,
  [DispatchTypes.PUSH_REALTIME_USER_ACK]: pushRealtimeUserAck,
  [DispatchTypes.DISMISS_REALTIME_ACKS]: dismissRealtimeAcks,

  // Websocket events
  [DispatchTypes.UPDATE_USER_ACKNOWLEDGMENTS]: updateUserAcknowledgments,
  [DispatchTypes.UPDATE_PORTFOLIO_DISPATCH]: updatePortfolioDispatch,
  [DispatchTypes.PROPAGATE_BATCH_DATA]: propagateBatchData,
  [DispatchTypes.UPDATE_IS_PROPAGATING_BATCH_UPDATES]:
    updateIsPropagatingBatchUpdates,
})
