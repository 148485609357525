import classnames from 'classnames'
import { noop } from 'lodash'
import * as React from 'react'
import ReactSelect, {
  ActionMeta,
  SelectInstance,
  GroupBase,
  Props,
} from 'react-select'

import {
  TreeSelectMultiValueLabel,
  TreeSelectValueContainer,
  TreeSelectCustomOption,
  FlatCustomOption,
  CustomMenuList,
  CustomDropdownIndicator,
  CustomMultiValueRemove,
  VIRTUALIZED_LIST_LENGTH,
} from '../../Dropdown/TreeSelectCustomComponents'
import { Node } from '../../Dropdown/optionsTree'
import { useTheme } from '../../ThemeProvider'

const customFilterOption = (option, rawInput) => {
  const words = rawInput.split(' ')
  const wordToMatch = option?.data?.option?.filterOption ?? option?.label

  const matches = words.every((word) => {
    return wordToMatch?.toLowerCase().includes(word.toLowerCase())
  })
  if (matches) {
    return true
  }

  const node = option.data
  if (node.childNodes) {
    let childMatches = false
    // check if any child value matches the input
    node.visitChildren((childNode) => {
      if (
        words.every((word) => {
          return childNode?.label?.toLowerCase().includes(word.toLowerCase())
        })
      ) {
        childMatches = true
      }
    })

    if (childMatches) {
      return true
    }
  }

  return false
}

/**
 * We extend the React select props according to their docs:
 * https://react-select.com/typescript#select-generics
 *
 * This is to allow passing extra arguments to what basically
 * amounts to a wrapped react-select component
 */
interface SiteSwitcherDropdownProps<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  ValueType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Node<ValueType>> = GroupBase<Node<ValueType>>,
> extends Props<Node<ValueType>, IsMulti, Group> {
  onOpen?: (
    options: Array<Node<ValueType>>,
    value: Node<ValueType> | Array<Node<ValueType>>
  ) => void
  onClose?: (
    options: Array<Node<ValueType>>,
    value: Node<ValueType> | Array<Node<ValueType>>
  ) => void
  isFetching?: boolean
  isTreeSelect?: boolean
  multiValueLabelSuffix?: string
  value?: Node<ValueType> | Array<Node<ValueType>>
  zIndex?: number
}

/**
 * A Wrapper around React-select that adds our custom styles
 * and passes in custom components
 */
export function SiteSwitcherDropdown<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  ValueType,
  IsMulti extends boolean = false,
  Group extends GroupBase<Node<ValueType>> = GroupBase<Node<ValueType>>,
>(props: SiteSwitcherDropdownProps<ValueType, IsMulti, Group>) {
  const theme = useTheme()
  const [inputValue, setInputValue] = React.useState('')

  const {
    className,
    isFetching = false,
    isSearchable = true,
    onChange,
    isTreeSelect = false,
    value,
    options,
    isMulti = false,
    multiValueLabelSuffix,
    onClose = noop,
    onOpen = noop,
    backspaceRemovesValue = true,
    hideSelectedOptions = false,
    zIndex,
    ...rest
  } = props
  const selectRef = React.useRef<SelectInstance>(null)

  const isVirtualized = options
    ? options.length >= VIRTUALIZED_LIST_LENGTH
    : false

  const handleChange:
    | ((
        newValue: IsMulti extends true
          ? Array<Node<ValueType>>
          : Node<ValueType>,
        actionMeta: ActionMeta<Node<ValueType>>
      ) => void)
    | undefined = (...args) => {
    // There's a weird focus bug where if you select a single tree option
    // then for some reason the entire select becomes unfocused. I'm not sure
    // why this happens. But this hacks around it by forcing the select to stay
    // focused after a change event fires.
    // The setTimeout is to ensure this happens after the next render
    setTimeout(() => selectRef.current?.focus())

    return onChange?.(...args)
  }

  const customComponents = isTreeSelect
    ? {
        ValueContainer: TreeSelectValueContainer,
        MultiValueLabel: TreeSelectMultiValueLabel,
        Option: TreeSelectCustomOption,
      }
    : {
        Option: FlatCustomOption,
      }

  return (
    // eslint-disable-next-line
    // @ts-ignore
    <ReactSelect
      {...rest}
      className={classnames(
        'site-switcher-select',
        {
          'site-switcher-select--fetching': isFetching,
          'site-switcher-select--searchable': isSearchable,
        },
        className
      )}
      options={options}
      inputValue={inputValue}
      onMenuClose={() => {
        // Reset the input value when the menu closes
        setInputValue('')
        // We have some fun behavior where after closing and re-opening the menu,
        // the items we selected should be sorted to the top of the list
        // To achieve this we call onChange again but with the values sorted
        onClose(options, value)
      }}
      onMenuOpen={() => {
        onOpen(options, value)
      }}
      onInputChange={(val, { action }) => {
        if (action === 'input-change') {
          // There's a weird bug where if you type to search
          // and there's no value, then after the first character
          // the input loses focus, meaning you can't type anymore
          // to hack around this, forcibly re-focus the input after
          // a change event
          // the setTimeout is to ensure this happens after the next render
          setTimeout(() => selectRef.current?.focus())
          setInputValue(val)
        }
      }}
      value={value}
      styles={{
        placeholder: (provided) => ({
          ...provided,
          fontSize: 24,
          color: 'unset',
        }),
        multiValue: (provided) => {
          const extra: {
            backgroundColor?: string
          } = {}
          if (isTreeSelect && Array.isArray(value) && value?.length > 1) {
            extra.backgroundColor = theme.colors.greens['60']
          }

          return {
            ...provided,
            borderRadius: 22,
            margin: 0,
            alignItems: 'center',
            paddingLeft: 4,
            paddingRight: 4,
            ...extra,
          }
        },
        indicatorSeparator: () => ({
          display: 'none',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: theme.colors.textColor.main,
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          padding: 0,
          fontSize: 24,
          fontWeight: 'bold',
          margin: 0,
          marginRight: '4px',
          paddingLeft: theme.space[2],
          color: 'white',
        }),
        option: (provided, data) => {
          const node = data.data as Node<ValueType>
          const {
            isSelected,
            isSaturated,
            isIndeterminate,
            isRootIndeterminte,
            isRootSaturated,
            isRootSelected,
            // Sorry for the cast - react-select is being very particular with this type
          } = node.getNodeState(value, data.options as Array<Node<ValueType>>)

          const isHighlighted =
            isRootIndeterminte ||
            isRootSaturated ||
            isRootSelected ||
            isSelected ||
            isSaturated ||
            isIndeterminate

          let background = 'white'
          if (isHighlighted || data.isSelected) {
            background = theme.colors.grays['5']
          }

          return {
            ...provided,
            background: node.isAllOption ? 'white' : background,
            fontSize: '14px',
            position: 'relative',
            fontWeight: node.isRoot ? 'bold' : 'normal',
            color: theme.colors.textColor.main,
            lineHeight: '24px',
            marginLeft: '8px',
            marginRight: '8px',
            marginTop: node.isRoot ? '4px' : 0,
            width: 'unset',
            padding: '4px 20px',
            paddingLeft: node.depth * 12 + 4,
            whiteSpace: 'nowrap',
            cursor: 'pointer',
          }
        },
        singleValue: (provided) => ({
          ...provided,
          whiteSpace: 'pre-wrap',
        }),
        menuList: (provided) => ({
          ...provided,
          marginBottom: '4px',
        }),
        menuPortal: (provided) => ({
          ...provided,
          zIndex: zIndex,
        }),
        input: (provided) => {
          if (isTreeSelect) {
            return {
              opacity: 0,
              width: 1,
            }
          }

          return {
            ...provided,
            fontSize: 24,
            fontWeight: 'bold',
            color: theme.colors.textColor.main,
          }
        },
      }}
      onChange={handleChange}
      isDisabled={isFetching}
      isMulti={isTreeSelect ? true : isMulti}
      backspaceRemovesValue={isTreeSelect ? false : backspaceRemovesValue}
      isClearable={false}
      tabSelectsValue={false}
      isSearchable={isSearchable || isVirtualized}
      hideSelectedOptions={isTreeSelect ? false : hideSelectedOptions}
      filterOption={customFilterOption}
      classNamePrefix="site-switcher-select"
      closeMenuOnSelect={!isTreeSelect}
      isFetching={isFetching}
      isTreeSelect={isTreeSelect}
      isVirtualized={isVirtualized}
      multiValueLabelSuffix={multiValueLabelSuffix}
      ref={selectRef}
      // eslint-disable-next-line
      // @ts-ignore
      components={{
        ...customComponents,
        MenuList: CustomMenuList,
        DropdownIndicator: CustomDropdownIndicator,
        MultiValueRemove: CustomMultiValueRemove,
      }}
    />
  )
}
