import get from 'lodash/get'
import { createSelector } from 'reselect'
import { addTargetsToSite } from '../../utils'
import { selectPortfolioId, selectDispatchId } from '../routeParams'

const rootPath = ['dispatches', 'dispatch']

export const selectDispatchDomain = (state) => get(state, rootPath)
export const selectDispatchesList = (state) => get(state, rootPath).list

export const selectSnapshots = (state) => get(state, rootPath).snapshots
export const selectIsFetchingSnapshot = (state) =>
  get(state, rootPath).isFetchingSnapshot
export const selectPinnedSites = (state) => get(state, rootPath).pinned
export const selectRealtimeAcks = (state) => get(state, rootPath).realtimeAcks
export const selectActiveDispatchesDomain = (state) =>
  get(state, rootPath).activeDispatches
export const selectIsPropagatingBatchUpdates = (state) =>
  get(state, rootPath).isPropagatingBatchUpdates

/**
 *
 * Transform data into a slightly nicer format than
 * what we get from the server
 */
const transformDispatchData = (snapshots = []) =>
  snapshots.map((dispatch) => ({
    ...dispatch,
    portfolios: dispatch.portfoliosMap
      .map(([key, portfolio]) => ({
        id: key,
        ...portfolio.metadata,
        ...portfolio.telemetry,
        sites: Object.entries(portfolio.metadata.acknowledgmentsByFacility).map(
          ([facilityId, acknowledgments]) => ({
            acknowledgments,
            id: facilityId,
          })
        ),
      }))
      .sort((a, b) => {
        return a.portfolioName - b.portfolioName
      }),
  }))

export const selectActiveDispatches = createSelector(
  selectActiveDispatchesDomain,
  (activeDispatches) => {
    return transformDispatchData(activeDispatches?.snapshots).sort((a, b) => {
      return new Date(a.startTime) - new Date(b.startTime)
    })
  }
)

/**
 * Select the currently visible snapshot, based on the url parameters
 * that define dispatchId and portfolioId
 */
export const selectCurrentSnapshot = createSelector(
  [selectSnapshots, selectPortfolioId, selectDispatchId],
  (snapshots, portfolioId, dispatchId) => snapshots?.[dispatchId]?.[portfolioId]
)

/**
 * Selects the dispatch metadata for the currently visible
 * dispatch. Does not include facility level data
 */
export const selectCurrentDispatch = createSelector(
  selectCurrentSnapshot,
  (snapshot) => snapshot?.data.portfolioDispatch
)

/**
 * Shorcut to select the facilites list from the currently
 * viewed snapshot
 */
export const selectSnapshotFacilities = createSelector(
  selectCurrentSnapshot,
  (currentSnapshot) => {
    return currentSnapshot.data.facilityDispatch
  }
)

export const selectDispatchFacilities = createSelector(
  [selectCurrentSnapshot],
  (snapshot) => snapshot?.data?.facilityDispatch ?? []
)

export const selectAssociatedAccountManagers = createSelector(
  selectDispatchFacilities,
  (facs) => {
    const found = {}
    return facs.reduce((accountManagers, fac) => {
      if (!fac.accountManager) {
        return accountManagers
      }

      if (!found[fac.accountManager.id]) {
        accountManagers.push(fac.accountManager)
      }
      found[fac.accountManager.id] = true
      return accountManagers
    }, [])
  }
)

const addPinnedToSites = (sites, pinnedSites) =>
  sites.map((site) => {
    const isPinned = pinnedSites[site.id]
    site.pinned = isPinned
    return site
  })

export const selectSites = createSelector(
  [selectCurrentSnapshot, selectPinnedSites],
  (activeSnapshot, pinnedSites) => {
    return addPinnedToSites(addTargetsToSite(activeSnapshot), pinnedSites)
  }
)
