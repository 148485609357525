import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { GetRecentConversationsResponse } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { getRecentConversations } from '../../../routes/dispatches/routes/ActiveDispatches/api/smsClient'

export const useGetRecentConversations = ({
  enabled,
}: {
  enabled: boolean
}): UseQueryResult<GetRecentConversationsResponse.AsObject> =>
  useQuery({
    queryKey: ['getRecentConversations'],
    refetchOnWindowFocus: false,
    queryFn: getRecentConversations,
    enabled,
  })
