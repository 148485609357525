import { select, takeLatest, put, all, call } from '@redux-saga/core/effects'
import { eventTracker, EVENTS, PROPERTIES } from '@voltus/event-tracking'
import logger from '@voltus/logger'
import { network, endpoints } from '@voltus/network'

import { selectProfile } from '../profile'
import { EventTrackingTypes, EventTrackingActions } from './eventTracking.redux'

// When the user navigates to a new page, we want to know which page they're
// navigating from. Unfortunately, history.listen() runs after the url has
// changed, so the location object it gives us does not have any info about the
// previous url. Instead, we need to manually keep track of the previous
// location data. We store that previous data in this object, which is
// initialized when we call initializeEventTracking() and updated every time
// navigationListener() runs.
// let previousLocation

// const navigationListener = (location, action) => {
//   // Don't track replaces
//   if (action === 'REPLACE') {
//     return
//   }

//   const navigateTo = location.pathname + location.search + location.hash
//   const navigateFrom =
//     previousLocation.pathname + previousLocation.search + previousLocation.hash

//   // Don't fire events if the url hasnt' actually changed
//   // Included here is the restriction that we don't look
//   if (navigateTo === navigateFrom) {
//     return
//   }

//   eventTracker.track(EVENTS.NAVIGATE, {
//     [PROPERTIES.NAVIGATE_TO]: navigateTo,
//     [PROPERTIES.NAVIGATE_FROM]: navigateFrom,
//     [PROPERTIES.NAVIGATE_TO_PATHNAME]: location.pathname,
//     [PROPERTIES.NAVIGATE_FROM_PATHNAME]: previousLocation.pathname,
//     [PROPERTIES.NAVIGATE_TO_SEARCH]: location.search,
//     [PROPERTIES.NAVIGATE_FROM_SEARCH]: previousLocation.search,
//     [PROPERTIES.NAVIGATE_TO_HASH]: location.hash,
//     [PROPERTIES.NAVIGATE_FROM_HASH]: previousLocation.hash,
//   })

//   previousLocation = { ...location }
// }

// TODO: @jcharry react-router v6
// FIGURE THIS OUT - I can't use `history.listen` anymore in here because
// history was removed from react-router v6
// function listenForNavigationEvents(history) {
//   if (window.navigation) {
//     window.navigation.addEventListener('navigate', (navigateEvent) => {
//       // Exit early if this navigation shouldn't be intercepted.
//       // The properties to look at are discussed later in the article.
//       // if (shouldNotIntercept(navigateEvent)) return;

//       const url = new URL(navigateEvent.destination.url)
//       console.log('navigate', url)
//       // if (url.pathname === '/') {
//       //   navigateEvent.intercept({handler: loadIndexPage});
//       // } else if (url.pathname === '/cats/') {
//       //   navigateEvent.intercept({handler: loadCatsPage});
//       // }
//     })
//   }
// }

let isInitialized = false
function* initializeEventTracking(application) {
  // Strange that we need this safeguard
  // But I can't track down why this is firing twice...
  if (isInitialized) {
    return
  }

  try {
    // To populate the initial application load event
    // we need some user data, and also want to know if they
    // are loading the app during an active event
    isInitialized = true
    const events = yield call(network.get, endpoints.v0_1.hasActiveEvent())
    const profile = yield select(selectProfile)

    eventTracker.init(
      {
        ...profile,
        // Internal users may not have a salesforce id, so fallback to email
        id: profile.salesforceId || profile.email,
      },
      {
        [PROPERTIES.APP]: application,
        [PROPERTIES.ACTIVE_EVENT]: Object.keys(events).length > 0,
      }
    )

    eventTracker.track(EVENTS.APP_LOAD, {
      [PROPERTIES.NAVIGATE_TO]: window.location.pathname,
    })
    // previousLocation = { ...window.location }

    // Initialize history listener for naviation events
    // listenForNavigationEvents(history)
    yield put(
      EventTrackingActions.receiveTrackingData({
        profile,
        events,
      })
    )
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

export function* watchEventTracking() {
  yield all([
    takeLatest(
      EventTrackingTypes.INITIALIZE_EVENT_TRACKING,
      initializeEventTracking
    ),
  ])
}
