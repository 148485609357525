import { all, fork } from 'redux-saga/effects'
import {
  watchPermissions,
  watchProfile,
  watchEventTracking,
} from '@voltus/modules'

import { saga as watchDispatches } from '../routes/dispatches/routes/ActiveDispatches/modules/sagas'

export default function* rootSaga() {
  yield all([
    fork(watchPermissions),
    fork(watchEventTracking),
    fork(watchProfile),
    fork(watchDispatches),
  ])
}
