import classnames from 'classnames'
import { upperFirst } from 'lodash'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import React from 'react'
import logger from '@voltus/logger'

import { VALIDATION_STATUS } from '../Forms/forms.constants'
import { CHECKBOX_THEMES } from './Checkbox.constants'
import stylesheet from './Checkbox.scss'

export interface CheckboxProps {
  /**
   * Wrapper element. Defaults to label
   * Only override in rare instances where you don't want
   * the wrapping label
   */
  ContainerElement?:
    | string
    | React.ComponentType<{ children: React.ReactNode; className?: string }>
  /**
   * Optional name applied to input
   */
  name?: string
  /**
   * Classname for the parent container
   */
  className?: string
  /**
   * Disallow the checkbox from changing its value
   */
  disabled?: boolean
  /**
   * @deprecated
   * Boolean value. Prefer `value` prop over `checked`
   */
  checked?: boolean
  /**
   * Boolean value
   */
  value: boolean
  /**
   * Visually puts the checkbox into "indeterminate" state.
   * This state is useful for showing partial checkboxes.
   * e.g. a parent checkbox showing if all (or some) nested children
   * are checked
   */
  isIndeterminate?: boolean
  /**
   * Fired when the checkbox changes value.
   * Passes back the react event and the new value
   */
  onChange: (evt: React.ChangeEvent<HTMLInputElement>, value: boolean) => void
  /**
   * The text that renders next to the checkbox
   */
  children?: React.ReactNode
  /**
   * Accepts either 'default' or 'circle'
   * In 'circle' mode, the checkbox looks kinda like
   * a radio button. I don't think we should support
   * the 'circle' mode going forward
   */
  theme?: (typeof CHECKBOX_THEMES)[keyof typeof CHECKBOX_THEMES]
  /**
   * To render an error state for the checkbox,
   * pass `validationStatus="error"`
   */
  validationStatus?:
    | (typeof VALIDATION_STATUS)[keyof typeof VALIDATION_STATUS]
    | null
  /**
   * Focus handler. Fired with the react dom event
   */
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void
  /**
   * Blur handler. Fired with the react dom event
   */
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void
}

export const Checkbox = ({
  ContainerElement = 'label',
  className,
  disabled,
  checked,
  value,
  onChange,
  name,
  isIndeterminate,
  children,
  theme = CHECKBOX_THEMES.DEFAULT,
  validationStatus,
  onFocus = noop,
  onBlur = noop,
  ...rest
}: CheckboxProps): JSX.Element => {
  const [focused, setFocused] = React.useState(false)

  const handleFocus = (e) => {
    onFocus(e)
    setFocused(true)
  }

  const handleBlur = (e) => {
    onBlur(e)
    setFocused(false)
  }

  if (!isNil(checked)) {
    logger.once.warn(
      'the `checked` prop is deprecated, please use `value` instead.'
    )
  }

  const isChecked = checked || value

  const checkboxKindClassName = validationStatus
    ? `checkbox${upperFirst(validationStatus)}`
    : ''
  return (
    <ContainerElement
      className={classnames(
        stylesheet.checkbox,
        stylesheet[`checkbox${upperFirst(theme)}`],
        className,
        {
          [stylesheet.disabled]: disabled,
          [stylesheet.focused]: focused,
          [stylesheet[checkboxKindClassName]]: !!validationStatus,
        }
      )}
    >
      <div className={stylesheet.container}>
        <input
          {...rest}
          className={stylesheet.input}
          name={name}
          type="checkbox"
          disabled={disabled}
          checked={isChecked}
          onChange={(e) => {
            onChange(e, e.target.checked)
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <span
          className={classnames(stylesheet.check, {
            [stylesheet.checked]: isChecked && !isIndeterminate,
            [stylesheet.indeterminate]: isIndeterminate,
          })}
        />
      </div>
      <span className={stylesheet.text}>{children}</span>
    </ContainerElement>
  )
}
