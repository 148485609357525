import { format, sub } from 'date-fns'
import { debounce, isEqual, uniq } from 'lodash'
import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch as ReduxDispatch } from 'redux'
import { createStructuredSelector } from 'reselect'
import { DATE_FORMAT, getVoltusPortfolioName } from '@voltus/constants'
import { Toast } from '@voltus/core-components'
import logger from '@voltus/logger'
import { selectProfile } from '@voltus/modules'
import { useUserPortfoliosQuery } from '@voltus/queries'
import { Any, Profile } from '@voltus/types'
import { getErrorMessage, usePrevious } from '@voltus/utils'
import {
  DispatchActions,
  SmsActions,
  selectConversations,
  CheckInCreators,
  selectAllUsers,
  selectAssignments,
  SiteAssignmentsCreators,
  NotesCreators,
} from '../../routes/dispatches/routes/ActiveDispatches/modules'
import { useActiveDispatchesQuery } from '../../routes/dispatches/routes/ActiveDispatches/queries/useActiveDispatchesQuery'
import {
  Contact,
  Dispatch,
} from '../../routes/dispatches/routes/ActiveDispatches/types'
import { Assignments } from '../../routes/dispatches/routes/ActiveDispatches/types/assignments'

import { SiteContactChatMenu } from './SiteContactChatMenu'
import {
  useDispatchDetails,
  useGetPastDispatches,
  useGetUserMessages,
  useGetRecentConversations,
  flattenPaginatedMessages,
  useSendMessage,
  useUpdateMessage,
  useIndicateTypingEvent,
} from './queries'
import { ConversationsEventStream } from './types'
import {
  combinePastMessagesWithEventStreamMessages,
  generateUnreadMessageNotifications,
  findRecentConversationsOutsideDispatches,
  getDispatchIdsToSiteIdsMapForContact,
  generateFacilityIdToRecentMessageTimestampMap,
} from './utils'

const mapStateToProps = createStructuredSelector({
  userProfile: selectProfile,
  conversations: selectConversations,
  users: selectAllUsers,
  assignments: selectAssignments,
})

export const mapDispatchToProps = (dispatch: ReduxDispatch): Any =>
  bindActionCreators(
    {
      fetchUsers: CheckInCreators.fetchUsers,
      closeSockets: DispatchActions.closeAllSockets,
      bootstrapConversationEventStream:
        SmsActions.bootstrapConversationEventStream,
      resetConversationEventStream: SmsActions.resetConversationEventStream,
      bootstrapMessageNotificationEventStream:
        SmsActions.bootstrapMessageNotificationEventStream,
      resetMessageNotificationEventStream:
        SmsActions.resetMessageNotificationEventStream,
      fetchSiteAssignments: SiteAssignmentsCreators.fetchSiteAssignments,
      createTextNote: NotesCreators.createTextNote,
    },
    dispatch
  )

const connector = connect<
  {
    users: {
      [key: number]: Profile
    }
    userProfile: Profile
    conversations: ConversationsEventStream
    assignments: Assignments
  },
  {
    fetchUsers: () => void
    closeSockets: () => void
    bootstrapConversationEventStream: (
      userId: number,
      lastSeenMessageId?: number
    ) => void
    resetConversationEventStream: (userId: number) => void
    bootstrapMessageNotificationEventStream: () => void
    resetMessageNotificationEventStream: () => void
    fetchSiteAssignments: (dispatchId: number) => void
    createTextNote: (
      dispatchId: number | string,
      params: {
        userId: number
        siteContactUserId: number
        siteIds: Array<number>
        dispatchId: number
        body: string
      }
    ) => void
  }
>(mapStateToProps, mapDispatchToProps)

interface Props extends ConnectedProps<typeof connector> {
  shouldRender: boolean
}

/* eslint-disable complexity */
function SiteContactChatMenuContainer({
  shouldRender,
  fetchUsers,
  users,
  userProfile,
  bootstrapConversationEventStream,
  resetConversationEventStream,
  bootstrapMessageNotificationEventStream,
  resetMessageNotificationEventStream,
  fetchSiteAssignments,
  conversations,
  assignments,
  createTextNote,
}: Props): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false)
  const toggleIsMenuOpen = () => {
    setIsMenuOpen((value) => !value)
  }
  const activeDispatchesQuery = useActiveDispatchesQuery({
    page: 1,
    perPage: 1000,
    enabled: isMenuOpen,
  })
  const activeDispatches = React.useMemo(
    () => activeDispatchesQuery.data ?? [],
    [activeDispatchesQuery.data]
  )

  const [isUserTyping, setIsUserTyping] = React.useState(false)

  const prevIsMenuOpen = usePrevious(isMenuOpen)
  React.useEffect(() => {
    if (!prevIsMenuOpen && isMenuOpen) {
      bootstrapMessageNotificationEventStream()
      fetchUsers()
    }
    if (prevIsMenuOpen && !isMenuOpen) {
      resetMessageNotificationEventStream()
    }
  }, [
    isMenuOpen,
    prevIsMenuOpen,
    bootstrapMessageNotificationEventStream,
    fetchUsers,
    activeDispatches.length,
    resetMessageNotificationEventStream,
    resetConversationEventStream,
  ])

  const prevActiveDispatches = usePrevious(activeDispatches)
  React.useEffect(() => {
    if (
      !isEqual(
        prevActiveDispatches?.map((d) => d.dispatchId),
        activeDispatches?.map((d) => d.dispatchId)
      )
    ) {
      activeDispatches.forEach((dispatch) => {
        fetchSiteAssignments(dispatch.dispatchId)
      })
    }
  }, [prevActiveDispatches, activeDispatches, fetchSiteAssignments])

  const readMessageIds = React.useRef<Array<number>>([])
  const [unreadMessageIds, setUnreadMessageIds] = React.useState<Array<number>>(
    []
  )

  const [selectedContact, setSelectedContact] = React.useState<Contact>()

  const [
    selectedContactDispatchIdToSiteIdsMap,
    setSelectedContactDispatchIdToSiteIdsMap,
  ] = React.useState<{ [key: number]: Array<number> }>()

  const eventStreamMessagesList =
    conversations?.[selectedContact?.userId || '']?.messages

  const typingStatus =
    conversations?.[selectedContact?.userId || '']?.typingStatus

  const detectIfUserIsTyping = React.useCallback(() => {
    const isTyping =
      typingStatus &&
      typingStatus.userId !== userProfile?.id &&
      new Date().getTime() - typingStatus.timestamp < 3000

    setIsUserTyping(isTyping)

    if (isTyping) {
      setTimeout(detectIfUserIsTyping, 5000)
    }
  }, [typingStatus, userProfile?.id])

  const prevTypingStatus = usePrevious(typingStatus)
  React.useEffect(() => {
    if (!isEqual(prevTypingStatus, typingStatus)) {
      detectIfUserIsTyping()
    }
  }, [detectIfUserIsTyping, prevTypingStatus, typingStatus])

  const nameOfUserTyping = React.useMemo(() => {
    if (isUserTyping) {
      return typingStatus.userId === selectedContact?.userId
        ? selectedContact?.fullName
        : users &&
            Object.values(users).find(
              (user) => user?.userId === typingStatus?.userId
            )?.fullName
    }
    return undefined
  }, [
    isUserTyping,
    selectedContact?.fullName,
    selectedContact?.userId,
    typingStatus?.userId,
    users,
  ])

  const {
    data: paginatedMessages,
    error: messagesError,
    isPending: isPendingMessages,
    fetchNextPage: handleLoadPreviousMessages,
    hasNextPage: hasPreviousMessages,
    isFetchingNextPage: isFetchingPreviousMessages,
  } = useGetUserMessages({
    userId: selectedContact?.userId as number,
    enabled: isMenuOpen && !!selectedContact,
  })

  const flattenedPaginatedMessages = React.useMemo(() => {
    return flattenPaginatedMessages(paginatedMessages)
  }, [paginatedMessages])

  const allMessages = React.useMemo(() => {
    return combinePastMessagesWithEventStreamMessages(
      flattenedPaginatedMessages,
      eventStreamMessagesList
    )
  }, [flattenedPaginatedMessages, eventStreamMessagesList])

  React.useEffect(() => {
    readMessageIds.current = uniq([
      ...readMessageIds.current,
      ...flattenedPaginatedMessages
        .filter(
          ({ metadataMap }) =>
            metadataMap.find(([key]) => key === 'markAsRead')?.[1] === 'true'
        )
        .map((message) => message.id),
    ])
  }, [flattenedPaginatedMessages])

  const { data: recentConversationsData, error: recentConversationsError } =
    useGetRecentConversations({
      enabled: isMenuOpen,
    })

  const sendMessage = useSendMessage()

  const updateMessage = useUpdateMessage()
  const indicateTypingEvent = useIndicateTypingEvent()

  const handleTypingMessage = React.useMemo(
    () =>
      debounce(() => {
        if (selectedContact?.userId && userProfile?.id) {
          indicateTypingEvent.mutate({
            userId: selectedContact.userId,
            senderUserId: userProfile.id,
          })
        }
      }, 500),
    [indicateTypingEvent, selectedContact?.userId, userProfile?.id]
  )

  // Cleanup the debounce instance on unmount
  React.useEffect(() => {
    return () => {
      handleTypingMessage.cancel()
    }
  }, [handleTypingMessage])

  const handleMarkMessageAsRead = React.useCallback(
    (id: number) => {
      if (readMessageIds.current.includes(id)) {
        return
      }
      updateMessage.mutate({
        id,
        metadata: ['markAsRead', 'true'],
      })
      readMessageIds.current = uniq([...readMessageIds.current, id])
      setUnreadMessageIds((current) =>
        current.filter((messageId) => messageId !== id)
      )
    },
    [updateMessage]
  )

  const handleMarkMessageAsUnread = React.useCallback(
    (id: number) => {
      updateMessage.mutate({
        id,
        metadata: ['markAsRead', 'false'],
      })
      setUnreadMessageIds((current) => [...current, id])
      readMessageIds.current = readMessageIds.current.filter(
        (messageId) => messageId !== id
      )
    },
    [updateMessage]
  )

  const handleSendMessage = React.useCallback(
    (messageBody: string) => {
      if (!selectedContact?.userId || !userProfile.id) {
        return
      }

      sendMessage.mutate(
        {
          body: messageBody,
          userId: selectedContact.userId,
          senderUserId: userProfile.id,
          metadataMap: [],
        },
        {
          onError: (error) => {
            logger.report.error(`Failed to send SMS message: ${error}`)
          },
        }
      )

      // Only create a dispatch log note that a text message conversation was initiated if:
      // No texts from Voltans have been sent to this contact in the past hour
      if (
        selectedContactDispatchIdToSiteIdsMap &&
        !allMessages.some((message) => {
          const isMessageFromVoltan =
            message.senderUserId !== selectedContact?.userId
          const ONE_HOUR_MS = 60 * 60 * 1000
          const wasMessageSentInPastHour =
            new Date().getTime() - new Date(message.timestamp).getTime() <
            ONE_HOUR_MS
          return isMessageFromVoltan && wasMessageSentInPastHour
        })
      ) {
        Object.entries(selectedContactDispatchIdToSiteIdsMap).forEach(
          ([dispatchId, siteIds]) => {
            createTextNote(dispatchId, {
              userId: userProfile?.id,
              siteContactUserId: selectedContact?.userId,
              siteIds,
              dispatchId: +dispatchId,
              body: `${userProfile?.fullName} initiated a text message conversation with ${selectedContact?.fullName} at ${format(
                new Date(),
                DATE_FORMAT.HOUR_MINUTE
              )}`,
            })
          }
        )
      }
    },
    [
      allMessages,
      createTextNote,
      selectedContact?.fullName,
      selectedContact?.userId,
      selectedContactDispatchIdToSiteIdsMap,
      sendMessage,
      userProfile?.fullName,
      userProfile?.id,
    ]
  )

  const { data: userPortfoliosData } = useUserPortfoliosQuery()
  const voltusPortfolioId: number | undefined = React.useMemo(() => {
    if (userPortfoliosData) {
      return userPortfoliosData.memberOf.find(
        (portfolio) => portfolio.name === getVoltusPortfolioName()
      )?.portfolioId
    }
  }, [userPortfoliosData])

  // Fetches all dispatches from the past day.
  // The menu displays all dispatches from the past day in case site contacts text us after a dispatch has ended.
  const { data: pastDispatches } = useGetPastDispatches({
    enabled: isMenuOpen && !!voltusPortfolioId,
    portfolioId: voltusPortfolioId as number,
    startDate: format(
      sub(new Date(), { days: 1 }),
      DATE_FORMAT.YEAR_MONTH_DAY_DASHES_DATE_FNS
    ),
    endDate: format(new Date(), DATE_FORMAT.YEAR_MONTH_DAY_DASHES_DATE_FNS),
  })

  const dispatchDetailsResults = useDispatchDetails({
    dispatches: [
      ...activeDispatches.map((dispatch) => ({
        dispatchId: dispatch.dispatchId,
        portfolioId: voltusPortfolioId,
      })),
      ...(Object.keys(pastDispatches?.dispatches || {}).map((id) => ({
        dispatchId: id,
        portfolioId: voltusPortfolioId,
      })) as unknown as Array<Dispatch>),
    ],
    enabled: isMenuOpen,
  })

  const dispatchesWithFacilities: Array<Dispatch> = Object.values(
    dispatchDetailsResults.filter((r) => r.data).map((r) => r.data as Dispatch)
  )

  const handleSelectContact = React.useCallback(
    (contact) => setSelectedContact(contact),
    []
  )

  const facilityIdToRecentMessageTimestampMap =
    generateFacilityIdToRecentMessageTimestampMap(
      dispatchesWithFacilities,
      recentConversationsData?.conversationsList,
      conversations
    )

  const unreadMessageNotifications = React.useMemo(
    () =>
      generateUnreadMessageNotifications(
        recentConversationsData?.conversationsList,
        conversations,
        readMessageIds.current,
        unreadMessageIds
      ),
    [
      conversations,
      readMessageIds,
      recentConversationsData?.conversationsList,
      unreadMessageIds,
    ]
  )

  const recentConversationsOutsideDispatches = React.useMemo(
    () =>
      findRecentConversationsOutsideDispatches(
        recentConversationsData?.conversationsList,
        dispatchesWithFacilities
      ),
    [dispatchesWithFacilities, recentConversationsData?.conversationsList]
  )

  // Primary notifications are unread messages from the site contact who we are chatting with in this conversation.
  const primaryUnreadMessageNotifications = React.useMemo(
    () =>
      unreadMessageNotifications.filter(
        (notification) => notification.userId === notification.senderUserId
      ),
    [unreadMessageNotifications]
  )

  // Secondary notifications are unread messages from other Voltans in this conversation.
  const secondaryUnreadMessageNotifications = React.useMemo(
    () =>
      unreadMessageNotifications.filter(
        (notification) => notification.userId !== notification.senderUserId
      ),
    [unreadMessageNotifications]
  )

  const prevSelectedContact = usePrevious(selectedContact)
  React.useEffect(() => {
    if (selectedContact?.userId !== prevSelectedContact?.userId) {
      // If there was a previously selected contact:
      // Close the event stream for that previously selected contact.
      if (prevSelectedContact) {
        resetConversationEventStream(prevSelectedContact.userId)
      }

      // If there's a newly selected contact:
      // 1. Open the event stream for the newly selected contact.
      if (selectedContact) {
        const lastSeenMessageId = recentConversationsData?.conversationsList
          ? recentConversationsData.conversationsList.find(
              (conversation) => conversation.userId === selectedContact.userId
              // eslint-disable-next-line
              // @ts-ignore
            )?.message?.id
          : undefined

        bootstrapConversationEventStream(
          selectedContact.userId,
          lastSeenMessageId
        )

        // 2. Set the SelectedContactDispatchIdToSiteIdsMap for the newly selected contact.
        const newDispatchIdToSiteIds = getDispatchIdsToSiteIdsMapForContact(
          selectedContact?.userId,
          dispatchesWithFacilities
        )
        setSelectedContactDispatchIdToSiteIdsMap(newDispatchIdToSiteIds)
      }
    }
  }, [
    selectedContact,
    prevSelectedContact,
    resetConversationEventStream,
    recentConversationsData?.conversationsList,
    bootstrapConversationEventStream,
    dispatchesWithFacilities,
  ])

  // Error handling for queries:
  const prevMessagesError = usePrevious(messagesError)
  const prevRecentConversationsError = usePrevious(recentConversationsError)
  React.useEffect(() => {
    if (dispatchDetailsResults.find((queryResult) => queryResult.isError)) {
      const result = dispatchDetailsResults.find(
        (queryResult) => queryResult.isError
      )
      const error = result?.error as Error
      Toast.push(<Toast.Error>Error: {error.message}</Toast.Error>)
    }
    if (!prevMessagesError && messagesError) {
      Toast.push(<Toast.Error>Error: {messagesError?.message}</Toast.Error>)
    }
    if (!prevRecentConversationsError && recentConversationsError) {
      Toast.push(
        <Toast.Error>Error: {recentConversationsError?.message}</Toast.Error>
      )
    }
  }, [
    dispatchDetailsResults,
    messagesError,
    prevMessagesError,
    prevRecentConversationsError,
    recentConversationsError,
  ])

  return (
    <SiteContactChatMenu
      shouldRender={shouldRender}
      dispatches={dispatchesWithFacilities}
      assignments={assignments}
      viewingUserId={userProfile?.id}
      selectedContact={selectedContact}
      onSelectContact={handleSelectContact}
      onSendMessage={handleSendMessage}
      onMarkMessageAsRead={handleMarkMessageAsRead}
      onMarkMessageAsUnread={handleMarkMessageAsUnread}
      messages={allMessages}
      onLoadPreviousMessages={handleLoadPreviousMessages}
      onTypingMessage={handleTypingMessage}
      hasPreviousMessages={!!hasPreviousMessages}
      isLoadingPreviousMessages={isFetchingPreviousMessages}
      isLoadingMessages={isPendingMessages}
      isLoadingContacts={
        dispatchesWithFacilities.length === 0 &&
        dispatchDetailsResults &&
        dispatchDetailsResults.some((result) => result.isPending)
      }
      primaryUnreadMessageNotifications={primaryUnreadMessageNotifications}
      secondaryUnreadMessageNotifications={secondaryUnreadMessageNotifications}
      recentConversationsOutsideDispatches={
        recentConversationsOutsideDispatches
      }
      facilityIdToRecentMessageTimestampMap={
        facilityIdToRecentMessageTimestampMap
      }
      nameOfUserTyping={nameOfUserTyping}
      toggleIsMenuOpen={toggleIsMenuOpen}
      errorMessage={
        sendMessage.error ? getErrorMessage(sendMessage.error) : undefined
      }
    />
  )
}

const Component = connector(SiteContactChatMenuContainer)

export default Component
