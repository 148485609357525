import { createSelector } from 'reselect'
import { selectUserId } from '@voltus/modules'
import rootSelector from '../rootSelector'
import { selectDispatchId } from '../routeParams'

export const selectCheckInDomain = createSelector(
  rootSelector,
  (state) => state.checkIns
)

/**
 * Select the checkins for a particular dispatchId
 */
export const selectDispatchCheckIns = createSelector(
  [selectCheckInDomain, selectDispatchId],
  (checkins, dispatchId) => checkins[dispatchId] || {}
)

export const selectAllUsers = createSelector(
  selectCheckInDomain,
  (state) => state.users
)

export const selectCheckedIn = createSelector(
  [selectCheckInDomain, selectUserId, selectDispatchId],
  (checkins, userId, dispatchId) => {
    return checkins[dispatchId]?.[userId]?.isCheckIn
  }
)

export const selectCheckedInUsers = createSelector(
  [selectDispatchCheckIns, selectAllUsers],
  (checkins, users) => {
    return Object.entries(checkins)
      .map(([userId, checkIn]) => {
        return {
          ...checkIn,
          userId,
          fullName: users?.[userId]?.fullName,
        }
      })
      .filter((user) => user?.isCheckIn)
  }
)

export const selectLoadingStates = createSelector(
  selectCheckInDomain,
  (state) => state.loadingStates
)

export const selectUsersLoadingState = createSelector(
  selectLoadingStates,
  (state) => state.users
)

export const selectCheckingInLoadingState = createSelector(
  selectLoadingStates,
  (state) => state.checkingIn
)

export const selectDispatchCheckInsLoadingState = createSelector(
  [selectLoadingStates, selectDispatchId],
  (state, dispatchId) => state[dispatchId]
)
