import { differenceInDays } from 'date-fns'
import * as React from 'react'
import { Box, Text, Modal } from '@voltus/core-components'
import { isIE } from '@voltus/utils'
import stylesheet from './IE11Modal.scss'

const persistedDataKey = 'last-ie11-modal-notification'
function IE11ModalBase(): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    // Three possibilities
    // 1. Never visited before - store time of page view
    // 2. Visited previously within a day
    // 3. Visited previously more than a day ago

    const lastTimeModalShown = localStorage.getItem(persistedDataKey)

    // 1. Never visited
    if (!lastTimeModalShown) {
      localStorage.setItem(persistedDataKey, new Date().toISOString())
      setIsOpen(true)
      return
    }

    const diff = differenceInDays(new Date(), new Date(lastTimeModalShown))

    // 2. Visited, but within the last day
    // Do nothing - don't show the modal again
    if (diff < 1) {
      return
    }

    // 3. Visited, but more than a day ago,
    // Store last modal shown time, and show the modal
    setIsOpen(true)
    localStorage.setItem(persistedDataKey, new Date().toISOString())
  }, [])

  return (
    <Modal
      className={stylesheet.modal}
      isOpen={isOpen}
      onRequestClose={() => {
        localStorage.setItem(persistedDataKey, new Date().toISOString())
        setIsOpen(false)
      }}
      header="It looks like you may be using Internet Explorer"
    >
      <Text.P>
        Starting <strong>November 9th, 2021</strong>, VoltApp will{' '}
        <strong>no longer be supporting Internet Explorer</strong>.
      </Text.P>
      <br />
      <Text.P fontWeight="bold">
        If you visit VoltApp with Internet Explorer, things may appear broken.
      </Text.P>
      <br />
      <Text.P mb={2}>
        We recommend switching to one of the following supported browsers:
      </Text.P>
      <Box as="ul" ml={4}>
        <Text mb={1} as="li">
          Microsoft Edge
        </Text>
        <Text mb={1} as="li">
          Google Chrome
        </Text>
        <Text mb={1} as="li">
          Mozilla Firefox
        </Text>
        <Text mb={1} as="li">
          Safari
        </Text>
      </Box>
      <Text.P pt={3}>
        If you have questions or concerns please let us know by sending an email
        to{' '}
        <Text.Link href="mailto:support@voltus.co" fontSize={3} as="a">
          support@voltus.co
        </Text.Link>
      </Text.P>
    </Modal>
  )
}

export function IE11Modal(): JSX.Element | null {
  if (isIE()) {
    return <IE11ModalBase />
  }
  return null
}
