import { isPast } from 'date-fns'
import { noop, isNil, get } from 'lodash'
import { eventChannel, END } from 'redux-saga'
import {
  all,
  put,
  call,
  take,
  takeLatest,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects'
import logger from '@voltus/logger'
import { network, endpoints } from '@voltus/network'
import { isTestEnv } from '@voltus/utils'

import * as vocAdminClient from '../../api/vocAdminClient'
import * as vocClient from '../../api/vocClient'
import { WS_EVENTS } from '../../constants'
import { selectDispatchId } from '../routeParams'
import { getFacilityDispatchPath } from './dispatch.helpers'
import { DispatchTypes, DispatchActions } from './dispatch.redux'
import { selectIsPropagatingBatchUpdates } from './dispatch.selectors'

export const UPDATE_DISPATCH_LIST_INTERVAL = 30 * 1000
export const UPDATE_SNAPSHOT_STATUS_INTERVAL = 10 * 1000

/**
 * Batch is for collecting redux actions that are collected
 * from websocket events. Rather than fire an action everytime
 * we get a message from a websocket, we batch them up
 * and apply them all at once on a given schedule.
 *
 * After each batch application, the batch is reset to an
 * empty object
 */
const batcher = {
  batch: {},
  getBatch: function () {
    return this.batch
  },
  /**
   * Clear the batch event
   */
  clearBatch: function () {
    this.batch = {}
  },
  /**
   * Pushes an action into `batch` object
   * @param {string} type - Redux action type
   * @param {Object} data - Websocket data
   * @param {string} data.type - Type of websocket event
   * @param {object} data.data - Data from the websocket, shape depends on data.type
   */
  batchEvent: function (type, data) {
    this.batch[type] = this.batch[type] ?? []
    this.batch[type].push(data)
  },
}

/**
 * Holds a map of websockets, keyed by portfolioId.
 * Each portfolio manages its own websocket,
 * allowing us to properly update the correct
 * parts of our redux store
 */
const channels = {}
const createWsEventChannel = (portfolioId) => {
  const channel = eventChannel((emitter) => {
    const client = vocClient.intializeTelemetryStream({
      portfolioId,
      onMessage: emitter,
      onEnd: () => {
        emitter(END)
      },
    })
    return () => {
      channels[portfolioId] = null
      client.close()
      client.clear()
    }
  })
  channels[portfolioId] = channel
  return channel
}

function closeAllSockets() {
  Object.values(channels)
    .filter((ws) => !!ws)
    .forEach((ws) => ws.close())
}

function* injectDispatchId(data) {
  return {
    ...data,
    dispatchId: yield select(selectDispatchId),
  }
}

function* updateUserAcknowledgments(data) {
  const {
    dispatchId,
    portfolioId,
    facilityId,
    userId,
    acknowledgmentStatus,
    acknowledgmentTime,
  } = data

  const facilities = yield select((state) =>
    get(state, [
      'dispatches',
      'dispatch',
      ...getFacilityDispatchPath(portfolioId, dispatchId),
    ])
  )

  // TODO: Unfortunately, user acknowledgment data lives on the portfolio snapshot,
  // which, if the user hasn't looked at that portfolio yet, may not exist.
  // That means we can't show a toast because we don't yet have facility data
  // to know what facility a user belongs to
  // If we try to show a toast anyway, we'll likely end up showing way too many, because
  // , so we can't show the right
  // number of toasts...We'll likely want to fix this in the future
  if (facilities) {
    const facility = facilities.find((fac) => fac.facilityId === facilityId)
    const foundUser = facility?.acknowledgments.find((u) => u.userId === userId)
    const newStatus = acknowledgmentStatus
    const prevStatus = foundUser?.acknowledgmentStatus

    if (newStatus !== prevStatus || !foundUser) {
      yield put(
        DispatchActions.pushRealtimeUserAck({
          ...data,
          acknowledgedAt: acknowledgmentTime,
          facilityName: facility?.name,
        })
      )
    }
  }

  return DispatchActions.updateUserAcknowledgments({
    ...data,
    acknowledgedAt: acknowledgmentTime,
  })
}

export function* connectToPortfolioDispatchSocket({ portfolioId, data }) {
  // For events that have ended, don't create a socket connection
  if (
    data.data.portfolioDispatch.endTime &&
    isPast(new Date(data.data.portfolioDispatch.endTime))
  ) {
    return
  }
  /**
   * TODO: Figure out stub socket server
   * We don't have a way yet of stubbing
   * out the websocket connection,
   * so in test environments, skip
   * the websocket for now
   */
  if (isTestEnv()) {
    return
  }

  closeAllSockets()
  const wsEventChannel = yield createWsEventChannel(portfolioId)
  yield put(DispatchActions.updateIsPropagatingBatchUpdates(true))

  while (true) {
    const { realtimeMessage } = yield take(wsEventChannel)
    const { data, portfolioId } = realtimeMessage
    const entries = Object.entries(data)
    for (let i = 0; i < entries.length; i++) {
      const [key, currentData] = entries[i]

      if (isNil(currentData)) {
        continue
      }

      const data = { portfolioId, ...currentData }

      switch (key) {
        case WS_EVENTS.streamingDispatch:
          yield put(DispatchActions.updatePortfolioDispatch(data))
          break
        case WS_EVENTS.userAcknowledgement:
          yield put(yield updateUserAcknowledgments(data))
          break
        case WS_EVENTS.cumulativeFacilityPerformance:
          batcher.batchEvent(
            DispatchTypes.UPDATE_FACILITY_PERFORMANCE_KWH,
            yield injectDispatchId(data)
          )
          yield put(DispatchActions.enqueueBatchUpdate())
          break
        case WS_EVENTS.facilityDemand:
          batcher.batchEvent(
            DispatchTypes.UPDATE_FACILITY_LOAD,
            yield injectDispatchId(data)
          )
          yield put(DispatchActions.enqueueBatchUpdate())
          break
        case WS_EVENTS.facilityPerformance:
          batcher.batchEvent(
            DispatchTypes.UPDATE_FACILITY_PERFORMANCE_KW,
            yield injectDispatchId(data)
          )
          yield put(DispatchActions.enqueueBatchUpdate())
          break
        case WS_EVENTS.portfolioPerformance:
          batcher.batchEvent(
            DispatchTypes.UPDATE_PORTFOLIO_PERFORMANCE_KW,
            yield injectDispatchId(data)
          )
          yield put(DispatchActions.enqueueBatchUpdate())
          break
        case WS_EVENTS.facilityTarget:
          batcher.batchEvent(
            DispatchTypes.UPDATE_FACILITY_TARGET,
            yield injectDispatchId(data)
          )
          yield put(DispatchActions.enqueueBatchUpdate())
          break
        default:
          break
      }
    }
  }
}

/**
 * Applies the batched events by dispatching a single action
 * and passing the batch of events to a reducer.
 */
function* propagateBatchData() {
  yield delay(5000)
  const isPropagatingBatchUpdates = yield select(
    selectIsPropagatingBatchUpdates
  )
  if (isPropagatingBatchUpdates) {
    yield put(DispatchActions.propagateBatchData(batcher.getBatch()))
    batcher.clearBatch()
  }
}

/**
 * Fetches the snapshot for a given portfolioId and dispatchId pair
 *
 * @param {Object} data
 * @param {number} data.portfolioId
 * @param {number} data.dispatchId
 */
export function* getSnapshot({ portfolioId, dispatchId }) {
  try {
    // @jcharry TODO: The new facilities endpoint from va-flask
    // is slightly differently structured than the one in voc-socket
    // So, fetch both, and make the new endpoint match the old structure,
    // A follow-up task will be to find all downstream usages of
    // the data structure defined by voc-socket and update them to use the
    // structured defined in va-flask endpoint
    const response = yield call(() =>
      network.get(
        endpoints.voc.snapshots.getPortfolioDetails({
          portfolio_id: portfolioId,
          dispatch_id: dispatchId,
        })
      )
    )

    const snapshot = response.portfolioDetailsSnapshot

    // the Snapshot service returns empty telemetry data for the first 10 minutes of a dispatch since there is no performance data yet calculated
    // So rather than handle empty data downstream everywhere, just stub it out
    const snapshotTelemetry = snapshot.telemetry ?? {
      performanceKw: null,
      performanceKwh: null,
      baselineType: 'capacity',
    }

    const baselineType = snapshotTelemetry.baselineType

    const result = {
      data: {
        facilityDispatch: [],
        facilitySparklines: {},
        facilityTargets: {},
        portfolioDispatch: {},
      },
    }

    // TODO: Figure out which performance should be shown...
    result.data.facilityDispatch = snapshot.facilitiesMap.map(
      ([facilityId, data]) => ({
        facilityId: facilityId,
        dispatchId: dispatchId,
        dispatch: {
          ...snapshot.metadata,
          dispatchId: dispatchId,
          id: dispatchId,
        },
        id: facilityId,
        ...data.metadata,
        acknowledgments: data.metadata.acknowledgmentsList.map((ack) => ({
          ...ack,
          dispatchId,
          portfolioId,
          facilityId,
        })),
        load: data.telemetry.load,
        ...(data.telemetry.performanceList.find(
          (perf) => perf.baselineType === baselineType
        ) ?? {}),
      })
    )

    result.data.facilitySparklines = snapshot.facilitiesMap.reduce(
      (sparklines, [facilityId, data]) => {
        sparklines[facilityId] = data.telemetry.sparklinesList
        return sparklines
      },
      {}
    )

    result.data.facilityTargets = snapshot.facilitiesMap.reduce(
      (targets, [facilityId, data]) => {
        targets[facilityId] = data.telemetry.targetsList.filter(
          (target) => target.baselineType === baselineType
        )
        return targets
      },
      {}
    )

    result.data.portfolioDispatch = {
      dispatchId: snapshot.dispatchId,
      portfolioId: snapshot.portfolioId,
      ...snapshot.metadata,
      ...snapshot.telemetry,
      ...snapshot.facilitiesMap.reduce(
        (res, [, facilityData]) => {
          const facPerf =
            facilityData.telemetry.performanceList?.find(
              ({ baselineType }) =>
                baselineType === snapshot.telemetry?.baselineType
            ) ?? null
          if (facPerf) {
            res.performanceKw += facPerf.performanceKw
            res.performanceKwh += facPerf.performanceKwh
          }

          res.commitmentKw += facilityData.metadata.commitmentKw
          res.commitmentKwh += facilityData.metadata.commitmentKwh
          return res
        },
        {
          commitmentKw: 0,
          performanceKw: 0,
          commitmentKwh: 0,
          performanceKwh: 0,
        }
      ),
    }

    yield put(
      DispatchActions.getSnapshotSuccess(portfolioId, dispatchId, result)
    )
  } catch (error) {
    if (error.message) {
      logger.report.error(error.message)
    }
    yield put(
      DispatchActions.getSnapshotFailure(portfolioId, dispatchId, error)
    )
  }
}

function* setUserAckStatus({ user, status, cb = {} }) {
  const { onSuccess = noop, onFailure = noop } = cb
  try {
    const res = yield call(vocAdminClient.setUserAckStatus, {
      eventId: user.dispatchId,
      userId: user.userId,
      acknowledgmentType: 'voltus-override',
      ackStatus: status,
    })

    yield put(
      DispatchActions.updateUserAcknowledgments({
        ...user,
        acknowledgmentStatus: status,
      })
    )
    onSuccess(res)
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    onFailure(e)
  }
}

/**
 * Main saga export
 */
export function* watchDispatch() {
  yield all([
    takeEvery(DispatchTypes.GET_SNAPSHOT, getSnapshot),
    takeEvery(
      DispatchTypes.GET_SNAPSHOT_SUCCESS,
      connectToPortfolioDispatchSocket
    ),
    takeEvery(DispatchTypes.SET_USER_ACK_STATUS, setUserAckStatus),
    takeEvery(DispatchTypes.CLOSE_ALL_SOCKETS, closeAllSockets),
    takeLatest(DispatchTypes.ENQUEUE_BATCH_UPDATE, propagateBatchData),
  ])
}
