import styled from '@emotion/styled'
import { isIE11 } from '@voltus/utils'
import { Box } from '../Box'

const Grid = styled(Box)((props) => {
  if (isIE11()) {
    return {
      display: props.display || '-ms-grid',
      msGridColumns: props.gridTemplateColumns,
      msGridRows: props.gridTemplateRows,
    }
  }

  return {
    display: props.display || 'grid',
  }
})

export { Grid }
