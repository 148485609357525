import { isPast } from 'date-fns'
import { isNil, isEmpty } from 'lodash'
import { DropdownOption } from '@voltus/types'
import { UnreadMessageNotification } from '../../../../../../components/SiteContactChatMenu/types'
import { Assignment, Contact, Dispatch, Facility } from '../../types'
import { DISPATCH_DROPDOWN_OPTIONS } from './constants'

export const getNumNotificationsForFacility = (
  notifications: Array<UnreadMessageNotification>,
  facility: Facility
): number => {
  return notifications.filter((n) =>
    facility.acknowledgments.find((c) => c.userId === n.userId)
  ).length
}

export const getNumNotificationsForContact = (
  notifications: Array<UnreadMessageNotification>,
  contact: Contact
): number => {
  return notifications.filter((n) => n.userId === contact.userId).length
}

export const filterFacilitiesFromDispatches = (
  selectedOption: DropdownOption,
  dispatches: Array<Dispatch>,
  assignments: Record<number, Record<number, Assignment>>,
  userId: number
): Array<Facility> => {
  if (selectedOption.value === DISPATCH_DROPDOWN_OPTIONS.ALL_DISPATCHES.value) {
    return dispatches.reduce((acc, d) => [...acc, ...d.facilities], [])
  }
  if (
    selectedOption.value === DISPATCH_DROPDOWN_OPTIONS.ACTIVE_DISPATCHES.value
  ) {
    return dispatches
      .filter((d) => !d.endTime || !isPast(new Date(d.endTime)))
      .reduce((acc, d) => [...acc, ...d.facilities], [])
  }
  if (
    selectedOption.value === DISPATCH_DROPDOWN_OPTIONS.PAST_DISPATCHES.value
  ) {
    return dispatches
      .filter((d) => !!d.endTime && isPast(new Date(d.endTime)))
      .reduce((acc, d) => [...acc, ...d.facilities], [])
  }
  if (selectedOption.value === DISPATCH_DROPDOWN_OPTIONS.CLAIMED_SITES.value) {
    const allFacilities = dispatches.reduce(
      (acc, d) => [...acc, ...d.facilities],
      []
    )
    return allFacilities.filter((facility) =>
      Object.values(assignments).some((dispatchAssignments) => {
        const assignment = dispatchAssignments[facility.id]
        return (
          assignment && assignment.isAssignment && assignment.userId === userId
        )
      })
    )
  }
  return dispatches.find((d) => d.id === selectedOption.value)?.facilities || []
}

export const filterContactsWithoutMobileNumberFromFacilities = (
  facilities: Array<Facility>
): Array<Facility> => {
  return facilities.map((facility) => ({
    ...facility,
    acknowledgments: facility.acknowledgments.filter(
      (ack) => !isNil(ack.phone) && !isEmpty(ack.phone)
    ),
  }))
}
