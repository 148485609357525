import { ISOKey } from '@voltus/constants'

export const PEAK_SAVER_ISO_KEY_TO_PROGRAM_NAME_MAP = {
  [ISOKey.IESO]: 'Global Adjustment Peak Saver',
  [ISOKey.ISONE]: 'ISO-NE Peak Saver',
  [ISOKey.PJM]: 'PJM Peak Saver',
  [ISOKey.NYISO]: 'NYISO Peak Saver',
  [ISOKey.ERCOT]: 'ERCOT 4CP Peak Saver',
}

export const PJM_TRANSCAP_PROGRAM_NAME_PREFIX = 'PJM - Peak Saver'

export enum DISPATCH_ROLE {
  PRIMARY = 'Primary Dispatch Contact',
  SECONDARY = 'Secondary/Backup Dispatch Contact',
  NOTIFICATION = 'Notifications Only',
}

export enum PARTICIPATION_STATUS {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  NOT_RESPONDED = 'not_responded',
}

export enum EVENT_TYPES {
  MARKET_DISPATCH = 'market_dispatch',
  MARKET_VOLUNTARY_DISPATCH = 'market_voluntary_dispatch',
  MARKET_CURTAILMENT_TEST = 'market_curtailment_test',
  VOLTUS_ACCEPTANCE_TEST = 'voltus_acceptance_test',
  VOLTUS_COMMUNICATIONS_TEST = 'voltus_communications_test',
  VOLTUS_CURTAILMENT_TEST = 'voltus_curtailment_test',
}
