// TODO consider moving this module to core-components or standalone util/module

import { takeLatest, put, all, call } from '@redux-saga/core/effects'
import cloneDeep from 'lodash/cloneDeep'
import logger from '@voltus/logger'
import { network, endpoints } from '@voltus/network'

import { PermissionsTypes, PermissionsActions } from './permissions.redux'

function* getPermissions() {
  try {
    const permissions = window.voltusMeta?.permissions
    if (permissions) {
      // Deliberately want to clone permissions, so that
      // it cannot be changed after the fact by updating
      // the value on window.
      yield put(
        PermissionsActions.receivePermissions(
          cloneDeep(window.voltusMeta.permissions)
        )
      )
    } else {
      const permissions = yield call(network.get, endpoints.auth.permissions())
      yield put(PermissionsActions.receivePermissions(permissions))
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

export function* watchPermissions() {
  yield all([takeLatest(PermissionsTypes.FETCH_PERMISSIONS, getPermissions)])
}
