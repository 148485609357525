import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions(
  {
    setDateRange: ['payload'],
  },
  {
    prefix: 'PAST_PERFORMANCE/',
  }
)
const initialState = {
  startDate: null,
  endDate: null,
}

const setDateRange = produce((draft, { payload }) => {
  const { startDate, endDate } = payload
  if (startDate) {
    draft.startDate = startDate
  }
  if (endDate) {
    draft.endDate = endDate
  }
})

export const dateRangeReducer = createReducer(initialState, {
  [Types.SET_DATE_RANGE]: setDateRange,
})
