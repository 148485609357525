// package: sms
// file: sms.proto

const sms_pb = require('./sms_pb')
const grpc = require('@improbable-eng/grpc-web').grpc

const Sms = (function () {
  function Sms() {}
  Sms.serviceName = 'sms.Sms'
  return Sms
})()

Sms.GetUserMessages = {
  methodName: 'GetUserMessages',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.GetUserMessagesRequest,
  responseType: sms_pb.GetUserMessagesResponse,
}

Sms.GetRecentConversations = {
  methodName: 'GetRecentConversations',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.GetRecentConversationsRequest,
  responseType: sms_pb.GetRecentConversationsResponse,
}

Sms.SendMessage = {
  methodName: 'SendMessage',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.SendMessageRequest,
  responseType: sms_pb.SendMessageResponse,
}

Sms.UpdateMessage = {
  methodName: 'UpdateMessage',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.UpdateMessageRequest,
  responseType: sms_pb.UpdateMessageResponse,
}

Sms.SearchMessages = {
  methodName: 'SearchMessages',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.SearchMessagesRequest,
  responseType: sms_pb.SearchMessagesResponse,
}

Sms.ConversationEventStream = {
  methodName: 'ConversationEventStream',
  service: Sms,
  requestStream: false,
  responseStream: true,
  requestType: sms_pb.ConversationEventStreamRequest,
  responseType: sms_pb.ConversationEventStreamResponse,
}

Sms.IndicateTypingEvent = {
  methodName: 'IndicateTypingEvent',
  service: Sms,
  requestStream: false,
  responseStream: false,
  requestType: sms_pb.IndicateTypingEventRequest,
  responseType: sms_pb.IndicateTypingEventResponse,
}

Sms.MessageNotificationEventStream = {
  methodName: 'MessageNotificationEventStream',
  service: Sms,
  requestStream: false,
  responseStream: true,
  requestType: sms_pb.MessageNotificationEventStreamRequest,
  responseType: sms_pb.MessageNotificationEventStreamResponse,
}

exports.Sms = Sms

function SmsClient(serviceHost, options) {
  this.serviceHost = serviceHost
  this.options = options || {}
}

SmsClient.prototype.getUserMessages = function getUserMessages(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.GetUserMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.getRecentConversations = function getRecentConversations(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.GetRecentConversations, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.sendMessage = function sendMessage(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.SendMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.updateMessage = function updateMessage(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.UpdateMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.searchMessages = function searchMessages(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.SearchMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.conversationEventStream = function conversationEventStream(
  requestMessage,
  metadata
) {
  let listeners = {
    data: [],
    end: [],
    status: [],
  }
  const client = grpc.invoke(Sms.ConversationEventStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    },
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    },
  }
}

SmsClient.prototype.indicateTypingEvent = function indicateTypingEvent(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(Sms.IndicateTypingEvent, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

SmsClient.prototype.messageNotificationEventStream =
  function messageNotificationEventStream(requestMessage, metadata) {
    let listeners = {
      data: [],
      end: [],
      status: [],
    }
    const client = grpc.invoke(Sms.MessageNotificationEventStream, {
      request: requestMessage,
      host: this.serviceHost,
      metadata: metadata,
      transport: this.options.transport,
      debug: this.options.debug,
      onMessage: function (responseMessage) {
        listeners.data.forEach(function (handler) {
          handler(responseMessage)
        })
      },
      onEnd: function (status, statusMessage, trailers) {
        listeners.status.forEach(function (handler) {
          handler({ code: status, details: statusMessage, metadata: trailers })
        })
        listeners.end.forEach(function (handler) {
          handler({ code: status, details: statusMessage, metadata: trailers })
        })
        listeners = null
      },
    })
    return {
      on: function (type, handler) {
        listeners[type].push(handler)
        return this
      },
      cancel: function () {
        listeners = null
        client.close()
      },
    }
  }

exports.SmsClient = SmsClient
