import {
  DispatchOverviewSnapshot,
  DispatchSnapshotWithPortfolios,
} from '../types/dispatch'

export const transformDispatchData = (
  snapshots: Array<DispatchOverviewSnapshot> = []
): Array<DispatchSnapshotWithPortfolios> =>
  snapshots.map((dispatch) => ({
    ...dispatch,
    portfolios: Object.entries(dispatch.portfolios)
      .map(([key, portfolio]) => ({
        id: parseInt(key),
        ...portfolio.metadata,
        ...portfolio.telemetry,
        sites: Object.entries(
          portfolio.metadata.acknowledgmentsByFacility || {}
        ).map(([facilityId, acknowledgements]) => ({
          acknowledgments: acknowledgements,
          id: facilityId,
        })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }))
