import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: RouteParamsTypes, Creators: RouteParamsCreators } =
  createActions(
    { routeParams: ['params'] },
    { prefix: 'DISPATCHES/ROUTE_PARAMS/' }
  )

const INITIAL_STATE = {
  portfolioId: null,
  dispatchId: null,
  tab: null,
}

const handleRouteParams = (state, { params = {} }) => {
  return produce(state, (draft) => {
    Object.entries(params).forEach(([param, value]) => {
      draft[param] = value
    })
  })
}

export const routeParamsReducer = createReducer(INITIAL_STATE, {
  [RouteParamsTypes.ROUTE_PARAMS]: handleRouteParams,
})
