import { noop } from 'lodash'
import * as React from 'react'

import { ONE_MINUTE_MS } from '@voltus/constants'
import { debugLogger } from '@voltus/logger'
import { useProfileQuery } from '@voltus/queries'
import { isTestEnv, isProdHost } from '@voltus/utils'
import { injectQualtrics } from './injectQualtrics'

export enum QualtricsStatus {
  Idle = 'idle',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

type QualtricsState = {
  status: `${QualtricsStatus}`
}
type QualtricsDispatch = {
  refresh: () => void
}
const qualtricsValueContext = React.createContext<QualtricsState>({
  status: QualtricsStatus.Idle,
})
const qualtricsDispatchContext = React.createContext<QualtricsDispatch>({
  refresh: noop,
})

export const QualtricsProvider = ({ children }) => {
  const [state, setState] = React.useState<QualtricsState>({
    status: QualtricsStatus.Idle,
  })
  const [dispatchCtx, setDispatchCtx] = React.useState<QualtricsDispatch>({
    refresh: noop,
  })
  const [initializedQualtrics, setInitializedQualtrics] = React.useState(false)
  const { data: profile } = useProfileQuery()

  React.useEffect(() => {
    const fetchScript = async () => {
      // Don't initialize qualtrics during tests
      if (isTestEnv() || process.env.PROXY_TYPE === 'stub') {
        return
      }

      // Don't re-initialize if we've already successfully done it
      if (initializedQualtrics) {
        return
      }

      // Email is required to know who is filling out the qualtrics surveys,
      // so no point in initializing if we don't have it
      if (!profile?.email) {
        return
      }

      // We're now going to initalize, so set it to true.
      // The rest of the effect will keep running, but next time the effect
      // runs we won't get here since `initializedQualtrics` will be true
      setInitializedQualtrics(true)

      // This is how we tell the qualtrics script who is filling out the survey
      // the `config` variable is picked up by qualtrics when it's scripts load
      // and it needs to be pre-populated with our unique user ID
      if (typeof window.QSI === 'undefined') {
        window.QSI = {}
        window.QSI.config = {
          externalReference: profile.salesforceId,
        }
      }
      // Qualtrics requires setting these as global variables
      // eslint-disable-next-line
      // @ts-ignore
      window.salesforceId = profile.salesforceId
      // eslint-disable-next-line
      // @ts-ignore
      window.Email = profile.email
      try {
        await injectQualtrics(isProdHost())
      } catch (e) {
        setState((state) => ({ ...state, status: QualtricsStatus.Error }))
        debugLogger.error('Failed to inject qualtrics', e)
        return
      }

      // This is a bummmmmer. The qualtrics script itself loads a bunch more stuff
      // before it populates QSI.API
      // But there's no way to know when QSI.API is loaded!
      // So we do it the crazy way, and just loop with a delay
      // until the API object is populated, then return ready
      // If someone's browser is blocking qualtrics for whatever, reason, though,
      // put some arbitrary timeout on this. No reason to run _forever_...
      // It will leave the CO2 feedback card in a somewhat broken looking state,
      // but there's nothing we can do at that point.
      let timeout = 0
      // If we haven't loaded after 5 minutes - it ain't gonna happen
      const maxTimeout = 5 * ONE_MINUTE_MS // 5 minutes
      const delay = 100
      let t: number
      const checkForAPI = () => {
        if (timeout > maxTimeout) {
          return
        }

        if (typeof window.QSI?.API?.run !== 'function') {
          timeout += delay
          window.clearTimeout(t)
          t = window.setTimeout(checkForAPI, delay)
          return
        }

        window.QSI.API.run()
        setState((state) => ({ ...state, status: QualtricsStatus.Ready }))
        setDispatchCtx((state) => ({
          ...state,
          refresh: () => {
            if (window.QSI?.API) {
              window.QSI.API.unload()
              window.QSI.API.load()
              window.QSI.API.run()
            }
          },
        }))
      }

      checkForAPI()
    }
    if (
      state.status !== QualtricsStatus.Ready &&
      state.status !== QualtricsStatus.Error
    ) {
      fetchScript()
    }
  }, [
    state.status,
    profile?.email,
    profile?.salesforceId,
    initializedQualtrics,
    setInitializedQualtrics,
  ])

  return (
    <qualtricsValueContext.Provider value={state}>
      <qualtricsDispatchContext.Provider value={dispatchCtx}>
        {children}
      </qualtricsDispatchContext.Provider>
    </qualtricsValueContext.Provider>
  )
}
const useQualtricsValue = () => React.useContext(qualtricsValueContext)
const useQualtricsDispatch = () => React.useContext(qualtricsDispatchContext)
export const useQualtricsContext: () => [
  QualtricsState,
  QualtricsDispatch,
] = () => [useQualtricsValue(), useQualtricsDispatch()]
