import noop from 'lodash/noop'
import { all, put, call, takeLatest } from 'redux-saga/effects'
import logger from '@voltus/logger'
import * as vocAdminClient from '../../api/vocAdminClient'
import { LOADING_STATES } from '../../constants'
import { CheckInTypes, CheckInCreators } from './checkin.redux'

function* checkIn({ payload }) {
  const {
    userId,
    dispatchId,
    status,
    onSuccess = noop,
    onFailure = noop,
  } = payload
  try {
    if (status) {
      const res = yield call(vocAdminClient.checkIn, dispatchId, userId)
      yield put(CheckInCreators.receiveCheckIn(res.userDispatchState))
      onSuccess()
    } else {
      const res = yield call(vocAdminClient.checkOut, dispatchId, userId)
      yield put(CheckInCreators.receiveCheckIn(res.userDispatchState))
      onSuccess()
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    onFailure()
  }
}

export function* handleFetchCheckIns({ dispatchId }) {
  yield put(
    CheckInCreators.loadingStates({
      [dispatchId]: LOADING_STATES.LOADING,
    })
  )
  try {
    const checkins = yield call(vocAdminClient.getCheckIns, dispatchId)
    const { userDispatchStatesList } = checkins

    yield put(CheckInCreators.receiveCheckInStatuses(userDispatchStatesList))
    yield put(
      CheckInCreators.loadingStates({
        [dispatchId]: LOADING_STATES.READY,
      })
    )
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    yield put(
      CheckInCreators.loadingStates({
        [dispatchId]: LOADING_STATES.ERROR,
      })
    )
  }
}

export function* handleFetchUsers() {
  yield put(
    CheckInCreators.loadingStates({
      users: LOADING_STATES.LOADING,
    })
  )

  try {
    const res = yield call(vocAdminClient.getVoltusUsers)

    yield put(
      CheckInCreators.receiveUsers(
        res[0].members.reduce((userObj, user) => {
          userObj[user.id] = {
            ...user,
            userId: user.id,
          }
          return userObj
        }, {})
      )
    )
    yield put(
      CheckInCreators.loadingStates({
        users: LOADING_STATES.READY,
      })
    )
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    yield put(
      CheckInCreators.loadingStates({
        users: LOADING_STATES.ERROR,
      })
    )
  }
}

export function* watchCheckIns() {
  yield all([
    takeLatest(CheckInTypes.FETCH_USERS, handleFetchUsers),
    takeLatest(CheckInTypes.FETCH_CHECK_IN_STATUSES, handleFetchCheckIns),
    takeLatest(CheckInTypes.CHECK_IN, checkIn),
  ])
}
