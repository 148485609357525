/**
 * A set of utilities to make working with react-query
 * more ergonomic
 * @packageDocumentation
 */
import { Any } from '@voltus/types'

/**
 * A helper that wraps a promise in a try/catch handler.
 * Useful for when your promise does not catch, and thus would throw,
 * causing a crash. By wrapping in a try catch, we can reject the passed in
 * promise rather than crash
 *
 * Why do we have this? The network utility methods do not catch when
 * there's a a network error, which can cause an application error
 * if not handled.
 *
 * react-query in particular is promise based, and needs a promise to resolve or
 * reject, it does not handle application errors. So in order to use the network
 * utilities with react-query, we need to wrap them in a try/catch
 *
 * ### Usage Example with react-query
 * ```
 * type MyResponseDataType = {
 *   someData: string
 *   otherData?: boolean
 * }
 *
 * useQuery(
 *   'my-key',
 *   makeQueryFetcher<MyResponseDataType>(
 *     () => network.get(endpoints.some.endpoint())
 *   )
 * )
 * ```
 *
 * @typeParam DataType - the return type (wrapped in a promise) from calling fn()
 * @param fn - Any function that returns a promise
 * @returns a wrapped function that calls fn with a try/catch loop
 */
export const makeQueryFetcher =
  <DataType = unknown>(fn: (...args: Array<Any>) => Promise<DataType>) =>
  (...args: Array<Any>): Promise<DataType> => {
    return new Promise((resolve, reject) => {
      const doFetch = async () => {
        try {
          const res = await fn(...args)
          resolve(res)
        } catch (e) {
          reject(e)
        }
      }
      doFetch()
    })
  }
