import qs from 'query-string'

/**
 * A handler to override the `/facility/search` endpoint to fake text
 * search functionality.
 * We do a bit of a bait and switch. We remove the search param from the url,
 * then fetch ALL facilities, then sanitize the data and then do string matching
 * on the sanitized facility names.
 *
 * Searching on facility names is good enough for demo purposes.
 */
export const handleFacilitySearch = (uri, opts) => {
  const { url, query } = qs.parseUrl(uri)
  const originalSearch = query.search
  if (query.search) {
    delete query.search
    query.per_page = '1000000'
  }
  return {
    uri: qs.stringifyUrl({ url, query }),
    opts,
    ctx: { search: originalSearch },
  }
}
