import { useQueries } from '@tanstack/react-query'
import { network, endpoints } from '@voltus/network'

interface CheckResponse {
  can: boolean
}
export const userCan = async (
  permissions: Array<string>,
  require_all: boolean = true
): Promise<boolean> => {
  const res = await network.get<CheckResponse>(
    endpoints.v0_1.permissionsCheck({
      permissions: permissions,
      require_all: require_all,
    })
  )
  return res.can
}

type Permission = string
export const useCanDo = (
  permissions: Array<string>
): {
  canDo: { [key: Permission]: boolean }
  isFetching: boolean
  isPending: boolean
} => {
  const queries = permissions.map((perm) => ({
    queryKey: ['can-do', perm],
    queryFn: async () => {
      return [perm, await userCan([perm], true)] as [string, boolean]
    },
  }))
  const results = useQueries({ queries })
  const isPending = results.some((result) => result.isPending)
  const isFetching = results.some((result) => result.isFetching)
  const isSuccess = results.every((result) => result.isSuccess)

  const canDo = !isSuccess
    ? {}
    : results.reduce(
        (acc: { [key: Permission]: boolean }, { data: [perm, can] = [] }) => {
          if (perm && typeof can === 'boolean') {
            acc[perm] = can
          }
          return acc
        },
        {}
      )

  return { canDo, isPending, isFetching }
}
