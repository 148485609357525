import { isWithinInterval, subWeeks } from 'date-fns'
import { flatten, flattenDeep, uniqBy } from 'lodash'
import { Conversation, SMSMessage } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { Dispatch } from '../../routes/dispatches/routes/ActiveDispatches/types'
import { ConversationsEventStream, UnreadMessageNotification } from './types'

export const isMessageUnread = (message: SMSMessage.AsObject): boolean =>
  !message.metadataMap.find(
    (metadata) => metadata[0] === 'markAsRead' && metadata[1] === 'true'
  )

export const combinePastMessagesWithEventStreamMessages = (
  pastMessages: Array<SMSMessage.AsObject> = [],
  eventStreamMessages: Array<SMSMessage.AsObject> = []
): Array<SMSMessage.AsObject> => {
  const combined = pastMessages.concat(eventStreamMessages)
  const duplicatesRemoved = uniqBy(combined, (m) => m.id)
  return duplicatesRemoved.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  )
}

export const generateNotificationsFromPastConversations = (
  pastConversations: Array<Conversation.AsObject>,
  readMessageIds: Array<number>,
  unReadMessageIds: Array<number>
): Array<UnreadMessageNotification> => {
  return pastConversations.reduce(
    (acc: Array<UnreadMessageNotification>, cur: Conversation.AsObject) => {
      if (
        // eslint-disable-next-line
        // @ts-ignore
        cur.message &&
        // eslint-disable-next-line
        // @ts-ignore
        (isMessageUnread(cur.message) ||
          // eslint-disable-next-line
          // @ts-ignore
          unReadMessageIds.includes(cur.message.id)) &&
        // eslint-disable-next-line
        // @ts-ignore
        !readMessageIds.includes(cur.message.id)
      ) {
        const notification: UnreadMessageNotification = {
          userId: cur.userId,
          userFullName: cur.userFullName,
          // eslint-disable-next-line
          // @ts-ignore
          messageId: cur.message.id,
          // eslint-disable-next-line
          // @ts-ignore
          senderUserId: cur.message.senderUserId,
        }
        acc.push(notification)
      }
      return acc
    },
    []
  )
}

export const generateNotificationsFromEventStream = (
  conversationsEventStream: ConversationsEventStream,
  readMessageIds: Array<number>,
  unreadMessageIds: Array<number>
): Array<UnreadMessageNotification> => {
  const messagesLists = Object.values(conversationsEventStream).map(
    (conversation) => conversation.messages
  )
  const messages = flatten(messagesLists)

  return messages.reduce(
    (acc: Array<UnreadMessageNotification>, cur: SMSMessage.AsObject) => {
      if (
        (isMessageUnread(cur) || unreadMessageIds.includes(cur.id)) &&
        !readMessageIds.includes(cur.id)
      ) {
        const notification: UnreadMessageNotification = {
          userId: cur.userId,
          messageId: cur.id,
          senderUserId: cur.senderUserId,
        }
        acc.push(notification)
      }
      return acc
    },
    []
  )
}

export const generateUnreadMessageNotifications = (
  pastConversations: Array<Conversation.AsObject> = [],
  conversationsEventStream: ConversationsEventStream = {},
  readMessageIds: Array<number>,
  unreadMessageIds: Array<number>
): Array<UnreadMessageNotification> => {
  return uniqBy(
    generateNotificationsFromPastConversations(
      pastConversations,
      readMessageIds,
      unreadMessageIds
    ).concat(
      generateNotificationsFromEventStream(
        conversationsEventStream,
        readMessageIds,
        unreadMessageIds
      )
    ),
    (notification) => notification.messageId
  )
}

export const findRecentConversationsOutsideDispatches = (
  pastConversations: Array<Conversation.AsObject> = [],
  dispatches: Array<Dispatch> = []
): Array<Conversation.AsObject> => {
  const allDispatchContactUserIds: Array<number> = flattenDeep(
    dispatches.map((dispatch) =>
      dispatch.facilities.map((facility) =>
        facility.acknowledgments.map((ack) => ack.userId)
      )
    )
  )
  return pastConversations.filter((conversation) => {
    const isUnread = isMessageUnread(
      // eslint-disable-next-line
      // @ts-ignore
      conversation.message as SMSMessage.AsObject
    )
    const isFromSiteContact =
      // eslint-disable-next-line
      // @ts-ignore
      conversation.userId === conversation.message?.senderUserId
    const isOutsideDispatches = !allDispatchContactUserIds.includes(
      conversation.userId
    )
    const isFromPastWeek = isWithinInterval(
      // eslint-disable-next-line
      // @ts-ignore
      new Date(conversation.message?.timestamp as string),
      { start: subWeeks(new Date(), 1), end: new Date() }
    )
    return (
      (isUnread || isFromPastWeek) && isFromSiteContact && isOutsideDispatches
    )
  })
}

export const getDispatchIdsToSiteIdsMapForContact = (
  contactId: number,
  dispatches: Array<Dispatch>
): { [key: number]: Array<number> } =>
  dispatches.reduce(
    (result: { [key: number]: Array<number> }, curDispatch: Dispatch) => {
      curDispatch.facilities.forEach((facility) => {
        if (facility.acknowledgments.some((ack) => ack.userId === contactId)) {
          if (
            result[curDispatch.id] &&
            !result[curDispatch.id].includes(facility.id)
          ) {
            result[curDispatch.id].push(facility.id)
          } else {
            result[curDispatch.id] = [facility.id]
          }
        }
      })
      return result
    },
    {}
  )

export const getFacilityIdsForContact = (
  contactId: number,
  dispatches: Array<Dispatch>
): Array<number> => {
  return dispatches.reduce(
    (facilityIds: Array<number>, curDispatch: Dispatch) => {
      curDispatch.facilities.forEach((facility) => {
        if (
          facility.acknowledgments.some(
            (contact) => contact.userId === contactId
          )
        ) {
          facilityIds.push(facility.id)
        }
      })
      return facilityIds
    },
    []
  )
}

export const generateFacilityIdToRecentMessageTimestampMap = (
  dispatches: Array<Dispatch>,
  pastConversations: Array<Conversation.AsObject> = [],
  conversationsEventStream: ConversationsEventStream = {}
): { [key: number]: string } => {
  const result = {}

  pastConversations.forEach((conversation) => {
    const facilityIds = getFacilityIdsForContact(
      conversation.userId,
      dispatches
    )
    // eslint-disable-next-line
    // @ts-ignore
    const timestamp = conversation.message?.timestamp

    facilityIds.forEach((facilityId) => {
      const existingFacilityTimestamp = result[facilityId]
      if (
        (existingFacilityTimestamp &&
          new Date(timestamp as string).getTime() >
            new Date(existingFacilityTimestamp).getTime()) ||
        !existingFacilityTimestamp
      ) {
        result[facilityId] = timestamp
      }
    })
  })

  Object.values(conversationsEventStream).forEach((value) => {
    if (value.messages) {
      const mostRecentMessage = value.messages.slice(-1)[0]
      if (mostRecentMessage) {
        const { timestamp, userId } = mostRecentMessage

        const facilityIds = getFacilityIdsForContact(userId, dispatches)
        facilityIds.forEach((facilityId) => {
          const existingFacilityTimestamp = result[facilityId]
          if (
            (existingFacilityTimestamp &&
              new Date(timestamp).getTime() >
                new Date(existingFacilityTimestamp).getTime()) ||
            !existingFacilityTimestamp
          ) {
            result[facilityId] = timestamp
          }
        })
      }
    }
  })

  return result
}
