export const removeStartingIfExists = (
  str: string,
  startingChar: string
): string => {
  if (str.startsWith(startingChar)) {
    return str.slice(startingChar.length)
  }

  return str
}

export const stripOuterCharacters = (str = '', char = '"'): string => {
  let res = str
  if (str.startsWith(char)) {
    res = str.slice(1)
  }
  if (str.endsWith(char)) {
    res = res.slice(0, res.length - 1)
  }

  return res
}

export const capitalizeLastWord = (str: string): string => {
  const words = str.split(' ')
  if (words.length === 0) {
    return str
  }
  const lastWordCaps = words[words.length - 1].toUpperCase()
  return words.slice(0, -1).concat(lastWordCaps).join(' ')
}

export const upperFirst = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1)

type padFn = (v: string | number) => string
type padPredicate = (v: string | number) => boolean

export const pad = (
  v: string | number,
  pad: string | padFn = '',
  shouldPad: boolean | padPredicate = true
): string => {
  const padder = (val: string | number) => {
    if (typeof pad === 'function') {
      return pad(val)
    }
    return `${pad}${val}`
  }

  if (typeof shouldPad === 'function') {
    if (shouldPad(v)) {
      return String(padder(v))
    }

    return String(v)
  }

  // shouldPad is a boolean
  if (shouldPad) {
    return String(padder(v))
  }

  return String(v)
}

export const ellipsisAfter = (str: string, len: number): string => {
  if (str.length <= len) {
    return str
  }

  return str.slice(0, len - 2) + '...'
}

const pluralRules = new Intl.PluralRules('en-US')

/**
 * Returns a grammatically correct string based on the count.
 * @param count - The number of items
 * @param singular - Text to use in the singular case
 * @param plural - Text to use in the plural case
 */
export const getPlural = (
  count: number,
  singular: string,
  plural: string
): string => {
  const grammaticalNumber = pluralRules.select(count)
  switch (grammaticalNumber) {
    case 'one':
      return singular
    case 'other':
      return plural
    default:
      throw new Error('Unknown: ' + grammaticalNumber)
  }
}

/**
 * Returns a grammatically correct string based on the count. The returned
 * string includes the count.
 * @param count - The number of items
 * @param singular - Text to use in the singular case
 * @param plural - Text to use in the plural case
 */
export function pluralize(count: number, singular: string, plural: string) {
  return `${count} ${getPlural(count, singular, plural)}`
}

// This method takes a value string of any size
// and hashes it to an unsigned 32 bit integer.
// The integer is appended to the supplied prefix
// and returned.
// source: https://stackoverflow.com/a/70563032
export function hashStringToUInt(value: string, prefix: string) {
  let hash = 0

  for (let i = 0; i < value.length; i++) {
    // use shifting and addition to generate a hash value
    // influenced by the input string's characters.
    // the left shift multiplies the previous hash value by 32,
    // subtraction creates a pseudo-randomness,
    // and retrieving the character code makes it specific
    // to that string.
    hash = (hash << 5) - hash + value.charCodeAt(i)
    hash = hash & hash // prevent overflow from happening
  }
  // mask to ensure value is within the UInt32 range
  const intValue = hash & 0xfffff
  return `${prefix} ${intValue}`
}
