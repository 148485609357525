// package: voc_admin
// file: voc_admin.proto

const voc_admin_pb = require('./voc_admin_pb')
const grpc = require('@improbable-eng/grpc-web').grpc

const VocAdmin = (function () {
  function VocAdmin() {}
  VocAdmin.serviceName = 'voc_admin.VocAdmin'
  return VocAdmin
})()

VocAdmin.CheckIn = {
  methodName: 'CheckIn',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.CheckInRequest,
  responseType: voc_admin_pb.CheckInResponse,
}

VocAdmin.CheckOut = {
  methodName: 'CheckOut',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.CheckOutRequest,
  responseType: voc_admin_pb.CheckOutResponse,
}

VocAdmin.Assign = {
  methodName: 'Assign',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.AssignRequest,
  responseType: voc_admin_pb.AssignResponse,
}

VocAdmin.Unassign = {
  methodName: 'Unassign',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.UnassignRequest,
  responseType: voc_admin_pb.UnassignResponse,
}

VocAdmin.GetAssignments = {
  methodName: 'GetAssignments',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.GetAssignmentsRequest,
  responseType: voc_admin_pb.GetAssignmentsResponse,
}

VocAdmin.GetCheckIns = {
  methodName: 'GetCheckIns',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.GetCheckInsRequest,
  responseType: voc_admin_pb.GetCheckInsResponse,
}

VocAdmin.CreateTextNote = {
  methodName: 'CreateTextNote',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.CreateTextNoteRequest,
  responseType: voc_admin_pb.CreateTextNoteResponse,
}

VocAdmin.GetFacilityNotes = {
  methodName: 'GetFacilityNotes',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.GetFacilityNotesRequest,
  responseType: voc_admin_pb.GetFacilityNotesResponse,
}

VocAdmin.GetSiteContactNotes = {
  methodName: 'GetSiteContactNotes',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.GetSiteContactNotesRequest,
  responseType: voc_admin_pb.GetSiteContactNotesResponse,
}

VocAdmin.EventStream = {
  methodName: 'EventStream',
  service: VocAdmin,
  requestStream: false,
  responseStream: true,
  requestType: voc_admin_pb.EventStreamRequest,
  responseType: voc_admin_pb.EventStreamResponse,
}

VocAdmin.OutboundCall = {
  methodName: 'OutboundCall',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.OutboundCallRequest,
  responseType: voc_admin_pb.OutboundCallResponse,
}

VocAdmin.GetDispatchCalls = {
  methodName: 'GetDispatchCalls',
  service: VocAdmin,
  requestStream: false,
  responseStream: false,
  requestType: voc_admin_pb.GetDispatchCallsRequest,
  responseType: voc_admin_pb.GetDispatchCallsResponse,
}

exports.VocAdmin = VocAdmin

function VocAdminClient(serviceHost, options) {
  this.serviceHost = serviceHost
  this.options = options || {}
}

VocAdminClient.prototype.checkIn = function checkIn(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.CheckIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.checkOut = function checkOut(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.CheckOut, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.assign = function assign(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.Assign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.unassign = function unassign(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.Unassign, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.getAssignments = function getAssignments(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.GetAssignments, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.getCheckIns = function getCheckIns(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.GetCheckIns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.createTextNote = function createTextNote(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.CreateTextNote, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.getFacilityNotes = function getFacilityNotes(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.GetFacilityNotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.getSiteContactNotes = function getSiteContactNotes(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.GetSiteContactNotes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.eventStream = function eventStream(
  requestMessage,
  metadata
) {
  let listeners = {
    data: [],
    end: [],
    status: [],
  }
  const client = grpc.invoke(VocAdmin.EventStream, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    },
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    },
  }
}

VocAdminClient.prototype.outboundCall = function outboundCall(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.OutboundCall, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

VocAdminClient.prototype.getDispatchCalls = function getDispatchCalls(
  requestMessage,
  metadata,
  callback
) {
  if (arguments.length === 2) {
    callback = arguments[1]
  }
  const client = grpc.unary(VocAdmin.GetDispatchCalls, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          const err = new Error(response.statusMessage)
          err.code = response.status
          err.metadata = response.trailers
          callback(err, null)
        } else {
          callback(null, response.message)
        }
      }
    },
  })
  return {
    cancel: function () {
      callback = null
      client.close()
    },
  }
}

exports.VocAdminClient = VocAdminClient
