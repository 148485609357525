import { isNil, uniq, isEmpty, get, identity } from 'lodash'
import { Any } from '@voltus/types'

export const ensureArray = <T = Any>(arr: T | Array<T>): Array<T> => {
  return Array.isArray(arr) ? arr : [arr]
}

/**
 * Updates an object in a array if it exists,
 * or appends it to the end if it doesn't exist
 *
 * Array must be filled with objects that have some unique identifier
 * This identifier should be passed as the `keyPath`.
 */
export const upsertBy = (
  arr: Array<Any> = [],
  updated: Any = [],
  keyPath: string | number
): Array<Any> => {
  const res = arr.slice()
  const updatedArr = ensureArray(updated).slice()

  const keyGetter =
    typeof keyPath === 'function' ? keyPath : (v) => get(v, keyPath)

  const notFound: Array<Any> = []
  while (updatedArr.length) {
    const newItem = updatedArr.shift()
    const foundIndex = arr.findIndex(
      (curr) => keyGetter(curr) === keyGetter(newItem)
    )
    if (foundIndex !== -1) {
      res.splice(foundIndex, 1, newItem)
    } else {
      notFound.push(newItem)
    }
  }

  return res.concat(notFound)
}

/**
 * Make a sort function allowing for selecting
 * the object properties to compare
 */
export const makeSortBy =
  <T>(
    key: string,
    opts?: {
      dir?: 'ascending' | 'descending'
      transform?: (v: Any) => string | number
    }
  ) =>
  (a: T, b: T): number => {
    const transform = opts?.transform ?? identity<Any>
    const direction = opts?.dir ?? 'ascending'
    const dir = direction === 'ascending' ? -1 : 1

    const aVal = transform(get(a, key))
    const bVal = transform(get(b, key))

    if (typeof aVal === 'string' && typeof bVal === 'string') {
      return bVal.localeCompare(aVal) * dir
    }

    if (aVal < bVal) {
      return 1 * dir
    } else if (aVal > bVal) {
      return -1 * dir
    }

    return 0
  }

export const getUniqueNonEmptyValues = (
  array: Array<Any>,
  key: string
): Array<Any> => {
  const uniqueValues = uniq(array.map((item) => item[key]))

  return uniqueValues.filter((value) => !isNil(value) && !isEmpty(value))
}

/**
 * Type guard-esque function to cast an array with nils
 * to a filtered array without nils, where each item
 * has the same type
 *
 * E.g. Given an array like
 *  const a = [1, 2, 3, false, false, 4, false]
 * calling a.filter(Boolean) returns an array with types (boolean | number)[]
 *
 * But what we really want is a result with the type number[]
 *
 * Takes one generic parameter to determine the type of the result
 *
 * @param arr - Array to filter and cast
 * @returns Array cast to ResultType[]
 */
export const filterNils = <ResultType>(
  arr: Array<boolean | ResultType>
): Array<ResultType> => {
  return arr.filter((a) => a) as Array<ResultType>
}
/**
 * Push an item into an array if it exists, otherwise return
 * a new array with the item as the first entry
 */
export const pushOrCreate = <ItemType>(
  item: ItemType,
  arr?: Array<ItemType>
): Array<ItemType> => {
  if (arr) {
    arr.push(item)
    return arr
  }
  return [item]
}

/**
 * Takes an array of data, and converts it to an object where the key of each
 * item is the value specified by the key of each item.
 *
 * E.g. Given an array of objects like
 * ```
 * const a = [{ id: 1, name: 'One' }, { id: 2, name: 'Two' }]
 * ```
 * calling:
 * ```
 * const b = arrToObjByKey(a, 'id')
 * // b is now:
 * // { 1: { id: 1, name: 'One' }, 2: { id: 2, name: 'Two' } }
 * ```
 *
 * Takes an optional third argument to transform the item before adding it to the object
 * ```
 * arrToObjByKey(a, 'id', (item) => item.name)
 * // returns { 1: 'One', 2: 'Two' }
 * ```
 */
export function arrToObjByKey<Data extends object>(
  arr: Array<Data>,
  key: string
): Record<string, Data>
export function arrToObjByKey<
  Data extends object,
  Transform extends (item: Data) => ReturnType<Transform>,
>(
  arr: Array<Data>,
  key: string,
  transform: Transform
): Record<string, ReturnType<typeof transform>>
export function arrToObjByKey<
  Data extends object,
  Transform extends (item: Data) => ReturnType<Transform>,
>(arr: Array<Data>, key: string, transform?: Transform) {
  return arr.reduce((acc, curr) => {
    acc[curr[key]] = transform ? transform(curr) : curr
    return acc
  }, {})
}

/**
 * Sums up items in an array. Accepts an array of primitive numbers, or an array of objects
 * If given an array of objects, expects a second `pathOrGetter` argument to grab the property that
 * we should sum.
 *
 * E.g. Given an array of objects and a string pathOrGetter
 * ```
 * sumBy([{ id: 1, value: 1 }, { id: 2, value: 2 }], 'value')
 * // returns 3
 * ```
 *
 * `pathOrGetter` can access nested properties as well
 * ```
 * sumBy([{ id: 1, value: { amount: 1 } }, { id: 2, value: { amount: 2 } }], 'value.amount')
 * // returns 3
 * ```
 *
 * If you need more flexibility, you can pass a function to `pathOrGetter`. Whatever is returned
 * from this function will be used to add to the sum
 */
export const sumBy = <ArrType>(
  arr: Array<ArrType>,
  pathOrGetter?: string | ((obj) => number)
): number => {
  return arr.reduce((acc, curr) => {
    // if the array is a number array, things are easy
    if (typeof curr === 'number') {
      acc += curr
    } else {
      // Otherwise we have an array of objects, do some extra work
      // to access the property we care about
      if (typeof pathOrGetter === 'string') {
        const val = get(curr, pathOrGetter)
        if (!isNaN(val)) {
          acc += val
        }
      } else {
        if (pathOrGetter && typeof pathOrGetter === 'function') {
          const val = pathOrGetter(curr)
          if (!isNaN(val)) {
            acc += val
          }
        }
      }
    }

    return acc
  }, 0)
}

export const avgBy = <ArrType>(
  arr: Array<ArrType>,
  pathOrGetter?: string | ((obj) => number)
): number => {
  const sum = sumBy(arr, pathOrGetter)
  return sum / arr.length
}

/**
 * Takes an array and breaks it up into n buckets of
 * equal size (with the exception of the last bucket)
 */
export const batchArray = <Item>(
  arr: Array<Item>,
  n: number
): Array<Array<Item>> => {
  if (!arr.length) {
    return []
  }
  const buckets: Array<Array<Item>> = []
  for (let i = 0; i < arr.length; i++) {
    if (i % n === 0) {
      buckets.push(arr.slice(i, i + n))
    }
  }
  return buckets
}
