import * as React from 'react'
import { TransitionStatus } from 'react-transition-group'
import { isTestEnv } from '@voltus/utils'
import { StyledPropsCss } from '../../utils/styledSystem'
import { Box } from '../Box'

export type AccordionContentProps<Item> = {
  contentInnerCss?: StyledPropsCss
  transitionState: TransitionStatus
  renderContent: (
    item: Item,
    isOpened: boolean,
    index: number,
    setRef: (ref: HTMLElement) => void
  ) => React.ReactNode
  item: Item
  isOpened: boolean
  heights: { [key: number]: number }
  index: number
  nodeRef: React.ForwardedRef<HTMLDivElement>
}

export function AccordionContent<Item>({
  contentInnerCss,
  transitionState,
  renderContent,
  item,
  isOpened,
  heights,
  index,
  nodeRef,
}: AccordionContentProps<Item>): JSX.Element {
  // const ref = React.useRef<HTMLDivElement>(null)
  const [contentRef, setContentRef] = React.useState<HTMLElement>()
  const [height, setHeight] = React.useState(0)

  // We want to know if the child container changes height,
  // so that we can reset the height of this content container
  // so we expose a ref setter for children if they want it
  const setRef = (ref: HTMLElement) => {
    if (ref !== contentRef) {
      setContentRef(ref)
    }
  }

  // Create a resize observer for the child content
  // and update the internal height if we're not
  // in the middle of a transition
  React.useEffect(() => {
    if (contentRef) {
      const observer = new ResizeObserver(([entry]) => {
        if (transitionState === 'entered') {
          setHeight(entry.borderBoxSize[0].blockSize)
        }
      })
      observer.observe(contentRef)
      return () => {
        observer.unobserve(contentRef)
      }
    }
  }, [contentRef, transitionState])

  const getStyle = () => {
    // If we're exiting, no matter what,
    // we should be going to 0 height
    if (transitionState === 'exiting') {
      return { height: 0 }
    }

    // Otherwise, we have some local height set from the
    // resize observer, meaning the content changed size
    // after the content was opened
    if (height) {
      return { height }
    }

    // If we don't have any height from the child, then
    // use the calculated height of this container
    return { height: heights[index] }
  }

  return (
    <Box
      ref={nodeRef}
      style={getStyle()}
      borderTopWidth={1}
      borderTopStyle="solid"
      borderTopColor="grays.20"
      css={{
        ...(isTestEnv()
          ? {}
          : {
              transition: 'height 200ms ease-out',
            }),
        ...contentInnerCss,
      }}
    >
      {renderContent(item, isOpened, index, setRef)}
    </Box>
  )
}
