import { useMutation } from '@tanstack/react-query'
import { SendMessageRequest } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { sendMessage } from '../../../routes/dispatches/routes/ActiveDispatches/api/smsClient'

export const useSendMessage = () => {
  return useMutation({
    mutationFn: (
      sendMessageRequest: Omit<SendMessageRequest.AsObject, 'accessToken'>
    ) => sendMessage(sendMessageRequest),
  })
}
