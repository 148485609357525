import Cookie from 'cookies-js'
import { isNil } from 'lodash'
import { debugLogger } from '@voltus/logger'
import { isTestEnv } from '@voltus/utils'

import auth from './auth'
import { saveAccessToken, ACCESS_TOKEN } from './tokens'

/*
 * @returns {Promise} promise used to do subsequent web app initialization
 * (wait for `auth/refresh` API call to complete)
 */
export default function setupAuth(
  domain = '',
  env,
  email = null,
  password = null
) {
  auth.setDomain(domain)
  checkEnv(env)

  // When running tests we don't want to fetch the access token, it should already exist
  // on a cookie from the test env (or not, if the env never sets a cookie, like when we're
  // testing unauthenticated views)
  if (isTestEnv()) {
    return saveAccessToken(Cookie.get(ACCESS_TOKEN))
  }

  // webpack dev server does not go through the auth user flow...
  if (env === 'webpack-dev-server') {
    return auth.setupWdsEnv(email, password).then(() => {
      saveAccessToken(Cookie.get(ACCESS_TOKEN))
    })
  }

  return saveAccessToken(Cookie.get(ACCESS_TOKEN))
}

function checkEnv(env) {
  if (isNil(env)) {
    debugLogger.warn(`NODE_ENV is null.`)
  }
}
