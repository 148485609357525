import { useState, useCallback, useLayoutEffect } from 'react'

export const useDimensions = <ElementType extends Element>() => {
  const [dimensions, setDimensions] = useState<DOMRectReadOnly>()
  const [node, setNode] = useState<ElementType>()

  const ref = useCallback((node: ElementType) => {
    setNode(node)
  }, [])

  useLayoutEffect(() => {
    if (node) {
      const observer = new ResizeObserver(([entry]) => {
        window.requestAnimationFrame(() => {
          setDimensions(entry.contentRect)
        })
      })

      observer.observe(node)

      return () => {
        observer.unobserve(node)
      }
    }
  }, [node])

  return [ref, dimensions, node] as [typeof ref, typeof dimensions, typeof node]
}

export default useDimensions
