import {
  addYears,
  addMinutes,
  subMinutes,
  endOfDay,
  startOfDay,
  differenceInDays,
} from 'date-fns'
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz'
import { isNil } from 'lodash'
import { ONE_DAY_MS } from '@voltus/constants'
import logger from '@voltus/logger'
import { pluralize } from '@voltus/utils'

/**
 * Often we get timestamps back from the server
 * that look like `2020-01-01T00:00:00Z`
 * Which, when used to compare against local start
 * end dates, gets tricky, since these offsets often
 * cause "month bleed". Basically, that timestamp
 * is technically 2019-12-31 for a lot of browsers,
 * so may get picked up in the previous month/year
 * if we're trying to figure out which month/year
 * to render something into
 *
 * This helper basically ignores UTC, and converts
 * the year, month, and day to the same year month and day
 * in the browsers local time. Essentially we throw away
 * timestamp, and assume that the day, year, and month are
 * right regardless of timezone
 *
 * @param utcTimestamp - UTC ISO 8601 string
 */
export function convertUTCToLocalDay(utcTimestamp: Date | string): Date {
  const date = new Date(utcTimestamp)
  const offset = date.getTimezoneOffset()
  // Some timezones (e.g. Australia, India) have a negative offset
  // from UTC. In that case, we need to subtract the (negative) minutes from
  // the datestamp instead
  const d = offset > 0 ? addMinutes(date, offset) : subMinutes(date, offset)
  return new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
}

/**
 * A convenience date constructor that allows for ignoring time
 * when constructing a Date object.
 *
 * When we get timestamps like '2021-11-01T00:00:00Z', sometimes
 * we want to disregard timezone entirely, and just make sure we
 * get a date that matches the day of the original UTC timestamp, Nov 1st 2021
 *
 * If we pass \{ ignoreTime: true \}, and my browser is in New York,
 * the Date object I get back will have a timestamp of '2021-11-01T04:00:00Z' (or could be 05:00:00 if DST)
 * This means future operations on that date will return Nov 1st, rather than Oct 31st
 *
 * @param date - an ISO 8601 string or object
 * @param opts - additional options to ignore the time when constructing the date
 * @returns Date
 */
export function toDate(
  date: string | number | Date,
  opts?: {
    ignoreTime?: boolean
  }
): Date {
  const d = typeof date === 'object' ? date : new Date(date)

  if (!isValidDate(d)) {
    logger.report.once.error(
      `Unable to convert value ${date} with type ${typeof date} to Date`
    )
  }

  return opts?.ignoreTime
    ? new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate())
    : d
}

/**
 * A helper to convert a date to an ISO8601 string with an option to ignore the trailing milliseconds
 * from the date string.
 *
 * Example:
 * ```ts
 * new Date().toISOString() // returns something like '2021-11-01T00:00:00.000Z'
 *
 * toISOString(new Date(), { ignoreMilliseconds: true }) // returns something like '2021-11-01T00:00:00Z'
 * ```
 */
export function toISOString(
  date: string | Date,
  opts?: { ignoreTime?: boolean; ignoreMilliseconds?: boolean }
): string {
  const d = toDate(date, opts)

  if (opts?.ignoreMilliseconds) {
    return d.toISOString().split('.')[0] + 'Z'
  }
  return d.toISOString()
}

// Return a local date from a UTC date 'representing' the same date with 00:00:00 time i.e. approximating a civil date
export function getCivilDate(date: string | Date) {
  const d = typeof date === 'string' ? new Date(date) : date
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
}

export function adjustTimezoneOffset(date?: string | Date): Date {
  let d: Date
  if (typeof date === 'object') {
    d = date
  } else if (typeof date === 'string') {
    d = new Date(date)
  } else {
    d = new Date()
  }

  d.setMinutes(d.getMinutes() - d.getTimezoneOffset())
  return d
}

// Take from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// We get Timezones back in deprecated formats, which can cause issues with using
// the browser built in internationalization utils, like Intl
const DeprecatedTimeZoneMap = new Map([
  ['Etc/Greenwich', 'Etc/GMT'],
  ['Etc/UCT', 'Etc/UTC'],
  ['Etc/Universal', 'Etc/UTC'],
  ['Etc/Zulu', 'Etc/UTC'],
  ['GMT+0', 'Etc/GMT'],
  ['GMT-0', 'Etc/GMT'],
  ['GMT0', 'Etc/GMT'],
  ['Greenwich', 'Etc/GMT'],
  ['UCT', 'Etc/UTC'],
  ['Universal', 'Etc/UTC'],
  ['Zulu', 'Etc/UTC'],
  ['Antarctica/South_Pole', 'Pacific/Auckland'],
  ['America/Argentina/ComodRivadavia', 'America/Argentina/Catamarca'],
  ['America/Buenos_Aires', 'America/Argentina/Buenos_Aires'],
  ['America/Catamarca', 'America/Argentina/Catamarca'],
  ['America/Cordoba', 'America/Argentina/Cordoba'],
  ['America/Jujuy', 'America/Argentina/Jujuy'],
  ['America/Mendoza', 'America/Argentina/Mendoza'],
  ['America/Rosario', 'America/Argentina/Cordoba'],
  ['Australia/ACT', 'Australia/Sydney'],
  ['Australia/Canberra', 'Australia/Sydney'],
  ['Australia/Currie', 'Australia/Hobart'],
  ['Australia/LHI', 'Australia/Lord_Howe'],
  ['Australia/North', 'Australia/Darwin'],
  ['Australia/NSW', 'Australia/Sydney'],
  ['Australia/Queensland', 'Australia/Brisbane'],
  ['Australia/South', 'Australia/Adelaide'],
  ['Australia/Tasmania', 'Australia/Hobart'],
  ['Australia/Victoria', 'Australia/Melbourne'],
  ['Australia/West', 'Australia/Perth'],
  ['Australia/Yancowinna', 'Australia/Broken_Hill'],
  ['Asia/Dacca', 'Asia/Dhaka'],
  ['America/Porto_Acre', 'America/Rio_Branco'],
  ['Brazil/Acre', 'America/Rio_Branco'],
  ['Brazil/DeNoronha', 'America/Noronha'],
  ['Brazil/East', 'America/Sao_Paulo'],
  ['Brazil/West', 'America/Manaus'],
  ['Asia/Thimbu', 'Asia/Thimphu'],
  ['America/Coral_Harbour', 'America/Atikokan'],
  ['America/Montreal', 'America/Toronto'],
  ['Canada/Atlantic', 'America/Halifax'],
  ['Canada/Central', 'America/Winnipeg'],
  ['Canada/Eastern', 'America/Toronto'],
  ['Canada/Mountain', 'America/Edmonton'],
  ['Canada/Newfoundland', 'America/St_Johns'],
  ['Canada/Pacific', 'America/Vancouver'],
  ['Canada/Saskatchewan', 'America/Regina'],
  ['Canada/Yukon', 'America/Whitehorse'],
  ['Chile/Continental', 'America/Santiago'],
  ['Chile/EasterIsland', 'Pacific/Easter'],
  ['Asia/Chongqing', 'Asia/Shanghai'],
  ['Asia/Chungking', 'Asia/Shanghai'],
  ['Asia/Harbin', 'Asia/Shanghai'],
  ['Asia/Kashgar', 'Asia/Urumqi'],
  ['PRC', 'Asia/Shanghai'],
  ['Cuba', 'America/Havana'],
  ['Egypt', 'Africa/Cairo'],
  ['Africa/Asmera', 'Africa/Nairobi'],
  ['Pacific/Ponape', 'Pacific/Pohnpei'],
  ['Pacific/Truk', 'Pacific/Chuuk'],
  ['Pacific/Yap', 'Pacific/Chuuk'],
  ['Atlantic/Faeroe', 'Atlantic/Faroe'],
  ['Europe/Belfast', 'Europe/London'],
  ['GB', 'Europe/London'],
  ['GB-Eire', 'Europe/London'],
  ['America/Godthab', 'America/Nuuk'],
  ['Hongkong', 'Asia/Hong_Kong'],
  ['Asia/Ujung_Pandang', 'Asia/Makassar'],
  ['Eire', 'Europe/Dublin'],
  ['Asia/Tel_Aviv', 'Asia/Jerusalem'],
  ['Israel', 'Asia/Jerusalem'],
  ['Asia/Calcutta', 'Asia/Kolkata'],
  ['Iran', 'Asia/Tehran'],
  ['Iceland', 'Atlantic/Reykjavik'],
  ['Jamaica', 'America/Jamaica'],
  ['Japan', 'Asia/Tokyo'],
  ['ROK', 'Asia/Seoul'],
  ['Libya', 'Africa/Tripoli'],
  ['Europe/Tiraspol', 'Europe/Chisinau'],
  ['Kwajalein', 'Pacific/Kwajalein'],
  ['Africa/Timbuktu', 'Africa/Abidjan'],
  ['Asia/Rangoon', 'Asia/Yangon'],
  ['Asia/Ulan_Bator', 'Asia/Ulaanbaatar'],
  ['Asia/Macao', 'Asia/Macau'],
  ['America/Ensenada', 'America/Tijuana'],
  ['America/Santa_Isabel', 'America/Tijuana'],
  ['Mexico/BajaNorte', 'America/Tijuana'],
  ['Mexico/BajaSur', 'America/Mazatlan'],
  ['Mexico/General', 'America/Mexico_City'],
  ['Asia/Katmandu', 'Asia/Kathmandu'],
  ['NZ', 'Pacific/Auckland'],
  ['NZ-CHAT', 'Pacific/Chatham'],
  ['Poland', 'Europe/Warsaw'],
  ['Portugal', 'Europe/Lisbon'],
  ['W-SU', 'Europe/Moscow'],
  ['Singapore', 'Asia/Singapore'],
  ['Atlantic/Jan_Mayen', 'Europe/Oslo'],
  ['Asia/Ashkhabad', 'Asia/Ashgabat'],
  ['Turkey', 'Europe/Istanbul'],
  ['ROC', 'Asia/Taipei'],
  ['Pacific/Johnston', 'Pacific/Honolulu'],
  ['America/Atka', 'America/Adak'],
  ['America/Fort_Wayne', 'America/Indiana/Indianapolis'],
  ['America/Indianapolis', 'America/Indiana/Indianapolis'],
  ['America/Knox_IN', 'America/Indiana/Knox'],
  ['America/Louisville', 'America/Kentucky/Louisville'],
  ['America/Shiprock', 'America/Denver'],
  ['Navajo', 'America/Denver'],
  ['US/Alaska', 'America/Anchorage'],
  ['US/Aleutian', 'America/Adak'],
  ['US/Arizona', 'America/Phoenix'],
  ['US/Central', 'America/Chicago'],
  ['US/East-Indiana', 'America/Indiana/Indianapolis'],
  ['US/Eastern', 'America/New_York'],
  ['US/Hawaii', 'Pacific/Honolulu'],
  ['US/Indiana-Starke', 'America/Indiana/Knox'],
  ['US/Michigan', 'America/Detroit'],
  ['US/Mountain', 'America/Denver'],
  ['US/Pacific', 'America/Los_Angeles'],
  ['America/Virgin', 'America/Port_of_Spain'],
  ['Asia/Saigon', 'Asia/Ho_Chi_Minh'],
  ['Pacific/Samoa', 'Pacific/Pago_Pago'],
  ['US/Samoa', 'Pacific/Pago_Pago'],
])

export const ensureIANATimezone = (tz: string): string => {
  const IanaTz = DeprecatedTimeZoneMap.get(tz)

  if (IanaTz) {
    return IanaTz
  }

  return tz
}

export const getStartEndOfDay = (timestamp: string | Date): [Date, Date] => {
  const date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp

  return [startOfDay(date), endOfDay(date)]
}

/**
 * Takes a date or a string and returns true if the date
 * is valid, otherwise returns false
 *
 */
export const isValidDate = (date: Date | string | number) => {
  const d =
    typeof date === 'string' || typeof date === 'number' ? new Date(date) : date
  return d instanceof Date && !isNaN(d.getTime())
}

/**
 * Returns a text string with the difference in days between two dates
 */
export const formatDifferenceInDays = (
  startDate: Date,
  endDate: Date
): string => {
  const difference = differenceInDays(endDate, startDate)
  return pluralize(difference, 'day', 'days')
}

/**
 * Return the passed in time zone, or fallback to the user's time zone.
 */
export const toTimeZone = (timeZone?: string) => {
  return timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone // browser timeZone
}

/**
 * We use this as a default for max selectable time in the future
 */
export const twoYearsFromNow = addYears(new Date(), 2)

/**
 * Clone a date, or return undefined
 */
export function cloneDate(d: undefined): undefined
export function cloneDate(d: Date): Date
export function cloneDate(d: Date | undefined): Date | undefined
export function cloneDate(d: Date | undefined): Date | undefined {
  return d ? new Date(d) : undefined
}

/**
 * Returns a date with the time set to 12:00:00
 *
 * This is useful for comparing two dates to determine if they are on the same day
 * e.g.
 *
 * ```ts
 * // Two dates on the same day but with different times
 * const dateA = new Date('2023-10-10T15:03:22Z')
 * const dateB = new Date('2023-10-10T23:03:22Z')
 * getTimeIrrlevantDate(dateA).getTime() === getTimeIrrelevantDate(dateB).getTime() // evaluates to true
 * ```
 */
export const getTimeIrrelevantDate = (date: Date) => {
  const d = cloneDate(date)
  d.setHours(12)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  return d
}

/**
 * A helper to set the time on a date object
 */
export const setTime = (
  date: Date,
  {
    hour,
    minute,
    second,
    millisecond,
  }: {
    hour?: number
    minute?: number
    second?: number
    millisecond?: number
  }
) => {
  const d = cloneDate(date)
  if (!isNil(hour)) {
    d.setHours(hour)
  }
  if (!isNil(minute)) {
    d.setMinutes(minute)
  }
  if (!isNil(second)) {
    d.setSeconds(second)
  }
  if (!isNil(millisecond)) {
    d.setMilliseconds(millisecond)
  }
  return d
}

/**
 * Given two dates, return the duration between them in milliseconds
 */
export const getDuration = (start: string | Date, end?: string | Date) => {
  const s = start ? toDate(start) : null
  const e = end ? toDate(end) : null
  if (s && e) {
    return e.getTime() - s.getTime()
  }

  return null
}

/**
 * Gets the difference in milliseconds between two timezones.
 * Defaults to the difference between the browser's timezone and the desired timezone:
 * e.g.
 * ```ts
 * getMsDiffToTimeZone(new Date(), 'America/Denver')
 * // returns the difference in milliseconds between the browser's timezone and America/Denver
 * ```
 * ```ts
 * getMsDiffToTimeZone(new Date(), 'America/Denver', 'America/Los_Angeles)
 * // returns the difference in milliseconds between America/Denver and America/Los_Angeles
 * ```
 */
export const getMsDiffToTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const desiredTzOffset = getTimezoneOffset(timeZone, date)
  const fromTzOffset = getTimezoneOffset(fromTimeZone, date)
  return fromTzOffset - desiredTzOffset
}

/**
 * Takes the difference between the two time zones and adds the difference to the original date.
 * E.g. Tokyo and Denver are 15 hours apart. So calling:
 * ```ts
 * const date = new Date()
 * addTimeZoneOffset(date, 'America/Denver', 'Asia/Tokyo')
 * ```
 * will add 15 hours to `date`
 *
 * Order matters! Swapping the order of arguments will either add or subtract hours. Take:
 * ```ts
 * addTimeZoneOffset(date, 'America/Denver', 'Asia/Tokyo') // adds 15 hours
 * addTimeZoneOffset(date, 'Asia/Tokyo', 'America/Denver') // subtracts 15 hours
 * ```
 *
 * This is useful in the following cases:
 * 1. I have a timestamp in browser local time, and I want to submit that timestamp to
 *    the server as if it was in some other timezone.
 *    E.g. I am in New York and I have a timestamp for 10am ET (2pm UTC), and I want to submit
 *    that timestamp to the server as if it was 10am in Denver (4pm UTC). Calling:
 *    `addTimeZoneOffset(new Date('2023-10-10T14:00:00Z'), 'America/Denver')`
 *    will return a date with the timestamp '2023-10-10T16:00:00Z' which is 4pm UTC (10am CT)
 */
export const addTimeZoneOffset = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  return new Date(
    date.getTime() + getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Takes the difference between the two time zones and subtracts the difference to the original date.
 * This is the inverse of `addTimeZoneOffset`
 * E.g. Tokyo and Denver are 15 hours apart. So calling:
 * ```ts
 * const date = new Date()
 * addTimeZoneOffset(date, 'America/Denver', 'Asia/Tokyo')
 * ```
 * will subtract 15 hours from `date`
 *
 * Order matters! Swapping the order of arguments will either add or subtract hours. Take:
 * ```ts
 * addTimeZoneOffset(date, 'America/Denver', 'Asia/Tokyo') // subtracts 15 hours
 * addTimeZoneOffset(date, 'Asia/Tokyo', 'America/Denver') // adds 15 hours
 * ```
 */
export const subTimeZoneOffset = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  return new Date(
    date.getTime() - getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Takes a date and hour in browser local time and finds the corresponding time
 * in the desired timezone.
 */
export const hourInTimeZone = (
  date: Date,
  hour: number,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  return new Date(
    new Date(parts.year, parts.month, parts.day, hour).getTime() +
      getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Takes a date in browser local time and finds the start of a given day
 * in the desired timezone.
 *
 * E.g. the start of the day in America/Denver
 * is 07:00:00Z, while the start of the day for a browser in America/New_York
 * is 05:00:00Z (depending on DST of course)
 * calling `startOfDayInTimeZone(new Date(), 'America/Denver')` will return the
 * start of the day in Denver - 07:00:00Z - regardless of the browser's timezone
 */
export const startOfDayInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  return new Date(
    new Date(parts.year, parts.month, parts.day).getTime() +
      getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Takes a date in browser local time and finds the start of a month
 * in the desired timezone.
 */
export const startOfMonthInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  return new Date(
    new Date(parts.year, parts.month, 1).getTime() +
      getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Takes a date in browser local time and finds the start of a month
 * in the desired timezone.
 */
export const endOfMonthInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  const daysInMonth = new Date(parts.year, parts.month + 1, 0).getDate()
  return new Date(
    new Date(parts.year, parts.month, daysInMonth, 23, 59, 59, 999).getTime() +
      getMsDiffToTimeZone(date, timeZone, fromTimeZone)
  )
}

/**
 * Returns a Date object at the start of year in a given time zone. Accepts an optional third argument
 * if you're converting between 2 arbitrary timezones. Without the third argument, this util will convert
 * from the browser's timezone to the desired timezone, which is almost always what you want.
 */
export const startOfYearInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  const d = new Date(parts.year, 0, 1, 0, 0, 0, 0)
  return addTimeZoneOffset(d, timeZone, fromTimeZone)
}

/**
 * Returns a Date object at the end of year in a given time zone. Accepts an optional third argument
 * if you're converting between 2 arbitrary timezones. Without the third argument, this util will convert
 * from the browser's timezone to the desired timezone, which is almost always what you want.
 */
export const endOfYearInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  const parts = getPartsInTimeZone(date, timeZone)
  const d = new Date(parts.year, 11, 31, 23, 59, 59, 999)
  return addTimeZoneOffset(d, timeZone, fromTimeZone)
}

/**
 * Takes a date in browser local time and finds the end of a given day
 * in the desired timezone.
 *
 * E.g. the end of the day in America/Denver
 * is 05:59:59Z, while the end of the day for a browser in America/New_York
 * is 03:59:59Z (depending on DST of course)
 * calling `endOfDayInTimeZone(new Date(), 'America/Denver')` will return the
 * end of the day in Denver - 05:59:59Z - regardless of the browser's timezone
 */
export const endOfDayInTimeZone = (
  date: Date,
  timeZone: string,
  fromTimeZone: string = toTimeZone()
) => {
  return new Date(
    startOfDayInTimeZone(date, timeZone, fromTimeZone).getTime() +
      (ONE_DAY_MS - 1)
  )
}

/**
 *
 * Takes a date and converts it to the desired timezone, and returns
 * the date parts.
 *
 * E.g. A date in America/New_York, "2024-02-12T18:30:00Z" is at hour 13:30 in EDT
 * If I want the hour of the day in America/Chicago (on hour behind from EDT),
 * calling `getPartsInTimeZone(new Date('2024-02-12T18:30:00Z'), 'America/Chicago')`
 * will return `{ year: 2024, month: 1, day: 12, hours: 12, minutes: 30, seconds: 0 }`
 * notice how the hours are 12, not 13.
 */
export const getPartsInTimeZone = (date: Date, timeZone: string) => {
  const d = utcToZonedTime(date, timeZone)
  return {
    year: d.getFullYear(),
    month: d.getMonth(),
    day: d.getDate(),
    hours: d.getHours(),
    minutes: d.getMinutes(),
    seconds: d.getSeconds(),
  }
}
