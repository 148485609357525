import { createActions, createReducer } from 'reduxsauce'

export const { Types: PermissionsTypes, Creators: PermissionsActions } =
  createActions(
    {
      fetchPermissions: null,
      receivePermissions: ['payload'],
    },
    { prefix: 'PERMISSIONS/' }
  )

const INITIAL_STATE = window.voltusMeta?.permissions || null

const setPermissions = (state, { payload }) => payload

export const permissionsReducer = createReducer(INITIAL_STATE, {
  [PermissionsTypes.RECEIVE_PERMISSIONS]: setPermissions,
})
