import { noop } from 'lodash'
import * as React from 'react'
import { usePrevious } from '@voltus/utils'

export const BROWSER_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone
/**
 * Time zones in their raw form aren't very human readable or friendly. E.g. America/New_York
 * This function takes a timezone, and uses the Intl.DateTimeFormat API to get a more human readable
 * timezone string. E.g. America/New_York is converted to EST (or EDT if we're in daylight saving)
 */
const getTzDisplayString = (timeZone: string) => {
  const date = new Date()
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'full',
    timeStyle: 'long',
    timeZone: timeZone,
  })
  const parts = dateTimeFormat.formatToParts(date)
  return parts.find((part) => part.type === 'timeZoneName')?.value ?? timeZone
}

const TimeZoneContext = React.createContext({
  timeZone: BROWSER_TIME_ZONE,
  displayTimeZone: getTzDisplayString(BROWSER_TIME_ZONE),
})
const TimeZoneDispatchContext = React.createContext({
  setTimeZone: noop,
})

type TimeZoneContextProviderProps = {
  children: React.ReactNode
  timeZone?: string
}
export const TimeZoneContextProvider = ({
  timeZone,
  children,
}: TimeZoneContextProviderProps) => {
  const [state, setState] = React.useState({
    timeZone: timeZone ?? BROWSER_TIME_ZONE,
    displayTimeZone: getTzDisplayString(timeZone ?? BROWSER_TIME_ZONE),
  })
  const setTimeZone = React.useCallback(
    (timeZone) => {
      setState((state) => ({
        ...state,
        timeZone,
        displayTimeZone: getTzDisplayString(timeZone),
      }))
    },
    [setState]
  )

  // Sync state from the outside if the timeZone prop changes
  const prevTimeZone = usePrevious(state.timeZone)
  React.useEffect(() => {
    if (prevTimeZone !== timeZone) {
      setTimeZone(timeZone ?? BROWSER_TIME_ZONE)
    }
  }, [timeZone, prevTimeZone, setTimeZone])

  const dispatchValue = React.useMemo(
    () => ({
      setTimeZone: setTimeZone,
    }),
    [setTimeZone]
  )

  return (
    <TimeZoneContext.Provider value={state}>
      <TimeZoneDispatchContext.Provider value={dispatchValue}>
        {children}
      </TimeZoneDispatchContext.Provider>
    </TimeZoneContext.Provider>
  )
}

export const useTimeZoneState = () => React.useContext(TimeZoneContext)
export const useTimeZoneDispatch = () =>
  React.useContext(TimeZoneDispatchContext)

export const useTimeZoneContext = () => {
  return [useTimeZoneState(), useTimeZoneDispatch()] as const
}
