import React, { Suspense, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import { NavbarDimensionsProvider } from '@voltus/context'
import {
  PromptContainer,
  ToastContainer,
  ActivityIndicator,
  Toast,
  Flex,
  QuickNavBar,
  CommandBarProvider,
  commandBarActions,
  CommandBar,
  NotFound,
  Skeleton,
  colors,
} from '@voltus/core-components'
import { useDDUser } from '@voltus/datadog'
import { APPLICATIONS } from '@voltus/event-tracking'
import {
  selectPermissions,
  PermissionsActions,
  ProfileActions,
  EventTrackingActions,
  selectUserId,
  globals,
  selectProfile,
} from '@voltus/modules'
import { Permissions, Profile } from '@voltus/types'
import { useDimensions, useOffline } from '@voltus/utils'

import stylesheet from './App.scss'
import { IE11Modal } from './IE11Modal'
import { GlobalSidebar } from './components/GlobalSidebar'
import { Navbar } from './components/Navbar'
import { SiteContactChatMenu } from './components/SiteContactChatMenu'
import { PATHS } from './constants/routes'
import { routes, getBackgroundColorByRoute } from './routes/routes'
import { useOldEarningsRedirect } from './useOldEarningsRedirect'
import { useCashdashOnlyRedirect } from './utils/useCashdashOnlyRedirect'

const AdminEnabledProviders = ({ permissions, children }) => {
  if (permissions?.viewAdmin) {
    return (
      <CommandBarProvider actions={commandBarActions({ paths: PATHS })}>
        <CommandBar permissions={permissions} />
        {children}
      </CommandBarProvider>
    )
  }

  return <>{children}</>
}

type StateProps = {
  permissions: Permissions
  userId: number
  globalPortfolioId: number
  profile?: Profile
}

type DispatchProps = {
  initializeEventTracking: typeof EventTrackingActions.initializeEventTracking
  fetchPermissions: typeof PermissionsActions.fetchPermissions
  fetchProfile: typeof ProfileActions.fetchProfile
}

const App = ({
  initializeEventTracking,
  permissions,
  fetchPermissions,
  fetchProfile,
  globalPortfolioId,
  profile,
}: StateProps & DispatchProps) => {
  useDDUser(profile)
  const [shouldRenderChatMenu, setShouldRenderChatMenu] = React.useState(false)

  const location = useLocation()

  // Sets up mixpanel tracking
  useEffect(() => {
    fetchPermissions()
    fetchProfile()
  }, [fetchPermissions, fetchProfile])

  useEffect(() => {
    if (profile) {
      initializeEventTracking(APPLICATIONS.VOLTAPP)
    }
  }, [profile, initializeEventTracking])

  const appContainerRef = useRef<HTMLDivElement>()

  useOffline(({ online }) => {
    if (online) {
      Toast.push(<Toast.Success>Reconnected!</Toast.Success>)
    } else {
      Toast.push(
        <Toast.Error>
          Looks like you may have lost your internet connection. Please check
          and try again
        </Toast.Error>
      )
    }
  }, [])

  useOldEarningsRedirect()
  useCashdashOnlyRedirect({ profile })

  const appBg = getBackgroundColorByRoute(location.pathname)

  const [navbarRef, navbarDimensions] = useDimensions<HTMLElement>()

  return (
    <NavbarDimensionsProvider value={navbarDimensions}>
      <AdminEnabledProviders permissions={permissions}>
        <Skeleton.Theme
          color={colors.grays['15']}
          highlightColor={colors.grays['10']}
        >
          <ToastContainer />
          {/* height={0} is needed for IE11 - otherwise minHeight doesn't get applied properly */}
          <Flex.Column
            ref={appContainerRef}
            bg={appBg}
            height={0}
            minHeight="100vh"
            width="100%"
          >
            {!permissions || !profile ? (
              <div className={stylesheet.centered}>
                <ActivityIndicator.Large />
              </div>
            ) : null}
            {permissions && profile ? (
              <>
                <Navbar
                  profile={profile}
                  portfolioId={globalPortfolioId}
                  isChatMenuOpen={shouldRenderChatMenu}
                  onChatMenuClick={setShouldRenderChatMenu}
                  navbarRef={navbarRef}
                  permissions={permissions}
                />
                <Suspense
                  fallback={
                    <div className={stylesheet.centered}>
                      <ActivityIndicator.Large />
                    </div>
                  }
                >
                  <Routes>
                    {routes.routes.map(
                      ({ Component, Wrapper, path, ...props }) => {
                        return (
                          <Route
                            key={path}
                            path={path}
                            element={
                              <Wrapper {...props}>
                                <Component />
                                <GlobalSidebar
                                  appContainerRef={appContainerRef}
                                />
                              </Wrapper>
                            }
                          />
                        )
                      }
                    )}
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Suspense>
              </>
            ) : null}
          </Flex.Column>
          {permissions?.viewAdmin ? (
            <QuickNavBar routes={routes.routes} />
          ) : null}
          <PromptContainer />
        </Skeleton.Theme>
        <IE11Modal />
        {permissions?.viewAdmin && (
          <SiteContactChatMenu shouldRender={shouldRenderChatMenu} />
        )}
      </AdminEnabledProviders>
    </NavbarDimensionsProvider>
  )
}

const mapStateToProps = createStructuredSelector({
  permissions: selectPermissions,
  userId: selectUserId,
  profile: selectProfile,
  globalPortfolioId: globals.selectGlobalPortfolioId,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchPermissions: PermissionsActions.fetchPermissions,
      fetchProfile: ProfileActions.fetchProfile,
      initializeEventTracking: EventTrackingActions.initializeEventTracking,
    },
    dispatch
  )

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(App)
