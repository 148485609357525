// source: streams.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

goog.exportSymbol('proto.streams.CumulativeFacilityPerformance', null, global)
goog.exportSymbol('proto.streams.CumulativePortfolioPerformance', null, global)
goog.exportSymbol('proto.streams.FacilityDemand', null, global)
goog.exportSymbol('proto.streams.FacilityDispatch', null, global)
goog.exportSymbol('proto.streams.FacilityPerformance', null, global)
goog.exportSymbol('proto.streams.FacilityPerformanceKW', null, global)
goog.exportSymbol('proto.streams.FacilityTarget', null, global)
goog.exportSymbol('proto.streams.PortfolioDemand', null, global)
goog.exportSymbol('proto.streams.PortfolioDispatch', null, global)
goog.exportSymbol('proto.streams.PortfolioPerformance', null, global)
goog.exportSymbol('proto.streams.RealtimeData', null, global)
goog.exportSymbol('proto.streams.RealtimeMessages', null, global)
goog.exportSymbol('proto.streams.StreamPortfolioDataRequest', null, global)
goog.exportSymbol('proto.streams.StreamPortfolioDataResponse', null, global)
goog.exportSymbol('proto.streams.StreamingDispatch', null, global)
goog.exportSymbol('proto.streams.User', null, global)
goog.exportSymbol('proto.streams.UserAcknowledgment', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.StreamPortfolioDataRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.StreamPortfolioDataRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.StreamPortfolioDataRequest.displayName =
    'proto.streams.StreamPortfolioDataRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.StreamPortfolioDataResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.StreamPortfolioDataResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.StreamPortfolioDataResponse.displayName =
    'proto.streams.StreamPortfolioDataResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.RealtimeMessages = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.RealtimeMessages, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.RealtimeMessages.displayName = 'proto.streams.RealtimeMessages'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.RealtimeData = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.RealtimeData, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.RealtimeData.displayName = 'proto.streams.RealtimeData'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.FacilityDemand = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.FacilityDemand, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.FacilityDemand.displayName = 'proto.streams.FacilityDemand'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.PortfolioPerformance = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.PortfolioPerformance, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.PortfolioPerformance.displayName =
    'proto.streams.PortfolioPerformance'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.FacilityPerformanceKW = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.FacilityPerformanceKW, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.FacilityPerformanceKW.displayName =
    'proto.streams.FacilityPerformanceKW'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.FacilityTarget = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.FacilityTarget, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.FacilityTarget.displayName = 'proto.streams.FacilityTarget'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.CumulativeFacilityPerformance = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.CumulativeFacilityPerformance, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.CumulativeFacilityPerformance.displayName =
    'proto.streams.CumulativeFacilityPerformance'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.CumulativePortfolioPerformance = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.CumulativePortfolioPerformance, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.CumulativePortfolioPerformance.displayName =
    'proto.streams.CumulativePortfolioPerformance'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.UserAcknowledgment = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.streams.UserAcknowledgment.repeatedFields_,
    null
  )
}
goog.inherits(proto.streams.UserAcknowledgment, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.UserAcknowledgment.displayName =
    'proto.streams.UserAcknowledgment'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.StreamingDispatch = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.streams.StreamingDispatch.repeatedFields_,
    null
  )
}
goog.inherits(proto.streams.StreamingDispatch, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.StreamingDispatch.displayName =
    'proto.streams.StreamingDispatch'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.PortfolioDispatch = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.PortfolioDispatch, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.PortfolioDispatch.displayName =
    'proto.streams.PortfolioDispatch'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.FacilityDispatch = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.streams.FacilityDispatch.repeatedFields_,
    null
  )
}
goog.inherits(proto.streams.FacilityDispatch, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.FacilityDispatch.displayName = 'proto.streams.FacilityDispatch'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.User = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.User, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.User.displayName = 'proto.streams.User'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.FacilityPerformance = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.FacilityPerformance, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.FacilityPerformance.displayName =
    'proto.streams.FacilityPerformance'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.streams.PortfolioDemand = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.streams.PortfolioDemand, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.streams.PortfolioDemand.displayName = 'proto.streams.PortfolioDemand'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.StreamPortfolioDataRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.StreamPortfolioDataRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.StreamPortfolioDataRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.StreamPortfolioDataRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        portfolioId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.StreamPortfolioDataRequest}
 */
proto.streams.StreamPortfolioDataRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.StreamPortfolioDataRequest()
  return proto.streams.StreamPortfolioDataRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.StreamPortfolioDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.StreamPortfolioDataRequest}
 */
proto.streams.StreamPortfolioDataRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setPortfolioId(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.StreamPortfolioDataRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.streams.StreamPortfolioDataRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.StreamPortfolioDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.StreamPortfolioDataRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getPortfolioId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
}

/**
 * optional sint32 portfolio_id = 1;
 * @return {number}
 */
proto.streams.StreamPortfolioDataRequest.prototype.getPortfolioId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
  }

/**
 * @param {number} value
 * @return {!proto.streams.StreamPortfolioDataRequest} returns this
 */
proto.streams.StreamPortfolioDataRequest.prototype.setPortfolioId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.StreamPortfolioDataResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.StreamPortfolioDataResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.StreamPortfolioDataResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.StreamPortfolioDataResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        realtimeMessage:
          (f = msg.getRealtimeMessage()) &&
          proto.streams.RealtimeMessages.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.StreamPortfolioDataResponse}
 */
proto.streams.StreamPortfolioDataResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.StreamPortfolioDataResponse()
  return proto.streams.StreamPortfolioDataResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.StreamPortfolioDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.StreamPortfolioDataResponse}
 */
proto.streams.StreamPortfolioDataResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.streams.RealtimeMessages()
          reader.readMessage(
            value,
            proto.streams.RealtimeMessages.deserializeBinaryFromReader
          )
          msg.setRealtimeMessage(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.StreamPortfolioDataResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.streams.StreamPortfolioDataResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.StreamPortfolioDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.StreamPortfolioDataResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getRealtimeMessage()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.streams.RealtimeMessages.serializeBinaryToWriter
    )
  }
}

/**
 * optional RealtimeMessages realtime_message = 1;
 * @return {?proto.streams.RealtimeMessages}
 */
proto.streams.StreamPortfolioDataResponse.prototype.getRealtimeMessage =
  function () {
    return /** @type{?proto.streams.RealtimeMessages} */ (
      jspb.Message.getWrapperField(this, proto.streams.RealtimeMessages, 1)
    )
  }

/**
 * @param {?proto.streams.RealtimeMessages|undefined} value
 * @return {!proto.streams.StreamPortfolioDataResponse} returns this
 */
proto.streams.StreamPortfolioDataResponse.prototype.setRealtimeMessage =
  function (value) {
    return jspb.Message.setWrapperField(this, 1, value)
  }

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.StreamPortfolioDataResponse} returns this
 */
proto.streams.StreamPortfolioDataResponse.prototype.clearRealtimeMessage =
  function () {
    return this.setRealtimeMessage(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.StreamPortfolioDataResponse.prototype.hasRealtimeMessage =
  function () {
    return jspb.Message.getField(this, 1) != null
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.RealtimeMessages.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.RealtimeMessages.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.RealtimeMessages} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.RealtimeMessages.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        portfolioId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        data:
          (f = msg.getData()) &&
          proto.streams.RealtimeData.toObject(includeInstance, f),
        isBackfill: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.RealtimeMessages}
 */
proto.streams.RealtimeMessages.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.RealtimeMessages()
  return proto.streams.RealtimeMessages.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.RealtimeMessages} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.RealtimeMessages}
 */
proto.streams.RealtimeMessages.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPortfolioId(value)
        break
      case 2:
        var value = new proto.streams.RealtimeData()
        reader.readMessage(
          value,
          proto.streams.RealtimeData.deserializeBinaryFromReader
        )
        msg.setData(value)
        break
      case 3:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setIsBackfill(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.RealtimeMessages.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.RealtimeMessages.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.RealtimeMessages} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.RealtimeMessages.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getPortfolioId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getData()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.streams.RealtimeData.serializeBinaryToWriter
    )
  }
  f = message.getIsBackfill()
  if (f) {
    writer.writeBool(3, f)
  }
}

/**
 * optional sint32 portfolio_id = 1;
 * @return {number}
 */
proto.streams.RealtimeMessages.prototype.getPortfolioId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.RealtimeMessages} returns this
 */
proto.streams.RealtimeMessages.prototype.setPortfolioId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional RealtimeData data = 2;
 * @return {?proto.streams.RealtimeData}
 */
proto.streams.RealtimeMessages.prototype.getData = function () {
  return /** @type{?proto.streams.RealtimeData} */ (
    jspb.Message.getWrapperField(this, proto.streams.RealtimeData, 2)
  )
}

/**
 * @param {?proto.streams.RealtimeData|undefined} value
 * @return {!proto.streams.RealtimeMessages} returns this
 */
proto.streams.RealtimeMessages.prototype.setData = function (value) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeMessages} returns this
 */
proto.streams.RealtimeMessages.prototype.clearData = function () {
  return this.setData(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeMessages.prototype.hasData = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * optional bool is_backfill = 3;
 * @return {boolean}
 */
proto.streams.RealtimeMessages.prototype.getIsBackfill = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 3, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.streams.RealtimeMessages} returns this
 */
proto.streams.RealtimeMessages.prototype.setIsBackfill = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.RealtimeData.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.RealtimeData.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.RealtimeData} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.RealtimeData.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        facilityDemand:
          (f = msg.getFacilityDemand()) &&
          proto.streams.FacilityDemand.toObject(includeInstance, f),
        portfolioPerformance:
          (f = msg.getPortfolioPerformance()) &&
          proto.streams.PortfolioPerformance.toObject(includeInstance, f),
        facilityPerformanceKw:
          (f = msg.getFacilityPerformanceKw()) &&
          proto.streams.FacilityPerformanceKW.toObject(includeInstance, f),
        facilityTarget:
          (f = msg.getFacilityTarget()) &&
          proto.streams.FacilityTarget.toObject(includeInstance, f),
        cumulativeFacilityPerformance:
          (f = msg.getCumulativeFacilityPerformance()) &&
          proto.streams.CumulativeFacilityPerformance.toObject(
            includeInstance,
            f
          ),
        cumulativePortfolioPerformance:
          (f = msg.getCumulativePortfolioPerformance()) &&
          proto.streams.CumulativePortfolioPerformance.toObject(
            includeInstance,
            f
          ),
        userAcknowledgment:
          (f = msg.getUserAcknowledgment()) &&
          proto.streams.UserAcknowledgment.toObject(includeInstance, f),
        streamingDispatch:
          (f = msg.getStreamingDispatch()) &&
          proto.streams.StreamingDispatch.toObject(includeInstance, f),
        facilityPerformance:
          (f = msg.getFacilityPerformance()) &&
          proto.streams.FacilityPerformance.toObject(includeInstance, f),
        portfolioDemand:
          (f = msg.getPortfolioDemand()) &&
          proto.streams.PortfolioDemand.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.RealtimeData}
 */
proto.streams.RealtimeData.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.RealtimeData()
  return proto.streams.RealtimeData.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.RealtimeData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.RealtimeData}
 */
proto.streams.RealtimeData.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.streams.FacilityDemand()
        reader.readMessage(
          value,
          proto.streams.FacilityDemand.deserializeBinaryFromReader
        )
        msg.setFacilityDemand(value)
        break
      case 2:
        var value = new proto.streams.PortfolioPerformance()
        reader.readMessage(
          value,
          proto.streams.PortfolioPerformance.deserializeBinaryFromReader
        )
        msg.setPortfolioPerformance(value)
        break
      case 3:
        var value = new proto.streams.FacilityPerformanceKW()
        reader.readMessage(
          value,
          proto.streams.FacilityPerformanceKW.deserializeBinaryFromReader
        )
        msg.setFacilityPerformanceKw(value)
        break
      case 4:
        var value = new proto.streams.FacilityTarget()
        reader.readMessage(
          value,
          proto.streams.FacilityTarget.deserializeBinaryFromReader
        )
        msg.setFacilityTarget(value)
        break
      case 5:
        var value = new proto.streams.CumulativeFacilityPerformance()
        reader.readMessage(
          value,
          proto.streams.CumulativeFacilityPerformance
            .deserializeBinaryFromReader
        )
        msg.setCumulativeFacilityPerformance(value)
        break
      case 6:
        var value = new proto.streams.CumulativePortfolioPerformance()
        reader.readMessage(
          value,
          proto.streams.CumulativePortfolioPerformance
            .deserializeBinaryFromReader
        )
        msg.setCumulativePortfolioPerformance(value)
        break
      case 7:
        var value = new proto.streams.UserAcknowledgment()
        reader.readMessage(
          value,
          proto.streams.UserAcknowledgment.deserializeBinaryFromReader
        )
        msg.setUserAcknowledgment(value)
        break
      case 8:
        var value = new proto.streams.StreamingDispatch()
        reader.readMessage(
          value,
          proto.streams.StreamingDispatch.deserializeBinaryFromReader
        )
        msg.setStreamingDispatch(value)
        break
      case 9:
        var value = new proto.streams.FacilityPerformance()
        reader.readMessage(
          value,
          proto.streams.FacilityPerformance.deserializeBinaryFromReader
        )
        msg.setFacilityPerformance(value)
        break
      case 10:
        var value = new proto.streams.PortfolioDemand()
        reader.readMessage(
          value,
          proto.streams.PortfolioDemand.deserializeBinaryFromReader
        )
        msg.setPortfolioDemand(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.RealtimeData.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.RealtimeData.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.RealtimeData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.RealtimeData.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFacilityDemand()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.streams.FacilityDemand.serializeBinaryToWriter
    )
  }
  f = message.getPortfolioPerformance()
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.streams.PortfolioPerformance.serializeBinaryToWriter
    )
  }
  f = message.getFacilityPerformanceKw()
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.streams.FacilityPerformanceKW.serializeBinaryToWriter
    )
  }
  f = message.getFacilityTarget()
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.streams.FacilityTarget.serializeBinaryToWriter
    )
  }
  f = message.getCumulativeFacilityPerformance()
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.streams.CumulativeFacilityPerformance.serializeBinaryToWriter
    )
  }
  f = message.getCumulativePortfolioPerformance()
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.streams.CumulativePortfolioPerformance.serializeBinaryToWriter
    )
  }
  f = message.getUserAcknowledgment()
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.streams.UserAcknowledgment.serializeBinaryToWriter
    )
  }
  f = message.getStreamingDispatch()
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.streams.StreamingDispatch.serializeBinaryToWriter
    )
  }
  f = message.getFacilityPerformance()
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.streams.FacilityPerformance.serializeBinaryToWriter
    )
  }
  f = message.getPortfolioDemand()
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.streams.PortfolioDemand.serializeBinaryToWriter
    )
  }
}

/**
 * optional FacilityDemand facility_demand = 1;
 * @return {?proto.streams.FacilityDemand}
 */
proto.streams.RealtimeData.prototype.getFacilityDemand = function () {
  return /** @type{?proto.streams.FacilityDemand} */ (
    jspb.Message.getWrapperField(this, proto.streams.FacilityDemand, 1)
  )
}

/**
 * @param {?proto.streams.FacilityDemand|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setFacilityDemand = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearFacilityDemand = function () {
  return this.setFacilityDemand(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasFacilityDemand = function () {
  return jspb.Message.getField(this, 1) != null
}

/**
 * optional PortfolioPerformance portfolio_performance = 2;
 * @return {?proto.streams.PortfolioPerformance}
 */
proto.streams.RealtimeData.prototype.getPortfolioPerformance = function () {
  return /** @type{?proto.streams.PortfolioPerformance} */ (
    jspb.Message.getWrapperField(this, proto.streams.PortfolioPerformance, 2)
  )
}

/**
 * @param {?proto.streams.PortfolioPerformance|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setPortfolioPerformance = function (
  value
) {
  return jspb.Message.setWrapperField(this, 2, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearPortfolioPerformance = function () {
  return this.setPortfolioPerformance(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasPortfolioPerformance = function () {
  return jspb.Message.getField(this, 2) != null
}

/**
 * optional FacilityPerformanceKW facility_performance_kw = 3;
 * @return {?proto.streams.FacilityPerformanceKW}
 */
proto.streams.RealtimeData.prototype.getFacilityPerformanceKw = function () {
  return /** @type{?proto.streams.FacilityPerformanceKW} */ (
    jspb.Message.getWrapperField(this, proto.streams.FacilityPerformanceKW, 3)
  )
}

/**
 * @param {?proto.streams.FacilityPerformanceKW|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setFacilityPerformanceKw = function (
  value
) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearFacilityPerformanceKw = function () {
  return this.setFacilityPerformanceKw(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasFacilityPerformanceKw = function () {
  return jspb.Message.getField(this, 3) != null
}

/**
 * optional FacilityTarget facility_target = 4;
 * @return {?proto.streams.FacilityTarget}
 */
proto.streams.RealtimeData.prototype.getFacilityTarget = function () {
  return /** @type{?proto.streams.FacilityTarget} */ (
    jspb.Message.getWrapperField(this, proto.streams.FacilityTarget, 4)
  )
}

/**
 * @param {?proto.streams.FacilityTarget|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setFacilityTarget = function (value) {
  return jspb.Message.setWrapperField(this, 4, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearFacilityTarget = function () {
  return this.setFacilityTarget(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasFacilityTarget = function () {
  return jspb.Message.getField(this, 4) != null
}

/**
 * optional CumulativeFacilityPerformance cumulative_facility_performance = 5;
 * @return {?proto.streams.CumulativeFacilityPerformance}
 */
proto.streams.RealtimeData.prototype.getCumulativeFacilityPerformance =
  function () {
    return /** @type{?proto.streams.CumulativeFacilityPerformance} */ (
      jspb.Message.getWrapperField(
        this,
        proto.streams.CumulativeFacilityPerformance,
        5
      )
    )
  }

/**
 * @param {?proto.streams.CumulativeFacilityPerformance|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setCumulativeFacilityPerformance =
  function (value) {
    return jspb.Message.setWrapperField(this, 5, value)
  }

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearCumulativeFacilityPerformance =
  function () {
    return this.setCumulativeFacilityPerformance(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasCumulativeFacilityPerformance =
  function () {
    return jspb.Message.getField(this, 5) != null
  }

/**
 * optional CumulativePortfolioPerformance cumulative_portfolio_performance = 6;
 * @return {?proto.streams.CumulativePortfolioPerformance}
 */
proto.streams.RealtimeData.prototype.getCumulativePortfolioPerformance =
  function () {
    return /** @type{?proto.streams.CumulativePortfolioPerformance} */ (
      jspb.Message.getWrapperField(
        this,
        proto.streams.CumulativePortfolioPerformance,
        6
      )
    )
  }

/**
 * @param {?proto.streams.CumulativePortfolioPerformance|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setCumulativePortfolioPerformance =
  function (value) {
    return jspb.Message.setWrapperField(this, 6, value)
  }

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearCumulativePortfolioPerformance =
  function () {
    return this.setCumulativePortfolioPerformance(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasCumulativePortfolioPerformance =
  function () {
    return jspb.Message.getField(this, 6) != null
  }

/**
 * optional UserAcknowledgment user_acknowledgment = 7;
 * @return {?proto.streams.UserAcknowledgment}
 */
proto.streams.RealtimeData.prototype.getUserAcknowledgment = function () {
  return /** @type{?proto.streams.UserAcknowledgment} */ (
    jspb.Message.getWrapperField(this, proto.streams.UserAcknowledgment, 7)
  )
}

/**
 * @param {?proto.streams.UserAcknowledgment|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setUserAcknowledgment = function (value) {
  return jspb.Message.setWrapperField(this, 7, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearUserAcknowledgment = function () {
  return this.setUserAcknowledgment(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasUserAcknowledgment = function () {
  return jspb.Message.getField(this, 7) != null
}

/**
 * optional StreamingDispatch streaming_dispatch = 8;
 * @return {?proto.streams.StreamingDispatch}
 */
proto.streams.RealtimeData.prototype.getStreamingDispatch = function () {
  return /** @type{?proto.streams.StreamingDispatch} */ (
    jspb.Message.getWrapperField(this, proto.streams.StreamingDispatch, 8)
  )
}

/**
 * @param {?proto.streams.StreamingDispatch|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setStreamingDispatch = function (value) {
  return jspb.Message.setWrapperField(this, 8, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearStreamingDispatch = function () {
  return this.setStreamingDispatch(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasStreamingDispatch = function () {
  return jspb.Message.getField(this, 8) != null
}

/**
 * optional FacilityPerformance facility_performance = 9;
 * @return {?proto.streams.FacilityPerformance}
 */
proto.streams.RealtimeData.prototype.getFacilityPerformance = function () {
  return /** @type{?proto.streams.FacilityPerformance} */ (
    jspb.Message.getWrapperField(this, proto.streams.FacilityPerformance, 9)
  )
}

/**
 * @param {?proto.streams.FacilityPerformance|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setFacilityPerformance = function (value) {
  return jspb.Message.setWrapperField(this, 9, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearFacilityPerformance = function () {
  return this.setFacilityPerformance(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasFacilityPerformance = function () {
  return jspb.Message.getField(this, 9) != null
}

/**
 * optional PortfolioDemand portfolio_demand = 10;
 * @return {?proto.streams.PortfolioDemand}
 */
proto.streams.RealtimeData.prototype.getPortfolioDemand = function () {
  return /** @type{?proto.streams.PortfolioDemand} */ (
    jspb.Message.getWrapperField(this, proto.streams.PortfolioDemand, 10)
  )
}

/**
 * @param {?proto.streams.PortfolioDemand|undefined} value
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.setPortfolioDemand = function (value) {
  return jspb.Message.setWrapperField(this, 10, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.RealtimeData} returns this
 */
proto.streams.RealtimeData.prototype.clearPortfolioDemand = function () {
  return this.setPortfolioDemand(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.RealtimeData.prototype.hasPortfolioDemand = function () {
  return jspb.Message.getField(this, 10) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.FacilityDemand.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.FacilityDemand.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.FacilityDemand} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.FacilityDemand.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        facilityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.FacilityDemand}
 */
proto.streams.FacilityDemand.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.FacilityDemand()
  return proto.streams.FacilityDemand.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.FacilityDemand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.FacilityDemand}
 */
proto.streams.FacilityDemand.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setValue(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.FacilityDemand.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.FacilityDemand.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.FacilityDemand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.FacilityDemand.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getValue()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional sint32 facility_id = 1;
 * @return {number}
 */
proto.streams.FacilityDemand.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDemand} returns this
 */
proto.streams.FacilityDemand.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional double value = 2;
 * @return {number}
 */
proto.streams.FacilityDemand.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDemand} returns this
 */
proto.streams.FacilityDemand.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional string timestamp = 3;
 * @return {string}
 */
proto.streams.FacilityDemand.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityDemand} returns this
 */
proto.streams.FacilityDemand.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.PortfolioPerformance.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.PortfolioPerformance.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.PortfolioPerformance} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.PortfolioPerformance.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        baselineType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        kw: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        portfolioId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 4, 0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 5, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.PortfolioPerformance}
 */
proto.streams.PortfolioPerformance.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.PortfolioPerformance()
  return proto.streams.PortfolioPerformance.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.PortfolioPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.PortfolioPerformance}
 */
proto.streams.PortfolioPerformance.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineType(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setKw(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPortfolioId(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.PortfolioPerformance.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.PortfolioPerformance.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.PortfolioPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.PortfolioPerformance.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getKw()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getPortfolioId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(4, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional string baseline_type = 1;
 * @return {string}
 */
proto.streams.PortfolioPerformance.prototype.getBaselineType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioPerformance} returns this
 */
proto.streams.PortfolioPerformance.prototype.setBaselineType = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional double kw = 2;
 * @return {number}
 */
proto.streams.PortfolioPerformance.prototype.getKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioPerformance} returns this
 */
proto.streams.PortfolioPerformance.prototype.setKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional sint32 portfolio_id = 3;
 * @return {number}
 */
proto.streams.PortfolioPerformance.prototype.getPortfolioId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioPerformance} returns this
 */
proto.streams.PortfolioPerformance.prototype.setPortfolioId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional sint32 dispatch_id = 4;
 * @return {number}
 */
proto.streams.PortfolioPerformance.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioPerformance} returns this
 */
proto.streams.PortfolioPerformance.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional string timestamp = 5;
 * @return {string}
 */
proto.streams.PortfolioPerformance.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioPerformance} returns this
 */
proto.streams.PortfolioPerformance.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.FacilityPerformanceKW.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.FacilityPerformanceKW.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.FacilityPerformanceKW} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.FacilityPerformanceKW.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        facilityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        loadKw: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        currentPerformance: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          4,
          0.0
        ),
        currentPerformancePercent:
          jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
        dispatchPerformanceKwh: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          6,
          0.0
        ),
        dispatchPerformancePercent:
          jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.FacilityPerformanceKW}
 */
proto.streams.FacilityPerformanceKW.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.FacilityPerformanceKW()
  return proto.streams.FacilityPerformanceKW.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.FacilityPerformanceKW} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.FacilityPerformanceKW}
 */
proto.streams.FacilityPerformanceKW.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setLoadKw(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCurrentPerformance(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCurrentPerformancePercent(value)
        break
      case 6:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setDispatchPerformanceKwh(value)
        break
      case 7:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setDispatchPerformancePercent(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.FacilityPerformanceKW.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.FacilityPerformanceKW.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.FacilityPerformanceKW} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.FacilityPerformanceKW.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getLoadKw()
  if (f !== 0.0) {
    writer.writeDouble(3, f)
  }
  f = message.getCurrentPerformance()
  if (f !== 0.0) {
    writer.writeDouble(4, f)
  }
  f = message.getCurrentPerformancePercent()
  if (f !== 0.0) {
    writer.writeDouble(5, f)
  }
  f = message.getDispatchPerformanceKwh()
  if (f !== 0.0) {
    writer.writeDouble(6, f)
  }
  f = message.getDispatchPerformancePercent()
  if (f !== 0.0) {
    writer.writeDouble(7, f)
  }
}

/**
 * optional sint32 facility_id = 1;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 dispatch_id = 2;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional double load_kw = 3;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getLoadKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setLoadKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value)
}

/**
 * optional double current_performance = 4;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getCurrentPerformance =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setCurrentPerformance = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 4, value)
}

/**
 * optional double current_performance_percent = 5;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getCurrentPerformancePercent =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setCurrentPerformancePercent =
  function (value) {
    return jspb.Message.setProto3FloatField(this, 5, value)
  }

/**
 * optional double dispatch_performance_kwh = 6;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getDispatchPerformanceKwh =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setDispatchPerformanceKwh =
  function (value) {
    return jspb.Message.setProto3FloatField(this, 6, value)
  }

/**
 * optional double dispatch_performance_percent = 7;
 * @return {number}
 */
proto.streams.FacilityPerformanceKW.prototype.getDispatchPerformancePercent =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformanceKW} returns this
 */
proto.streams.FacilityPerformanceKW.prototype.setDispatchPerformancePercent =
  function (value) {
    return jspb.Message.setProto3FloatField(this, 7, value)
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.FacilityTarget.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.FacilityTarget.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.FacilityTarget} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.FacilityTarget.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        facilityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        target: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        baseline: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        baselineType: jspb.Message.getFieldWithDefault(msg, 4, ''),
        adjustment: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 6, ''),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 7, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.FacilityTarget}
 */
proto.streams.FacilityTarget.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.FacilityTarget()
  return proto.streams.FacilityTarget.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.FacilityTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.FacilityTarget}
 */
proto.streams.FacilityTarget.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setTarget(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setBaseline(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineType(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setAdjustment(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      case 7:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.FacilityTarget.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.FacilityTarget.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.FacilityTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.FacilityTarget.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getTarget()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getBaseline()
  if (f !== 0.0) {
    writer.writeDouble(3, f)
  }
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getAdjustment()
  if (f !== 0.0) {
    writer.writeDouble(5, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(7, f)
  }
}

/**
 * optional sint32 facility_id = 1;
 * @return {number}
 */
proto.streams.FacilityTarget.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional double target = 2;
 * @return {number}
 */
proto.streams.FacilityTarget.prototype.getTarget = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setTarget = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional double baseline = 3;
 * @return {number}
 */
proto.streams.FacilityTarget.prototype.getBaseline = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setBaseline = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value)
}

/**
 * optional string baseline_type = 4;
 * @return {string}
 */
proto.streams.FacilityTarget.prototype.getBaselineType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setBaselineType = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional double adjustment = 5;
 * @return {number}
 */
proto.streams.FacilityTarget.prototype.getAdjustment = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setAdjustment = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value)
}

/**
 * optional string timestamp = 6;
 * @return {string}
 */
proto.streams.FacilityTarget.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional sint32 dispatch_id = 7;
 * @return {number}
 */
proto.streams.FacilityTarget.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityTarget} returns this
 */
proto.streams.FacilityTarget.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.CumulativeFacilityPerformance.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.CumulativeFacilityPerformance.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.CumulativeFacilityPerformance} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.CumulativeFacilityPerformance.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        baselineType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        kwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        demandKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        baselineKwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        adjustmentKwh: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          5,
          0.0
        ),
        facilityId: jspb.Message.getFieldWithDefault(msg, 6, 0),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 7, 0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 8, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.CumulativeFacilityPerformance}
 */
proto.streams.CumulativeFacilityPerformance.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.CumulativeFacilityPerformance()
  return proto.streams.CumulativeFacilityPerformance.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.CumulativeFacilityPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.CumulativeFacilityPerformance}
 */
proto.streams.CumulativeFacilityPerformance.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setBaselineType(value)
          break
        case 2:
          var value = /** @type {number} */ (reader.readDouble())
          msg.setKwh(value)
          break
        case 3:
          var value = /** @type {number} */ (reader.readDouble())
          msg.setDemandKwh(value)
          break
        case 4:
          var value = /** @type {number} */ (reader.readDouble())
          msg.setBaselineKwh(value)
          break
        case 5:
          var value = /** @type {number} */ (reader.readDouble())
          msg.setAdjustmentKwh(value)
          break
        case 6:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setFacilityId(value)
          break
        case 7:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setDispatchId(value)
          break
        case 8:
          var value = /** @type {string} */ (reader.readString())
          msg.setTimestamp(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.CumulativeFacilityPerformance.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.streams.CumulativeFacilityPerformance.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.CumulativeFacilityPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.CumulativeFacilityPerformance.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getKwh()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getDemandKwh()
  if (f !== 0.0) {
    writer.writeDouble(3, f)
  }
  f = message.getBaselineKwh()
  if (f !== 0.0) {
    writer.writeDouble(4, f)
  }
  f = message.getAdjustmentKwh()
  if (f !== 0.0) {
    writer.writeDouble(5, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(6, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(7, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
}

/**
 * optional string baseline_type = 1;
 * @return {string}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getBaselineType =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setBaselineType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value)
  }

/**
 * optional double kwh = 2;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getKwh = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setKwh = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional double demand_kwh = 3;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getDemandKwh =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setDemandKwh = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 3, value)
}

/**
 * optional double baseline_kwh = 4;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getBaselineKwh =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setBaselineKwh =
  function (value) {
    return jspb.Message.setProto3FloatField(this, 4, value)
  }

/**
 * optional double adjustment_kwh = 5;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getAdjustmentKwh =
  function () {
    return /** @type {number} */ (
      jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
    )
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setAdjustmentKwh =
  function (value) {
    return jspb.Message.setProto3FloatField(this, 5, value)
  }

/**
 * optional sint32 facility_id = 6;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getFacilityId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0))
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setFacilityId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 6, value)
}

/**
 * optional sint32 dispatch_id = 7;
 * @return {number}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getDispatchId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0))
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setDispatchId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 7, value)
}

/**
 * optional string timestamp = 8;
 * @return {string}
 */
proto.streams.CumulativeFacilityPerformance.prototype.getTimestamp =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
  }

/**
 * @param {string} value
 * @return {!proto.streams.CumulativeFacilityPerformance} returns this
 */
proto.streams.CumulativeFacilityPerformance.prototype.setTimestamp = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.CumulativePortfolioPerformance.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.CumulativePortfolioPerformance.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.CumulativePortfolioPerformance} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.CumulativePortfolioPerformance.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        baselineType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        kwh: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        portfolioId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 4, 0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 5, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.CumulativePortfolioPerformance}
 */
proto.streams.CumulativePortfolioPerformance.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.CumulativePortfolioPerformance()
  return proto.streams.CumulativePortfolioPerformance.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.CumulativePortfolioPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.CumulativePortfolioPerformance}
 */
proto.streams.CumulativePortfolioPerformance.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setBaselineType(value)
          break
        case 2:
          var value = /** @type {number} */ (reader.readDouble())
          msg.setKwh(value)
          break
        case 3:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setPortfolioId(value)
          break
        case 4:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setDispatchId(value)
          break
        case 5:
          var value = /** @type {string} */ (reader.readString())
          msg.setTimestamp(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.CumulativePortfolioPerformance.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.streams.CumulativePortfolioPerformance.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.CumulativePortfolioPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.CumulativePortfolioPerformance.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined
    f = message.getBaselineType()
    if (f.length > 0) {
      writer.writeString(1, f)
    }
    f = message.getKwh()
    if (f !== 0.0) {
      writer.writeDouble(2, f)
    }
    f = message.getPortfolioId()
    if (f !== 0) {
      writer.writeSint32(3, f)
    }
    f = message.getDispatchId()
    if (f !== 0) {
      writer.writeSint32(4, f)
    }
    f = message.getTimestamp()
    if (f.length > 0) {
      writer.writeString(5, f)
    }
  }

/**
 * optional string baseline_type = 1;
 * @return {string}
 */
proto.streams.CumulativePortfolioPerformance.prototype.getBaselineType =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.streams.CumulativePortfolioPerformance} returns this
 */
proto.streams.CumulativePortfolioPerformance.prototype.setBaselineType =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value)
  }

/**
 * optional double kwh = 2;
 * @return {number}
 */
proto.streams.CumulativePortfolioPerformance.prototype.getKwh = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.CumulativePortfolioPerformance} returns this
 */
proto.streams.CumulativePortfolioPerformance.prototype.setKwh = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional sint32 portfolio_id = 3;
 * @return {number}
 */
proto.streams.CumulativePortfolioPerformance.prototype.getPortfolioId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativePortfolioPerformance} returns this
 */
proto.streams.CumulativePortfolioPerformance.prototype.setPortfolioId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 3, value)
  }

/**
 * optional sint32 dispatch_id = 4;
 * @return {number}
 */
proto.streams.CumulativePortfolioPerformance.prototype.getDispatchId =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
  }

/**
 * @param {number} value
 * @return {!proto.streams.CumulativePortfolioPerformance} returns this
 */
proto.streams.CumulativePortfolioPerformance.prototype.setDispatchId =
  function (value) {
    return jspb.Message.setProto3IntField(this, 4, value)
  }

/**
 * optional string timestamp = 5;
 * @return {string}
 */
proto.streams.CumulativePortfolioPerformance.prototype.getTimestamp =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
  }

/**
 * @param {string} value
 * @return {!proto.streams.CumulativePortfolioPerformance} returns this
 */
proto.streams.CumulativePortfolioPerformance.prototype.setTimestamp = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.streams.UserAcknowledgment.repeatedFields_ = [10]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.UserAcknowledgment.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.UserAcknowledgment.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.UserAcknowledgment} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.UserAcknowledgment.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dispatchId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        fullName: jspb.Message.getFieldWithDefault(msg, 4, ''),
        phone: jspb.Message.getFieldWithDefault(msg, 5, ''),
        email: jspb.Message.getFieldWithDefault(msg, 6, ''),
        dispatchRole: jspb.Message.getFieldWithDefault(msg, 7, ''),
        acknowledgmentTime: jspb.Message.getFieldWithDefault(msg, 8, ''),
        acknowledgmentStatus: jspb.Message.getFieldWithDefault(msg, 9, ''),
        acknowledgmentTypesList:
          (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.UserAcknowledgment}
 */
proto.streams.UserAcknowledgment.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.UserAcknowledgment()
  return proto.streams.UserAcknowledgment.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.UserAcknowledgment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.UserAcknowledgment}
 */
proto.streams.UserAcknowledgment.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFullName(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setPhone(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setEmail(value)
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.setDispatchRole(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setAcknowledgmentTime(value)
        break
      case 9:
        var value = /** @type {string} */ (reader.readString())
        msg.setAcknowledgmentStatus(value)
        break
      case 10:
        var value = /** @type {string} */ (reader.readString())
        msg.addAcknowledgmentTypes(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.UserAcknowledgment.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.UserAcknowledgment.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.UserAcknowledgment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.UserAcknowledgment.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getFullName()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getPhone()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getEmail()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getDispatchRole()
  if (f.length > 0) {
    writer.writeString(7, f)
  }
  f = message.getAcknowledgmentTime()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
  f = message.getAcknowledgmentStatus()
  if (f.length > 0) {
    writer.writeString(9, f)
  }
  f = message.getAcknowledgmentTypesList()
  if (f.length > 0) {
    writer.writeRepeatedString(10, f)
  }
}

/**
 * optional sint32 dispatch_id = 1;
 * @return {number}
 */
proto.streams.UserAcknowledgment.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 facility_id = 2;
 * @return {number}
 */
proto.streams.UserAcknowledgment.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 user_id = 3;
 * @return {number}
 */
proto.streams.UserAcknowledgment.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string full_name = 4;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getFullName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setFullName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string phone = 5;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setPhone = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string email = 6;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string dispatch_role = 7;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getDispatchRole = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setDispatchRole = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional string acknowledgment_time = 8;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getAcknowledgmentTime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setAcknowledgmentTime = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional string acknowledgment_status = 9;
 * @return {string}
 */
proto.streams.UserAcknowledgment.prototype.getAcknowledgmentStatus =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
  }

/**
 * @param {string} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setAcknowledgmentStatus = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * repeated string acknowledgment_types = 10;
 * @return {!Array<string>}
 */
proto.streams.UserAcknowledgment.prototype.getAcknowledgmentTypesList =
  function () {
    return /** @type {!Array<string>} */ (
      jspb.Message.getRepeatedField(this, 10)
    )
  }

/**
 * @param {!Array<string>} value
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.setAcknowledgmentTypesList =
  function (value) {
    return jspb.Message.setField(this, 10, value || [])
  }

/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.addAcknowledgmentTypes = function (
  value,
  opt_index
) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index)
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.streams.UserAcknowledgment} returns this
 */
proto.streams.UserAcknowledgment.prototype.clearAcknowledgmentTypesList =
  function () {
    return this.setAcknowledgmentTypesList([])
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.streams.StreamingDispatch.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.StreamingDispatch.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.StreamingDispatch.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.StreamingDispatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.StreamingDispatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dispatch:
          (f = msg.getDispatch()) &&
          proto.streams.PortfolioDispatch.toObject(includeInstance, f),
        facilitiesList: jspb.Message.toObjectList(
          msg.getFacilitiesList(),
          proto.streams.FacilityDispatch.toObject,
          includeInstance
        ),
        createdAt: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.StreamingDispatch}
 */
proto.streams.StreamingDispatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.StreamingDispatch()
  return proto.streams.StreamingDispatch.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.StreamingDispatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.StreamingDispatch}
 */
proto.streams.StreamingDispatch.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.streams.PortfolioDispatch()
        reader.readMessage(
          value,
          proto.streams.PortfolioDispatch.deserializeBinaryFromReader
        )
        msg.setDispatch(value)
        break
      case 2:
        var value = new proto.streams.FacilityDispatch()
        reader.readMessage(
          value,
          proto.streams.FacilityDispatch.deserializeBinaryFromReader
        )
        msg.addFacilities(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setCreatedAt(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.StreamingDispatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.StreamingDispatch.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.StreamingDispatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.StreamingDispatch.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getDispatch()
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.streams.PortfolioDispatch.serializeBinaryToWriter
    )
  }
  f = message.getFacilitiesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.streams.FacilityDispatch.serializeBinaryToWriter
    )
  }
  f = message.getCreatedAt()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional PortfolioDispatch dispatch = 1;
 * @return {?proto.streams.PortfolioDispatch}
 */
proto.streams.StreamingDispatch.prototype.getDispatch = function () {
  return /** @type{?proto.streams.PortfolioDispatch} */ (
    jspb.Message.getWrapperField(this, proto.streams.PortfolioDispatch, 1)
  )
}

/**
 * @param {?proto.streams.PortfolioDispatch|undefined} value
 * @return {!proto.streams.StreamingDispatch} returns this
 */
proto.streams.StreamingDispatch.prototype.setDispatch = function (value) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.streams.StreamingDispatch} returns this
 */
proto.streams.StreamingDispatch.prototype.clearDispatch = function () {
  return this.setDispatch(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.streams.StreamingDispatch.prototype.hasDispatch = function () {
  return jspb.Message.getField(this, 1) != null
}

/**
 * repeated FacilityDispatch facilities = 2;
 * @return {!Array<!proto.streams.FacilityDispatch>}
 */
proto.streams.StreamingDispatch.prototype.getFacilitiesList = function () {
  return /** @type{!Array<!proto.streams.FacilityDispatch>} */ (
    jspb.Message.getRepeatedWrapperField(
      this,
      proto.streams.FacilityDispatch,
      2
    )
  )
}

/**
 * @param {!Array<!proto.streams.FacilityDispatch>} value
 * @return {!proto.streams.StreamingDispatch} returns this
 */
proto.streams.StreamingDispatch.prototype.setFacilitiesList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.streams.FacilityDispatch=} opt_value
 * @param {number=} opt_index
 * @return {!proto.streams.FacilityDispatch}
 */
proto.streams.StreamingDispatch.prototype.addFacilities = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.streams.FacilityDispatch,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.streams.StreamingDispatch} returns this
 */
proto.streams.StreamingDispatch.prototype.clearFacilitiesList = function () {
  return this.setFacilitiesList([])
}

/**
 * optional string created_at = 3;
 * @return {string}
 */
proto.streams.StreamingDispatch.prototype.getCreatedAt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.StreamingDispatch} returns this
 */
proto.streams.StreamingDispatch.prototype.setCreatedAt = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.PortfolioDispatch.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.PortfolioDispatch.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.PortfolioDispatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.PortfolioDispatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        portfolioId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        baselineType: jspb.Message.getFieldWithDefault(msg, 2, ''),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        portfolioName: jspb.Message.getFieldWithDefault(msg, 4, ''),
        programName: jspb.Message.getFieldWithDefault(msg, 5, ''),
        programPhone: jspb.Message.getFieldWithDefault(msg, 6, ''),
        startTime: jspb.Message.getFieldWithDefault(msg, 7, ''),
        endTime: jspb.Message.getFieldWithDefault(msg, 8, ''),
        defaultEndTime: jspb.Message.getFieldWithDefault(msg, 9, ''),
        dispatchType: jspb.Message.getFieldWithDefault(msg, 10, ''),
        potentialEarnings: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          11,
          0.0
        ),
        commitmentKw: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          12,
          0.0
        ),
        commitmentKwh: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          13,
          0.0
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.PortfolioDispatch}
 */
proto.streams.PortfolioDispatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.PortfolioDispatch()
  return proto.streams.PortfolioDispatch.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.PortfolioDispatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.PortfolioDispatch}
 */
proto.streams.PortfolioDispatch.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPortfolioId(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineType(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setPortfolioName(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setProgramName(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setProgramPhone(value)
        break
      case 7:
        var value = /** @type {string} */ (reader.readString())
        msg.setStartTime(value)
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setEndTime(value)
        break
      case 9:
        var value = /** @type {string} */ (reader.readString())
        msg.setDefaultEndTime(value)
        break
      case 10:
        var value = /** @type {string} */ (reader.readString())
        msg.setDispatchType(value)
        break
      case 11:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setPotentialEarnings(value)
        break
      case 12:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCommitmentKw(value)
        break
      case 13:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCommitmentKwh(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.PortfolioDispatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.PortfolioDispatch.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.PortfolioDispatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.PortfolioDispatch.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getPortfolioId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getPortfolioName()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getProgramName()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getProgramPhone()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getStartTime()
  if (f.length > 0) {
    writer.writeString(7, f)
  }
  f = message.getEndTime()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
  f = message.getDefaultEndTime()
  if (f.length > 0) {
    writer.writeString(9, f)
  }
  f = message.getDispatchType()
  if (f.length > 0) {
    writer.writeString(10, f)
  }
  f = message.getPotentialEarnings()
  if (f !== 0.0) {
    writer.writeDouble(11, f)
  }
  f = message.getCommitmentKw()
  if (f !== 0.0) {
    writer.writeDouble(12, f)
  }
  f = message.getCommitmentKwh()
  if (f !== 0.0) {
    writer.writeDouble(13, f)
  }
}

/**
 * optional sint32 portfolio_id = 1;
 * @return {number}
 */
proto.streams.PortfolioDispatch.prototype.getPortfolioId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setPortfolioId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string baseline_type = 2;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getBaselineType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setBaselineType = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional sint32 dispatch_id = 3;
 * @return {number}
 */
proto.streams.PortfolioDispatch.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string portfolio_name = 4;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getPortfolioName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setPortfolioName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string program_name = 5;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getProgramName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setProgramName = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string program_phone = 6;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getProgramPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setProgramPhone = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional string start_time = 7;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getStartTime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setStartTime = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value)
}

/**
 * optional string end_time = 8;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getEndTime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setEndTime = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

/**
 * optional string default_end_time = 9;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getDefaultEndTime = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setDefaultEndTime = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * optional string dispatch_type = 10;
 * @return {string}
 */
proto.streams.PortfolioDispatch.prototype.getDispatchType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setDispatchType = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value)
}

/**
 * optional double potential_earnings = 11;
 * @return {number}
 */
proto.streams.PortfolioDispatch.prototype.getPotentialEarnings = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setPotentialEarnings = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 11, value)
}

/**
 * optional double commitment_kw = 12;
 * @return {number}
 */
proto.streams.PortfolioDispatch.prototype.getCommitmentKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setCommitmentKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 12, value)
}

/**
 * optional double commitment_kwh = 13;
 * @return {number}
 */
proto.streams.PortfolioDispatch.prototype.getCommitmentKwh = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDispatch} returns this
 */
proto.streams.PortfolioDispatch.prototype.setCommitmentKwh = function (value) {
  return jspb.Message.setProto3FloatField(this, 13, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.streams.FacilityDispatch.repeatedFields_ = [10]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.FacilityDispatch.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.FacilityDispatch.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.FacilityDispatch} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.FacilityDispatch.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        facilityId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        dispatchId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        name: jspb.Message.getFieldWithDefault(msg, 3, ''),
        commitmentKw: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          4,
          0.0
        ),
        commitmentKwh: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          5,
          0.0
        ),
        curtailmentPlan: jspb.Message.getFieldWithDefault(msg, 6, ''),
        potentialEarnings: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          7,
          0.0
        ),
        instrumented: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
        baselineType: jspb.Message.getFieldWithDefault(msg, 9, ''),
        usersList: jspb.Message.toObjectList(
          msg.getUsersList(),
          proto.streams.User.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.FacilityDispatch}
 */
proto.streams.FacilityDispatch.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.FacilityDispatch()
  return proto.streams.FacilityDispatch.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.FacilityDispatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.FacilityDispatch}
 */
proto.streams.FacilityDispatch.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setName(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCommitmentKw(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setCommitmentKwh(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setCurtailmentPlan(value)
        break
      case 7:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setPotentialEarnings(value)
        break
      case 8:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setInstrumented(value)
        break
      case 9:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineType(value)
        break
      case 10:
        var value = new proto.streams.User()
        reader.readMessage(
          value,
          proto.streams.User.deserializeBinaryFromReader
        )
        msg.addUsers(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.FacilityDispatch.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.FacilityDispatch.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.FacilityDispatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.FacilityDispatch.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getName()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getCommitmentKw()
  if (f !== 0.0) {
    writer.writeDouble(4, f)
  }
  f = message.getCommitmentKwh()
  if (f !== 0.0) {
    writer.writeDouble(5, f)
  }
  f = message.getCurtailmentPlan()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getPotentialEarnings()
  if (f !== 0.0) {
    writer.writeDouble(7, f)
  }
  f = message.getInstrumented()
  if (f) {
    writer.writeBool(8, f)
  }
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(9, f)
  }
  f = message.getUsersList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.streams.User.serializeBinaryToWriter
    )
  }
}

/**
 * optional sint32 facility_id = 1;
 * @return {number}
 */
proto.streams.FacilityDispatch.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 dispatch_id = 2;
 * @return {number}
 */
proto.streams.FacilityDispatch.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string name = 3;
 * @return {string}
 */
proto.streams.FacilityDispatch.prototype.getName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setName = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * optional double commitment_kw = 4;
 * @return {number}
 */
proto.streams.FacilityDispatch.prototype.getCommitmentKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setCommitmentKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value)
}

/**
 * optional double commitment_kwh = 5;
 * @return {number}
 */
proto.streams.FacilityDispatch.prototype.getCommitmentKwh = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setCommitmentKwh = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value)
}

/**
 * optional string curtailment_plan = 6;
 * @return {string}
 */
proto.streams.FacilityDispatch.prototype.getCurtailmentPlan = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setCurtailmentPlan = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional double potential_earnings = 7;
 * @return {number}
 */
proto.streams.FacilityDispatch.prototype.getPotentialEarnings = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setPotentialEarnings = function (
  value
) {
  return jspb.Message.setProto3FloatField(this, 7, value)
}

/**
 * optional bool instrumented = 8;
 * @return {boolean}
 */
proto.streams.FacilityDispatch.prototype.getInstrumented = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 8, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setInstrumented = function (value) {
  return jspb.Message.setProto3BooleanField(this, 8, value)
}

/**
 * optional string baseline_type = 9;
 * @return {string}
 */
proto.streams.FacilityDispatch.prototype.getBaselineType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setBaselineType = function (value) {
  return jspb.Message.setProto3StringField(this, 9, value)
}

/**
 * repeated User users = 10;
 * @return {!Array<!proto.streams.User>}
 */
proto.streams.FacilityDispatch.prototype.getUsersList = function () {
  return /** @type{!Array<!proto.streams.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.streams.User, 10)
  )
}

/**
 * @param {!Array<!proto.streams.User>} value
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.setUsersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value)
}

/**
 * @param {!proto.streams.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.streams.User}
 */
proto.streams.FacilityDispatch.prototype.addUsers = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    10,
    opt_value,
    proto.streams.User,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.streams.FacilityDispatch} returns this
 */
proto.streams.FacilityDispatch.prototype.clearUsersList = function () {
  return this.setUsersList([])
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.User.prototype.toObject = function (opt_includeInstance) {
    return proto.streams.User.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.User} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.User.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dispatchId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        fullName: jspb.Message.getFieldWithDefault(msg, 4, ''),
        phone: jspb.Message.getFieldWithDefault(msg, 5, ''),
        email: jspb.Message.getFieldWithDefault(msg, 6, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.User}
 */
proto.streams.User.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.User()
  return proto.streams.User.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.User}
 */
proto.streams.User.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setDispatchId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setFullName(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setPhone(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setEmail(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.User.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.User.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.User.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getDispatchId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getFullName()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getPhone()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getEmail()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
}

/**
 * optional sint32 dispatch_id = 1;
 * @return {number}
 */
proto.streams.User.prototype.getDispatchId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setDispatchId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 facility_id = 2;
 * @return {number}
 */
proto.streams.User.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 user_id = 3;
 * @return {number}
 */
proto.streams.User.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string full_name = 4;
 * @return {string}
 */
proto.streams.User.prototype.getFullName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setFullName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string phone = 5;
 * @return {string}
 */
proto.streams.User.prototype.getPhone = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setPhone = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string email = 6;
 * @return {string}
 */
proto.streams.User.prototype.getEmail = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.User} returns this
 */
proto.streams.User.prototype.setEmail = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.FacilityPerformance.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.FacilityPerformance.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.FacilityPerformance} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.FacilityPerformance.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        baselineType: jspb.Message.getFieldWithDefault(msg, 1, ''),
        kw: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        demandKw: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
        baselineKw: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
        adjustmentKw: jspb.Message.getFloatingPointFieldWithDefault(
          msg,
          5,
          0.0
        ),
        baselineTimestamp: jspb.Message.getFieldWithDefault(msg, 6, ''),
        baselineInterval: jspb.Message.getFieldWithDefault(msg, 7, 0),
        facilityId: jspb.Message.getFieldWithDefault(msg, 8, 0),
        eventId: jspb.Message.getFieldWithDefault(msg, 9, 0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 10, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.FacilityPerformance}
 */
proto.streams.FacilityPerformance.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.FacilityPerformance()
  return proto.streams.FacilityPerformance.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.FacilityPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.FacilityPerformance}
 */
proto.streams.FacilityPerformance.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineType(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setKw(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setDemandKw(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setBaselineKw(value)
        break
      case 5:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setAdjustmentKw(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setBaselineTimestamp(value)
        break
      case 7:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setBaselineInterval(value)
        break
      case 8:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setFacilityId(value)
        break
      case 9:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setEventId(value)
        break
      case 10:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.FacilityPerformance.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.FacilityPerformance.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.FacilityPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.FacilityPerformance.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getBaselineType()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getKw()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getDemandKw()
  if (f !== 0.0) {
    writer.writeDouble(3, f)
  }
  f = message.getBaselineKw()
  if (f !== 0.0) {
    writer.writeDouble(4, f)
  }
  f = message.getAdjustmentKw()
  if (f !== 0.0) {
    writer.writeDouble(5, f)
  }
  f = message.getBaselineTimestamp()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getBaselineInterval()
  if (f !== 0) {
    writer.writeSint32(7, f)
  }
  f = message.getFacilityId()
  if (f !== 0) {
    writer.writeSint32(8, f)
  }
  f = message.getEventId()
  if (f !== 0) {
    writer.writeSint32(9, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(10, f)
  }
}

/**
 * optional string baseline_type = 1;
 * @return {string}
 */
proto.streams.FacilityPerformance.prototype.getBaselineType = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setBaselineType = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional double kw = 2;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional double demand_kw = 3;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getDemandKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setDemandKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 3, value)
}

/**
 * optional double baseline_kw = 4;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getBaselineKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setBaselineKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 4, value)
}

/**
 * optional double adjustment_kw = 5;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getAdjustmentKw = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setAdjustmentKw = function (value) {
  return jspb.Message.setProto3FloatField(this, 5, value)
}

/**
 * optional string baseline_timestamp = 6;
 * @return {string}
 */
proto.streams.FacilityPerformance.prototype.getBaselineTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setBaselineTimestamp = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * optional sint32 baseline_interval = 7;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getBaselineInterval = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setBaselineInterval = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 7, value)
}

/**
 * optional sint32 facility_id = 8;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getFacilityId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setFacilityId = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value)
}

/**
 * optional sint32 event_id = 9;
 * @return {number}
 */
proto.streams.FacilityPerformance.prototype.getEventId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setEventId = function (value) {
  return jspb.Message.setProto3IntField(this, 9, value)
}

/**
 * optional string timestamp = 10;
 * @return {string}
 */
proto.streams.FacilityPerformance.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.FacilityPerformance} returns this
 */
proto.streams.FacilityPerformance.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 10, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.streams.PortfolioDemand.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.streams.PortfolioDemand.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.streams.PortfolioDemand} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.streams.PortfolioDemand.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        portfolioId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        value: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
        timestamp: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.streams.PortfolioDemand}
 */
proto.streams.PortfolioDemand.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.streams.PortfolioDemand()
  return proto.streams.PortfolioDemand.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.streams.PortfolioDemand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.streams.PortfolioDemand}
 */
proto.streams.PortfolioDemand.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setPortfolioId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readDouble())
        msg.setValue(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.streams.PortfolioDemand.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.streams.PortfolioDemand.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.streams.PortfolioDemand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.streams.PortfolioDemand.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getPortfolioId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getValue()
  if (f !== 0.0) {
    writer.writeDouble(2, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional sint32 portfolio_id = 1;
 * @return {number}
 */
proto.streams.PortfolioDemand.prototype.getPortfolioId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDemand} returns this
 */
proto.streams.PortfolioDemand.prototype.setPortfolioId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional double value = 2;
 * @return {number}
 */
proto.streams.PortfolioDemand.prototype.getValue = function () {
  return /** @type {number} */ (
    jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0)
  )
}

/**
 * @param {number} value
 * @return {!proto.streams.PortfolioDemand} returns this
 */
proto.streams.PortfolioDemand.prototype.setValue = function (value) {
  return jspb.Message.setProto3FloatField(this, 2, value)
}

/**
 * optional string timestamp = 3;
 * @return {string}
 */
proto.streams.PortfolioDemand.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.streams.PortfolioDemand} returns this
 */
proto.streams.PortfolioDemand.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

goog.object.extend(exports, proto.streams)
