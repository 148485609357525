import isNil from 'lodash/isNil'
import React from 'react'
import { Text } from '@voltus/core-components'
import { PERFORMANCE_STATUS, PERFORMANCE_THRESHOLD } from '../constants'

/**
 * Utils for working with performance or participation calculations
 */

/**
 * Return the percentage of confirmed user acknowledgements
 */
export const getParticipationPercentage = (
  confirmed,
  rejected,
  notResponded
) => {
  const res = Math.floor(
    (confirmed / (confirmed + rejected + notResponded)) * 100
  )

  return isNaN(res) || !isFinite(res) ? 0 : res
}

/**
 * Helper to convert performanceKw and commitmentKw into a percent
 */
export const getPerformancePercentage = (perf, commitment) => {
  const res = Math.floor((perf / commitment) * 100)
  return isNaN(res) || !isFinite(res) ? 0 : res
}

/**
 * Get the Performance Status based on some passed
 * in performance percentage.
 */
export const getPerformanceStatus = (pct) => {
  if (isNil(pct)) {
    return PERFORMANCE_STATUS.NO_DATA
  }
  if (pct >= PERFORMANCE_THRESHOLD.ON_TRACK) {
    return PERFORMANCE_STATUS.ON_TRACK
  } else if (pct > PERFORMANCE_THRESHOLD.LAGGING) {
    return PERFORMANCE_STATUS.LAGGING
  }

  return PERFORMANCE_STATUS.AT_RISK
}

/**
 * Helper component to make rendering status labels
 * a bit easier. The reason we do this here, rather
 * than in a given component, is that one particular
 * status message has a link in it, which means we can't
 * just pass back a string, we need to render html
 * so the link is clickable
 */
const LabelWrapper = ({ children }) => {
  return (
    <Text.Helper display="block" fontWeight="bold">
      {children}
    </Text.Helper>
  )
}

/**
 * Returns a label to be rendered in the DispatchDetail page
 * depending on how a portfolio is performing
 *
 * @param {string} status - Enum of type PERFORMANCE_STATUS
 */
export const getPerformanceStatusLabel = (status) => {
  switch (status) {
    case PERFORMANCE_STATUS.NO_DATA:
      return <LabelWrapper>No Data Available</LabelWrapper>
    case PERFORMANCE_STATUS.ON_TRACK:
      return <LabelWrapper>On Track</LabelWrapper>
    case PERFORMANCE_STATUS.LAGGING:
      return <LabelWrapper>Additional Action Required</LabelWrapper>
    case PERFORMANCE_STATUS.AT_RISK:
      return <LabelWrapper>Underperforming</LabelWrapper>
  }
}

/**
 * Returns description copy to be rendered in the DispatchDetail page
 * depending on how a portfolio is performing
 *
 * @param {string} status - Enum of type PERFORMANCE_STATUS
 */
export const getPerformanceStatusDescription = (status, phone) => {
  switch (status) {
    case PERFORMANCE_STATUS.NO_DATA:
      return (
        <Text.Helper as="span">
          No sites have available performance data
        </Text.Helper>
      )
    case PERFORMANCE_STATUS.AT_RISK:
      return (
        <Text.Helper as="span">
          You are not on track to meet your commitment.
          {phone && (
            <>
              Give Voltus a call at <a href={`tel:${phone}`}>{phone}</a> for
              help.
            </>
          )}
        </Text.Helper>
      )
    case PERFORMANCE_STATUS.LAGGING:
      return (
        <Text.Helper as="span">
          You need to deliver a few more kW to meet your commitment.
        </Text.Helper>
      )
    case PERFORMANCE_STATUS.ON_TRACK:
      return (
        <Text.Helper>
          You are delivering enough kWs to meet your commitment.
        </Text.Helper>
      )
  }
}
