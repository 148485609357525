import uniqBy from 'lodash/uniqBy'
import { arrayUtils } from '@voltus/utils'
import { PatchTypes } from '../constants'
export const transformEventStreamToNoteTypes = (eventStream) => {
  return arrayUtils.ensureArray(eventStream).reduce((acc, event = {}) => {
    if (event.call) {
      acc.push({
        type: PatchTypes.CALL,
        data: event.call,
      })
    }
    if (event.assignment) {
      acc.push({
        type: PatchTypes.ASSIGNMENT,
        data: event.assignment,
      })
    }
    if (event.note) {
      acc.push({
        type: PatchTypes.TEXT_NOTE,
        data: event.note,
      })
    }
    if (event.userDispatchState) {
      acc.push({
        type: PatchTypes.USER_DISPATCH_STATE,
        data: event.userDispatchState,
      })
    }
    return acc
  }, [])
}

const getNoteId = (note) => {
  return note.type + note.data.id
}

export const dedupeAndSort = (notes) =>
  uniqBy(notes, getNoteId).sort((a, b) => {
    return new Date(b.data.createdAt) - new Date(a.data.createdAt)
  })
