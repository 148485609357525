import qs from 'query-string'
import { AnyObject } from '@voltus/types'
import { stringUtils, toISOString } from '@voltus/utils'

export const makeQueryString = (
  params: string | AnyObject | undefined,
  includeQuestionMark = false
) => {
  if (!params) {
    return ''
  }

  let queryString = ''
  if (typeof params === 'object') {
    queryString = qs.stringify(params, {
      skipNull: true,
    })
  } else if (typeof params === 'string') {
    queryString = params
  }

  // No need to include question mark if query string is empty
  if (queryString === '') {
    return ''
  }

  if (includeQuestionMark) {
    queryString = `?${queryString}`
  }

  return queryString
}

export const auth = {
  permissions: () => '/auth/permissions',
  changePassword: () => '/auth/change-password',
  csrf: () => '/auth/csrf',
  // MUST HAVE TRAILING SLASH!!!!
  // Flask routes defined with a trailing slash will automatically redirect
  // urls that are missing the slash (so, '/auth/login' will get redirected to '/auth/login/')
  // BUT, this is only reliable for GET requests, and browsers _may not_ resend form data
  // when the POST request is redirected. As a result, these endpoinst MUST have a trailing
  // slash to avoid the flask redirect
  login: () => '/auth/login/',
  reset: () => '/auth/reset/',
  signup: () => '/auth/signup/',
  sso: {
    zendesk: () => '/auth/sso/zendesk/',
    zendeskJWT: (params?: { return_to?: string }) =>
      `/auth/sso/zendesk/jwt${makeQueryString(params, true)}`,
    google: (params?: {
      next?: string
      to_zendesk?: '1'
      return_to?: string
    }) => `/auth/sso/google/${makeQueryString(params, true)}`,
  },
  welcome: () => '/auth/welcome',
}

const UTILITY_API_PREFIX = '/udr-api'

export const boards = {
  peaksAndEvents: (params: {
    start_time: string
    end_time: string
    region: Array<string>
  }) => `/api/v0.1/peaks-and-events/${makeQueryString(params, true)}`,
}

export const admin = {
  events: {
    export: {
      acknowledgments: {
        eventId: (id) => `/admin/events/export-acknowledgments/${id}`,
      },
      summary: {
        eventId: (id) => `/admin/voc-admin/dispatches/${id}/archive.csv`,
      },
    },
  },
  internalDispatch: {
    registrations: (id) => `/internal-dispatch/registrations/${id}`,
    dispatchProgram: (sfid) =>
      `/internal-dispatch/dispatch/program/salesforce/${sfid}`,
    postTests: (sfid) =>
      `/internal-dispatch/dispatch/program/salesforce/${sfid}`,
  },
  facilities: {
    facilitiesPaginated: (params?: Parameters<typeof makeQueryString>[0]) => {
      const url = `/admin/facilitiespaginated`
      if (params) {
        return `${url}?${makeQueryString(params)}`
      }
      return url
    },
    facilityDetail: (id) => `/admin/facility-json/${id}`,
    sendEmails: (id) =>
      `/admin/facility/${id}/resend-welcome-emails?origin=Facility Page`,
    resetOrRetire: (facilityId, voltletId, operation) =>
      `/admin/facility/${facilityId}/voltlet/${voltletId}/${operation}`,
    upload: () => '/api/v0.1/facilities',
  },
  grits: {
    contacts: () => '/admin/grits-api/contacts/',
    robodial: {
      person: (id: number) => `/admin/grits-api/robodial/people/${id}/`,
      people: () => '/admin/grits-api/robodial/people/',
      programs: {
        all: () => `/admin/grits-api/robodial/programs/`,
        id: (id: number) => `/admin/grits-api/robodial/programs/${id}/`,
        vafProgram: (id: number) =>
          `/admin/grits-api/robodial/programs/${id}/vaf-program`,
        dial: (programId: number) =>
          `/admin/grits-api/robodial/programs/${programId}/dial/`,
      },
    },
    voltlets: (params) =>
      `/admin/grits-api/voltlets/?${makeQueryString(params)}`,
    voltlet: (id) => `/admin/grits-api/voltlets/${id}`,
    voltletClient: (clientId) => `/api/v0.1/voltlets/${clientId}`,
    voltletFirmware: (id) => `/admin/grits-api/voltlets/${id}/firmware`,
    voltletGpios: (id, params) =>
      `/admin/grits-api/voltlets/${id}/gpios/?${makeQueryString(params)}`,
    networkInterfaces: (id, params) =>
      `/admin/grits-api/voltlets/${id}/network-interfaces/?${makeQueryString(
        params
      )}`,
  },
  utilityApi: {
    data: {
      requests: (params?: Parameters<typeof makeQueryString>[0]) =>
        `${UTILITY_API_PREFIX}/request?${makeQueryString(params)}`,
      singleRequest: (id) => `${UTILITY_API_PREFIX}/request/${id}`,
      bulkcreate: () => `${UTILITY_API_PREFIX}/request/zip`,
      createStandardizedRequests: () =>
        `${UTILITY_API_PREFIX}/request/standardized`,
      utilityFacilities: (sfid) =>
        `/markets/utilities/salesforce/${sfid}/accounts/facilities`,
      uploadSourceFiles: (id) =>
        `${UTILITY_API_PREFIX}/request/${id}/uploads/source`,
      uploadStandardizedFiles: (id) =>
        `${UTILITY_API_PREFIX}/request/${id}/uploads/standardized`,
      uploadAggregationFiles: (id) =>
        `${UTILITY_API_PREFIX}/request/${id}/uploads/aggregation`,
      searchFilters: () => `${UTILITY_API_PREFIX}/search-filters`,
      bulkUpdate: () => `${UTILITY_API_PREFIX}/request`,
    },
  },
  dispatchPerformance: {
    utilityDataUploads: (params) =>
      `/recent_uploads?${makeQueryString(params)}`,
    rollup: (params) => `/rollup_preview?${makeQueryString(params)}`,
  },
  marketResources: {
    marketResourcesList: (market, params) =>
      `/market-mirror/${market}/market-resources-paginated?${makeQueryString(
        params
      )}`,
    marketResourceDetail: (
      market,
      resourceName,
      params?: Parameters<typeof makeQueryString>[0]
    ) =>
      `/market-mirror/${market}/market_resources/${resourceName}?${makeQueryString(
        params
      )}`,
    marketResourceFacilityAssociations: (
      params?: Parameters<typeof makeQueryString>[0]
    ) => {
      const url = `/market-mirror/market-resource-facility-association`
      if (params) {
        return `${url}?${makeQueryString(params)}`
      }
      return url
    },
    marketResourceFacilityAssociationsDisable: () =>
      '/market-mirror/market-resource-facility-association/disable',
    marketResourceFacilityAssociationsDisqualify: () =>
      '/market-mirror/market-resource-facility-association/disqualify',
  },
  dispatchQueue: {
    getDispatches: (params) =>
      `/api/dispatch-queue/dispatch?${makeQueryString(params)}`,
    getCompletedDispatches: (programSalesforceId, updatedSince) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}?submitting=true&submitted=true&deleted=true&queued=false&updated_since=${updatedSince}`,
    deleteDispatch: (programSalesforceId, dispatchId) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}/${dispatchId}`,
    restoreDispatch: (programSalesforceId, dispatchId) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}/${dispatchId}/restore`,
    getDispatch: (programSalesforceId, dispatchId) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}/${dispatchId}`,
    modifyDispatch: (programSalesforceId, dispatchId) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}/${dispatchId}`,
    submitDispatch: (programSalesforceId, dispatchId) =>
      `/api/dispatch-queue/dispatch/${programSalesforceId}/${dispatchId}`,
    bulkUpload: () => `/api/dispatch-queue/upload`,
    qualifyingRegistrations: (programSalesforceId, params) =>
      `/markets/programs/salesforce/${programSalesforceId}/qualifying-registrations?${makeQueryString(
        params
      )}`,
  },
  voltlets: {
    getVoltletDetails: (clientId: string) =>
      `/api/v0.1/voltlets/${clientId}/details`,
    getVoltletConfigDetails: (configId: string | number) =>
      `/api/v0.1/voltlets/configurations/${configId}/details`,
    getVoltletsPaginated: (params: Parameters<typeof makeQueryString>[0]) =>
      `/api/v0.1/voltlets/paginated?${makeQueryString(params)}`,
  },
}

export const file = {
  upload: (params?: Parameters<typeof makeQueryString>[0]) =>
    `/file/upload?${makeQueryString(params)}`,
}
export const fileMetadata = {
  metadatas: (params?: Parameters<typeof makeQueryString>[0]) => {
    const url = `/file_metadata`
    if (params) {
      return `${url}?${makeQueryString(params)}`
    }
    return url
  },
  metadata: (id: number) => `/file_metadata/${id}`,
  downloadLink: (id: number) => `/file_metadata/${id}/download_link`,
  download: (params: Parameters<typeof makeQueryString>[0]) =>
    `/file_metadata/download?${makeQueryString(params)}`,
}

export const carbon = {
  reduction: (params) =>
    `/api/carbon-reporting/external/v1_0/reduction/?${makeQueryString(params)}`,
}

export const profile = {
  user: () => '/profile/user',
  facilities: (id) => `/profile/${id}/facilities`,
}

export const portfolio = {
  user: (params) => `/portfolio/user?${makeQueryString(params)}`,
  registrationMetadata: (portfolioId: number) =>
    `/api/v0.1/portfolio/${portfolioId}/registrations/metadata`,
  currencies: (portfolioId: number | string) =>
    `/api/v0.1/portfolio/${portfolioId}/currencies`,
}

export const portfolioDash = {
  sites: (portfolioId, params?) =>
    `/api/portfolio-dash/${portfolioId}/sites?${makeQueryString(params)}`,
  sitesAdditionalData: (portfolioId, params) =>
    `/api/portfolio-dash/${portfolioId}/sites/additional-table-data?${params}`,
  siteDetails: (portfolioId, siteId, params) =>
    `/api/portfolio-dash/${portfolioId}/sites/${siteId}?${makeQueryString(
      params
    )}`,
  modifySiteContacts: (portfolioId, userId) =>
    `/api/portfolio-dash/${portfolioId}/site-contacts/contact/${userId}`,
  programs: (portfolioId, params?) =>
    `/api/portfolio-dash/${portfolioId}/programs?${makeQueryString(params)}`,
  programDetails: (portfolioId, programId) =>
    `/api/portfolio-dash/${portfolioId}/programs/${programId}`,
  overview: (portfolioId, params) =>
    `/api/portfolio-dash/${portfolioId}/overview?${makeQueryString(params)}`,
  registrations: (portfolioId, params) =>
    `/api/portfolio-dash/${portfolioId}/registrations?${makeQueryString(
      params
    )}`,
  registrationsKwByMonth: (portfolioId, params) =>
    `/api/portfolio-dash/${portfolioId}/registrations/kw-by-month?${makeQueryString(
      params
    )}`,
  monthlyCommitments: (portfolioId, params) =>
    `/api/portfolio-dash/${portfolioId}/registrations/csv-monthly-breakdown?${makeQueryString(
      params
    )}`,
}

export const meter = {
  virtualMeterStatus: () => `/meter/virtual/status/all`,
}

export const ledgerCashdash = {
  dateFilterStartDate: (params: string | AnyObject) =>
    `/ledger/cashdash/date-filter-start-date?${makeQueryString(params)}`,
  paymentsSummary: (params: string | AnyObject) =>
    `/ledger/cashdash/payments/summary?${makeQueryString(params)}`,
}

export const earnings = {
  /**
   * makeEarningsQueryString create a URL to fetch earnings overview data from cashdash.
   * Note - this endpoint expects an end_date parameter in the query string,
   * and it will add 1 FULL DAY to the end_date provided.
   */
  overview: (portfolioId: number | string, params: string | AnyObject) =>
    `/api/cashdash/v0.1/${portfolioId}/overview/?${makeQueryString(params)}`,
  lineItems: (portfolioId: number | string, params: string | AnyObject) =>
    `/api/cashdash/v0.1/${portfolioId}/line-items/?${makeQueryString(params)}`,
  lineItemOrDetails: (portfolioId: number | string, params: AnyObject) =>
    `/api/cashdash/v0.1/${portfolioId}/or-details/?${makeQueryString(params)}`,
  lineItemPerformance: (
    portfolioId: number | string,
    registrationSfid: string,
    dispatchesArray: Array<number> = []
  ) =>
    `/earnings/${portfolioId}/performance/${registrationSfid}?dispatch_ids=${dispatchesArray.join()}`,
  // TOOD: Properly type the params allowed here
  paymentItem: (
    portfolioId: number | string,
    paymentId?: string,
    params?: string
  ) =>
    `/api/cashdash/v0.1/${portfolioId}/payment/${paymentId}/?${makeQueryString(
      params
    )}`,
  // TODO: the paymentDetails endpoint doesn't appear to be in use, can we delete?
  paymentDetails: (
    portfolioId: number | string,
    paymentId: string,
    format = 'pdf'
  ) =>
    `/api/cashdash/v0.1/${portfolioId}/payment-detail/${paymentId}/download/?format=${format}`,
  chartMonthlyValue: (
    portfolioId: number | string,
    params: string | AnyObject
  ) =>
    `/api/cashdash/v0.1/${portfolioId}/overview/value-by-month/?${makeQueryString(
      params
    )}`,
  lastPayment: (portfolioId: number | string, params: AnyObject | string) =>
    `/api/cashdash/v0.1/${portfolioId}/overview/last-payment/?${makeQueryString(
      params
    )}`,
}

export const bankAccount = {
  tokenUrl: () => '/bank-account/getpaid/client-token',
  dwollaCustomer: () => `/bank-account/getpaid/customer`,
  fundingSourceCreateCallback: () =>
    `/bank-account/getpaid/funding-source-success`,
}

// TODO: replace old endpoints with the new ones and place them in `earnings` object
export const oldEarnings = {
  dispatches: (portfolioId, startDate, endDate) =>
    `/earnings/${portfolioId}/dispatches/${startDate}/${endDate}`,
  sites: (portfolioId, startDate, endDate) =>
    `/earnings/${portfolioId}/facilities/${startDate}/${endDate}`,
  siteDispatchPerformancePairs: (portfolioId, startDate, endDate) =>
    `/earnings/${portfolioId}/facility-dispatch/${startDate}/${endDate}`,
  siteDispatches: (portfolioId, siteId, startDate, endDate) =>
    `/earnings/${portfolioId}/site-dispatches/${siteId}?${makeQueryString({
      start_time: startDate,
      end_time: endDate,
    })}`,
  performanceGraph: (portfolioId, facilityId, dispatchId) =>
    `/earnings/${portfolioId}/facility-dispatch-graph/${facilityId}/${dispatchId}`,
  dispatch: (portfolioId, dispatchId) =>
    `/earnings/${portfolioId}/dispatch/${dispatchId}`,
}

export const financeDash = {
  payments: (params) => `/api/v0.1/payments?${makeQueryString(params)}`,
  accounts: (params) =>
    `/api/v0.1/payments/accounts?${makeQueryString(params)}`,
  pendingBalances: (params) =>
    `/api/cashdash/v0.1/unbilled-receivables/?${makeQueryString(params)}`,
  availablePayments: '/api/cashdash/v0.1/finance/available-payments/',
  unmatchedCogs: '/api/cashdash/v0.1/finance/unmatched-cogs/',
  paymentsLedger: (params) =>
    `/api/cashdash/v0.1/finance/payments/?${makeQueryString(params)}`,
  createPayments: '/api/cashdash/v0.1/finance/payments/',
  approve: '/api/cashdash/v0.1/payments/approve/',
  send: '/api/cashdash/v0.1/payments/send/',
  reject: '/api/cashdash/v0.1/payments/reject/',
  dissolve: '/api/cashdash/v0.1/payments/dissolve/',
  hold: '/api/cashdash/v0.1/payments/hold/',
}

export const user = {
  user: (userId) => `/api/user/${userId}`,
  add: (portfolioId) => {
    const queryString = qs.stringify({
      portfolio_id: portfolioId,
      sync: true,
    })
    return `/api/user/add?${queryString}`
  },
  externalUpdate: (userId) =>
    `/api/user/external/${userId}?${makeQueryString({ sync: true })}`,
  search: (search) => `/api/v0.1/user/?${makeQueryString({ search })}`,
}
export const site = {
  search: (search) => `/api/v0.1/site/?${makeQueryString({ search })}`,
}
export const organization = {
  search: (search) => `/api/v0.1/organization/?${makeQueryString({ search })}`,
}

export const pastPerformance = {
  dispatchDetails: (portfolioId, dispatchId) =>
    `/earnings/${portfolioId}/dispatch/${dispatchId}`,
  dispatchSitePerformance: (portfolioId, dispatchId, siteId) =>
    `/earnings/${portfolioId}/dispatch-site-performance/${dispatchId}/${siteId}`,
  acknowledgments: (portfolioId, dispatchId, siteId) =>
    `/api/v0.1/acknowledgments/portfolio/${portfolioId}/dispatch/${dispatchId}/site/${siteId}`,
}

export const siteContact = {
  getEligibleUsers: (portfolioId, siteId) =>
    `/api/portfolio-dash/${portfolioId}/site-contacts/site/${siteId}/add/eligible-users`,
  siteContact: (portfolioId, siteId, userId) =>
    `/api/portfolio-dash/${portfolioId}/site-contacts/site/${siteId}/contact/${userId}`,
  createUserAndAddSiteContact: (portfolioId, siteId) =>
    `/api/portfolio-dash/${portfolioId}/site-contacts/site/${siteId}/contact`,
}

export const programDetails = {
  details: (portfolioId, programId) =>
    `/api/portfolio-dash/${portfolioId}/programs/${programId}`,
  downloadPDF: (id) => `api/program-details/${id}/download`,
}

export const telemetryAlerts = {
  autoAdjustParams: () => `/alerts/site_config_params`,
  autoAdjustParamsBySfid: (params: {
    program_salesforce_id
    site_salesforce_id?
  }) =>
    `/alerts/site_config_params_by_salesforce_id${makeQueryString(
      params,
      true
    )}`,
  alertConfig: (alert_id) => `/alerts/config/${alert_id}`,
  alertConfigByType: (params: { entity_id: number; alert_type: string }) =>
    `/alerts/configs-by-type${makeQueryString(params, true)}`,
}

export const scheduler = {
  availability: (programSfid) =>
    `/admin/scheduler-api/programs/${programSfid}/availability`,
  fulfillment: (marketplace) =>
    `/admin/scheduler-api/marketplace/${marketplace}/fulfillment`,
  program: (programSfid: string) =>
    `/admin/scheduler-api/programs/${programSfid}`,
  marketResource: (market: string, orid: string) =>
    `/admin/scheduler-api/${market}/resource/${orid}`,
  programs: () => `/admin/scheduler-api/programs`,
  programStats: (programId, params) =>
    `/admin/scheduler-api/programs/${programId}/stats?${makeQueryString(
      params
    )}`,
  sites: (
    programId: string,
    params: { start_time?: string; end_time?: string }
  ) =>
    `/admin/scheduler-api/programs/${programId}/sites${makeQueryString(
      params,
      true
    )}`,
  siteRegistrations: (programSfid, siteSfid) =>
    `/admin/scheduler-api/programs/${programSfid}/sites/${siteSfid}`,
  site: (siteId: string, programSFID?: string) => {
    if (programSFID) {
      return `/admin/scheduler-api/sites/${siteId}?program_id=${programSFID}`
    }
    return `/admin/scheduler-api/sites/${siteId}`
  },
  drafts: {
    draftId: (draftId = '') => `/admin/scheduler-api/drafts/${draftId}`,
  },
  events: {
    programSiteEvents: ({
      programSfid,
      siteSfid,
      startTime,
      endTime,
    }: {
      programSfid?: string
      siteSfid: string
      startTime?: string
      endTime?: string
    }) =>
      `/admin/scheduler-api/sites/${siteSfid}/events${makeQueryString(
        {
          program_salesforce_id: programSfid,
          start_time: startTime,
          end_time: endTime,
        },
        true
      )}`,
    eventId: (eventId) => `/admin/scheduler-api/events/${eventId}`,
  },
  autoevents: {
    autoeventId: (eventId) => `/admin/scheduler-api/autoevents/${eventId}`,
  },
  manualSubmission: () => `/admin/scheduler-api/submit-offers`,

  exportZip: (programShortName, siteId, startTime) =>
    `/admin/scheduler-api/${programShortName}/export-offers?site_id=${siteId}&start_time=${startTime}`,
  calendarDataCsv: (programShortName, startTime, endTime) =>
    `/admin/scheduler-api/calendar-data.csv?program=${programShortName.toLowerCase()}&start_time=${startTime}&end_time=${endTime}`,
  submissionDataCsv: (programShortName, startTime, endTime) =>
    `/admin/scheduler-api/submission-data.csv?program=${programShortName.toLowerCase()}&start_time=${startTime}&end_time=${endTime}`,
  marketOffers: (programShortName, startTime, endTime, programSFID) => {
    const program_id_query = programSFID ? `&program_id=${programSFID}` : ''
    return `/admin/scheduler-api/${programShortName.toLowerCase()}/market-offers?start_time=${startTime}&end_time=${endTime}${program_id_query}`
  },
  bulkOverlaps: () => '/admin/scheduler-api/bulk/overlaps',
  bulkCreateEvents: () => '/admin/scheduler-api/bulk/events',
  bulkDeleteEvents: () => '/admin/scheduler-api/bulk/events/delete',
  importPjmData: () => '/admin/scheduler-api/pjm/import-data',
  uploadOffers: (market) => `/admin/scheduler-api/${market}/upload-offers`,
}

export const descheduler = {
  remove: () => '/admin/descheduler-api/remove',
}

export const markets = {
  baselineRules: () => '/markets/baseline-rules',
  programTree: (params?: { order_by?: string; order_dir?: string }) =>
    `/markets/program-tree?${makeQueryString(params)}`,
  programs: (params: { all?: boolean }) => {
    return `/markets/programs?${makeQueryString(params)}`
  },
  programsId: (id) => {
    return `/markets/programs/${id}`
  },
  programInfo: (programSfid, params) =>
    `/markets/programs/salesforce/${programSfid}${makeQueryString(
      params,
      true
    )}`,
  facilityInfo: (siteSfid) => `/markets/facilities/salesforce/${siteSfid}`,
  organizations: (name, include) => {
    const params = { name, include }
    const queryString = qs.stringify(params)
    return `/markets/organizations?${stringUtils.removeStartingIfExists(
      queryString,
      '?'
    )}`
  },
  dispatches: {
    acknowledge: (params) => {
      const queryString = qs.stringify(params)
      return `/markets/dispatches/acknowledge?${queryString}`
    },
  },
  users: (id) => `markets/users/${id}`,
  voc: {
    scheduledEvents: (dispatchId, portfolioId) => {
      const queryParams = qs.stringify({ portfolio_id: portfolioId })
      return `/markets/voc/scheduled-events/${dispatchId}?${queryParams}`
    },
  },
  utilities: () => '/markets/utilities',
  utilitySites: (utilitySfid) =>
    `/utilities/salesforce/${utilitySfid}/accounts/facilities`,
  sitesByUtilities: () => `/markets/utilities/sites`,
}

export const notes = {
  bySite: (id) => `/api/notes/site/${id}`,
  byUser: (id) => `/api/notes/user/${id}`,
}

export const realtimeEnergy = {
  series: () => '/api/v0.2/dashboard/series',
  facilitiesSearch: (params) =>
    `/api/v0.1/facility/search${makeQueryString(params, true)}`,
  filters: (params) => `/api/v0.1/powerbox/filter?${makeQueryString(params)}`,
  categories: () => `/api/v0.1/powerbox/category`,
  systemLoadsSearch: (params?) =>
    `/api/v0.1/iso/search${makeQueryString(params, true)}`,
  events: (params: {
    start_time: string
    end_time?: string | null
    program_type?: Array<string>
    exclude?: Array<string>
    program_market?: Array<string>
  }) => `/api/v0.1/events?${makeQueryString(params)}`,
  combinedLoads: (params?: { search?: string }) =>
    `/api/v0.1/combined-load${makeQueryString(params, true)}`,
  loadStreams: (params) => `/api/v0.2/load-streams?${qs.stringify(params)}`,
  loadAggregateStreams: () => `/api/v0.1/load-streams/aggregate`,
  loadStreamsMeta: () => `/api/v0.1/load-streams/metadata`,
  facilityBaselines: (params: {
    start?: string
    end?: string
    facility_id: Array<number>
  }) =>
    `/api/v0.1/facility-baselines/${makeQueryString(
      {
        ...params,
        // Endpoint is strict about date formats. It must look like `2021-01-01T00:00:00` and NOT `2021-01-01T00:00:00.000Z`
        start: params.start
          ? toISOString(params.start, { ignoreMilliseconds: true })
          : null,
        end: params.end
          ? toISOString(params.end, { ignoreMilliseconds: true })
          : null,
      },
      true
    )}`,
  baselines: (params: {
    facility_id: string | number
    event_id: string | number
  }) => `/api/v0.1/baseline?${qs.stringify(params)}`,
  facilityCardSiteInfo: (id) => `/api/v0.1/facility-info/${id}`,
  peaks: {
    historicalParameterized: (params) =>
      `/api/v0.2/dashboard/iso/peaks/historical?${qs.stringify(params)}`,
    toDate: (id) => `/api/v0.2/dashboard/iso/peaks/to-date/${id}`,
  },
  export: (params) => `/api/v0.1/export?${qs.stringify(params)}`,
  exportCsv: (params: {
    interval: number
    stream_ids: string
    start_time: string
    end_time: string
    combined_load_ids?: Array<string>
    tz: string
  }) =>
    `/api/v0.1/export-streams?${qs.stringify(params, {
      skipNull: true,
      skipEmptyString: true,
    })}`,
  requestJobStatus: (id) => `/request-job/status/${id}`,
}

export const v0_1 = {
  facilityEvents: (params: {
    facility_id: Array<number>
    start_time: string
    end_time: string
  }) => `/api/v0.1/facility-events${makeQueryString(params, true)}`,
  facilityAvailability: (params: {
    start_time: string
    end_time: string
    facility_id: Array<number>
  }) => `/api/v0.1/facility/availability${makeQueryString(params, true)}`,
  trackEvent: () => '/api/v0.1/track/',
  contractAddenda: {
    getDefs: () => '/api/v0.1/contract-addenda/',
    getPresignedUrl: (id) => `/api/v0.1/contract-addendum/${id}/download/`,
    getInternalPresignedUrl: (id) =>
      `/api/v0.1/contract-addendum/${id}/download-internal/`,
  },
  accountManagement: {
    usersPageData: (portfolioId) =>
      `/api/v0.1/account-management/users/${portfolioId}/`,
    accounts: () => '/api/v0.1/account-management/accounts',
    misoOffersEligibility: (id: number) =>
      `/api/v0.1/account-management/${id}/miso-lmr-offers/eligibility`,
    misoOffers: (id: number) =>
      `/api/v0.1/account-management/${id}/miso-lmr-offers`,
  },
  weather: {
    timeline: (params: {
      location: string
      start_time: string
      end_time: string
      include?: string
      elements?: string
      unitGroup?: 'us' | 'metric'
    }) => `/api/v0.1/weather/timeline${makeQueryString(params, true)}`,
  },
  events: () => `/api/v0.1/events`,
  hasActiveEvent: () => '/api/v0.1/has-active-event/',
  marketEventsPaginated: (params) =>
    `/api/v0.1/scheduled-events?${makeQueryString(params)}`,
  eventDetails: (eventId) => `/api/v0.1/event-details/${eventId}`,
  modifyEvent: (eventId) => `/api/v0.1/scheduled-event/${eventId}/`,
  modifyEventBulk: () => `/api/v0.1/scheduled-event`,
  modifyEventMetadata: (eventId) =>
    `/api/v0.1/scheduled-event/metadata/${eventId}/`,
  cancelEvent: (eventId) => `/api/v0.1/scheduled-event/${eventId}/cancel`,
  notifyParticipants: (eventId) =>
    `/api/v0.1/scheduled-event/${eventId}/notify`,
  notifyParticipantsBulk: () => `/api/v0.1/scheduled-event/notify`,
  portfolio: {
    one: (id) => `/api/v0.1/portfolio/${id}`,
    all: (params) => `/api/v0.1/portfolio?${makeQueryString(params)}`,
    create: () => '/api/v0.1/portfolio',
    users: (portfolioId) => `/api/v0.1/portfolio/${portfolioId}/users`,
    applications: (portfolioId) =>
      `/api/v0.1/portfolio/${portfolioId}/applications`,
    facilityContactsByPortfolio: (portfolioId) =>
      `/api/v0.1/portfolio/${portfolioId}/facility-contacts`,
    sites: (portfolioId) => `/api/v0.1/portfolio/${portfolioId}/facilities`,
    portfolioApplication: (portfolioId, applicationId) =>
      `/api/v0.1/portfolio/${portfolioId}/applications/${applicationId}`,
    portfolioUser: (portfolioId, userId) =>
      `/api/v0.1/portfolio/${portfolioId}/users/${userId}`,
    portfolioSite: (portfolioId, siteId) =>
      `/api/v0.1/portfolio/${portfolioId}/facilities/${siteId}`,
    earliestPerformance: (portfolioId) =>
      `/api/v0.1/portfolio/${portfolioId}/earliest-performance`,
  },
  application: {
    search: (params: {
      page?: number
      per_page?: number
      order_by?: string
      order_dir?: string
      search?: string
      name?: string
    }) => `/api/v0.1/applications?${makeQueryString(params)}`,
    detail: (applicationId: number) =>
      `/api/v0.1/applications/${applicationId}`,
    edit: (applicationId: number) => `/api/v0.1/applications/${applicationId}`,
    create: () => `/api/v0.1/applications`,
    portfolios: (applicationId: number) =>
      `/api/v0.1/applications/${applicationId}/portfolios`,
    createKey: (applicationId: number) =>
      `/api/v0.1/applications/${applicationId}/key`,
  },
  applicationKey: {
    edit: (applicationId: number, keyId: number) =>
      `/api/v0.1/applications/${applicationId}/key/${keyId}`,
  },
  user: {
    search: (params) => `/api/v0.1/user/?${makeQueryString(params)}`,
  },
  site: {
    search: (params) => `/api/v0.1/site/?${makeQueryString(params)}`,
  },
  organization: {
    search: (params) => `/api/v0.1/organization/?${makeQueryString(params)}`,
  },
  permissions: () => '/api/v0.1/permission',
  permissionsCheck: (params) =>
    `/api/v0.1/permission/check?${makeQueryString(params)}`,
  roles: () => '/api/v0.1/role',
  role: (id) => `/api/v0.1/role/${id}`,
  utilityAccountsByOrganization: (organizationId) =>
    `/api/v0.1/utility-account/organization/${organizationId}`,
  orids: (params: { program_ids?: Array<number>; orid?: string } = {}) =>
    `/api/v0.1/orids${makeQueryString(params, true)}`,
}

export const voc = {
  snapshots: {
    listDispatchSnapshots: (params) =>
      `/api/voc/snapshots/dispatches?${makeQueryString(params)}`,
    getPortfolioDetails: (params) =>
      `/api/voc/snapshots/portfolio-details?${makeQueryString(params)}`,
  },
}

export const iotManager = {
  getVoltlet: (clientId: string) =>
    `/api/iot-manager/2023-06-22/get/voltlet/${clientId}`,
  getConfig: (clientId: string) =>
    `/api/iot-manager/2023-06-22/get/voltlet/${clientId}/current-config`,
  getOutputStatusSpans: (
    clientId: string,
    params: Parameters<typeof makeQueryString>[0]
  ) =>
    `/api/iot-manager/2023-06-22/get/voltlet/${clientId}/output-status-spans?${makeQueryString(
      params
    )}`,
  getExpectedMsgsRecvRatio: (
    clientId: string,
    params: Parameters<typeof makeQueryString>[0]
  ) =>
    `/api/iot-manager/2023-06-22/get/voltlet/${clientId}/metrics/expected-msgs-recv-ratio?${makeQueryString(
      params
    )}`,
}

export default {
  auth,
  carbon,
  earnings,
  bankAccount,
  markets,
  notes,
  portfolio,
  portfolioDash,
  profile,
  programDetails,
  scheduler,
  siteContact,
  user,
  v0_1,
}
