/**
 * Type guard to remove null from a union type
 *
 * @param v - value to check
 * @returns passed in value cast to the DataType (i.e. null removed from its type definition)
 */
export const isNotNull = <DataType>(v?: DataType | null): v is DataType => {
  if (v === null || typeof v === 'undefined') {
    return false
  }

  return true
}
