import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '../Box'
import { Card } from '../Card'
import { Text } from '../Text'

type ForbiddenProps = {
  fallbackMessage?: string
  fallbackUrl?: string
  LinkComponent?:
    | ((linkProps: { href: string; to: string }) => JSX.Element)
    | typeof Link
}

export function Forbidden({
  fallbackUrl = '/',
  fallbackMessage = 'Return to main page',
  LinkComponent = Link,
}: ForbiddenProps) {
  return (
    <Box width="100%" maxWidth={750} ml="auto" mr="auto" mt={4}>
      <Card>
        <Card.Content>
          <Text.Headline mb={2} textSize="medium">
            Permission Denied
          </Text.Headline>
          <Text.Paragraph mb={2}>
            Sorry, you don&apos;t have permission to view this resource. Please
            verify that you are logged into the correct account and try again.
          </Text.Paragraph>
          <LinkComponent href={fallbackUrl} to={fallbackUrl}>
            {fallbackMessage}
          </LinkComponent>
        </Card.Content>
      </Card>
    </Box>
  )
}
