import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { network, endpoints } from '@voltus/network'
import { Dispatches } from '../../../routes/dispatches/routes/pastPerformance/types'

const getPastDispatches = async (
  portfolioId: number,
  startDate: string,
  endDate: string
) => {
  return await network.get<{ dispatches: Dispatches }>(
    endpoints.oldEarnings.dispatches(portfolioId, startDate, endDate)
  )
}

export const useGetPastDispatches = ({
  enabled,
  portfolioId,
  startDate,
  endDate,
}: {
  enabled: boolean
  portfolioId: number
  startDate: string
  endDate: string
}): UseQueryResult<{ dispatches: Dispatches }> =>
  useQuery({
    queryKey: ['pastDispatches', portfolioId, startDate, endDate],
    refetchOnWindowFocus: false,
    queryFn: () => getPastDispatches(portfolioId, startDate, endDate),
    enabled,
  })
