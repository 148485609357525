import { END, eventChannel } from 'redux-saga'
import { all, call, take, put, takeLatest } from 'redux-saga/effects'
import logger, { debugLogger } from '@voltus/logger'
import {
  initializeConversationEventStream,
  initializeMessageNotificationEventStream,
} from '../../api/smsClient'
import { SmsTypes, SmsActions } from './sms.redux'

const channels = {}
const createConversationEventStreamChannel = (userId, lastSeenMessageId) => {
  const channel = eventChannel((emitter) => {
    const client = initializeConversationEventStream({
      userId,
      lastSeenMessageId,
      onMessage: emitter,
      onEnd: () => {
        emitter(END)
      },
    })
    return () => {
      debugLogger.info('Closing SMS Conversation event stream')
      channels[userId] = null
      client.close()
    }
  })
  channels[userId] = channel
  return channel
}

function* bootstrapConversationEventStreamForUserId({
  userId,
  lastSeenMessageId,
}) {
  try {
    let channel
    if (channels[userId]) {
      channel = channels[userId]
    } else {
      channel = yield call(
        createConversationEventStreamChannel,
        userId,
        lastSeenMessageId
      )
    }

    while (true) {
      const data = yield take(channel)
      const { messagesList, typingStatus } = data
      debugLogger.info('SMS Conversation event stream msg', data)

      if (messagesList) {
        yield put(SmsActions.receiveConversationMessages(userId, messagesList))
      }
      if (typingStatus) {
        yield put(
          SmsActions.receiveConversationTypingStatus(
            userId,
            typingStatus.userId
          )
        )
      }
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

function* resetConversationEventStreamForUserId({ userId }) {
  try {
    if (channels[userId]) {
      yield channels[userId].close()
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

let messageNotificationEventStream

const createMessageNotificationEventStreamChannel = () => {
  messageNotificationEventStream = eventChannel((emitter) => {
    const client = initializeMessageNotificationEventStream({
      onMessage: emitter,
      onEnd: () => {
        emitter(END)
      },
    })
    return () => {
      debugLogger.info('Closing Message Notification event stream')
      messageNotificationEventStream = null
      client.close()
    }
  })
  return messageNotificationEventStream
}

function* bootstrapMessageNotificationEventStream() {
  try {
    let channel
    if (messageNotificationEventStream) {
      channel = messageNotificationEventStream
    } else {
      channel = yield call(createMessageNotificationEventStreamChannel)
    }

    while (true) {
      const data = yield take(channel)
      const { fieldList } = data
      debugLogger.info('Message Notification event stream msg', data)

      if (fieldList) {
        yield put(SmsActions.receiveMessageNotifications(fieldList))
      }
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

function* resetMessageNotificationEventStream() {
  try {
    if (messageNotificationEventStream) {
      yield messageNotificationEventStream.close()
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

export function* watchSms(): Generator {
  yield all([
    takeLatest(
      // eslint-disable-next-line
      // @ts-ignore
      SmsTypes.BOOTSTRAP_CONVERSATION_EVENT_STREAM,
      bootstrapConversationEventStreamForUserId
    ),
    takeLatest(
      // eslint-disable-next-line
      // @ts-ignore
      SmsTypes.RESET_CONVERSATION_EVENT_STREAM,
      resetConversationEventStreamForUserId
    ),
    takeLatest(
      SmsTypes.BOOTSTRAP_MESSAGE_NOTIFICATION_EVENT_STREAM,
      bootstrapMessageNotificationEventStream
    ),
    takeLatest(
      SmsTypes.RESET_MESSAGE_NOTIFICATION_EVENT_STREAM,
      resetMessageNotificationEventStream
    ),
  ])
}
