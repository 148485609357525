export const MONTHS = {
  SHORT_NAMES: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
}

// YEAR_MONTH_DAY_DASHES is meant to be used in URLs
// MONTHNAME_DAY_YEAR is the default date format
// MONTHNAME_YEAR_DATE_FNS is default when we don't need information about the day (e.g. labels in vertical chart in Cashdash)
// MONTHNAME_DAY_YEAR_TIME is default when we need information about time (e.g. PastPerformance table)
// CHART_DATA is date format of data received for Vertical Bar Chart in Cashdash

// constants with DATE_FNS ending are using format from date-fns library
// constants without it are using format from moment library
// the goal is to switch from moment to only using date-fns - then we can remove the DATE_FNS endings and moment formats

export const DATE_FORMAT = {
  YEAR_MONTH_DAY_DASHES_DATE_FNS: 'yyyy-MM-dd',
  YEAR_MONTH_DAY_HOUR_MINUTE_DASHES_DATE_FNS: 'yyyy-MM-dd HH:mm',
  YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS_DASHES_DATE_FNS: 'yyyy-LL-dd HH:mm:ss',
  YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS_TIMEZONE_DASHES_DATE_FNS:
    'yyyy-LL-dd HH:mm:ss z',
  YEAR_MONTH_DAY_DASHES: 'YYYY-MM-DD',
  MONTHNAME_DAY_YEAR_DATE_FNS: 'MMM d, yyyy',
  WEEKDAY_MONTHNAME_DAY_YEAR_DATE_FNS: 'EEE, MMM d, yyyy',
  MONTHNAME_DAY_YEAR: 'MMM D, YYYY',
  MONTHNAME_YEAR_DATE_FNS: 'MMM yyyy',
  MONTHNAME_DAY_YEAR_TIME_DATE_FNS: 'MMM d, yyyy h:mm aaa',
  MONTHNAME_DAY_YEAR_TIME: 'MMM D, YYYY h:mma',
  MONTHNAME_DAY_TIME_DATE_FNS: 'MMM d, hh:mm aaa',
  MONTHNAME_DAY_24_HOUR_TIME_DATE_FNS: 'MMM dd, yyyy HH:mm:ss',
  DATE_SLASHES_MINUTE_HOUR_SECONDS_DATE_FNS: 'MM/dd/yyyy HH:mm:ss',
  CHART_DATA: 'YYYY-M',
  ISO_8601_DATE_FNS: "yyyy-MM-dd'T'HH:mm:ssxxx",
  ISO_8601_NO_TIMEZONE_DATE_FNS: "yyyy-MM-dd'T'HH:mm:ss'Z'",
  HOUR_MINUTE: 'h:mmaaa',
  HOUR_MINUTE_SECONDS_DATE_FNS: 'HH:mm:ss zzz',
  MONTH_DAY_YEAR_TIME_DATE_FNS: 'M/dd/yy h:mmaaa',
} as const

export const ONE_SECOND_MS = 1000
export const ONE_MINUTE_S = 60
// Typescript doesn't have type level arithmetic, so can't do things like ONE_MINUTE_MS * 60
// Otherwise these types will have type = number, not a literal type
export const ONE_MINUTE_MS = 60000
export const HALF_HOUR_MS = 1.8e6
export const TEN_MINUTES_MS = 600000
export const FIFTEEN_MINUTES_MS = 900000
export const ONE_HOUR_MS = 3.6e6
export const ONE_DAY_MS = 8.64e7
