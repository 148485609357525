import get from 'lodash/get'
import { createSelector } from 'reselect'
import { selectDispatchId } from '../routeParams'

const rootPath = ['dispatches', 'siteAssignments']

export const selectAssignments = (state) => get(state, rootPath).assignments

export const selectSiteAssignments = createSelector(
  [selectAssignments, selectDispatchId],
  (assignments, dispatchId) => {
    return assignments[dispatchId] ?? {}
  }
)

export const selectLoadingStateDomain = (state) =>
  get(state, rootPath).loadingState
export const selectLoadingState = createSelector(
  [selectLoadingStateDomain, selectDispatchId],
  (state, dispatchId) => state[dispatchId]
)
export const selectAssignmentsInFlight = (state) =>
  get(state, rootPath).inFlight
