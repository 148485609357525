import { Any, TreeNestedDropdownOption } from '@voltus/types'

export type SiteSwitcherOption<ValueType = Any> =
  TreeNestedDropdownOption<ValueType>

export type NormalizedOption<ValueType> = ValueType extends object
  ? ValueType &
      Omit<SiteSwitcherOption<ValueType>, 'options'> & {
        childOptions?: Array<NormalizedOption<ValueType>>
      }
  : Omit<SiteSwitcherOption<ValueType>, 'options'> & {
      childOptions?: Array<NormalizedOption<ValueType>>
    }

export enum OptionConfig {
  Flat = 'flat',
  Tree = 'tree',
  Group = 'group',
}
