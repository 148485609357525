import produce from 'immer'
import { call, put, all, takeEvery } from 'redux-saga/effects'
import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import { LOADING_STATES } from '../constants'
import { handleFetchCheckIns, handleFetchUsers } from './checkin'
import rootSelector from './rootSelector'
import { handleFetchAssignments } from './siteAssignments'

export const { Types: BootstrapAdminTypes, Creators: BootstrapAdminCreators } =
  createActions(
    {
      bootstrap: ['dispatchId'],
      loadingStates: ['dispatchId', 'loadingState'],
    },
    {
      prefix: 'DISPATCHES/ADMIN/BOOTSTRAP/',
    }
  )

const INITIAL_STATE = {
  loadingStates: {},
}

const handleBootstrap = (state, { dispatchId }) => {
  return produce(state, (draftState) => {
    draftState.loadingStates[dispatchId] = LOADING_STATES.LOADING
  })
}

const handleLoadingStates = (state, { dispatchId, loadingState }) => {
  return produce(state, (draftState) => {
    draftState.loadingStates[dispatchId] = loadingState
  })
}

export const bootstrapReducer = createReducer(INITIAL_STATE, {
  [BootstrapAdminTypes.BOOTSTRAP]: handleBootstrap,
  [BootstrapAdminTypes.LOADING_STATES]: handleLoadingStates,
})

function* handleBootstrapAdmin({ dispatchId }) {
  yield all([
    call(handleFetchAssignments, { dispatchId }),
    call(handleFetchUsers, { dispatchId }),
    call(handleFetchCheckIns, { dispatchId }),
  ])
  yield put(
    BootstrapAdminCreators.loadingStates(dispatchId, LOADING_STATES.READY)
  )
}

export function* watchBootstrapAdmin() {
  yield takeEvery(BootstrapAdminTypes.BOOTSTRAP, handleBootstrapAdmin)
}

export const selectBootstrapAdminDomain = createSelector(
  rootSelector,
  (state) => state.bootstrapAdmin
)

export const selectBootstrapAdminLoadingStates = createSelector(
  selectBootstrapAdminDomain,
  (state) => state.loadingStates
)
