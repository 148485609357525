import { PARTICIPATION_STATUS } from '../constants'
import { aggregateSitesByParticipationStatus } from './siteUtils'

/**
 * Utils meant to be used with snapshot data
 * Filters, sorts, and aggregates sites for various use cases
 */

/**
 * Break up sites into buckets keyed by their participation status
 */
export const aggregateSnapshotSitesByParticipationStatus = (snapshot) => {
  return aggregateSitesByParticipationStatus(snapshot?.data?.facilityDispatch)
}

/**
 * Return only those sites that match a given participation status
 * Operates on a snapshot
 */
export const getSnapshotSitesByParticipationStatus = (snapshot, status) => {
  const { confirmedSites, rejectedSites, notRespondedSites } =
    aggregateSnapshotSitesByParticipationStatus(snapshot)

  switch (status) {
    case PARTICIPATION_STATUS.CONFIRMED:
      return confirmedSites
    case PARTICIPATION_STATUS.REJECTED:
      return rejectedSites
    case PARTICIPATION_STATUS.NOT_RESPONDED:
    default:
      return notRespondedSites
  }
}

/**
 * Helper to append facilityTargets and facilitySparklines
 * to a site object
 */
export const addTargetsToSite = (snapshot) => {
  if (!snapshot) {
    return []
  }

  const { facilityDispatch, facilityTargets, facilitySparklines } =
    snapshot.data
  return facilityDispatch.map((site) => {
    site.targets = facilityTargets[site.facilityId]
    site.sparklines = facilitySparklines[site.facilityId]
    return site
  })
}
export const addPinnedToSites = (sites, pinned) =>
  sites.map((site) => {
    const isPinned = pinned[site.id]
    site.pinned = isPinned
    return site
  })

/**
 * Get the associated list of facilities for a given snapshot
 *
 * @param {Snapshot} snapshot
 * @param {number} userId
 */
export const getSitesForUser = (snapshot, userId) => {
  return snapshot.data.facilityDispatch.filter((site) => {
    return site.acknowledgments.some((userAck) => {
      return userAck.userId === userId
    })
  })
}
