import get from 'lodash/get'
import { createSelector } from 'reselect'
import { selectProfile } from '@voltus/modules'
import { isUserOnACall } from '../../utils/callingUtils'

const rootPath = ['dispatches', 'calling']

export const selectCalls = (state) => get(state, rootPath).calls
export const selectCallStatuses = (state) => get(state, rootPath).callStatuses
export const selectAllDispatchCalls = createSelector(selectCalls, (calls) => {
  return Object.values(calls).reduce((acc, eventCalls) => {
    acc = acc.concat(Object.values(eventCalls))
    return acc
  }, [])
})
export const makeSelectActiveCallForUser = createSelector(
  selectAllDispatchCalls
)
export const selectMyActiveCall = createSelector(
  selectAllDispatchCalls,
  selectProfile,
  (allCalls, profile) => {
    return isUserOnACall(allCalls, profile?.id)
  }
)
