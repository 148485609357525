import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'
import { LOADING_STATES } from '../../constants'

export const { Types: CheckInTypes, Creators: CheckInCreators } = createActions(
  {
    checkIn: ['payload'],
    receiveCheckInFailure: null,
    receiveCheckIn: ['userDispatchState'],

    fetchCheckInStatuses: ['dispatchId'],
    receiveCheckInStatuses: ['checkins'],

    fetchUsers: null,
    receiveUsers: ['users'],

    loadingStates: ['loadingStates'],
  },
  {
    prefix: 'DISPATCHES/CHECKINS/',
  }
)

const initialState = {
  loadingStates: {
    checkingIn: LOADING_STATES.INITIAL,
    checkInStatuses: LOADING_STATES.INITIAL,
    users: LOADING_STATES.INITIAL,
  },
}

const handleCheckIn = (state, { userDispatchState }) => {
  const { scheduledEventId, userId } = userDispatchState
  return produce(state, (draftState) => {
    draftState.loadingStates.checkingIn = LOADING_STATES.READY
    if (draftState[scheduledEventId]) {
      draftState[scheduledEventId][userId] = userDispatchState
    } else {
      draftState[scheduledEventId] = {
        [userId]: userDispatchState,
      }
    }
  })
}

const handleReceiveCheckIns = (state, { checkins }) => {
  return produce(state, (draftState) => {
    checkins.forEach((checkin) => {
      const { scheduledEventId, userId } = checkin
      if (draftState[scheduledEventId]) {
        draftState[scheduledEventId][userId] = checkin
      } else {
        // Dispatch doesn't yet exist in the new state,
        // so add it, along with the new user
        draftState[scheduledEventId] = {
          [userId]: checkin,
        }
      }
    })
  })
}

const handleReceiveUsers = (state, { users }) => {
  return produce(state, (draftState) => {
    draftState.users = users
  })
}

const handleLoadingStates = (state, { loadingStates }) => {
  return produce(state, (draftState) => {
    Object.entries(loadingStates).forEach(([key, loadingState]) => {
      draftState.loadingStates[key] = loadingState
    })
  })
}

const handleStartCheckIn = (state) => {
  return produce(state, (draftState) => {
    draftState.loadingStates.checkingIn = LOADING_STATES.LOADING
  })
}

const handleCheckInFailure = (state) => {
  return produce(state, (draftState) => {
    draftState.loadingStates.checkingIn = LOADING_STATES.ERROR
  })
}

export const checkInReducer = createReducer(initialState, {
  [CheckInTypes.CHECK_IN]: handleStartCheckIn,
  [CheckInTypes.RECEIVE_CHECK_IN]: handleCheckIn,
  [CheckInTypes.RECEIVE_CHECK_IN_FAILURE]: handleCheckInFailure,
  [CheckInTypes.RECEIVE_CHECK_IN_STATUSES]: handleReceiveCheckIns,
  [CheckInTypes.RECEIVE_USERS]: handleReceiveUsers,
  [CheckInTypes.LOADING_STATES]: handleLoadingStates,
})
