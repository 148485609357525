import React from 'react'
import { call, put, takeEvery, all } from 'redux-saga/effects'
import { Toast } from '@voltus/core-components'
import logger from '@voltus/logger'
import * as vocAdminClient from '../../api/vocAdminClient'
import { LOADING_STATES } from '../../constants'
import {
  SiteAssignmentsTypes,
  SiteAssignmentsCreators,
} from './siteAssignments.redux'

function* handleAssignSites({ payload }) {
  const {
    isEscalation,
    userId,
    dispatchId,
    sites,
    reason,
    reasonBody,
    onSuccess,
    onFailure,
  } = payload
  try {
    yield put(
      SiteAssignmentsCreators.assignmentsInFlight(
        sites.map((s) => ({
          siteId: s.id,
          inFlight: true,
        }))
      )
    )

    const res = yield call(vocAdminClient.assignSites, {
      scheduledEventId: dispatchId,
      userId,
      sites,
      reason,
      reasonBody,
      isEscalation,
    })
    yield put(
      SiteAssignmentsCreators.receiveSiteAssignments(
        res.reduce((assignments, curr) => {
          assignments.push(curr.assignment)
          return assignments
        }, [])
      )
    )
    onSuccess?.()
  } catch (e) {
    onFailure?.()
    Toast.push(<Toast.Error>Something went wrong.</Toast.Error>)
    if (e.message) {
      logger.report.error(e.message)
    }
    yield put(
      SiteAssignmentsCreators.assignmentsInFlight(
        sites.map((s) => ({
          siteId: s.facilityId,
          inFlight: false,
        }))
      )
    )
  }
}

export function* handleFetchAssignments({ dispatchId }) {
  try {
    yield put(
      SiteAssignmentsCreators.loadingState(dispatchId, LOADING_STATES.LOADING)
    )

    const assignments = yield call(vocAdminClient.getAssignments, dispatchId)
    yield put(
      SiteAssignmentsCreators.receiveSiteAssignments(
        assignments.assignmentsList
      )
    )
    yield put(
      SiteAssignmentsCreators.loadingState(dispatchId, LOADING_STATES.READY)
    )
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    Toast.push(<Toast.Error>Something went wrong.</Toast.Error>)
    yield put(
      SiteAssignmentsCreators.loadingState(dispatchId, LOADING_STATES.ERROR)
    )
  }
}

function* setAssignmentInFlightStatus({ assignments }) {
  yield put(
    SiteAssignmentsCreators.assignmentsInFlight(
      assignments.map((a) => ({
        siteId: a.facilityId,
        inFlight: false,
      }))
    )
  )
}

function* handleUnassignSites({ payload }) {
  const { userId, dispatchId, sites, onSuccess, onFailure } = payload
  try {
    yield put(
      SiteAssignmentsCreators.assignmentsInFlight(
        sites.map((s) => ({
          siteId: s.facilityId,
          inFlight: true,
        }))
      )
    )

    const res = yield call(vocAdminClient.unassignSites, {
      scheduledEventId: dispatchId,
      userId,
      sites,
    })

    yield put(
      SiteAssignmentsCreators.receiveSiteAssignments(
        res.reduce((assignments, curr) => {
          assignments.push(curr.assignment)
          return assignments
        }, [])
      )
    )

    onSuccess?.()
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    Toast.push(<Toast.Error>Something went wrong.</Toast.Error>)
    onFailure?.()
    yield put(
      SiteAssignmentsCreators.assignmentsInFlight(
        sites.map((s) => ({
          siteId: s.facilityId,
          inFlight: false,
        }))
      )
    )
  }
}

export function* watchSiteAssignments() {
  yield all([
    takeEvery(
      SiteAssignmentsTypes.RECEIVE_SITE_ASSIGNMENTS,
      setAssignmentInFlightStatus
    ),
    takeEvery(
      SiteAssignmentsTypes.FETCH_SITE_ASSIGNMENTS,
      handleFetchAssignments
    ),
    takeEvery(SiteAssignmentsTypes.ASSIGN_SITES, handleAssignSites),
    takeEvery(SiteAssignmentsTypes.UNASSIGN_SITES, handleUnassignSites),
  ])
}
