import produce from 'immer'
import setWith from 'lodash/setWith'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: SmsTypes, Creators: SmsActions } = createActions({
  bootstrapConversationEventStream: ['userId', 'lastSeenMessageId'],
  resetConversationEventStream: ['userId'],
  receiveConversationMessages: ['userId', 'messagesList'],
  receiveMessageNotifications: ['messagesList'],
  receiveConversationTypingStatus: ['conversationUserId', 'typingUserId'],
  bootstrapMessageNotificationEventStream: [],
  resetMessageNotificationEventStream: [],
})

const INITIAL_STATE = {
  conversations: {},
}

const handleReceiveConversationMessages = produce(
  (draft, { userId, messagesList }) => {
    const existingMessagesList = draft.conversations?.[userId]?.messages
    if (existingMessagesList) {
      setWith(
        draft,
        ['conversations', userId, 'messages'],
        existingMessagesList.concat(messagesList),
        Object
      )
    } else
      setWith(
        draft,
        ['conversations', userId, 'messages'],
        messagesList,
        Object
      )
  }
)

const handleReceiveMessageNotifications = produce((draft, { messagesList }) => {
  messagesList.forEach((message) => {
    const { userId } = message
    const existingMessagesList = draft.conversations?.[userId]?.messages

    if (existingMessagesList) {
      setWith(
        draft,
        ['conversations', userId, 'messages'],
        existingMessagesList.concat([message]),
        Object
      )
    } else
      setWith(draft, ['conversations', userId, 'messages'], [message], Object)
  })
})

const handleReceiveConversationTypingStatus = (
  state,
  { conversationUserId, typingUserId }
) => {
  return produce(state, (draftState) => {
    setWith(
      draftState,
      ['conversations', conversationUserId, 'typingStatus'],
      { timestamp: new Date().getTime(), userId: typingUserId },
      Object
    )
  })
}

export const smsReducer = createReducer(INITIAL_STATE, {
  [SmsTypes.RECEIVE_CONVERSATION_MESSAGES]: handleReceiveConversationMessages,
  [SmsTypes.RECEIVE_MESSAGE_NOTIFICATIONS]: handleReceiveMessageNotifications,
  [SmsTypes.RECEIVE_CONVERSATION_TYPING_STATUS]:
    handleReceiveConversationTypingStatus,
})
