import {
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
  isPast,
  isSameDay,
  setSeconds,
} from 'date-fns'
import { uniqBy } from 'lodash'
import { SiteSwitcherOption } from '@voltus/core-components'
import { arrayUtils } from '@voltus/utils'
import { DISPATCH_TYPES } from '../constants'
import { DISPATCH_STATUS } from '../constants'
import {
  DispatchGroup,
  DispatchSnapshotWithPortfolios,
} from '../types/dispatch'
/**
 * Generic utils to handle getting data
 * from the raw dispatches list - stored in the redux store
 * under dispatches.list
 */

/**
 * Operates on a dispatches list of the shape generated
 * by `selectDispatches` in modules/dispatch.selectors.js
 */
export const findActiveDispatch = (dispatches, portfolioId, dispatchId) => {
  // it's possible that dispatchId or portfolioId can come
  // in as strings, so try to coerce everything to integers
  // so the comparison works as expected
  return dispatches
    .find((d) => +d.dispatchId === +dispatchId)
    ?.portfolios.find((p) => +p.portfolioId === +portfolioId)
}

/**
 * Used to collect portfolio options for the SiteSwitcher in detail page
 * returns a list of `{ label: 'Portfolio', value: 'value' }` objects
 */
export const getPortfolioOptions = (
  active: Array<DispatchSnapshotWithPortfolios> = [],
  historical: Array<DispatchSnapshotWithPortfolios> = [],
  dispatchId
): Array<SiteSwitcherOption<number | null>> => {
  const getOpts = (dispatches: Array<DispatchSnapshotWithPortfolios>) => {
    return uniqBy(
      dispatches
        .filter((d) => {
          const dids = arrayUtils.ensureArray(dispatchId).map((did) => +did)
          return dids.includes(+d.dispatchId)
        })
        .map((dispatch) =>
          dispatch.portfolios.map((p) => ({
            value: p.id,
            label: p.name,
          }))
        )
        .reduce((acc, dispatchPortfolios) => {
          return [...acc, ...dispatchPortfolios]
        }, []),
      'value'
    )
  }
  return [
    {
      label: 'Active',
      value: null,
      isHeader: true,
      options: getOpts(active),
    },
    {
      label: 'Recently Ended',
      value: null,
      isHeader: true,
      options: getOpts(historical),
    },
  ]
}

/**
 * Used to collect dispatch options for the SiteSwitcher in detail page
 * returns a list of `{ label: 'Portfolio', value: 'value' }` objects
 */
export const getDispatchesOptions = (active, historical, portfolioId) => {
  const getOpts = (dispatches) => {
    return (
      dispatches
        .reduce((options, dispatch) => {
          // If a dispatch matches the portfolio we're looking at, grab it
          if (dispatch.portfolios.find((p) => +p.id === +portfolioId)) {
            options.push(dispatch)
          }

          return options
        }, [])
        .map((dispatch) => ({
          value: dispatch.dispatchId,
          label: dispatch.program.name,
          portfolioId: dispatch.portfolioId,
        })) ?? []
    )
  }
  return [
    {
      label: 'Active',
      isHeader: true,
      value: 'currently-active',
      options: getOpts(active),
    },
    {
      label: 'Recently Ended',
      isHeader: true,
      value: 'recently-ended',
      options: getOpts(historical),
    },
  ]
}

export const getDispatchesGroupOptions = (active, historical) => {
  const getOpts = (dispatches) => {
    return dispatches.reduce((options, dispatch) => {
      // const dispatchesInPortfolio
      if (dispatch.dispatches.length > 1) {
        // we have a group! tack on a parent option that points to the group
        options.push({
          label: `${dispatch.program.name} (Aggregate)`,
          value: dispatch.dispatches
            .map((d) => d.dispatchId)
            .sort()
            .join(','),
        })
        for (const d of dispatch.dispatches) {
          options.push({
            label: d.program.name,
            value: `${d.dispatchId}`,
          })
        }
      } else {
        options.push({
          label: dispatch.program.name,
          value: dispatch.dispatches
            .map((d) => d.dispatchId)
            .sort()
            .join(','),
        })
      }
      return options
    }, [])
  }
  return [
    {
      label: 'Active',
      isHeader: true,
      value: 'currently-active',
      options: getOpts(active),
    },
    {
      label: 'Recently Ended',
      isHeader: true,
      value: 'recently-ended',
      options: getOpts(historical),
    },
  ]
}

/**
 * Returns true if the dispatch is a test
 */
export const isTestDispatch = (dispatchType) => {
  return (
    dispatchType === DISPATCH_TYPES.ACCEPTANCE_TEST ||
    dispatchType === DISPATCH_TYPES.COMMUNICATIONS_TEST ||
    dispatchType === DISPATCH_TYPES.CURTAILMENT_TEST
  )
}

export const isVoluntaryDispatch = (dispatchType) => {
  return dispatchType === DISPATCH_TYPES.VOLUNTARY
}

type MaybeHasEndTime = {
  endTime?: string
}

export const isDispatchOver = (d: MaybeHasEndTime) => {
  return d.endTime && isPast(new Date(d.endTime))
}

/**
 * Returns copy for a given dispatch type to be
 * rendered in a notification at the top of the detail
 * page for tests.
 */
export const getDispatchTestMessage = (dispatchType) => {
  switch (dispatchType) {
    case DISPATCH_TYPES.ACCEPTANCE_TEST:
      return 'This is a dispatch verification test'
    case DISPATCH_TYPES.COMMUNICATIONS_TEST:
      return 'This is a communications test'
    case DISPATCH_TYPES.CURTAILMENT_TEST:
      return 'This is a hardware integration test'
  }
}

/**
 * Determine if the dispatch has yet to start,
 * has started or has ended based
 * on the current time, and the dispatch start/end times.
 */
export const getDispatchStatus = ({ startTime, endTime }) => {
  const now = new Date()

  if (endTime && isBefore(new Date(endTime), now)) {
    return DISPATCH_STATUS.PAST
  }

  if (isAfter(new Date(startTime), now)) {
    return DISPATCH_STATUS.UPCOMING
  }

  return DISPATCH_STATUS.ACTIVE
}

export const getDispatchDate = (dispatch) => {
  const { startTime, endTime } = dispatch

  if (isSameDay(new Date(startTime), new Date(endTime))) {
    return `${format(new Date(startTime), 'LLLL d, yyyy h:mm a')} - ${format(
      new Date(endTime),
      'h:mma'
    )}`
  }

  if (!endTime) {
    return `${format(new Date(startTime), 'LLLL d, yyyy h:mm a')} - TBD `
  }
  return `${format(new Date(startTime), 'LLLL d, yyyy h:mm a')} - ${format(
    new Date(endTime),
    'LLLL d, yyyy h:mm a'
  )} `
}

export const flattenDispatchGroups = (
  dispatchGroups: Array<DispatchGroup>
): Array<DispatchSnapshotWithPortfolios> => {
  return dispatchGroups.reduce(
    (dispatches: Array<DispatchSnapshotWithPortfolios>, group) => {
      dispatches = [...dispatches, ...group.dispatches]
      return dispatches
    },
    []
  )
}

// Reduce a DispatchGroup based on the current portfolio id
export function filterGroupByPortfolio(
  group: Array<DispatchGroup>,
  portfolioId
): Array<DispatchGroup> {
  return group.reduce((newGroup: Array<DispatchGroup>, item: DispatchGroup) => {
    const dispatchesInPortfolio = item.dispatches.filter((dispatch) =>
      dispatch.portfolios.some((p) => p.portfolioId === portfolioId)
    )
    if (dispatchesInPortfolio.length) {
      newGroup.push({
        ...item,
        dispatches: dispatchesInPortfolio,
      })
    }
    return newGroup
  }, [])
}

export enum OrderBy {
  ASC,
  DESC,
}
export function groupDispatches(
  dispatches: Array<DispatchSnapshotWithPortfolios> = [],
  orderBy: OrderBy = OrderBy.ASC
): Array<DispatchGroup> {
  // Group dispatches into five minute intervals.
  const DISPATCH_INTERVAL = 5

  // Deep clone the dispatches so we can sort them.
  const sortedDispatches = JSON.parse(JSON.stringify(dispatches))
  // Always sort dispatches in ascending order (oldest first).
  // This ensures that we're rolling up with the earliest start times.
  sortedDispatches.sort(
    (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime)
  )

  // For every dispatch in sortedDispatches
  // Put the dispatch into an array inside of a dictionary, keyed by programId and eventType
  // When adding a dispatch to the dictionary, check if it is within five minutes of the first item of the last array.
  // If it is, append it to the array, otherwise, create a new array.
  const dispatchesDict: {
    [key: string]: Array<Array<DispatchSnapshotWithPortfolios>>
  } = {}
  for (const d of sortedDispatches) {
    const key = `${d.program.programId}-${d.eventType}`
    if (!Array.isArray(dispatchesDict[key])) {
      dispatchesDict[key] = [[d]]
    } else {
      const groups = dispatchesDict[key]
      const group = groups[groups.length - 1]
      // We don't show seconds in the UI, so we drop them here.
      // If we didn't do this, then an event that's one second out of the
      // interval would create a new roll-up, which would look like a bug.
      const groupStartTime = setSeconds(new Date(group[0].startTime), 0)
      const dispatchStartTime = setSeconds(new Date(d.startTime), 0)
      const difference = differenceInMinutes(dispatchStartTime, groupStartTime)
      if (Math.abs(difference) <= DISPATCH_INTERVAL) {
        group.push(d)
      } else {
        groups.push([d])
      }
    }
  }

  // Flatten the dictionary into an array of arrays, and sort the arrays by startTime.
  // We do this because an entry in the dictionary may contain multiple dispatch groups
  // with different start times.
  //
  // For example, you might have a Voltus, Market Dispatch entry, with two groups.
  // The first group starts at 10:00am, the second group starts at 02:00pm.
  // You might also have an ERCOT, Curtailment Test entry, with one group that
  // starts at 11:00am.
  // The final ascending sort order of the groups should be:
  // - Voltus, Market Dispatch, 10:00am
  // - ERCOT, Curtailment Test, 11:00am
  // - Voltus, Market Dispatch, 02:00pm
  const groups = Object.values(dispatchesDict).flat()
  groups.sort((a, b) => {
    if (orderBy === OrderBy.ASC) {
      return Date.parse(a[0].startTime) - Date.parse(b[0].startTime)
    }

    return Date.parse(b[0].startTime) - Date.parse(a[0].startTime)
  })

  // Turn the groups into their final form.
  // Here we're just upleveling information like the program and start/end times
  // so we can display them easily in the UI.
  const dispatchGroups: Array<DispatchGroup> = []
  for (const group of groups) {
    const item = group[0]
    const dispatchGroup: DispatchGroup = {
      program: item.program,
      key: `${item.program.programId}-${item.eventType}-${item.startTime}`,
      startTime: item.startTime,
      dispatches: group.slice(),
    }

    // If there are multiple end times in a group, use whichever one is the latest.
    // If there are no end times, then use undefined. This will render as "TBD" in the UI.
    let lastEndTime = 0
    for (const g of group) {
      if (g.endTime && new Date(g.endTime).getTime() > lastEndTime) {
        lastEndTime = new Date(g.endTime).getTime()
      }
      dispatchGroup.endTime =
        lastEndTime > 0 ? new Date(lastEndTime).toISOString() : undefined
    }

    dispatchGroups.push(dispatchGroup)
  }
  return dispatchGroups
}
