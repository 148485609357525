import { select, call, takeEvery, all, put } from 'redux-saga/effects'
import { EVENTS, eventTracker, PROPERTIES } from '@voltus/event-tracking'
import logger from '@voltus/logger'
import {
  createTextNote,
  getSiteNotes,
  getSiteContactNotes,
} from '../../api/vocAdminClient'
import { LOADING_STATES, NUM_DISPATCH_NOTES_PER_PAGE } from '../../constants'
import { transformEventStreamToNoteTypes } from '../../utils'
import { NotesTypes, NotesCreators } from './notes.redux'
import { selectNotesLoadingStates } from './notes.selectors'

function* getNotesBySite({ siteId, pageKey }) {
  // If the notes already exist, don't fetch them again
  const loadingStates = yield select(selectNotesLoadingStates)
  if (loadingStates.sites?.[siteId] === LOADING_STATES.READY) {
    return
  }

  const data = yield call(getSiteNotes, {
    siteId,
    pageLimit: NUM_DISPATCH_NOTES_PER_PAGE,
    pageKey,
  })

  yield put(
    NotesCreators.getSiteNotesSuccess(
      siteId,
      transformEventStreamToNoteTypes(data.eventsList),
      data.pagination.hasNextPage,
      data.pagination.pageKey
    )
  )
}

function* getNotesByUser({ dispatchId, userId }) {
  // If the notes already exist, don't fetch them again
  const loadingStates = yield select(selectNotesLoadingStates)
  if (loadingStates.users?.[userId] === LOADING_STATES.READY) {
    return
  }

  const data = yield call(getSiteContactNotes, {
    scheduledEventId: dispatchId,
    userId,
  })
  yield put(
    NotesCreators.getUserNotesSuccess(
      userId,
      transformEventStreamToNoteTypes(data.eventsList)
    )
  )
}

function* handleCreateTextNote({ payload }) {
  try {
    const { note } = yield call(createTextNote, payload)

    // This conditional lets us differentiate between a site note and a
    // contact note.
    // TODO(robdodson): When we remove contact notes from the VOC, we can remove
    // this conditional.
    // https://app.shortcut.com/voltus/story/6750/remove-contact-notes-in-the-voc
    if (!payload.siteContactUserId) {
      eventTracker.track(EVENTS.VOC_CREATE_SITE_NOTE, {
        [PROPERTIES.CASE_TYPES]: payload.caseTypes,
      })
    }

    yield put(NotesCreators.receiveNote({ note }))
    payload.onSuccess?.()
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    payload.onFailure?.(e)
  }
}

export function* watchNotes() {
  yield all([
    takeEvery(NotesTypes.GET_NOTES_BY_SITE, getNotesBySite),
    takeEvery(NotesTypes.GET_NOTES_BY_USER, getNotesByUser),
    takeEvery(NotesTypes.CREATE_TEXT_NOTE, handleCreateTextNote),
  ])
}
