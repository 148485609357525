import { isObject, isMap, isString, isNil, mapKeys, snakeCase } from 'lodash'

/*
  the fetch spec doesn't come with a way to handle query parameters out of the box.

  @param path {String} - the path the params are added to
  @param params {Object|String} - a POJO with key value pairs representing a collection
  of parameters, or a string
  @returns {String} - in the form of 'some-path?foo=bar&baz=qux'.
*/
export function addParams(path, params) {
  if (isNil(params)) {
    return path
  }

  return `${path}?${formatParams(params)}`
}

/*
  @param params {Object|Map|String}; {Object} and {Map} keys are converted to
  snake case for ease of use on the Flask side
  @returns {URLSearchParams}
*/
function formatParams(params) {
  let formattedParams = null

  if (isObject(params)) {
    formattedParams = mapKeys(params, (val, key) => snakeCase(key))
  }

  if (isMap(params)) {
    formattedParams = new Map(
      [...params.entries()].map(([key, val]) => [snakeCase(key), val])
    )
  }

  if (isString(params)) {
    formattedParams = params
  }

  return new URLSearchParams(formattedParams)
}
