import * as React from 'react'
import { Box, Grid } from '@voltus/core-components'
import { usePrevious, blockScroll, allowScroll } from '@voltus/utils'
import { useMobileContext } from '../../context/MobileContext'
import { SIZES } from './constants'
import { useGlobalSidebarContext } from './global-sidebar-context'

type Props = {
  appContainerRef: React.MutableRefObject<HTMLDivElement | undefined>
}

function GlobalSidebar({ appContainerRef }: Props): JSX.Element {
  const { isMobile } = useMobileContext()
  const [state] = useGlobalSidebarContext()

  const containerRef = React.useRef<HTMLDivElement>()

  const { isPrimarySidebarOpen, isSecondarySidebarOpen } = state
  const prevIsPrimarySidebarOpen = usePrevious(isPrimarySidebarOpen)
  React.useEffect(() => {
    // Only on mobile because the GlobalSidebar will take up the full screen:
    // - Prevent document.body from scrolling when the GlobalSidebar opens
    // - Enable document.body scrolling when the GlobalSidebar closes
    if (isMobile) {
      if (!prevIsPrimarySidebarOpen && isPrimarySidebarOpen) {
        blockScroll()
      }
      if (prevIsPrimarySidebarOpen && !isPrimarySidebarOpen) {
        allowScroll()
      }
    }
  }, [isPrimarySidebarOpen, prevIsPrimarySidebarOpen, isMobile])

  React.useEffect(
    () =>
      function cleanup() {
        allowScroll()
      },
    []
  )

  const width = React.useMemo(() => {
    if (isMobile) {
      return isPrimarySidebarOpen || isSecondarySidebarOpen
        ? SIZES.MOBILE_SIDEBAR_WIDTH
        : '0px'
    }

    let width = 0

    if (isSecondarySidebarOpen) {
      width += SIZES.SECONDARY_SIDEBAR_WIDTH
    }

    if (isPrimarySidebarOpen) {
      width += SIZES.PRIMARY_SIDEBAR_WIDTH
    }

    return `${width}px`
  }, [isMobile, isPrimarySidebarOpen, isSecondarySidebarOpen])

  React.useEffect(() => {
    if (appContainerRef.current) {
      appContainerRef.current.style.transition = 'width 100ms ease-out'
      appContainerRef.current.style.width = `calc(100% - ${width})`
    }
  }, [appContainerRef, width])

  const primarySidebarWidth = isMobile
    ? SIZES.MOBILE_SIDEBAR_WIDTH
    : SIZES.PRIMARY_SIDEBAR_WIDTH + 'px'

  const secondarySidebarWidth = isMobile
    ? SIZES.MOBILE_SIDEBAR_WIDTH
    : SIZES.SECONDARY_SIDEBAR_WIDTH + 'px'
  // Contains the open and closed X position of the secondarySidebar.
  // On mobile, the secondarySidebar will move on top of the primarySidebar.
  // On desktop, the secondarySidebar will move next to the primarySidebar.
  const secondarySidebarX = isMobile
    ? { opened: 0, closed: '100%' }
    : { opened: '-100%', closed: 0 }

  return (
    // We use a Grid to hold the primary and secondary sidebars so we can force
    // them into the same column and row.
    // This causes the sidebars to visually stack without needing to absolutely
    // position them or change their width.
    <Grid
      height="100vh"
      // Make the Grid the same width as the primarySidebar.
      // This makes it easy to animate the primarySidebar on screen by just
      // moving this container back to 0.
      // The secondarySidebar is allowed to break out of this container
      // so it can appear next to the primarySidebar on desktop.
      // This works because we have an implicit overflow: visible on the Grid.
      width={primarySidebarWidth}
      position="fixed"
      top={0}
      right={0}
      zIndex={3}
      css={{
        transition: 'transform 100ms ease-out',
        transform: `translateX(${
          state.isPrimarySidebarOpen ? 0 : `${primarySidebarWidth}`
        })`,
      }}
      ref={containerRef}
    >
      <Box
        key="primarySidebar"
        display="block"
        gridColumn={1}
        gridRow={1}
        inert={!state.isPrimarySidebarOpen ? '' : undefined}
        height="100%"
        width={primarySidebarWidth}
        zIndex={1}
        css={{ overflow: 'hidden' }}
      >
        {state.primaryChild ? state.primaryChild() : null}
      </Box>
      <Box
        key="secondarySidebar"
        width={secondarySidebarWidth}
        display="block"
        gridColumn={1}
        gridRow={1}
        inert={!state.isSecondarySidebarOpen ? '' : undefined}
        height="100%"
        // On mobile, the secondarySidebar needs to appear on top of the
        // primarySidebar, so we boost its zIndex.
        zIndex={isMobile ? 2 : undefined}
        css={{
          transition: 'transform 100ms ease-out',
          transform: `translateX(${
            state.isSecondarySidebarOpen
              ? secondarySidebarX.opened
              : secondarySidebarX.closed
          })`,
          overflow: 'hidden',
        }}
      >
        {state.secondaryChild ? state.secondaryChild() : null}
      </Box>
    </Grid>
  )
}

export { GlobalSidebar }
