import { isEqual } from 'lodash'
import { ActionMeta, GroupBase } from 'react-select'
import { Any, DropdownOption } from '@voltus/types'
import { Node, OptionsTree } from './optionsTree'
import { DropdownProps } from './types'

/**
 * Our dropdown component allows for passing in a primitive `value` prop, or passing
 * in the full options object as the `value` prop (and supports arrays of each if isMulti is true)
 *
 * This helper takes the passed in value, and finds the corresponding option object
 * We search through the options array and, and look at if the value is primitive or not.
 *
 * We return the found option object that corresponds to the value (or an array of options
 * if isMulti is true)
 */
export function getValueOption<
  ValueType,
  Option extends DropdownOption<ValueType>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  value,
  options,
  isMulti,
}: Pick<
  DropdownProps<ValueType, Option, IsMulti, Group>,
  'value' | 'options' | 'isMulti'
>) {
  const isGroup = (option: Option | Group): option is Group => {
    // Need the cast because Option and Group don't have an overlapping `options` property
    if ((option as Group).options) {
      return true
    }

    return false
  }

  let valueOption
  if (isMulti && Array.isArray(value)) {
    valueOption = []
    for (const val of value) {
      // For object values, just keep everything, since the value should
      // directly equal the options
      if (val && typeof val === 'object') {
        valueOption.push(val)
      } else {
        // For the case where value is an array of primitives,
        // we need to find the corresponding options
        let found: Option | undefined = undefined
        for (const option of options) {
          if (isGroup(option)) {
            for (const nestedOption of option.options) {
              if (nestedOption.value === val) {
                found = nestedOption
              }
            }
          } else {
            if (isEqual(option.value, val)) {
              found = option
            }
          }
        }

        if (found) {
          valueOption.push(found)
        }
      }
    }
  } else {
    // start with null instead of undefined because if you pass undefined
    // into react select it will treat itself as uncontrolled, which will
    // lead to stale state
    valueOption = null
    if (value && typeof value === 'object') {
      valueOption = value
    } else {
      let found: Option | undefined = undefined
      for (const option of options) {
        if (isGroup(option)) {
          for (const nestedOption of option.options) {
            if (nestedOption.value === value) {
              found = nestedOption
            }
          }
        } else {
          if (option.value === value) {
            found = option
          }
        }
        if (found) {
          valueOption = found
        }
      }
    }
  }
  return valueOption
}

export const customNodeFilterOption = (option, rawInput) => {
  const words = rawInput.split(' ')
  const matches = words.every((word) => {
    return option?.label?.toLowerCase().includes(word.toLowerCase())
  })
  if (matches) {
    return true
  }

  const node = option.data
  if (node.childNodes) {
    let childMatches = false
    // check if any child value matches the input
    node.visitChildren((childNode) => {
      if (
        words.every((word) => {
          return childNode?.label?.toLowerCase().includes(word.toLowerCase())
        })
      ) {
        childMatches = true
      }
    })

    if (childMatches) {
      return true
    }
  }

  return false
}

export function makeHandleNodeChange<ValueType>(
  cb: (
    val: Node<ValueType> | Array<Node<ValueType>> | null,
    action: ActionMeta<Node<ValueType>>
  ) => Any,
  isTreeSelect: boolean,
  tree: OptionsTree<ValueType>
) {
  return (
    opts: Node<ValueType> | Array<Node<ValueType>> | null,
    action: ActionMeta<Node<ValueType>>
  ) => {
    if (action.action === 'clear') {
      return cb?.([], action)
    }

    if (isTreeSelect) {
      // allow for `null` to be passed to updateValues
      if (typeof action.option !== 'undefined') {
        return cb?.(tree.updateValues(action.option), action)
      }
    }

    return cb?.(opts, action)
  }
}
