import { isNil, isEqual, omit } from 'lodash'
import { Simplify } from 'type-fest'
import { Any } from '@voltus/types'
import { arrayUtils, isNotNull } from '@voltus/utils'
import { SiteSwitcherOption, NormalizedOption } from '../SiteSwitcher/types'

const AllSymbol = Symbol('all')

/**
 * React Select is opinionated about the structure of
 * the option array passed in. If you pass in options as a tree,
 * e.g. options nested within options, React Select will
 * treat your options as "grouped", and make a bunch of assumption
 * about how to render the options as groups.
 *
 * We are implemting a custom tree select, so we need
 * to trick React Select a bit. We do this by renaming
 * nested `options` to `childOptions`.
 */
function normalizeOpts<ValueType>(
  opts: Array<SiteSwitcherOption<ValueType>>
): Array<NormalizedOption<ValueType>> {
  const newOpts: Array<NormalizedOption<ValueType>> = []
  for (const opt of opts) {
    if (opt.options) {
      newOpts.push({
        ...omit(opt, 'options'),
        // Recurse through the tree, and normalize all children
        childOptions: normalizeOpts<ValueType>(opt.options),
      } as NormalizedOption<ValueType>)
    } else {
      newOpts.push({
        ...opt,
      } as NormalizedOption<ValueType>)
    }
  }
  return newOpts
}

function convertToNodes<ValueType>(
  opts: Array<NormalizedOption<ValueType>>
): Array<Node<ValueType>> {
  function recurse(
    option: NormalizedOption<ValueType>,
    parent: Node<ValueType> | null,
    depth: number
  ) {
    const node = new Node({ option, parent, depth })

    if (!option.childOptions || option.childOptions.length === 0) {
      return node
    }

    const childNodes = option.childOptions.map((child) => {
      return recurse(child, node, depth + 1)
    })

    node.setChildNodes(childNodes)
    return node
  }

  const nodes: Array<Node<ValueType>> = []
  for (const opt of opts) {
    nodes.push(recurse(opt, null, 0))
  }
  return nodes
}

export function flattenTree<ValueType>(
  tree: Array<Node<ValueType>> | null
): Array<Node<ValueType>> {
  let flatNodes: Array<Node<ValueType>> = []
  if (tree && tree.length > 0) {
    for (const node of tree) {
      flatNodes.push(node)
      if (node.childNodes && node.childNodes.length > 0) {
        flatNodes = flatNodes.concat(flattenTree(node.childNodes))
      }
    }
  }

  return flatNodes
}

// export interface INode<ValueType = Any> {
//   label: string
//   value: ValueType
//   option: Simplify<NormalizedOption<ValueType>>
//   childOptions?: Simplify<Array<NormalizedOption<ValueType>>>
//   childNodes: Array<INode<ValueType>> | null
//   parent?: INode | null
//   depth: number
//   isHeader?: boolean
//   isAllOption?: boolean | null
//   isLeaf: boolean
//   isRoot: boolean
// }

interface NodeConstructorOptions<ValueType> {
  option: NormalizedOption<ValueType>
  parent: Node<ValueType> | null
  depth: number
  isAllOption?: boolean
}

export class Node<ValueType = Any> {
  label: string
  value: ValueType
  option: Simplify<NormalizedOption<ValueType>>
  childOptions?: Simplify<Array<NormalizedOption<ValueType>>>
  childNodes: Array<Node<ValueType>> | null
  parent: Node<ValueType> | null
  depth: number
  isHeader?: boolean
  isAllOption: boolean
  isLeaf: boolean
  isRoot: boolean

  constructor({
    option,
    parent,
    depth,
    isAllOption,
  }: NodeConstructorOptions<ValueType>) {
    this.label = option.label
    this.value = option.value
    this.option = option
    this.childOptions = option.childOptions
    this.childNodes = null
    this.parent = parent
    this.depth = depth
    this.isHeader = option.isHeader
    this.isAllOption = isAllOption ?? false
    this.isRoot = isNil(parent)

    if (isAllOption) {
      this.isLeaf = false
    } else {
      this.isLeaf = !option.childOptions || option.childOptions.length === 0
    }
  }

  setChildNodes(childNodes: Array<Node<ValueType>>) {
    this.isLeaf = false
    this.childNodes = childNodes
  }

  getParents() {
    const parents: Array<Node<ValueType>> = []
    let parent = this.parent
    while (parent) {
      parents.push(parent)
      parent = parent.parent
    }

    return parents
  }

  getRoot(): Node<ValueType> {
    if (this.parent) {
      return this.parent.getRoot()
    }
    return this
  }

  getFlattenedChildren(): Array<Node<ValueType>> {
    return flattenTree(this.childNodes)
  }

  visitChildren(cb: (node: Node<ValueType>) => void) {
    for (const child of this.getFlattenedChildren()) {
      cb(child)
    }
  }

  getNodeState(
    // The value coming in from the SiteSwitcher component. This
    // is controlled by the consumer
    currentValue: Node<ValueType> | Array<Node<ValueType>> | undefined | null,
    flattenedOptions: Array<Node<ValueType>>
  ): {
    isParent: boolean
    isSelected: boolean
    isSaturated: boolean
    isIndeterminate: boolean
    isRootIndeterminte: boolean
    isRootSaturated: boolean
    isRootSelected: boolean
  } {
    const allValues = arrayUtils
      .ensureArray(currentValue)
      .filter(isNotNull)
      .map((value) => (typeof value === 'object' ? value.value : value))

    /* true for all parent nodes (aka node that have children) */
    let isParent = false
    /* true if this node is specifically selected */
    let isSelected = false
    /* represents a parent whose entire child tree is also selected */
    let isSaturated = false
    /* true if this node's child tree is partially selected */
    let isIndeterminate = false

    let isRootIndeterminte = false
    let isRootSaturated = false
    let isRootSelected = false

    // For the special "Select All" option, we handle it a bit differently
    // In tree select mode, the component can itself inject an option to select every
    // item in the list. That's this special `isAllOption`
    // From the mocks, we want this checkbox to appear "indeterminate" when no options
    // or some option are selected
    if (this.isAllOption) {
      isParent = false

      const leaves = flattenedOptions.filter((node) => node.isLeaf)
      const selectedLeaves = arrayUtils
        .ensureArray(currentValue)
        .filter(isNotNull)
        .filter((node) => node.isLeaf)

      if (leaves.length === selectedLeaves.length) {
        isSelected = true
        isSaturated = true
        isIndeterminate = false
      } else {
        isSelected = false
        isSaturated = false
        isIndeterminate = true
      }
    } else {
      const root = this.getRoot()
      if (!this.isRoot) {
        if (!root.isHeader) {
          const rootState = root.getNodeState(currentValue, flattenedOptions)
          isRootIndeterminte = rootState.isIndeterminate
          isRootSaturated = rootState.isSaturated
          isRootSelected = rootState.isSelected
        }
      }

      if (this?.childNodes) {
        isParent = true

        if (
          arrayUtils
            .ensureArray(currentValue)
            .filter(isNotNull)
            ?.find((v) => v.value === this.value)
        ) {
          isSelected = true
        }

        const allChildren = this.getFlattenedChildren()

        // If a parent item has every child selected, we call it "saturated"
        if (allChildren.every((opt) => allValues.includes(opt.value))) {
          isSaturated = true
        }

        // if only some of the child items are selected, we call the parent "indeterminate"
        if (!isSaturated) {
          if (allChildren.some((opt) => allValues.includes(opt.value))) {
            isIndeterminate = true
          }
        }
      } else {
        if (currentValue && Array.isArray(currentValue)) {
          if (currentValue.find((v) => v.value === this.value)) {
            isSelected = true
          } else {
            isSelected = false
          }
        }
      }
    }

    return {
      isParent,
      isSelected,
      isSaturated,
      isIndeterminate,
      isRootIndeterminte,
      isRootSaturated,
      isRootSelected,
    }
  }
}

/**
 * A simple tree walker that calls a callback on each node in the tree
 */
function visit<ValueType>(
  nodes: Array<Node<ValueType>>,
  cb: (node: Node<ValueType>) => void
) {
  for (const node of nodes) {
    cb(node)
    if (node.childNodes) {
      visit<ValueType>(node.childNodes, cb)
    }
  }
}

/**
 * Options can be either the full option (with a `value` property), or just the primitive value
 */
function extractValue<ValueType>(
  opt: SiteSwitcherOption<ValueType>
): ValueType | null {
  if (opt && typeof opt === 'object') {
    return opt.value
  }

  return opt
}

export interface OptionsTree<ValueType> {
  updateValues: (opt: Node<ValueType> | null) => Array<Node<ValueType>>
  findValue: (
    value?:
      | ValueType
      | Array<ValueType>
      | SiteSwitcherOption<ValueType>
      | Array<SiteSwitcherOption<ValueType>>
      | Array<{ value: ValueType }>
      | { value: ValueType }
      | null
  ) => Node<ValueType> | Array<Node<ValueType>> | undefined
  options: Array<NormalizedOption<ValueType>>
  flattenedOptions: Array<Node<ValueType>>
  sortBySelected: (
    value: Node<ValueType> | Array<Node<ValueType>> | null | undefined
  ) => Array<Node<ValueType>>
}

export interface OptionsTreeConfig {
  includeAllOption?: boolean
}

export function OptionsTree<ValueType = Any>(
  options: Array<SiteSwitcherOption<ValueType>>,
  originalValues: Any,
  config: OptionsTreeConfig = {}
): OptionsTree<ValueType> {
  const includeAllOption = config.includeAllOption ?? false
  // React-select is opinionated about having `options` as a property on an option object.
  // So we "normalize" - AKA change the name of "option" to "childOptions" to avoid
  // the default behavior that kicks in when react-select finds the "options" property
  const normalizedOptionsTree = normalizeOpts(options)
  // We then convert the tree of simple option objects to a tree of Node instances
  const nodeTree = convertToNodes(normalizedOptionsTree)

  /**
   * Sorts the tree based on if any child nodes within a given branch
   * are selected. All branches that have at least one selected options
   * are brought to the top of the list.
   */
  function sortBySelected(
    value: Node<ValueType> | Array<Node<ValueType>> | null | undefined
  ): Array<Node<ValueType>> {
    const selectedRoots: Set<Node<ValueType>> = new Set()

    const isValueSelected = (o1, o2) => {
      const v1 = extractValue(o1)
      const v2 = extractValue(o2)
      return isEqual(v1, v2)
    }

    visit<ValueType>(nodeTree, (node) => {
      if (
        arrayUtils
          .ensureArray(value)
          .some((v) => isValueSelected(v, node.value))
      ) {
        selectedRoots.add(node.getRoot())
      }
    })

    const sortedTree: Array<Node<ValueType>> = [
      includeAllOption
        ? new Node<ValueType>({
            // We don't care about the "value" for this all option, so just cast
            // the symbol to make Typescript happy. We should only ever look at the `isAllOption`
            // property to determine if the value is the special "All" option
            // eslint-disable-next-line
            // @ts-ignore
            option: { label: 'Select All', value: AllSymbol as ValueType },
            parent: null,
            depth: 0,
            isAllOption: true,
          })
        : null,
      ...selectedRoots,
      // We only need to filter the roots, because all children within
      // the roots will come along for the ride
      ...nodeTree.filter((node) => !selectedRoots.has(node)),
    ].filter(isNotNull)
    return sortedTree
  }

  let flattenedOptions: Array<Node<ValueType>> = flattenTree(nodeTree)

  if (includeAllOption) {
    flattenedOptions = [
      new Node<ValueType>({
        // We don't care about the "value" for this all option, so just cast
        // the symbol to make Typescript happy. We should only ever look at the `isAllOption`
        // property to determine if the value is the special "All" option
        // eslint-disable-next-line
        // @ts-ignore
        option: { label: 'All', value: AllSymbol as ValueType },
        parent: null,
        depth: 0,
        isAllOption: true,
      }),
      ...flattenedOptions,
    ]
  }

  /**
   * Takes an array of selected options, and compares against values to determine
   * if an option is selected. This means the passed in value can be a fully formed
   * Option, or just the value that matches some option value.
   *
   * e.g.
   * `value = [{ label: 'One', value: 1 }, { label: 'Two', value: 2 }]`
   * or
   *  `value = [1, 2]`
   *
   * Returns a flat list of selected options
   */
  function findValue(
    value?:
      | ValueType
      | SiteSwitcherOption<ValueType>
      | Array<SiteSwitcherOption<ValueType>>
      | null
  ): Node<ValueType> | Array<Node<ValueType>> | undefined {
    // If no value, nothing is selected
    if (!value) {
      return undefined
    }

    // Return the options that have values that match the values passed in
    if (Array.isArray(value)) {
      const allVals = value.map((value) =>
        typeof value === 'object' ? value.value : value
      )
      const foundOpts = flattenedOptions.filter(({ value }) =>
        allVals.some((val) => isEqual(val, value))
      )
      return foundOpts
    }

    // not array, single item, find in list
    return flattenedOptions.find((opt) => {
      if (typeof value === 'object') {
        // Not sure how to get around this type issue.
        // Property 'value' does not exist on type 'ValueType & object'.ts(2339)
        // Something to do with using `ValueType` and `Option<ValueType>` as the potential
        // value on the option
        // eslint-disable-next-line
        // @ts-ignore
        return isEqual(opt.value, value.value)
      }

      return isEqual(opt.value, value)
    })
  }

  // We'll use the values later in some other handlers
  const values = findValue(originalValues)

  /**
   * Handle deselecting an option.
   * This operation has to look at the whole tree and decide if children
   * and/or parents should also be deselected
   *
   * Accepts a single option, and returns the updated, flat list
   * of options minus the options that are removed here
   */
  function handleDeselect(opt: Node<ValueType>): Array<Node<ValueType>> {
    // we always deslect the one passed in
    let optsToDeselect: Array<ValueType | null> = [opt.value]

    // Find the original option that matches the one we are deselecting
    const found = flattenedOptions.find(({ value }) =>
      isEqual(opt.value, value)
    )

    // Deselect all children
    if (found?.childNodes) {
      optsToDeselect = [
        ...optsToDeselect,
        ...flattenTree<ValueType>(found.childNodes).map(({ value }) => value),
      ]
    }

    // If we are deselecting an item, we also need to make sure all parents of that item
    // are deselected. This is because a parent can only be selected if ALL its children are selected
    // So deselecting a child means the parent, by definition, cannot be selected
    const parents = opt.getParents()

    // Smush in the parent opts that we also want to filter out
    optsToDeselect = [...optsToDeselect, ...parents.map(({ value }) => value)]

    let newValue: Array<Node<ValueType>> = []
    // Deselect only really makes sense when the value is an array
    // We can't deselect a single option, so this check is just to make
    // typescript happy
    if (Array.isArray(values)) {
      newValue = values?.filter(({ value }) => !optsToDeselect.includes(value))
    }
    return newValue
  }

  /**
   * Handle selecting an option.
   * This operation has to look at the whole tree and decide if children
   * and/or parents should also be selected
   *
   * When we select an option, we should also select all children.
   * If we select an option that completes a group, we also need to select the parent option
   */
  function handleSelect(opt: Node<ValueType>): Array<Node<ValueType>> {
    // Always select the option passed in
    let selectedOpts: Array<Node<ValueType>> = []
    selectedOpts.push(opt)

    // Select all children
    if (opt.childNodes) {
      selectedOpts = [opt, ...flattenTree<ValueType>(opt.childNodes)]
    }

    const newValue = [
      ...arrayUtils.ensureArray(values),
      ...selectedOpts,
    ].filter(isNotNull)

    // Get all parents, and check if all parents are "saturated",
    // meaning all children are selected
    const parents = opt.getParents()
    for (const p of parents) {
      if (p.getNodeState(newValue, flattenedOptions).isSaturated) {
        newValue.push(p)
      }
    }

    return newValue
  }

  /**
   * Wrapper around the handleSelect and handleDeselect functions
   * and also handles a "clear" operation to set the value to an empty array
   */
  const updateValues = (
    opt: Node<ValueType> | null
  ): Array<Node<ValueType>> => {
    // This is essentially a "clear" operation, by passing null or an empty array
    if (isNil(opt)) {
      return []
    }

    // Handle the special case of selecting the "All" option
    // and either select or deselect everything
    if (opt.isAllOption) {
      // Collect all leaves and check if they are selected.
      // if all leaves are selected, it means the tree is fully selected,
      // so this is a deselect operation, otherwise it's a select operation
      const leaves = flattenedOptions.filter((node) => node.isLeaf)
      let selectedLeaves: Array<Node<ValueType>> = []
      if (Array.isArray(values)) {
        selectedLeaves = values?.filter((node) => node.isLeaf)
      }
      if (leaves.length === selectedLeaves.length) {
        return []
      }

      return flattenedOptions.filter((opt) => !opt.isAllOption)
    }

    // In this case, we selected an item that was already selected
    // So we need to carefully deselect some items.
    // We deselect the item itself, as well as any children of that item.
    // But leave the rest intact

    const found = flattenedOptions.find(({ value }) =>
      isEqual(value, opt.value)
    )
    const { isSaturated } = opt.getNodeState(values, flattenedOptions)

    // If a node is fully saturated, deselect it, even if it's not actively selected
    if (isSaturated) {
      return handleDeselect(opt)
    }

    // If a node is actively selected, deselect it
    if (found && values && Array.isArray(values)) {
      if (values.find((prevVal) => isEqual(prevVal.value, found.value))) {
        return handleDeselect(found)
      }
    }

    // Otherwise we are adding a new item into the existing items
    return handleSelect(opt)
  }

  return {
    updateValues,
    findValue,
    options: normalizedOptionsTree,
    flattenedOptions,
    sortBySelected,
  }
}
