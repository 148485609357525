import { grpc } from '@improbable-eng/grpc-web'
import auth from '@voltus/auth'
import logger from '@voltus/logger'
import { parseAndSanitizeData } from '@voltus/network'
import { isDemoEnabled } from '@voltus/utils'
import DOMAIN from '../../../../../constants/domain'

export const makegRPCHost = (subpath) => {
  return DOMAIN + subpath
}

// This transport is configured to send Browser cookies along with cross-origin requests.
const crossBrowserTransport = grpc.CrossBrowserHttpTransport({
  withCredentials: true,
})

/**
 * If the site is in demo mode, strip any PII from the message object.
 * If the site is not in demo mode, just return the message object.
 */
export const sanitizeMessageForDemo = async (service, host, message) => {
  const msgObj = message.toObject()
  if (!isDemoEnabled()) {
    return msgObj
  }

  const uri =
    new URL(host).pathname +
    `/${service.service.serviceName}/${service.methodName}`
  return JSON.parse(
    await parseAndSanitizeData(uri, 'POST', JSON.stringify(msgObj))
  )
}

export const unary = (host, request, service, opts = {}) => {
  if (typeof request.setAccessToken === 'function') {
    request.setAccessToken(auth.accessToken)
  }
  return new Promise((resolve, reject) => {
    grpc.unary(service, {
      request,
      host,
      transport: crossBrowserTransport,
      metadata: new grpc.Metadata({ authorization: auth.accessToken }),
      onEnd: async (res) => {
        const { status, message, statusMessage } = res
        if (status === grpc.Code.OK && message) {
          logger.info('GRPC:', service.methodName, message.toObject())
          resolve(await sanitizeMessageForDemo(service, host, message))
          return
        }

        logger.info('GRPC:', service.methodName, 'failed')
        reject(statusMessage)
      },
      ...opts,
    })
  })
}
