import { all, fork } from 'redux-saga/effects'
import logger from '@voltus/logger'

import { watchBootstrapAdmin } from './bootstrapAdmin'
import { watchCalling } from './calling'
import { watchCheckIns } from './checkin'
import { watchDispatch } from './dispatch'
import { watchEventStream } from './eventStream'
import { watchNotes } from './notes'
import { watchSiteAssignments } from './siteAssignments'
import { watchSms } from './sms'

export const saga = function* saga() {
  try {
    yield all([
      fork(watchDispatch),
      fork(watchNotes),
      fork(watchSiteAssignments),
      fork(watchCheckIns),
      fork(watchEventStream),
      fork(watchBootstrapAdmin),
      fork(watchCalling),
      fork(watchSms),
    ])
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}
