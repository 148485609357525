// Creates a method to inject newly loaded sagas,
// Like those loaded async in chunked application bundles
// This should only every be called _once_ at the
// initial loading of an application
// Typically, applications will not have to call this method
// directly, but will rather use `store.injectSaga` provided by
// by the `configureStore` util next to this file.
function createSagaInjector(runSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map()

  const isInjected = (key) => injectedSagas.has(key)

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return

    // Kick off the saga
    const task = runSaga(saga)

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task)
  }

  return injectSaga
}

export default createSagaInjector
