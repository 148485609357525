// source: sms.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf')
var goog = jspb
var global = Function('return this')()

goog.exportSymbol('proto.sms.Conversation', null, global)
goog.exportSymbol('proto.sms.ConversationEventStreamRequest', null, global)
goog.exportSymbol('proto.sms.ConversationEventStreamResponse', null, global)
goog.exportSymbol('proto.sms.GetRecentConversationsRequest', null, global)
goog.exportSymbol('proto.sms.GetRecentConversationsResponse', null, global)
goog.exportSymbol('proto.sms.GetUserMessagesRequest', null, global)
goog.exportSymbol('proto.sms.GetUserMessagesResponse', null, global)
goog.exportSymbol('proto.sms.IndicateTypingEventRequest', null, global)
goog.exportSymbol('proto.sms.IndicateTypingEventResponse', null, global)
goog.exportSymbol(
  'proto.sms.MessageNotificationEventStreamRequest',
  null,
  global
)
goog.exportSymbol(
  'proto.sms.MessageNotificationEventStreamResponse',
  null,
  global
)
goog.exportSymbol('proto.sms.SMSMessage', null, global)
goog.exportSymbol('proto.sms.SearchMessagesRequest', null, global)
goog.exportSymbol('proto.sms.SearchMessagesResponse', null, global)
goog.exportSymbol('proto.sms.SendMessageRequest', null, global)
goog.exportSymbol('proto.sms.SendMessageResponse', null, global)
goog.exportSymbol('proto.sms.TypingStatus', null, global)
goog.exportSymbol('proto.sms.UpdateMessageRequest', null, global)
goog.exportSymbol('proto.sms.UpdateMessageResponse', null, global)
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.GetUserMessagesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.GetUserMessagesRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.GetUserMessagesRequest.displayName =
    'proto.sms.GetUserMessagesRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.GetUserMessagesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.sms.GetUserMessagesResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.sms.GetUserMessagesResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.GetUserMessagesResponse.displayName =
    'proto.sms.GetUserMessagesResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.SMSMessage = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.SMSMessage, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.SMSMessage.displayName = 'proto.sms.SMSMessage'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.GetRecentConversationsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.GetRecentConversationsRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.GetRecentConversationsRequest.displayName =
    'proto.sms.GetRecentConversationsRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.GetRecentConversationsResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.sms.GetRecentConversationsResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.sms.GetRecentConversationsResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.GetRecentConversationsResponse.displayName =
    'proto.sms.GetRecentConversationsResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.Conversation = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.Conversation, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.Conversation.displayName = 'proto.sms.Conversation'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.SendMessageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.SendMessageRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.SendMessageRequest.displayName = 'proto.sms.SendMessageRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.SendMessageResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.SendMessageResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.SendMessageResponse.displayName = 'proto.sms.SendMessageResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.UpdateMessageRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.UpdateMessageRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.UpdateMessageRequest.displayName = 'proto.sms.UpdateMessageRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.UpdateMessageResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.UpdateMessageResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.UpdateMessageResponse.displayName =
    'proto.sms.UpdateMessageResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.SearchMessagesRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.SearchMessagesRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.SearchMessagesRequest.displayName =
    'proto.sms.SearchMessagesRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.SearchMessagesResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.sms.SearchMessagesResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.sms.SearchMessagesResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.SearchMessagesResponse.displayName =
    'proto.sms.SearchMessagesResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.ConversationEventStreamRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.ConversationEventStreamRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.ConversationEventStreamRequest.displayName =
    'proto.sms.ConversationEventStreamRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.ConversationEventStreamResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.sms.ConversationEventStreamResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.sms.ConversationEventStreamResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.ConversationEventStreamResponse.displayName =
    'proto.sms.ConversationEventStreamResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.TypingStatus = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.TypingStatus, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.TypingStatus.displayName = 'proto.sms.TypingStatus'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.IndicateTypingEventRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.IndicateTypingEventRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.IndicateTypingEventRequest.displayName =
    'proto.sms.IndicateTypingEventRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.IndicateTypingEventResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.IndicateTypingEventResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.IndicateTypingEventResponse.displayName =
    'proto.sms.IndicateTypingEventResponse'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.MessageNotificationEventStreamRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null)
}
goog.inherits(proto.sms.MessageNotificationEventStreamRequest, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.MessageNotificationEventStreamRequest.displayName =
    'proto.sms.MessageNotificationEventStreamRequest'
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sms.MessageNotificationEventStreamResponse = function (opt_data) {
  jspb.Message.initialize(
    this,
    opt_data,
    0,
    -1,
    proto.sms.MessageNotificationEventStreamResponse.repeatedFields_,
    null
  )
}
goog.inherits(proto.sms.MessageNotificationEventStreamResponse, jspb.Message)
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sms.MessageNotificationEventStreamResponse.displayName =
    'proto.sms.MessageNotificationEventStreamResponse'
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.GetUserMessagesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.GetUserMessagesRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.GetUserMessagesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.GetUserMessagesRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        beforeId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        afterId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        limit: jspb.Message.getFieldWithDefault(msg, 4, 0),
        accessToken: jspb.Message.getFieldWithDefault(msg, 5, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.GetUserMessagesRequest}
 */
proto.sms.GetUserMessagesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.GetUserMessagesRequest()
  return proto.sms.GetUserMessagesRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.GetUserMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.GetUserMessagesRequest}
 */
proto.sms.GetUserMessagesRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setBeforeId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setAfterId(value)
        break
      case 4:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setLimit(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.GetUserMessagesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.GetUserMessagesRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.GetUserMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.GetUserMessagesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getBeforeId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getAfterId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getLimit()
  if (f !== 0) {
    writer.writeSint32(4, f)
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.GetUserMessagesRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.GetUserMessagesRequest} returns this
 */
proto.sms.GetUserMessagesRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 before_id = 2;
 * @return {number}
 */
proto.sms.GetUserMessagesRequest.prototype.getBeforeId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.GetUserMessagesRequest} returns this
 */
proto.sms.GetUserMessagesRequest.prototype.setBeforeId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 after_id = 3;
 * @return {number}
 */
proto.sms.GetUserMessagesRequest.prototype.getAfterId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.GetUserMessagesRequest} returns this
 */
proto.sms.GetUserMessagesRequest.prototype.setAfterId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional sint32 limit = 4;
 * @return {number}
 */
proto.sms.GetUserMessagesRequest.prototype.getLimit = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.GetUserMessagesRequest} returns this
 */
proto.sms.GetUserMessagesRequest.prototype.setLimit = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value)
}

/**
 * optional string access_token = 5;
 * @return {string}
 */
proto.sms.GetUserMessagesRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.GetUserMessagesRequest} returns this
 */
proto.sms.GetUserMessagesRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sms.GetUserMessagesResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.GetUserMessagesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.GetUserMessagesResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.GetUserMessagesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.GetUserMessagesResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        resultsList: jspb.Message.toObjectList(
          msg.getResultsList(),
          proto.sms.SMSMessage.toObject,
          includeInstance
        ),
        hasMore: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.GetUserMessagesResponse}
 */
proto.sms.GetUserMessagesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.GetUserMessagesResponse()
  return proto.sms.GetUserMessagesResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.GetUserMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.GetUserMessagesResponse}
 */
proto.sms.GetUserMessagesResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.sms.SMSMessage()
        reader.readMessage(
          value,
          proto.sms.SMSMessage.deserializeBinaryFromReader
        )
        msg.addResults(value)
        break
      case 2:
        var value = /** @type {boolean} */ (reader.readBool())
        msg.setHasMore(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.GetUserMessagesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.GetUserMessagesResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.GetUserMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.GetUserMessagesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getResultsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sms.SMSMessage.serializeBinaryToWriter
    )
  }
  f = message.getHasMore()
  if (f) {
    writer.writeBool(2, f)
  }
}

/**
 * repeated SMSMessage results = 1;
 * @return {!Array<!proto.sms.SMSMessage>}
 */
proto.sms.GetUserMessagesResponse.prototype.getResultsList = function () {
  return /** @type{!Array<!proto.sms.SMSMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sms.SMSMessage, 1)
  )
}

/**
 * @param {!Array<!proto.sms.SMSMessage>} value
 * @return {!proto.sms.GetUserMessagesResponse} returns this
 */
proto.sms.GetUserMessagesResponse.prototype.setResultsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.sms.SMSMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sms.SMSMessage}
 */
proto.sms.GetUserMessagesResponse.prototype.addResults = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.sms.SMSMessage,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sms.GetUserMessagesResponse} returns this
 */
proto.sms.GetUserMessagesResponse.prototype.clearResultsList = function () {
  return this.setResultsList([])
}

/**
 * optional bool has_more = 2;
 * @return {boolean}
 */
proto.sms.GetUserMessagesResponse.prototype.getHasMore = function () {
  return /** @type {boolean} */ (
    jspb.Message.getBooleanFieldWithDefault(this, 2, false)
  )
}

/**
 * @param {boolean} value
 * @return {!proto.sms.GetUserMessagesResponse} returns this
 */
proto.sms.GetUserMessagesResponse.prototype.setHasMore = function (value) {
  return jspb.Message.setProto3BooleanField(this, 2, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.SMSMessage.prototype.toObject = function (opt_includeInstance) {
    return proto.sms.SMSMessage.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.SMSMessage} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.SMSMessage.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        senderUserId: jspb.Message.getFieldWithDefault(msg, 3, 0),
        senderUserFullName: jspb.Message.getFieldWithDefault(msg, 4, ''),
        body: jspb.Message.getFieldWithDefault(msg, 5, ''),
        timestamp: jspb.Message.getFieldWithDefault(msg, 6, ''),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        userPhoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.SMSMessage}
 */
proto.sms.SMSMessage.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.SMSMessage()
  return proto.sms.SMSMessage.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.SMSMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.SMSMessage}
 */
proto.sms.SMSMessage.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 3:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setSenderUserId(value)
        break
      case 4:
        var value = /** @type {string} */ (reader.readString())
        msg.setSenderUserFullName(value)
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setBody(value)
        break
      case 6:
        var value = /** @type {string} */ (reader.readString())
        msg.setTimestamp(value)
        break
      case 7:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      case 8:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserPhoneNumber(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.SMSMessage.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.SMSMessage.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.SMSMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.SMSMessage.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getSenderUserId()
  if (f !== 0) {
    writer.writeSint32(3, f)
  }
  f = message.getSenderUserFullName()
  if (f.length > 0) {
    writer.writeString(4, f)
  }
  f = message.getBody()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
  f = message.getTimestamp()
  if (f.length > 0) {
    writer.writeString(6, f)
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      7,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
  f = message.getUserPhoneNumber()
  if (f.length > 0) {
    writer.writeString(8, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.sms.SMSMessage.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 user_id = 2;
 * @return {number}
 */
proto.sms.SMSMessage.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional sint32 sender_user_id = 3;
 * @return {number}
 */
proto.sms.SMSMessage.prototype.getSenderUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setSenderUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value)
}

/**
 * optional string sender_user_full_name = 4;
 * @return {string}
 */
proto.sms.SMSMessage.prototype.getSenderUserFullName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setSenderUserFullName = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value)
}

/**
 * optional string body = 5;
 * @return {string}
 */
proto.sms.SMSMessage.prototype.getBody = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setBody = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

/**
 * optional string timestamp = 6;
 * @return {string}
 */
proto.sms.SMSMessage.prototype.getTimestamp = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value)
}

/**
 * map<string, string> metadata = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sms.SMSMessage.prototype.getMetadataMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 7, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

/**
 * optional string user_phone_number = 8;
 * @return {string}
 */
proto.sms.SMSMessage.prototype.getUserPhoneNumber = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SMSMessage} returns this
 */
proto.sms.SMSMessage.prototype.setUserPhoneNumber = function (value) {
  return jspb.Message.setProto3StringField(this, 8, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.GetRecentConversationsRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.GetRecentConversationsRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.GetRecentConversationsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.GetRecentConversationsRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {}

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.GetRecentConversationsRequest}
 */
proto.sms.GetRecentConversationsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.GetRecentConversationsRequest()
  return proto.sms.GetRecentConversationsRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.GetRecentConversationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.GetRecentConversationsRequest}
 */
proto.sms.GetRecentConversationsRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.GetRecentConversationsRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.GetRecentConversationsRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.GetRecentConversationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.GetRecentConversationsRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sms.GetRecentConversationsResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.GetRecentConversationsResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.GetRecentConversationsResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.GetRecentConversationsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.GetRecentConversationsResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        conversationsList: jspb.Message.toObjectList(
          msg.getConversationsList(),
          proto.sms.Conversation.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.GetRecentConversationsResponse}
 */
proto.sms.GetRecentConversationsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.GetRecentConversationsResponse()
  return proto.sms.GetRecentConversationsResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.GetRecentConversationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.GetRecentConversationsResponse}
 */
proto.sms.GetRecentConversationsResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.sms.Conversation()
          reader.readMessage(
            value,
            proto.sms.Conversation.deserializeBinaryFromReader
          )
          msg.addConversations(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.GetRecentConversationsResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.GetRecentConversationsResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.GetRecentConversationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.GetRecentConversationsResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getConversationsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sms.Conversation.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Conversation conversations = 1;
 * @return {!Array<!proto.sms.Conversation>}
 */
proto.sms.GetRecentConversationsResponse.prototype.getConversationsList =
  function () {
    return /** @type{!Array<!proto.sms.Conversation>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.sms.Conversation, 1)
    )
  }

/**
 * @param {!Array<!proto.sms.Conversation>} value
 * @return {!proto.sms.GetRecentConversationsResponse} returns this
 */
proto.sms.GetRecentConversationsResponse.prototype.setConversationsList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value)
  }

/**
 * @param {!proto.sms.Conversation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sms.Conversation}
 */
proto.sms.GetRecentConversationsResponse.prototype.addConversations = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.sms.Conversation,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sms.GetRecentConversationsResponse} returns this
 */
proto.sms.GetRecentConversationsResponse.prototype.clearConversationsList =
  function () {
    return this.setConversationsList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.Conversation.prototype.toObject = function (opt_includeInstance) {
    return proto.sms.Conversation.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.Conversation} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.Conversation.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        userFullName: jspb.Message.getFieldWithDefault(msg, 2, ''),
        message:
          (f = msg.getMessage()) &&
          proto.sms.SMSMessage.toObject(includeInstance, f),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.Conversation}
 */
proto.sms.Conversation.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.Conversation()
  return proto.sms.Conversation.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.Conversation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.Conversation}
 */
proto.sms.Conversation.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setUserFullName(value)
        break
      case 3:
        var value = new proto.sms.SMSMessage()
        reader.readMessage(
          value,
          proto.sms.SMSMessage.deserializeBinaryFromReader
        )
        msg.setMessage(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.Conversation.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.Conversation.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.Conversation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.Conversation.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getUserFullName()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
  f = message.getMessage()
  if (f != null) {
    writer.writeMessage(3, f, proto.sms.SMSMessage.serializeBinaryToWriter)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.Conversation.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.Conversation} returns this
 */
proto.sms.Conversation.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional string user_full_name = 2;
 * @return {string}
 */
proto.sms.Conversation.prototype.getUserFullName = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.Conversation} returns this
 */
proto.sms.Conversation.prototype.setUserFullName = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * optional SMSMessage message_ = 3;
 * @return {?proto.sms.SMSMessage}
 */
proto.sms.Conversation.prototype.getMessage = function () {
  return /** @type{?proto.sms.SMSMessage} */ (
    jspb.Message.getWrapperField(this, proto.sms.SMSMessage, 3)
  )
}

/**
 * @param {?proto.sms.SMSMessage|undefined} value
 * @return {!proto.sms.Conversation} returns this
 */
proto.sms.Conversation.prototype.setMessage = function (value) {
  return jspb.Message.setWrapperField(this, 3, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.sms.Conversation} returns this
 */
proto.sms.Conversation.prototype.clearMessage = function () {
  return this.setMessage(undefined)
}

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sms.Conversation.prototype.hasMessage = function () {
  return jspb.Message.getField(this, 3) != null
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.SendMessageRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.SendMessageRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.SendMessageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.SendMessageRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        senderUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        body: jspb.Message.getFieldWithDefault(msg, 3, ''),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        accessToken: jspb.Message.getFieldWithDefault(msg, 5, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.SendMessageRequest}
 */
proto.sms.SendMessageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.SendMessageRequest()
  return proto.sms.SendMessageRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.SendMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.SendMessageRequest}
 */
proto.sms.SendMessageRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setSenderUserId(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setBody(value)
        break
      case 4:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      case 5:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.SendMessageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.SendMessageRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.SendMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.SendMessageRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getSenderUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getBody()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      4,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(5, f)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.SendMessageRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SendMessageRequest} returns this
 */
proto.sms.SendMessageRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 sender_user_id = 2;
 * @return {number}
 */
proto.sms.SendMessageRequest.prototype.getSenderUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SendMessageRequest} returns this
 */
proto.sms.SendMessageRequest.prototype.setSenderUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string body = 3;
 * @return {string}
 */
proto.sms.SendMessageRequest.prototype.getBody = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SendMessageRequest} returns this
 */
proto.sms.SendMessageRequest.prototype.setBody = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * map<string, string> metadata = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sms.SendMessageRequest.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 4, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sms.SendMessageRequest} returns this
 */
proto.sms.SendMessageRequest.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

/**
 * optional string access_token = 5;
 * @return {string}
 */
proto.sms.SendMessageRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SendMessageRequest} returns this
 */
proto.sms.SendMessageRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.SendMessageResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.SendMessageResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.SendMessageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.SendMessageResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.SendMessageResponse}
 */
proto.sms.SendMessageResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.SendMessageResponse()
  return proto.sms.SendMessageResponse.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.SendMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.SendMessageResponse}
 */
proto.sms.SendMessageResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.SendMessageResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.SendMessageResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.SendMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.SendMessageResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.sms.SendMessageResponse.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.SendMessageResponse} returns this
 */
proto.sms.SendMessageResponse.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.UpdateMessageRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.UpdateMessageRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.UpdateMessageRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.UpdateMessageRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
        metadataMap: (f = msg.getMetadataMap())
          ? f.toObject(includeInstance, undefined)
          : [],
        accessToken: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.UpdateMessageRequest}
 */
proto.sms.UpdateMessageRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.UpdateMessageRequest()
  return proto.sms.UpdateMessageRequest.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.UpdateMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.UpdateMessageRequest}
 */
proto.sms.UpdateMessageRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      case 2:
        var value = msg.getMetadataMap()
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readString,
            null,
            '',
            ''
          )
        })
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.UpdateMessageRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.UpdateMessageRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.UpdateMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.UpdateMessageRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getMetadataMap(true)
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      2,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeString
    )
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.sms.UpdateMessageRequest.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.UpdateMessageRequest} returns this
 */
proto.sms.UpdateMessageRequest.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * map<string, string> metadata = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.sms.UpdateMessageRequest.prototype.getMetadataMap = function (
  opt_noLazyCreate
) {
  return /** @type {!jspb.Map<string,string>} */ (
    jspb.Message.getMapField(this, 2, opt_noLazyCreate, null)
  )
}

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sms.UpdateMessageRequest} returns this
 */
proto.sms.UpdateMessageRequest.prototype.clearMetadataMap = function () {
  this.getMetadataMap().clear()
  return this
}

/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.sms.UpdateMessageRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.UpdateMessageRequest} returns this
 */
proto.sms.UpdateMessageRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.UpdateMessageResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.UpdateMessageResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.UpdateMessageResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.UpdateMessageResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        id: jspb.Message.getFieldWithDefault(msg, 1, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.UpdateMessageResponse}
 */
proto.sms.UpdateMessageResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.UpdateMessageResponse()
  return proto.sms.UpdateMessageResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.UpdateMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.UpdateMessageResponse}
 */
proto.sms.UpdateMessageResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.UpdateMessageResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.UpdateMessageResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.UpdateMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.UpdateMessageResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
}

/**
 * optional sint32 id = 1;
 * @return {number}
 */
proto.sms.UpdateMessageResponse.prototype.getId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.UpdateMessageResponse} returns this
 */
proto.sms.UpdateMessageResponse.prototype.setId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.SearchMessagesRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.SearchMessagesRequest.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.SearchMessagesRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.SearchMessagesRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        text: jspb.Message.getFieldWithDefault(msg, 1, ''),
        accessToken: jspb.Message.getFieldWithDefault(msg, 2, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.SearchMessagesRequest}
 */
proto.sms.SearchMessagesRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.SearchMessagesRequest()
  return proto.sms.SearchMessagesRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.SearchMessagesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.SearchMessagesRequest}
 */
proto.sms.SearchMessagesRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString())
        msg.setText(value)
        break
      case 2:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.SearchMessagesRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.SearchMessagesRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.SearchMessagesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.SearchMessagesRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getText()
  if (f.length > 0) {
    writer.writeString(1, f)
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(2, f)
  }
}

/**
 * optional string text = 1;
 * @return {string}
 */
proto.sms.SearchMessagesRequest.prototype.getText = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SearchMessagesRequest} returns this
 */
proto.sms.SearchMessagesRequest.prototype.setText = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value)
}

/**
 * optional string access_token = 2;
 * @return {string}
 */
proto.sms.SearchMessagesRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.SearchMessagesRequest} returns this
 */
proto.sms.SearchMessagesRequest.prototype.setAccessToken = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sms.SearchMessagesResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.SearchMessagesResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.SearchMessagesResponse.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.SearchMessagesResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.SearchMessagesResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        conversationsList: jspb.Message.toObjectList(
          msg.getConversationsList(),
          proto.sms.Conversation.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.SearchMessagesResponse}
 */
proto.sms.SearchMessagesResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.SearchMessagesResponse()
  return proto.sms.SearchMessagesResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.SearchMessagesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.SearchMessagesResponse}
 */
proto.sms.SearchMessagesResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = new proto.sms.Conversation()
        reader.readMessage(
          value,
          proto.sms.Conversation.deserializeBinaryFromReader
        )
        msg.addConversations(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.SearchMessagesResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.SearchMessagesResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.SearchMessagesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.SearchMessagesResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getConversationsList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sms.Conversation.serializeBinaryToWriter
    )
  }
}

/**
 * repeated Conversation conversations = 1;
 * @return {!Array<!proto.sms.Conversation>}
 */
proto.sms.SearchMessagesResponse.prototype.getConversationsList = function () {
  return /** @type{!Array<!proto.sms.Conversation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sms.Conversation, 1)
  )
}

/**
 * @param {!Array<!proto.sms.Conversation>} value
 * @return {!proto.sms.SearchMessagesResponse} returns this
 */
proto.sms.SearchMessagesResponse.prototype.setConversationsList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value)
}

/**
 * @param {!proto.sms.Conversation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sms.Conversation}
 */
proto.sms.SearchMessagesResponse.prototype.addConversations = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.sms.Conversation,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sms.SearchMessagesResponse} returns this
 */
proto.sms.SearchMessagesResponse.prototype.clearConversationsList =
  function () {
    return this.setConversationsList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.ConversationEventStreamRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.ConversationEventStreamRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.ConversationEventStreamRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.ConversationEventStreamRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        lastSeenMessage: jspb.Message.getFieldWithDefault(msg, 2, 0),
        accessToken: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.ConversationEventStreamRequest}
 */
proto.sms.ConversationEventStreamRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.ConversationEventStreamRequest()
  return proto.sms.ConversationEventStreamRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.ConversationEventStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.ConversationEventStreamRequest}
 */
proto.sms.ConversationEventStreamRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setUserId(value)
          break
        case 2:
          var value = /** @type {number} */ (reader.readSint32())
          msg.setLastSeenMessage(value)
          break
        case 3:
          var value = /** @type {string} */ (reader.readString())
          msg.setAccessToken(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.ConversationEventStreamRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.ConversationEventStreamRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.ConversationEventStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.ConversationEventStreamRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getLastSeenMessage()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.ConversationEventStreamRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.ConversationEventStreamRequest} returns this
 */
proto.sms.ConversationEventStreamRequest.prototype.setUserId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 last_seen_message = 2;
 * @return {number}
 */
proto.sms.ConversationEventStreamRequest.prototype.getLastSeenMessage =
  function () {
    return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
  }

/**
 * @param {number} value
 * @return {!proto.sms.ConversationEventStreamRequest} returns this
 */
proto.sms.ConversationEventStreamRequest.prototype.setLastSeenMessage =
  function (value) {
    return jspb.Message.setProto3IntField(this, 2, value)
  }

/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.sms.ConversationEventStreamRequest.prototype.getAccessToken =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
  }

/**
 * @param {string} value
 * @return {!proto.sms.ConversationEventStreamRequest} returns this
 */
proto.sms.ConversationEventStreamRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sms.ConversationEventStreamResponse.repeatedFields_ = [2]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.ConversationEventStreamResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.ConversationEventStreamResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.ConversationEventStreamResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.ConversationEventStreamResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        typingStatus:
          (f = msg.getTypingStatus()) &&
          proto.sms.TypingStatus.toObject(includeInstance, f),
        messagesList: jspb.Message.toObjectList(
          msg.getMessagesList(),
          proto.sms.SMSMessage.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.ConversationEventStreamResponse}
 */
proto.sms.ConversationEventStreamResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.ConversationEventStreamResponse()
  return proto.sms.ConversationEventStreamResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.ConversationEventStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.ConversationEventStreamResponse}
 */
proto.sms.ConversationEventStreamResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.sms.TypingStatus()
          reader.readMessage(
            value,
            proto.sms.TypingStatus.deserializeBinaryFromReader
          )
          msg.setTypingStatus(value)
          break
        case 2:
          var value = new proto.sms.SMSMessage()
          reader.readMessage(
            value,
            proto.sms.SMSMessage.deserializeBinaryFromReader
          )
          msg.addMessages(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.ConversationEventStreamResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.ConversationEventStreamResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.ConversationEventStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.ConversationEventStreamResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getTypingStatus()
  if (f != null) {
    writer.writeMessage(1, f, proto.sms.TypingStatus.serializeBinaryToWriter)
  }
  f = message.getMessagesList()
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sms.SMSMessage.serializeBinaryToWriter
    )
  }
}

/**
 * optional TypingStatus typing_status = 1;
 * @return {?proto.sms.TypingStatus}
 */
proto.sms.ConversationEventStreamResponse.prototype.getTypingStatus =
  function () {
    return /** @type{?proto.sms.TypingStatus} */ (
      jspb.Message.getWrapperField(this, proto.sms.TypingStatus, 1)
    )
  }

/**
 * @param {?proto.sms.TypingStatus|undefined} value
 * @return {!proto.sms.ConversationEventStreamResponse} returns this
 */
proto.sms.ConversationEventStreamResponse.prototype.setTypingStatus = function (
  value
) {
  return jspb.Message.setWrapperField(this, 1, value)
}

/**
 * Clears the message field making it undefined.
 * @return {!proto.sms.ConversationEventStreamResponse} returns this
 */
proto.sms.ConversationEventStreamResponse.prototype.clearTypingStatus =
  function () {
    return this.setTypingStatus(undefined)
  }

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sms.ConversationEventStreamResponse.prototype.hasTypingStatus =
  function () {
    return jspb.Message.getField(this, 1) != null
  }

/**
 * repeated SMSMessage messages = 2;
 * @return {!Array<!proto.sms.SMSMessage>}
 */
proto.sms.ConversationEventStreamResponse.prototype.getMessagesList =
  function () {
    return /** @type{!Array<!proto.sms.SMSMessage>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.sms.SMSMessage, 2)
    )
  }

/**
 * @param {!Array<!proto.sms.SMSMessage>} value
 * @return {!proto.sms.ConversationEventStreamResponse} returns this
 */
proto.sms.ConversationEventStreamResponse.prototype.setMessagesList = function (
  value
) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value)
}

/**
 * @param {!proto.sms.SMSMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sms.SMSMessage}
 */
proto.sms.ConversationEventStreamResponse.prototype.addMessages = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    2,
    opt_value,
    proto.sms.SMSMessage,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sms.ConversationEventStreamResponse} returns this
 */
proto.sms.ConversationEventStreamResponse.prototype.clearMessagesList =
  function () {
    return this.setMessagesList([])
  }

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.TypingStatus.prototype.toObject = function (opt_includeInstance) {
    return proto.sms.TypingStatus.toObject(opt_includeInstance, this)
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.TypingStatus} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.TypingStatus.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.TypingStatus}
 */
proto.sms.TypingStatus.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.TypingStatus()
  return proto.sms.TypingStatus.deserializeBinaryFromReader(msg, reader)
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.TypingStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.TypingStatus}
 */
proto.sms.TypingStatus.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.TypingStatus.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.TypingStatus.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.TypingStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.TypingStatus.serializeBinaryToWriter = function (message, writer) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.TypingStatus.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.TypingStatus} returns this
 */
proto.sms.TypingStatus.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.IndicateTypingEventRequest.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.IndicateTypingEventRequest.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.IndicateTypingEventRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.IndicateTypingEventRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        senderUserId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        accessToken: jspb.Message.getFieldWithDefault(msg, 3, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.IndicateTypingEventRequest}
 */
proto.sms.IndicateTypingEventRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.IndicateTypingEventRequest()
  return proto.sms.IndicateTypingEventRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.IndicateTypingEventRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.IndicateTypingEventRequest}
 */
proto.sms.IndicateTypingEventRequest.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setUserId(value)
        break
      case 2:
        var value = /** @type {number} */ (reader.readSint32())
        msg.setSenderUserId(value)
        break
      case 3:
        var value = /** @type {string} */ (reader.readString())
        msg.setAccessToken(value)
        break
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.IndicateTypingEventRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.IndicateTypingEventRequest.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.IndicateTypingEventRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.IndicateTypingEventRequest.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
  f = message.getUserId()
  if (f !== 0) {
    writer.writeSint32(1, f)
  }
  f = message.getSenderUserId()
  if (f !== 0) {
    writer.writeSint32(2, f)
  }
  f = message.getAccessToken()
  if (f.length > 0) {
    writer.writeString(3, f)
  }
}

/**
 * optional sint32 user_id = 1;
 * @return {number}
 */
proto.sms.IndicateTypingEventRequest.prototype.getUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.IndicateTypingEventRequest} returns this
 */
proto.sms.IndicateTypingEventRequest.prototype.setUserId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value)
}

/**
 * optional sint32 sender_user_id = 2;
 * @return {number}
 */
proto.sms.IndicateTypingEventRequest.prototype.getSenderUserId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0))
}

/**
 * @param {number} value
 * @return {!proto.sms.IndicateTypingEventRequest} returns this
 */
proto.sms.IndicateTypingEventRequest.prototype.setSenderUserId = function (
  value
) {
  return jspb.Message.setProto3IntField(this, 2, value)
}

/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.sms.IndicateTypingEventRequest.prototype.getAccessToken = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ''))
}

/**
 * @param {string} value
 * @return {!proto.sms.IndicateTypingEventRequest} returns this
 */
proto.sms.IndicateTypingEventRequest.prototype.setAccessToken = function (
  value
) {
  return jspb.Message.setProto3StringField(this, 3, value)
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.IndicateTypingEventResponse.prototype.toObject = function (
    opt_includeInstance
  ) {
    return proto.sms.IndicateTypingEventResponse.toObject(
      opt_includeInstance,
      this
    )
  }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.IndicateTypingEventResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.IndicateTypingEventResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {}

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.IndicateTypingEventResponse}
 */
proto.sms.IndicateTypingEventResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.IndicateTypingEventResponse()
  return proto.sms.IndicateTypingEventResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.IndicateTypingEventResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.IndicateTypingEventResponse}
 */
proto.sms.IndicateTypingEventResponse.deserializeBinaryFromReader = function (
  msg,
  reader
) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break
    }
    var field = reader.getFieldNumber()
    switch (field) {
      default:
        reader.skipField()
        break
    }
  }
  return msg
}

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.IndicateTypingEventResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter()
  proto.sms.IndicateTypingEventResponse.serializeBinaryToWriter(this, writer)
  return writer.getResultBuffer()
}

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.IndicateTypingEventResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.IndicateTypingEventResponse.serializeBinaryToWriter = function (
  message,
  writer
) {
  var f = undefined
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.MessageNotificationEventStreamRequest.prototype.toObject =
    function (opt_includeInstance) {
      return proto.sms.MessageNotificationEventStreamRequest.toObject(
        opt_includeInstance,
        this
      )
    }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.MessageNotificationEventStreamRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.MessageNotificationEventStreamRequest.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        accessToken: jspb.Message.getFieldWithDefault(msg, 1, ''),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.MessageNotificationEventStreamRequest}
 */
proto.sms.MessageNotificationEventStreamRequest.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.MessageNotificationEventStreamRequest()
  return proto.sms.MessageNotificationEventStreamRequest.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.MessageNotificationEventStreamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.MessageNotificationEventStreamRequest}
 */
proto.sms.MessageNotificationEventStreamRequest.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = /** @type {string} */ (reader.readString())
          msg.setAccessToken(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.MessageNotificationEventStreamRequest.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.MessageNotificationEventStreamRequest.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.MessageNotificationEventStreamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.MessageNotificationEventStreamRequest.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined
    f = message.getAccessToken()
    if (f.length > 0) {
      writer.writeString(1, f)
    }
  }

/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.sms.MessageNotificationEventStreamRequest.prototype.getAccessToken =
  function () {
    return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ''))
  }

/**
 * @param {string} value
 * @return {!proto.sms.MessageNotificationEventStreamRequest} returns this
 */
proto.sms.MessageNotificationEventStreamRequest.prototype.setAccessToken =
  function (value) {
    return jspb.Message.setProto3StringField(this, 1, value)
  }

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sms.MessageNotificationEventStreamResponse.repeatedFields_ = [1]

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sms.MessageNotificationEventStreamResponse.prototype.toObject =
    function (opt_includeInstance) {
      return proto.sms.MessageNotificationEventStreamResponse.toObject(
        opt_includeInstance,
        this
      )
    }

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sms.MessageNotificationEventStreamResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sms.MessageNotificationEventStreamResponse.toObject = function (
    includeInstance,
    msg
  ) {
    var f,
      obj = {
        fieldList: jspb.Message.toObjectList(
          msg.getFieldList(),
          proto.sms.SMSMessage.toObject,
          includeInstance
        ),
      }

    if (includeInstance) {
      obj.$jspbMessageInstance = msg
    }
    return obj
  }
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sms.MessageNotificationEventStreamResponse}
 */
proto.sms.MessageNotificationEventStreamResponse.deserializeBinary = function (
  bytes
) {
  var reader = new jspb.BinaryReader(bytes)
  var msg = new proto.sms.MessageNotificationEventStreamResponse()
  return proto.sms.MessageNotificationEventStreamResponse.deserializeBinaryFromReader(
    msg,
    reader
  )
}

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sms.MessageNotificationEventStreamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sms.MessageNotificationEventStreamResponse}
 */
proto.sms.MessageNotificationEventStreamResponse.deserializeBinaryFromReader =
  function (msg, reader) {
    while (reader.nextField()) {
      if (reader.isEndGroup()) {
        break
      }
      var field = reader.getFieldNumber()
      switch (field) {
        case 1:
          var value = new proto.sms.SMSMessage()
          reader.readMessage(
            value,
            proto.sms.SMSMessage.deserializeBinaryFromReader
          )
          msg.addField(value)
          break
        default:
          reader.skipField()
          break
      }
    }
    return msg
  }

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sms.MessageNotificationEventStreamResponse.prototype.serializeBinary =
  function () {
    var writer = new jspb.BinaryWriter()
    proto.sms.MessageNotificationEventStreamResponse.serializeBinaryToWriter(
      this,
      writer
    )
    return writer.getResultBuffer()
  }

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sms.MessageNotificationEventStreamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sms.MessageNotificationEventStreamResponse.serializeBinaryToWriter =
  function (message, writer) {
    var f = undefined
    f = message.getFieldList()
    if (f.length > 0) {
      writer.writeRepeatedMessage(
        1,
        f,
        proto.sms.SMSMessage.serializeBinaryToWriter
      )
    }
  }

/**
 * repeated SMSMessage field = 1;
 * @return {!Array<!proto.sms.SMSMessage>}
 */
proto.sms.MessageNotificationEventStreamResponse.prototype.getFieldList =
  function () {
    return /** @type{!Array<!proto.sms.SMSMessage>} */ (
      jspb.Message.getRepeatedWrapperField(this, proto.sms.SMSMessage, 1)
    )
  }

/**
 * @param {!Array<!proto.sms.SMSMessage>} value
 * @return {!proto.sms.MessageNotificationEventStreamResponse} returns this
 */
proto.sms.MessageNotificationEventStreamResponse.prototype.setFieldList =
  function (value) {
    return jspb.Message.setRepeatedWrapperField(this, 1, value)
  }

/**
 * @param {!proto.sms.SMSMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sms.SMSMessage}
 */
proto.sms.MessageNotificationEventStreamResponse.prototype.addField = function (
  opt_value,
  opt_index
) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    1,
    opt_value,
    proto.sms.SMSMessage,
    opt_index
  )
}

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sms.MessageNotificationEventStreamResponse} returns this
 */
proto.sms.MessageNotificationEventStreamResponse.prototype.clearFieldList =
  function () {
    return this.setFieldList([])
  }

goog.object.extend(exports, proto.sms)
