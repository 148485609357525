/**
 * I am deeply sorry aobut this, but I can't think of another way to do
 * this without actually stubbing grpc calls in a stub server.
 * But doing that would require setting up a grpc server for testing
 * which is a headache all its own. That can be a future improvement
 * of this, but for now, we need to stub out the vocClient completely
 * when we're in a test env. Mocking isn't good enough because we can't
 * mock grpc responses, since they are binary. So we have to swap
 * out the underlying implementation in test environments.
 */
// import { isTestEnv } from '@voltus/utils'
import * as realVocClient from './vocClientBase'
// import * as mockVocClient from './__mocks__/vocClient'

const client = realVocClient

const intializeTelemetryStream = client.intializeTelemetryStream

export { intializeTelemetryStream }
