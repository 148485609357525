import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { Navigate, RouteProps } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { useFeatureFlagContext } from '@voltus/feature-flags'
import { selectPermissions } from '@voltus/modules'
import { Permissions } from '@voltus/types'
import { ActivityIndicator } from '../ActivityIndicator'
import { Forbidden } from '../ErrorPages'

type StateProps<Perms = Permissions> = {
  permissions: Perms
}
type PredicateOpts<Perms = Permissions> = {
  permissions: Perms
}
type OwnProps<Perms = Permissions> = {
  predicate: (predicateOpts: PredicateOpts<Perms>) => Promise<boolean>
  fallback?: string
  children: React.ReactNode
  flag?: { value: string }
}

export type ProtectedRouteProps<Perms = Permissions> = StateProps<Perms> &
  RouteProps &
  OwnProps<Perms>

function ProtectedRouteBase<Perms = Permissions>({
  predicate,
  fallback,
  permissions,
  children,
  flag,
}: ProtectedRouteProps<Perms>): JSX.Element {
  const [state, setState] = React.useState({
    canView: false,
    isLoading: true,
  })
  const { variation, ready } = useFeatureFlagContext()

  React.useLayoutEffect(() => {
    if (state.isLoading === false) {
      return
    }

    const doAsyncTask = async () => {
      try {
        const canDo = await predicate({ permissions })
        if (canDo) {
          setState({
            isLoading: false,
            canView: true,
          })
        } else {
          setState({
            isLoading: false,
            canView: false,
          })
        }
      } catch (_) {
        setState({
          isLoading: false,
          canView: false,
        })
      }
    }
    doAsyncTask()
  })

  if (!state.isLoading) {
    if (flag?.value) {
      if (state.canView && ready && variation(flag.value)) {
        return <>{children}</>
      }

      return fallback ? <Navigate replace to={fallback} /> : <Forbidden />
    }

    if (state.canView) {
      return <>{children}</>
    }

    return fallback ? <Navigate replace to={fallback} /> : <Forbidden />
  }
  return <ActivityIndicator />
}

const ProtectedRoute: (props: OwnProps<Permissions> & RouteProps) => ReactNode =
  connect<StateProps<Permissions>, undefined, ProtectedRouteProps<Permissions>>(
    createStructuredSelector({
      permissions: selectPermissions,
    })
  )(ProtectedRouteBase)

export { ProtectedRoute, ProtectedRouteBase }
