import { useQueries, UseQueryResult } from '@tanstack/react-query'
import { getDispatchDetail } from '../../../routes/dispatches/routes/ActiveDispatches/api/vocAdminClient'

export const useDispatchDetails = ({
  dispatches,
  enabled,
}: {
  dispatches: Array<{ dispatchId: number; portfolioId?: number }>
  enabled: boolean
}): Array<UseQueryResult> => {
  const queries = dispatches.map(({ dispatchId, portfolioId }) => ({
    queryKey: ['getDispatchDetails', dispatchId, portfolioId],
    queryFn: () => getDispatchDetail(dispatchId, portfolioId),
    enabled: enabled && !!portfolioId,
  }))
  return useQueries({ queries })
}
