import * as React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { PATHS } from '@voltus/constants'
import {
  Button,
  Text,
  BUTTON_KIND,
  Flex,
  ButtonProps,
} from '@voltus/core-components'
import { eventTracker, EVENTS, PROPERTIES } from '@voltus/event-tracking'
import { selectPermissions } from '@voltus/modules'
import { Permissions } from '@voltus/types'
import {
  disableDemo,
  enableDemo,
  isDemoEnabled,
  stopProp,
  useZendeskWidget,
} from '@voltus/utils'
import { getCurrentURL } from '@voltus/utils'

/**
 * This isn't great, but we are trying to match
 * the height of the nav items, which are 30 px because
 * they have a line height of 22px + 4px padding on top and bottom
 * for a whopping total of 30px
 */
const BUTTON_HEIGHT = 30

/**
 * A transparent button for performing on-page actions in the Help menu.
 */
const HelpButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      height={BUTTON_HEIGHT}
      kind={BUTTON_KIND.TRANSPARENT}
      textAlign="left"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      {...props}
    >
      {children}
    </Button>
  )
}

/**
 * A transprent link for performing page navigation in the Help menu.
 */
const HelpLink = ({ children, ...props }: ButtonProps) => {
  return (
    <HelpButton
      as="a"
      target="_blank"
      textDecoration="none"
      justifyContent="flex-start !important"
      {...props}
    >
      {children}
    </HelpButton>
  )
}

type StateProps = {
  permissions: Permissions
}

const HelpMenu = ({ permissions }: StateProps) => {
  const { showWidget } = useZendeskWidget()

  return (
    <Flex.Column onClick={stopProp()} px={1} py={2}>
      <Text
        py={1}
        px={3}
        fontSize={4}
        fontWeight="bold"
        color="voltus-text-color"
        borderBottomStyle="solid"
        borderBottomColor="grays.10"
        borderBottomWidth="1px"
      >
        Help
      </Text>
      <Flex.Column>
        <HelpButton onClick={showWidget}>Contact Support</HelpButton>
        <HelpLink
          href={PATHS.EXTERNAL_HELP_CENTER}
          onClick={() => {
            eventTracker.track(EVENTS.CLICK_HELP_CENTER, {
              [PROPERTIES.NAVIGATE_FROM]: getCurrentURL(),
              [PROPERTIES.NAVIGATE_TO]: PATHS.EXTERNAL_HELP_CENTER,
            })
          }}
        >
          Help Center
        </HelpLink>
        {permissions.viewAdmin ? (
          <HelpButton onClick={isDemoEnabled() ? disableDemo : enableDemo}>
            {isDemoEnabled() ? 'Disable' : 'Enable'} Demo Mode
          </HelpButton>
        ) : null}
      </Flex.Column>
    </Flex.Column>
  )
}

const mapStateToProps = createStructuredSelector({
  permissions: selectPermissions,
})

export default connect<StateProps>(mapStateToProps)(HelpMenu)
