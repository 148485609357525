import * as React from 'react'
import Select, { GroupBase, SelectInstance, createFilter } from 'react-select'
import { DropdownOption } from '@voltus/types'
import { renderNode } from '@voltus/utils'
import { Box } from '../Box'
import { Text } from '../Text'
import {
  CustomLoadingIndicator,
  CustomMenuList,
  CustomOption,
  CustomDropdownIndicator,
} from './DropdownCustomComponents'
import { getValueOption } from './helpers'
import { useStyles } from './styles'
import { DropdownProps } from './types'

/*
 * According to react-select docs, this is how you should type the extra `selectProps`
 * that you pass through the select to custom components
 * https://react-select.com/typescript#custom-select-props
 */
declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line
    IsMulti extends boolean,
    // eslint-disable-next-line
    Group extends GroupBase<Option>,
  > {
    isFetching?: boolean
    /**
     * Used to properly style custom multi select dropdown components
     */
    isTreeSelect?: boolean
    /**
     * Used as the label that appears in the dropdown when multiple values are selected and the
     * Dropdown is closed
     */
    multiValueLabelSuffix?: string
  }
}

type PortalProps = {
  menuPortalTarget: HTMLElement | null
}

export function Dropdown<
  /* Extends react-select built-in props: https://react-select.com/typescript#select-generics  */
  ValueType,
  Option extends DropdownOption<ValueType>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  dropdownRef,
  background = 'default',
  isMulti,
  isLoading = false,
  isSearchable = false,
  isVirtualized,
  options,
  value,
  onChange,
  closeMenuOnSelect = true,
  closeMenuOnScroll = false,
  rowHeight,
  label,
  portalTarget,
  components,
  menuPlacement = 'auto',
  inputId,
  onInputChange,
  size = 'large',
  ...props
}: DropdownProps<ValueType, Option, IsMulti, Group>): JSX.Element {
  // If the user does not pass in an inputId, then we will generate a unique id
  // to link the Dropdown's label with its hidden input.
  // Typically a user would pass in an inputId if they want to provide their
  // own custom label that isn't in the default position above the Dropdown.
  //
  // <Dropdown inputId="foo" />
  // <label htmlFor="foo">My Custom Label</label>
  //
  // Doing so allows a user to click on the label to focus the Dropdown.
  // This is a nice accessibility feature and can make testing easier.
  const uniqueId = React.useId()
  // Prefer name over everything else so it can act like a form field
  const selectId = props.name ? props.name : inputId ?? uniqueId

  // The ref is hard to type - I'm really not sure what type it's supposed to be
  const selectRef = React.useRef<SelectInstance<Option, IsMulti, Group> | null>(
    null
  )
  const valueOption = getValueOption<ValueType, Option, IsMulti, Group>({
    value,
    options,
    isMulti,
  })
  const portalProps: PortalProps = {
    menuPortalTarget: null,
  }

  const renderLabel = (label) => {
    if (typeof label === 'function') {
      return renderNode(label)
    }

    return <Text.Helper fontWeight="bold">{label}</Text.Helper>
  }

  const styles = useStyles<Option, IsMulti, Group>({
    size,
    background,
    stylesFromProps: props.styles,
  })

  let portalStyles = {}
  if (portalTarget) {
    if (typeof portalTarget === 'boolean') {
      portalProps.menuPortalTarget = document.body
    } else if (typeof portalTarget === 'object') {
      portalProps.menuPortalTarget = portalTarget
    }
    portalStyles = {
      menuList: (base, state) => {
        return {
          maxHeight: 400,
          ...styles.menuList?.(base, state),
        }
      },
      menuPortal: (base, state) => ({
        ...base,
        zIndex: 12000,
        ...(styles.menuPortal?.(base, state) ?? {}),
      }),
    }
  }

  return (
    <div data-testid={`Dropdown-${selectId}`}>
      {label ? (
        <Box as="label" mb={1} display="inline-block" htmlFor={selectId}>
          {renderLabel(label)}
        </Box>
      ) : null}
      <Select
        inputId={selectId}
        menuPlacement={menuPlacement}
        ref={(ref) => {
          selectRef.current = ref
          if (dropdownRef) {
            dropdownRef.current = ref
          }
        }}
        value={valueOption}
        background={background}
        isMulti={isMulti}
        isLoading={isLoading}
        options={options}
        openMenuOnFocus
        closeMenuOnScroll={closeMenuOnScroll}
        closeMenuOnSelect={closeMenuOnSelect}
        isVirtualized={isVirtualized}
        isSearchable={isSearchable}
        onChange={onChange}
        rowHeight={rowHeight}
        size={size}
        filterOption={createFilter({
          ignoreAccents: false,
        })}
        {...portalProps}
        onInputChange={(val, actionMeta) => {
          if (actionMeta.action === 'input-change') {
            // There's a weird bug where if you type to search
            // and there's no value, then after the first character
            // the input loses focus, meaning you can't type anymore
            // to hack around this, forcibly re-focus the input after
            // a change event
            // the setTimeout is to ensure this happens after the next render
            // eslint-disable-next-line
            // @ts-ignore
            setTimeout(() => selectRef.current?.focus())
            onInputChange?.(val, actionMeta)
          }
        }}
        {...props}
        components={{
          MenuList: CustomMenuList,
          LoadingIndicator: CustomLoadingIndicator,
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
          ...components,
        }}
        styles={{
          ...styles,
          ...portalStyles,
        }}
      />
    </div>
  )
}
