export const DISPATCH_DROPDOWN_OPTIONS = {
  ALL_DISPATCHES: {
    value: 'all',
    label: 'All Dispatches',
  },
  ACTIVE_DISPATCHES: {
    value: 'active',
    label: 'Active Dispatches',
  },
  PAST_DISPATCHES: {
    value: 'past',
    label: 'Past Dispatches',
  },
  CLAIMED_SITES: {
    value: 'claimed',
    label: 'All My Claimed Sites',
  },
}
