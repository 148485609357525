import qs from 'query-string'
import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getResolvedPath } from '@voltus/utils'
import { PATHS } from './constants/routes'

/**
 * Our "old" cashdash - known as earnings dash, is no longer in production
 * so we want to redirect users to the new dashboard
 *
 * We can't just use a plain old react-router Redirect, though,
 * as we want to be sort of smart and bring along any query params
 * in the redirect itself
 */
export const useOldEarningsRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()

  React.useEffect(() => {
    if (
      location.pathname === '/earnings' ||
      location.pathname === '/earnings/'
    ) {
      const { portfolioId, startDate, endDate } = qs.parse(location.search)
      if (portfolioId) {
        navigate(
          {
            pathname: getResolvedPath(PATHS.CASHDASH, [portfolioId]),
            search: qs.stringify({ startDate, endDate }, { skipNull: true }),
          },
          { replace: true }
        )
      } else {
        navigate(
          {
            pathname: PATHS.CASHDASH_HOME,
            search: qs.stringify({ startDate, endDate }, { skipNull: true }),
          },
          { replace: true }
        )
      }
    }
  }, [navigate, location])
}
