import { useMutation } from '@tanstack/react-query'
import { IndicateTypingEventRequest } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { indicateTypingEvent } from '../../../routes/dispatches/routes/ActiveDispatches/api/smsClient'

export const useIndicateTypingEvent = () => {
  return useMutation({
    mutationFn: (
      indicateTypingEventRequest: Omit<
        IndicateTypingEventRequest.AsObject,
        'accessToken'
      >
    ) => indicateTypingEvent(indicateTypingEventRequest),
  })
}
