import { format } from 'date-fns'
import React from 'react'
import { eventChannel, END } from 'redux-saga'
import { all, take, select, takeEvery, call, put } from 'redux-saga/effects'
import { Flex, Toast, Text } from '@voltus/core-components'
import logger, { debugLogger } from '@voltus/logger'
import { selectUserId } from '@voltus/modules'
import { initializeEventsStream } from '../../api/vocAdminClient'
import { CallCreators } from '../calling'
import { CheckInCreators, selectAllUsers } from '../checkin'
import { selectCurrentDispatch, selectSnapshotFacilities } from '../dispatch'
import { NotesCreators } from '../notes'
import { SiteAssignmentsCreators } from '../siteAssignments'
import { selectIsMobile } from '../ui'
import { EventStreamTypes } from './eventStream.redux'

const getToastComponent = (isAssignment, isEscalation) => {
  if (isEscalation) {
    return Toast.Error
  }
  if (!isAssignment) {
    return Toast.Warning
  }

  return Toast.Success
}

const getToastMessage = ({
  isAssignment,
  isEscalation,
  reason,
  reasonBody,
  site,
  programName,
  assigningUser,
  timestamp,
}) => {
  const time = format(new Date(timestamp), 'hh:mm a')
  let message = ''

  if (isAssignment) {
    if (isEscalation) {
      if (reasonBody) {
        message = (
          <>
            <strong>Escalated to you</strong>: {reasonBody}
          </>
        )
      } else if (reason) {
        message = (
          <>
            <strong>Escalated to you</strong>: {reason}
          </>
        )
      }
    } else {
      message = 'Assigned'
    }
  } else {
    message = 'Unassigned'
  }

  return (
    <Flex.Column width="100%">
      <Flex.Row width="100%">
        <Text.Title
          fontSize={2}
          mb={1}
          flex={1}
          fontWeight="bold"
          textSize="large"
        >
          {site.name}
        </Text.Title>
        <Flex.Column>
          <Text.Helper
            textAlign="right"
            fontSize={1}
            flex={0}
            css={{ whiteSpace: 'nowrap' }}
          >
            {time}
          </Text.Helper>
          {assigningUser && (
            <Text.Helper
              display="block"
              fontSize={1}
              width="100%"
              textAlign="right"
            >
              by {assigningUser.fullName}
            </Text.Helper>
          )}
        </Flex.Column>
      </Flex.Row>
      <Text.Paragraph fontSize={1} mb={1}>
        {programName}
      </Text.Paragraph>
      <Text.Helper fontSize={1}>{message}</Text.Helper>
    </Flex.Column>
  )
}

function* showAssignmentToast({
  reason,
  reasonBody,
  userId,
  facilityId,
  isAssignment,
  isEscalation,
  assigningUserId,
  createdAt,
}) {
  const myId = yield select(selectUserId)
  const details = yield select(selectCurrentDispatch)
  const facilities = yield select(selectSnapshotFacilities)
  const allUsers = yield select(selectAllUsers)
  const fac = facilities.find((f) => f.id === facilityId)
  if (userId === myId && fac) {
    const Comp = getToastComponent(isAssignment, isEscalation)
    const message = getToastMessage({
      isAssignment,
      isEscalation,
      reason,
      reasonBody,
      site: fac,
      programName: details.programName,
      assigningUser: allUsers[assigningUserId],
      timestamp: createdAt,
    })
    const isMobile = yield select(selectIsMobile)
    if (!isMobile) {
      Toast.push(<Comp>{message}</Comp>, {
        autoClose: 5000,
      })
    }
  }
}

const channels = {}
const createEventStreamChannel = (dispatchId) => {
  const channel = eventChannel((emitter) => {
    const client = initializeEventsStream({
      dispatchId,
      onMessage: emitter,
      onEnd: () => {
        emitter(END)
      },
    })
    return () => {
      channels[dispatchId] = null
      client.close()
      client.clear()
    }
  })
  channels[dispatchId] = channel
  return channel
}

function* bootstrapEventStreamForDispatch({ dispatchId }) {
  try {
    let channel
    if (channels[dispatchId]) {
      channel = channels[dispatchId]
    } else {
      channel = yield call(createEventStreamChannel, dispatchId)
    }

    while (true) {
      const data = yield take(channel)
      const { event } = data
      const { assignment, call: phoneCall, note, userDispatchState } = event
      debugLogger.info('VOC-admin event stream msg', event)
      if (assignment) {
        const myUserId = yield select(selectUserId)
        yield put(SiteAssignmentsCreators.receiveSiteAssignments([assignment]))
        yield put(NotesCreators.receiveNote(event))

        // if the assinging user and the assigned user are the same
        // It means I've assigned something to myself,
        // so no need to show a toast
        if (+assignment?.assigningUserId !== +myUserId) {
          yield call(showAssignmentToast, assignment)
        }
      }
      if (phoneCall) {
        yield put(CallCreators.setCall(phoneCall))
        yield put(NotesCreators.receiveNote(event))
      }
      if (userDispatchState) {
        yield put(CheckInCreators.receiveCheckIn(userDispatchState))
        yield put(NotesCreators.receiveNote(event))
      }
      if (note) {
        yield put(NotesCreators.receiveNote(event))
      }
    }
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
  }
}

function* resetEventStreamForDispatch({ dispatchId }) {
  yield channels[dispatchId]?.close()
}

export function* watchEventStream() {
  yield all([
    takeEvery(EventStreamTypes.BOOTSTRAP, bootstrapEventStreamForDispatch),
    takeEvery(EventStreamTypes.RESET, resetEventStreamForDispatch),
  ])
}
