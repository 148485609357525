import { decamelizeKeys } from 'humps'
import qs, { ParseOptions } from 'query-string'
import { AnyObject } from '@voltus/types'
import { safeClone } from '@voltus/utils'
import { network, NetworkOpts } from './network'

type Config = {
  url: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  params?: AnyObject
  data?: RequestInit['body'] | AnyObject | null
  headers?: Record<string, string>
  responseType?: string
}

type Options = NetworkOpts & {
  /**
   * Automatically snake case the request query string params
   * Defaults to true
   */
  decamelizeParams?: boolean
  /**
   * Automatically snakeCase the request body
   * Defaults to true
   */
  decamelizeBody?: boolean
  /**
   * Configuration options for https://github.com/sindresorhus/query-string
   * @example `queryStringOptions: { arrayFormat: 'comma' },`
   */
  queryStringOptions?: ParseOptions
}

const getParams = (
  params: Config['params'],
  decamelizeParams: Options['decamelizeParams'] = true
) => {
  if (!params) {
    return
  }

  return decamelizeParams ? decamelizeKeys(params) : params
}

const getBody = (
  data: Config['data'],
  decamelizeBody: Options['decamelizeBody'] = true
) => {
  if (!data) {
    return
  }

  return decamelizeBody ? decamelizeKeys(data) : data
}

const getUrl = (
  url: Config['url'],
  params: ReturnType<typeof getParams> = {},
  queryStringOptions: Options['queryStringOptions'] = {}
) => {
  const searchParams = qs.stringify(params, {
    skipNull: true,
    ...queryStringOptions,
  })
  return searchParams.length ? `${url}?${searchParams.toString()}` : url
}

export const httpClient = async <T>(config: Config, options: Options = {}) => {
  const { url, method, params, data, headers } = config

  const fetcher = network[method.toLowerCase() as Lowercase<`${typeof method}`>]
  const formattedParams = getParams(params, options?.decamelizeParams)
  const formattedBody = getBody(data, options?.decamelizeBody)
  const fullUrl = getUrl(url, formattedParams, options.queryStringOptions)

  // Remove the options that are specific to this util,
  // and pass the pruned object to the network util.
  const opts = safeClone(options)
  delete opts?.decamelizeBody
  delete opts?.decamelizeParams
  if (formattedBody) {
    opts.body = JSON.stringify(formattedBody)
  }
  if (headers) {
    opts.headers = headers
  }

  const response = await fetcher<T>(fullUrl, opts)

  return response
}
