import { StylesConfig, GroupBase } from 'react-select'
import { AnyObject } from '@voltus/types'
import { useTheme } from '../ThemeProvider'
import { DropdownSize, Background } from './types'

type StyleFnArgs = {
  size: DropdownSize
  background: Background
  theme: ReturnType<typeof useTheme>
}

const controlStylesFn =
  ({ size, background, theme }: StyleFnArgs) =>
  (base, state) => {
    const { colors, space, radii, shadows } = theme
    const stateStyles: AnyObject = {}
    const backgroundStyles =
      background === 'light'
        ? {
            backgroundColor: state.isDisabled
              ? colors.grays['10']
              : colors['color-white'],
            border: `1px solid ${colors.borderColors.primary}`,
            '&:hover': {
              border: `1px solid ${colors.grays['60']}`,
            },
          }
        : {}
    if (state.isFocused) {
      stateStyles.boxShadow = `inset ${shadows.focus}`
    }
    const smallStyles =
      size === 'small'
        ? {
            minHeight: 22,
            padding: `0 ${space['1']}`,
          }
        : {}
    return {
      ...base,
      border: 'none',
      borderRadius: radii['1'],
      backgroundColor: colors.grays['15'],
      cursor: 'pointer',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: colors.grays['20'],
      },
      ...stateStyles,
      ...smallStyles,
      ...backgroundStyles,
    }
  }

const valueContainerStylesFn =
  ({ size, theme }: Omit<StyleFnArgs, 'background'>) =>
  (base, state) => {
    const { colors, space, fontSizes } = theme
    return {
      ...base,
      padding: `0 ${space['2']}`,
      fontSize: size === 'small' ? fontSizes['2'] : fontSizes['2'],
      fontWeight: 600,
      color: colors.textColor.main,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    }
  }

const optionStylesFn =
  ({ size, theme }: Omit<StyleFnArgs, 'background'>) =>
  (base, state) => {
    const { colors, fontSizes } = theme
    const stateStyles: AnyObject = {}
    if (state.isDisabled) {
      stateStyles.color = colors.grays['20']
      stateStyles.cursor = 'not-allowed'
    } else if (state.isSelected) {
      stateStyles.backgroundColor = colors.greens['30']
      stateStyles.color = 'white'
    } else if (state.isFocused) {
      stateStyles.backgroundColor = colors.greens['30']
      stateStyles.color = 'white'
    }

    const sizeStyles =
      size === 'small'
        ? {
            minHeight: 22,
            fontSize: fontSizes['1'],
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center',
          }
        : {}

    return {
      ...base,
      backgroundColor: 'transparent',
      color: colors.textColor.main,
      borderBottom: `0.0625rem solid ${colors['voltus-input-border-color']}`,
      fontSize: fontSizes['2'],
      fontWeight: 600,
      cursor: 'pointer',
      '&:active': {
        backgroundColor: colors.greens['30'],
      },
      ...sizeStyles,
      ...stateStyles,
    }
  }

/**
 * Apply our custom styles to react-select.
 * Additionally take in styles functions from props and conditionally
 * call them if they exist, allowing consumers to override any style
 */
export function useStyles<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  size,
  background,
  stylesFromProps,
}: {
  size: DropdownSize
  background: Background
  stylesFromProps?: StylesConfig<Option, IsMulti, Group>
}): StylesConfig<Option, IsMulti, Group> {
  const theme = useTheme()
  const { space, colors, shadows } = theme

  return {
    ...stylesFromProps,
    input: (base, state) => {
      const styles = {
        ...base,
        padding: 0,
        margin: `0 ${space['1']}`,
      }
      // Here we allow consumers to override styles by passing our resolved
      // styles back to the function we got from props.
      // If the function doesn't exist, simply return our custom styles
      return stylesFromProps?.input?.(styles, state) ?? styles
    },
    control: (base, state) => {
      const styles = controlStylesFn({ size, background, theme })(base, state)
      return stylesFromProps?.control?.(styles, state) ?? styles
    },
    valueContainer: (base, state) => {
      const styles = valueContainerStylesFn({ size, theme })(base, state)
      return stylesFromProps?.valueContainer?.(styles, state) ?? styles
    },
    indicatorSeparator: (base, state) => {
      const styles = {
        ...base,
        display: 'none',
      }
      return stylesFromProps?.indicatorSeparator?.(styles, state) ?? styles
    },
    multiValueLabel: (base, state) => {
      const styles = {
        ...base,
        color: state.isDisabled ? colors.grays['40'] : colors.textColor.main,
        padding: size === 'small' ? 0 : `3px 4px`,
      }
      return stylesFromProps?.multiValueLabel?.(styles, state) ?? styles
    },
    multiValueRemove: (base, state) => {
      const styles = {
        ...base,
        color: state.isDisabled ? colors.grays['40'] : colors.textColor.main,
      }
      return stylesFromProps?.multiValueRemove?.(styles, state) ?? styles
    },
    indicatorsContainer: (base, state) => {
      const styles = {
        ...base,
        padding: 0,
      }
      return stylesFromProps?.indicatorsContainer?.(styles, state) ?? styles
    },
    dropdownIndicator: (base, state) => {
      const styles = {
        ...base,
        color: colors.textColor.main,
        padding: size === 'small' ? 0 : space['1.5'],
      }
      return stylesFromProps?.dropdownIndicator?.(styles, state) ?? styles
    },
    clearIndicator: (base, state) => {
      const styles = {
        ...base,
        color: colors.textColor.main,
        padding: size === 'small' ? 0 : space['1.5'],
      }
      return stylesFromProps?.clearIndicator?.(styles, state) ?? styles
    },
    menu: (base, state) => {
      const styles = {
        ...base,
        background: colors.background.primary,
        borderTop: 0,
        borderRadius: 0,
        marginTop: 0,
        zIndex: 30,
        boxShadow: shadows['1'],
      }
      return stylesFromProps?.menu?.(styles, state) ?? styles
    },
    menuList: (base, state) => {
      const styles = {
        ...base,
        padding: 0,
      }
      return stylesFromProps?.menuList?.(styles, state) ?? styles
    },
    option: (base, state) => {
      const styles = optionStylesFn({ size, theme })(base, state)
      return stylesFromProps?.option?.(styles, state) ?? styles
    },
  }
}
