import { useQuery } from '@tanstack/react-query'
import { queryKeys, network, endpoints } from '@voltus/network'

type Organization = {
  name: string
  id: number | null
  organizationId?: number | null
}

type Manager = {
  fullName: string
  phone: string
  email: string
  organization: Organization
}

export type ProfileQueryResult = {
  id: number | null
  role: string
  firstName: string
  lastName: string
  phone: string
  landlinePhone: string | null
  fullName: string
  email: string
  salesforceId: string | null
  accountManagers: Array<Manager>
  organization: Organization
  organizationId: number
  permissions?: Array<string>
}

type UseProfileQueryOptions = {
  enabled?: boolean
}

export const useProfileQuery = ({
  enabled = true,
}: UseProfileQueryOptions = {}) => {
  return useQuery<ProfileQueryResult>({
    queryKey: queryKeys.userProfile(),
    queryFn: () => network.get(endpoints.profile.user()),
    enabled,
    staleTime: 1000 * 60 * 60,
  })
}
