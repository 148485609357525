import produce from 'immer'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: GlobalsTypes, Creators: GlobalsActions } = createActions(
  {
    setPortfolioId: ['portfolioId'],
    setPortfolioName: ['portfolioName'],
    setPortfolioPermissions: ['permissions'],
  },
  { prefix: 'GLOBALS/' }
)

const initialState = {
  portfolioId: null,
}

const setPortfolioId = produce((draft, { portfolioId }) => {
  if (portfolioId) {
    draft.portfolioId = parseInt(portfolioId, 10)
  }
})
const setPortfolioName = produce((draft, { portfolioName }) => {
  draft.portfolioName = portfolioName
})

export const globalsReducer = createReducer(initialState, {
  [GlobalsTypes.SET_PORTFOLIO_ID]: setPortfolioId,
  [GlobalsTypes.SET_PORTFOLIO_NAME]: setPortfolioName,
})
