import { matchPath, useNavigate, useLocation } from 'react-router-dom'
import { PATHS } from '@voltus/constants'
import { Profile } from '@voltus/types'

/**
 * This is a major major hack that absolutely should not be repeated.
 * We have a temporary need to only allow residential customers to view cashdash
 * because of concerns that other parts of the platform have incorrect or misleading
 * data. We aren't using user roles or user permissions because we'd have to create a new
 * role to restrict to cashdash only, but we'd also have to decouple the VIEW_EARNINGS
 * permission since that (for better or worse) also controls access to other parts of the platform.
 * As a result - we're doing a client side hack to look at a user attribute rather than role
 * or permissions to determine if they are "residential" customer and thus only allowed to see cashdash.
 */
const allowedPaths = [
  `${PATHS.CASHDASH_HOME}/*`,
  `${PATHS.ACCOUNT_MANAGEMENT_ROOT}/*`,
]

export const useCashdashOnlyRedirect = ({ profile }: { profile?: Profile }) => {
  const navigate = useNavigate()
  const location = useLocation()
  if (!profile?.cashdashOnly) {
    return
  }

  if (
    allowedPaths.some((path) => matchPath({ path }, location.pathname) !== null)
  ) {
    return
  }

  navigate(PATHS.CASHDASH_HOME, { replace: true })
}
