import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '../Box'
import { Card } from '../Card'
import { Text } from '../Text'

type NotFoundProps = {
  fallbackMessage?: string
  fallbackUrl?: string
  title?: string
  message?: string
  LinkComponent?:
    | ((linkProps: { href: string; to: string }) => JSX.Element)
    | typeof Link
}

export function NotFound({
  fallbackMessage = 'Return to main page',
  fallbackUrl = '/',
  title = 'Page not found',
  message = "Sorry, we couldn't find that resource.",
  LinkComponent = Link,
}: NotFoundProps) {
  return (
    <Box width="100%" maxWidth={750} ml="auto" mr="auto" mt={4}>
      <Card>
        <Card.Content>
          <Text.Headline mb={2} textSize="medium">
            {title}
          </Text.Headline>
          <Text.Paragraph mb={2}>{message}</Text.Paragraph>
          <LinkComponent href={fallbackUrl} to={fallbackUrl}>
            {fallbackMessage}
          </LinkComponent>
        </Card.Content>
      </Card>
    </Box>
  )
}
