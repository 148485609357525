import { isNotNull } from './isNotNull'

export const getErrorMessage = (
  error: unknown,
  fallback = 'Something went wrong'
) => {
  if (typeof error === 'object' && isNotNull(error)) {
    if ('msg' in error && typeof error.msg === 'string') {
      return error.msg
    }
    if ('message' in error && typeof error.message === 'string') {
      return error.message
    }
  }

  if (typeof error === 'string') {
    return error
  }

  return fallback
}
