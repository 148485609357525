/**
 * This list of non-exhaustive paths is meant to be the source of truth
 * for all pages.
 *
 * You'll notice paths contain variable declarations like `cashdash/:portfolioId`
 * This is because these path definitions are meant to be used with a utility helper
 * like so:
 * ```ts
 *  import { PATHS } from '@voltus/contants'
 *  import { getResolvedPath } from '@voltus/utils'
 *
 * const resolvedPath = getResolvedPath(PATHS.CASHDASH, [123])
 * // resolvedPath will now equal '/cashdash/123'
 * // the array items replace the params in the path in order
 * ```
 */
export const PATHS = {
  HOME: '/',
  INDEX: '/index',
  NOT_FOUND: '/404',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  TERMS_AND_CONDITIONS_ACCEPT: '/terms-and-conditions/accept',

  INTERNAL_FLAGS: '/internal/flags',
  INTERNAL_BUILD: '/internal/build',
  INTERNAL_HELP_CENTER: 'https://voltus-support.zendesk.com/hc/en-us',
  EXTERNAL_HELP_CENTER: 'https://support-voltus.zendesk.com/hc/en-us',

  GET_ENABLED: '/get-enabled',

  // ADMIN
  ADMIN: '/admin',
  APPLICATION_DETAIL: '/admin/applications/:applicationId',
  ADMIN_EVENTS_DETAIL: '/admin/events/detail/:dispatchId',
  ADMIN_FACILITY: '/admin/facilities/:facilityId',
  ADMIN_UTILITY_ACCOUNT_DETAIL: '/admin/utility-accounts/:utilityAccountId',
  SCHEDULE_DISPATCH: '/admin/dispatch',
  VIEW_EVENTS: '/admin/events',
  SCHEDULE_TEST_DISPATCH: '/admin/dispatch-verification',
  SCHEDULER: '/admin/scheduler',

  // VOLTLETS
  VOLTLETS_DETAILS: '/admin/voltlets/details/:voltletId',
  VOLTLETS_LIST: '/admin/voltlets',

  // VOLTAPP-REACT
  EARNINGS_V1: '/earnings',

  REPORTS: '/reports',
  REPORTS_PEAK_SAVER_ROOT: '/reports/peak-saver',
  REPORTS_PEAK_SAVER_PORTFOLIO: '/reports/peak-saver/:portfolioId',
  REPORTS_PEAK_SAVER_PORTFOLIO_SITE: '/reports/peak-saver/:portfolioId/:siteId',

  CASHDASH_HOME: '/cashdash',
  CASHDASH: '/cashdash/:portfolioId',
  CASHDASH_PAYMENT: '/cashdash/:portfolioId/payment/:paymentId',
  CASHDASH_OR: '/cashdash/:portfolioId/or',

  // Path that serves the dashboarding app
  // It's different from /dashboard. /dashboard is the old url for what is now /realtime-energy
  BOARDS: '/boards',
  BOARD_BY_ID: '/boards/:boardId',
  REALTIME_ENERGY: '/realtime-energy',
  REALTIME_ENERGY_COMPARE_VIEW:
    '/realtime-energy/detail/compare/:compareSourceKey',

  DISPATCHES: '/dispatches',
  ACTIVE_DISPATCHES: '/dispatches/active',
  // This is a legacy route, for the non-rollups version of VOC
  // It is only used now to redirect old URLs to the rollups version.
  // See useRollupsRedirect.ts
  ACTIVE_DISPATCHES_BY_TAB: '/dispatches/active/:dispatchId/:portfolioId/:tab',
  ACTIVE_DISPATCHES_GROUP_BY_TAB: '/dispatches/group/active/:portfolioId/:tab',
  ACTIVE_DISPATCHES_PARTICIPATION:
    '/dispatches/active/:dispatchId/:portfolioId/participation',
  ACTIVE_DISPATCHES_PERFORMANCE:
    '/dispatches/active/:dispatchId/:portfolioId/performance',
  ACTIVE_DISPATCHES_ADMIN: '/dispatches/active/:dispatchId/:portfolioId/admin',
  ACTIVE_DISPATCHES_GROUP_ADMIN: '/dispatches/group/active/:portfolioId/admin',
  PAST_PERFORMANCE: '/dispatches/past-performance',
  PAST_PERFORMANCE_BY_SITE: '/dispatches/past-performance/sites',
  PAST_PERFORMANCE_BY_DISPATCH: '/dispatches/past-performance/dispatches',
  PAST_PERFORMANCE_SITE_DETAILS: '/dispatches/past-performance/sites/:siteId',
  PAST_PERFORMANCE_DISPATCH_DETAILS:
    '/dispatches/past-performance/dispatches/:dispatchId',

  PORTFOLIO_HOME: '/portfolio',
  PORTFOLIO: '/portfolio/:portfolioId',
  PORTFOLIO_BY_SUBPAGE: '/portfolio/:portfolioId/:subpage/:siteId',
  PORTFOLIO_SITE_DETAILS: '/portfolio/:portfolioId/site/:siteId',

  ACCOUNT_MANAGEMENT_ACTION_ITEMS_ROOT: '/account-management/action-items',
  ACCOUNT_MANAGEMENT_ACTION_ITEMS:
    '/account-management/:organizationId/action-items',
  ACCOUNT_MANAGEMENT_SUPPLEMENTAL_INFO: '/account-management/supplemental-info',
  ACCOUNT_MANAGEMENT_ALERTS: '/account-management/alerts',
  ACCOUNT_MANAGEMENT_SUPPLEMENTAL_INFO_CONTRACT_ADDENDA:
    '/account-management/supplemental-info/contract-addenda',
  ACCOUNT_MANAGEMENT_SUPPLEMENTAL_INFO_MISO_LMR_OFFERS:
    '/account-management/supplemental-info/:organizationId/offers/miso-lmr-offers',
  ACCOUNT_MANAGEMENT_FILE_DOWNLOAD: '/account-management/files/download',
  ACCOUNT_MANAGEMENT_FILES_UPLOAD:
    '/account-management/:organizationId/files/upload',
  ACCOUNT_MANAGEMENT_FILES_UPLOAD_ROOT: '/account-management/files/upload',
  ACCOUNT_MANAGEMENT_FILES: '/account-management/:organizationId/files',
  ACCOUNT_MANAGEMENT_FILES_ROOT: '/account-management/files',
  ACCOUNT_MANAGEMENT_BILL_DETAIL:
    '/account-management/:organizationId/bills/:billId',
  ACCOUNT_MANAGEMENT_BILLS: '/account-management/:organizationId/bills',
  ACCOUNT_MANAGEMENT_BILLS_ROOT: '/account-management/bills',
  ACCOUNT_MANAGEMENT_PROFILE: '/account-management/profile',
  ACCOUNT_MANAGEMENT_USERS: '/account-management/users',
  ACCOUNT_MANAGEMENT_ROOT: '/account-management',

  VOLTAPPENINGS: '/voltappenings/',
} as const
