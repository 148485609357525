import defaultTheme from '../../constants/theme'

export default {
  MessageList: {
    css: {
      background: 'transparent',
    },
  },
  Message: {
    css: {
      color: defaultTheme.colors.slates['50'],
      fontSize: defaultTheme.fontSizes['3'],
    },
  },
  MessageText: {
    css: {
      color: defaultTheme.colors.textColor.main,
    },
  },
  Bubble: {
    css: {
      background: defaultTheme.colors['color-white'],
    },
  },
  TextComposer: {
    css: {
      border: `1px solid ${defaultTheme.colors.grays['50']}`,
      borderRadius: 5,
    },
  },
}
