import { isNil } from 'lodash'
import * as React from 'react'
import {
  Icons,
  ChatWindow,
  IMessage,
  Box,
  Button,
  useNavbarContext,
} from '@voltus/core-components'
import { Conversation } from '@voltus/grpc-clients/voc-sms/sms_pb'
import { usePrevious } from '@voltus/utils'
import { DispatchContactMenu } from '../../routes/dispatches/routes/ActiveDispatches/components/DispatchContactMenu/DispatchContactMenu'
import {
  Assignment,
  Contact,
  Dispatch,
} from '../../routes/dispatches/routes/ActiveDispatches/types'
import {
  useGlobalSidebarContext,
  GlobalSidebarActionType,
} from '../GlobalSidebar'
import { UnreadMessageNotification } from './types'
import { isMessageUnread } from './utils'

type Props = {
  shouldRender: boolean
  dispatches: Array<Dispatch>
  assignments: Record<number, Record<number, Assignment>>
  viewingUserId: number
  selectedContact: Contact | undefined
  onSelectContact: (contact: Contact | undefined) => void
  onSendMessage: (messageBody: string) => void
  onMarkMessageAsRead: (id: number) => void
  onMarkMessageAsUnread: (id: number) => void
  onTypingMessage: () => void
  messages: Array<IMessage>
  onLoadPreviousMessages: () => void
  hasPreviousMessages: boolean
  isLoadingPreviousMessages?: boolean
  isLoadingMessages?: boolean
  isLoadingContacts?: boolean
  primaryUnreadMessageNotifications: Array<UnreadMessageNotification>
  secondaryUnreadMessageNotifications: Array<UnreadMessageNotification>
  recentConversationsOutsideDispatches: Array<Conversation.AsObject>
  facilityIdToRecentMessageTimestampMap: { [key: number]: string }
  nameOfUserTyping?: string
  toggleIsMenuOpen: () => void
  errorMessage?: string
}

function SiteContactChatMenu({
  shouldRender,
  dispatches,
  assignments,
  viewingUserId,
  selectedContact,
  onSelectContact,
  onSendMessage,
  onMarkMessageAsRead,
  onMarkMessageAsUnread,
  onLoadPreviousMessages,
  onTypingMessage,
  messages,
  hasPreviousMessages,
  isLoadingPreviousMessages,
  isLoadingMessages,
  isLoadingContacts,
  primaryUnreadMessageNotifications,
  secondaryUnreadMessageNotifications,
  recentConversationsOutsideDispatches,
  facilityIdToRecentMessageTimestampMap,
  nameOfUserTyping,
  toggleIsMenuOpen,
  errorMessage,
}: Props): null {
  const [state, dispatch] = useGlobalSidebarContext()
  const { closeMobileMenu } = useNavbarContext()

  const toggleEntireSidebar = React.useCallback(() => {
    dispatch({
      type: GlobalSidebarActionType.toggleEntireSidebar,
    })
  }, [dispatch])

  const renderChatWindow = React.useCallback((): JSX.Element => {
    const toggleSecondarySidebar = () => {
      onSelectContact(undefined)

      dispatch({
        type: GlobalSidebarActionType.toggleSecondarySidebar,
      })
    }

    const handleSeenMessage = (message) => {
      if (isMessageUnread(message)) {
        onMarkMessageAsRead(message.id)
      }
    }

    const handleMarkUnreadMessage = (message) => {
      onMarkMessageAsUnread(message.id)
    }

    return (
      <Box height="100%" bg="grays.10">
        <Box height="3%" display="flex">
          <Button.Inline
            onClick={toggleSecondarySidebar}
            mr={3}
            ml="auto"
            leftIcon={<Icons.Dismiss />}
          />
        </Box>
        <Box height="97%">
          <ChatWindow
            messages={messages ?? []}
            onSend={onSendMessage}
            shouldDisplayTopButton={hasPreviousMessages}
            topButtonText="Load Previous Messages"
            onClickTopButton={onLoadPreviousMessages}
            isTopButtonLoading={isLoadingPreviousMessages}
            isLoading={isLoadingMessages}
            onSeenMessage={handleSeenMessage}
            onTypingMessage={onTypingMessage}
            onMarkMessageAsUnread={handleMarkUnreadMessage}
            isTyping={!!nameOfUserTyping}
            nameOfUserTyping={nameOfUserTyping}
            errorMessage={errorMessage}
          />
        </Box>
      </Box>
    )
  }, [
    messages,
    onSendMessage,
    hasPreviousMessages,
    onLoadPreviousMessages,
    isLoadingPreviousMessages,
    isLoadingMessages,
    onTypingMessage,
    nameOfUserTyping,
    onSelectContact,
    dispatch,
    onMarkMessageAsRead,
    onMarkMessageAsUnread,
    errorMessage,
  ])

  const renderDispatchMenu = React.useCallback((): JSX.Element => {
    const handleSelectContact = (contact: Contact) => {
      onSelectContact(contact)

      // Only open the menu when we are actually selecting a contact.
      // Not when we are clearing the selection.
      if (contact) {
        dispatch({
          type: GlobalSidebarActionType.setSecondarySidebarOpen,
          payload: true,
        })
      }
    }

    return (
      <Box height="100%" bg="slates.5">
        <Box height="3%" display="flex">
          <Button.Inline
            onClick={() => {
              toggleEntireSidebar()
              toggleIsMenuOpen()
              onSelectContact(undefined)
            }}
            mr={3}
            ml="auto"
            leftIcon={<Icons.Dismiss />}
          />
        </Box>
        <Box height="97%">
          <DispatchContactMenu
            dispatches={dispatches}
            assignments={assignments}
            viewingUserId={viewingUserId}
            selectedContactUserId={selectedContact?.userId}
            handleSelectContact={handleSelectContact}
            primaryNotifications={primaryUnreadMessageNotifications}
            secondaryNotifications={secondaryUnreadMessageNotifications}
            recentConversationsOutsideDispatches={
              recentConversationsOutsideDispatches
            }
            facilityIdToRecentMessageTimestampMap={
              facilityIdToRecentMessageTimestampMap
            }
            isLoading={isLoadingContacts}
          />
        </Box>
      </Box>
    )
  }, [
    dispatches,
    assignments,
    viewingUserId,
    selectedContact?.userId,
    primaryUnreadMessageNotifications,
    secondaryUnreadMessageNotifications,
    recentConversationsOutsideDispatches,
    facilityIdToRecentMessageTimestampMap,
    isLoadingContacts,
    onSelectContact,
    dispatch,
    toggleEntireSidebar,
    toggleIsMenuOpen,
  ])

  React.useEffect(() => {
    dispatch({
      type: GlobalSidebarActionType.setPrimaryChild,
      payload: renderDispatchMenu,
    })
  }, [renderDispatchMenu, dispatch])

  React.useEffect(() => {
    dispatch({
      type: GlobalSidebarActionType.setSecondaryChild,
      payload: renderChatWindow,
    })
  }, [renderChatWindow, dispatch])

  const toggleChatMenu = React.useCallback(() => {
    toggleIsMenuOpen()
    onSelectContact(undefined)
    closeMobileMenu()
    toggleEntireSidebar()

    if (state.secondaryChild !== renderChatWindow) {
      dispatch({
        type: GlobalSidebarActionType.setSecondaryChild,
        payload: renderChatWindow,
      })
    }

    if (state.primaryChild !== renderDispatchMenu) {
      dispatch({
        type: GlobalSidebarActionType.setPrimaryChild,
        payload: renderDispatchMenu,
      })
    }
  }, [
    closeMobileMenu,
    dispatch,
    renderChatWindow,
    renderDispatchMenu,
    onSelectContact,
    state.primaryChild,
    state.secondaryChild,
    toggleEntireSidebar,
    toggleIsMenuOpen,
  ])

  const prevShouldRender = usePrevious(shouldRender)
  React.useEffect(() => {
    if (
      !isNil(shouldRender) &&
      !isNil(prevShouldRender) &&
      shouldRender !== prevShouldRender
    ) {
      toggleChatMenu()
    }
  }, [toggleChatMenu, shouldRender, prevShouldRender])

  return null
}

export { SiteContactChatMenu }
