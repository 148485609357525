import React, { createContext, useContext } from 'react'
import { useBreakpoints } from '@voltus/utils'

const context = createContext({
  isMobile: false,
  isTablet: false,
})

const MobileContext = {}
function MobileContextProvider({ breakpoints, children }) {
  const contextValue = useBreakpoints(breakpoints)
  return <context.Provider value={contextValue}>{children}</context.Provider>
}
MobileContext.Provider = MobileContextProvider
MobileContext.Consumer = context.Consumer

const useMobileContext = () => useContext(context)

const withIsMobile = (WrappedComponent) => (props) => {
  const { isMobile } = useMobileContext()
  return <WrappedComponent isMobile={isMobile} {...props} />
}

export { MobileContext, useMobileContext, withIsMobile }
