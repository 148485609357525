import { decamelizeKeys } from 'humps'
import { Any, AnyObject } from '@voltus/types'

const defaultShouldNullify = (value) =>
  value === '' || typeof value === 'undefined'

/**
 *
 * Walk an object or array and recursively set values to null
 * if they match some predicate.
 *
 * By default, converts all empty strings and 'undefined' to null
 * `null` values
 */
export const nullify = (
  obj: AnyObject | Array<Any>,
  shouldNullify = defaultShouldNullify
): AnyObject | Array<Any> | void => {
  if (obj === null) {
    return
  }
  if (Array.isArray(obj)) {
    return obj.map((val) => {
      if (typeof val === 'object') {
        return nullify(val, shouldNullify)
      }

      return shouldNullify(val) ? null : val
    })
  }

  return Object.entries(obj).reduce((acc, [key, val]) => {
    if (typeof val === 'object') {
      acc[key] = nullify(val, shouldNullify)
    } else {
      acc[key] = shouldNullify(val) ? null : val
    }
    return acc
  }, {})
}

/**
 * Decamelize object keys to snake case.
 * Numbers will be treated as capital letters and separated from other words.
 * @example
 * `additional_vendor_1_type`
 */
export const decamelizeKeysWithNumbers = (obj: object) => {
  return decamelizeKeys(obj, { split: /(?=[A-Z0-9])/ })
}
