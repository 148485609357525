import { sortBy } from 'lodash'
import * as React from 'react'
import { CSVLink } from 'react-csv'
import { Modal, Box, Dropdown, Button } from '@voltus/core-components'
import { DropdownOption } from '@voltus/types'
import {
  useGetRecentConversations,
  useGetAllUserMessages,
} from '../../../../../../components/SiteContactChatMenu/queries'

const csvDataHeaders = [
  { key: 'id', label: 'ID' },
  { key: 'conversationId', label: 'Conversation ID' },
  { key: 'senderUserId', label: 'Sender User ID' },
  { key: 'senderUserFullName', label: 'Sender User Full Name' },
  { key: 'timestamp', label: 'Timestamp' },
  { key: 'body', label: 'Message Body' },
]

interface Props {
  isOpen: boolean
  onRequestClose: () => void
}

export const ExportConversationRecordsModal = ({
  isOpen,
  onRequestClose,
}: Props): JSX.Element => {
  const [selectedSiteContactId, setSelectedSiteContactId] =
    React.useState<number>()

  // Clear the selected site contact when the modal closes.
  React.useEffect(() => {
    setSelectedSiteContactId(undefined)
  }, [isOpen])

  const { data: recentConversationsData } = useGetRecentConversations({
    enabled: isOpen,
  })

  const { data: allUserMessagesData, isPending } = useGetAllUserMessages({
    enabled: !!selectedSiteContactId,
    userId: selectedSiteContactId as number,
  })

  const csvMessageData = React.useMemo(() => {
    if (allUserMessagesData?.resultsList) {
      return allUserMessagesData.resultsList.map((message) => ({
        id: message.id,
        conversationId: message.userId,
        senderUserId: message.senderUserId,
        senderUserFullName: message.senderUserFullName,
        timestamp: message.timestamp,
        body: message.body,
      }))
    }
    return []
  }, [allUserMessagesData])

  const siteContactOptions: Array<DropdownOption> = React.useMemo(() => {
    if (recentConversationsData?.conversationsList) {
      return sortBy(
        recentConversationsData.conversationsList.map((conversation) => ({
          label: conversation.userFullName,
          value: conversation.userId,
        })),
        'label'
      )
    }
    return []
  }, [recentConversationsData])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      header="Export Conversation Records"
      scrollable={false}
    >
      <Box minWidth={['95vw', 600]}>
        <Dropdown
          placeholder="Select Contact"
          isSearchable
          options={siteContactOptions}
          onChange={(option) => {
            if (option) {
              setSelectedSiteContactId(option.value)
            }
          }}
        />
        <CSVLink
          data={csvMessageData}
          headers={csvDataHeaders}
          filename="conversation-records.csv"
        >
          <Button.Success
            mt={5}
            width="100%"
            disabled={!allUserMessagesData}
            isLoading={isPending}
          >
            Download Conversation Records
          </Button.Success>
        </CSVLink>
      </Box>
    </Modal>
  )
}
