import { merge } from 'lodash'
import { createActions, createReducer } from 'reduxsauce'

export const { Types: ProfileTypes, Creators: ProfileActions } = createActions(
  {
    fetchProfile: [],
    receiveProfile: ['profile'],
    updateProfile: ['data', 'onSuccess', 'onFailure'],
    updateProfileSuccess: ['data'],
    updateProfileFailure: ['error'],
  },
  { prefix: 'PROFILE/' }
)

const INITIAL_STATE = {
  data: null,
  loading: true,
  error: null,
}

const fetchProfile = (state) => ({
  ...state,
  loading: true,
})

const setProfile = (state, { profile }) => ({
  ...state,
  ...profile,
})

// received data should only extend current state (i.e. there are no sites list in API response
// but we are getting them on profile GET request)
const updateProfileSuccess = (state, { data }) => merge(state, data)

export const profileReducer = createReducer(INITIAL_STATE, {
  [ProfileTypes.FETCH_PROFILE]: fetchProfile,
  [ProfileTypes.RECEIVE_PROFILE]: setProfile,
  [ProfileTypes.UPDATE_PROFILE_SUCCESS]: updateProfileSuccess,
})
