import Cookie from 'cookies-js'
import { has, isEqual, isNull, isString, isNil, isEmpty, toUpper } from 'lodash'
import { Mutex } from '@voltus/locks/mutex'
import { debugLogger } from '@voltus/logger'
import { isTestEnv } from '@voltus/utils'
import { getToken, saveAccessToken, ACCESS_TOKEN } from './tokens'

const REQUEST_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
}

// Mutex used to synchronize requests made to get refresh tokens
// Only used if the browser does not suppoer the Web Lock API
const REFRESH_TOKEN_MUTEX = new Mutex()

/*
 * handles Voltus API requests. takes into account:
 *   - user doesn't have an access token
 *   - access token has expired and API request sends a 401 ('unauthorized') error
 */
class Auth {
  constructor() {
    this.domain = ''
  }

  get accessToken() {
    return getToken(ACCESS_TOKEN)
  }

  removeStartingSlash(str) {
    if (str.startsWith('/')) {
      return str.slice(1)
    }

    return str
  }

  /**
   * Perform an authenticated fetch while keeping the same
   * public facing API as native window.fetch
   *
   * We add some extra defaults to fetch, such as:
   *  - adding the access token to the header
   *  - JSON.stringify on the passed in body
   *  - adding 'Content-Type': 'application/json' to the header
   *
   * To remove the implicit Content-Type: application/json header,
   * pass `raw: true` to the opts objects.
   *
   * Note! If you pass in a FormData instance as the body, this
   * method automatically removes the Content-Type JSON header for you,
   * without needing to pass `raw: true`
   *
   * ```
   *  // This will work as expected.
   *  fetchParity('/api/some/multipart/form-data', {
   *    method: 'POST',
   *    body: formData,
   * })
   *
   *  fetchParity('/api/some/multipart/form-data', {
   *    method: 'POST',
   *    body: formData,
   * })
   * ```
   *
   */
  fetchParity(url, opts = {}) {
    // Don't need an authenticated fetch for external urls
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return fetch(url, opts)
    }

    const safeURL = this.removeStartingSlash(url)

    this._checkUrl(safeURL)
    const formattedReqMethod = toUpper(opts.method || REQUEST_METHODS.GET)
    this._checkRequestMethod(formattedReqMethod)

    const headers = {
      Authorization: `Bearer ${this.accessToken}`,
      'Content-Type': 'application/json',
    }

    // Most requests are JSON, which was why this was written to default to JSON, to avoid having
    // to add headers all over the place. That was short-sighted.
    // In some cases, we do NOT want to send JSON, so we need to delete the Content-Type: application/json header
    // as well as avoid stringifying the body.
    // If you pass in a FormData object or explicitly pass in `raw: true`, we will not treat the request
    // as JSON and delete the JSON content type header and avoid stringifying the body
    const isRaw = opts.raw || opts.body instanceof FormData
    if (isRaw) {
      delete headers['Content-Type']
    }

    let options = {
      ...opts,
      method: formattedReqMethod,
      headers: {
        ...headers,
        ...opts.headers,
      },
    }

    // Stringify the body if it was passed in as anything other
    // than a string
    if (!isNil(opts.body) && typeof opts.body !== 'string') {
      options = {
        ...options,
        body: isRaw ? opts.body : JSON.stringify(opts.body),
      }
    }
    // raw does not need to go along for the ride to the final destination. Its our own special field
    delete options.raw

    // If we already have an access token, just pass on through to fetch
    if (isTestEnv() || this.accessToken) {
      return fetch(`${this.domain}/${safeURL}`, options).then((res) => {
        return this._handleExpiredToken(
          res,
          `${this.domain}/${safeURL}`,
          options
        )
      })
    }

    // This should never fire on a production server
    if (__DEV__) {
      return this._fetchWithUpdatedAccessTokenParity(safeURL, opts)
    }

    // No Access Token available, attempt to get one from a refresh
    this._refreshAccessToken().then((isOk) => {
      if (!isOk) {
        // Could not get an access token send the user to login
        this._loginRedirect()
      } else {
        // Got an access token so attempt the request!
        return fetch(url, opts)
      }
    })
  }

  removeTrailingSlash = (str) =>
    str.endsWith('/') ? str.slice(0, str.length - 1) : str

  setDomain(domain) {
    this.domain = this.removeTrailingSlash(domain)
  }

  _checkIsString(prop, val) {
    if (!isString(val)) {
      throw new Error(`${prop} must be a string!`)
    }
  }

  _checkUrl(url) {
    this._checkIsString('url', url)

    if (isEqual(url, '')) {
      throw new Error('a URL must be specified to make an API call!')
    }
  }

  _checkRequestMethod(reqMethod) {
    this._checkIsString('request method', reqMethod)

    if (!has(REQUEST_METHODS, reqMethod)) {
      throw new Error('unsupported request method!')
    }
  }

  _checkReqIsPost(reqMethod) {
    if (!isEqual(reqMethod, REQUEST_METHODS.POST)) {
      throw new Error('request method must be `POST` when including a body!')
    }
  }

  _hasBody(body) {
    return !isNull(body)
  }

  _formatBody(body) {
    return JSON.stringify(body)
  }

  _formatOptions = (reqMethod, body) => {
    let options = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
      },
      method: reqMethod,
    }

    if (this._hasBody(body)) {
      this._checkReqIsPost(reqMethod)

      const formattedBody = this._formatBody(body)
      options = {
        ...options,
        body: formattedBody,
      }
    }

    return options
  }

  _requestNewAccessToken = async (old_access_token) => {
    const refreshUrl = `${this.domain}/auth/refresh`
    const requestOptions = {
      method: 'POST',
    }

    // Check the snapshotted token
    if (old_access_token !== getToken(ACCESS_TOKEN)) {
      return true
    }

    // Otherwise make a request to VoltApp for a new one
    const isOk = await fetch(refreshUrl, requestOptions)
      .then((res) => {
        if (res.ok) {
          return res
            .json()
            .then(({ access_token }) => {
              Cookie.set('access_token', access_token)
              return saveAccessToken(access_token)
            })
            .then(
              () => {
                // Succesfully saved a new access token
                return true
              },
              () => {
                // Failed to save access token
                return false
              }
            )
        }
        debugLogger.error(
          'Received non-ok status when requesting new access token'
        )
        return false
      })
      // On a failure to do any of the above return false
      .catch(() => {
        debugLogger.error('Ran into an error requesting a new access token')
        return false
      })
    return isOk
  }

  // Call when we get a 401 from any non-auth endpoint
  _refreshAccessToken = async () => {
    const lockName = 'refresh_token_lock'
    // Snapshot the current access token before attempting to acquire lock
    const old_access_token = getToken(ACCESS_TOKEN)

    // Check that browser supports Web Locks API
    if (navigator?.locks?.request) {
      const isOk = await navigator.locks.request(lockName, async () => {
        // Lock acquired
        const ok = await this._requestNewAccessToken(old_access_token)
        return ok
        // Give up lock
      })
      return isOk
    }
    // Fall back to standard mutex if not
    const unlock = await REFRESH_TOKEN_MUTEX.lock()
    // Lock acquired
    try {
      const isOk = await this._requestNewAccessToken(old_access_token)
      return isOk
    } finally {
      // Always unlock even after error to avoid deadlocking
      unlock()
      // Give up lock
    }
  }

  // Keep parity with the fetch API
  _fetchWithUpdatedAccessTokenParity = async (url, opts) => {
    await this._fetchAccessToken()
    return this.fetchParity(url, opts)
  }

  _loginRedirect = () => {
    window.location.href = `${this.domain}/auth/login?next=${document.location.pathname}${document.location.search}`
  }

  _handleExpiredToken = (res, url, opts) => {
    const unauthorizedStatus = 401
    const unauthorized = res.status === unauthorizedStatus
    if (unauthorized) {
      this._refreshAccessToken().then((ok) => {
        if (!ok) {
          this._loginRedirect()
        } else {
          return fetch(url, opts)
        }
      })
    } else {
      return res
    }
  }

  /*
   * useful for developing with Webpack Dev Server
   * call this method at start of app
   */
  setupWdsEnv(EMAIL = process.env.EMAIL, PASSWORD = process.env.PASSWORD) {
    return this._fetchAccessToken(EMAIL, PASSWORD)
  }

  /*
   * only used in webpack dev server environment
   */
  _fetchAccessToken = (
    EMAIL = process.env.EMAIL,
    PASSWORD = process.env.PASSWORD
  ) => {
    const authUrl = `${this.domain}/auth/authorize`

    const noEmail = isNil(EMAIL) || isEmpty(EMAIL)
    const noPassword = isNil(PASSWORD) || isEmpty(PASSWORD)

    if (noEmail || noPassword) {
      throw new Error(
        'EMAIL and/or PASSWORD are not set for webpack dev server environment. Is a .env file inside the project with EMAIL and PASSWORD properly set?'
      )
    }

    return fetch(authUrl, {
      method: REQUEST_METHODS.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: `${EMAIL}`,
        password: `${PASSWORD}`,
      }),
    })
      .then((res) => res.json())
      .then(({ access_token }) => {
        // Cookies are not set properly in dev,
        // likely due to cross-origin issues, so instead just
        // explicity set it
        Cookie.set('access_token', access_token)
        saveAccessToken(access_token)
      })
      .catch((err) => debugLogger.error(err))
  }
}

const auth = new Auth()
export default auth
