/**
 * A common file to store client side tokens
 * for external services. These tokens are safe
 * to commit, as they are public tokens.
 */
export const LAUNCH_DARKLY_TOKENS = {
  DEVELOPMENT: '615706ea102d3625c403369f',
  PRODUCTION: '615706ae8b3909257634d441',
  TEST: '615706ae8b3909257634d440',
}
