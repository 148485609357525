// package: streams
// file: streams.proto

var streams_pb = require('./streams_pb')
var grpc = require('@improbable-eng/grpc-web').grpc

var Streams = (function () {
  function Streams() {}
  Streams.serviceName = 'streams.Streams'
  return Streams
})()

Streams.StreamPortfolioData = {
  methodName: 'StreamPortfolioData',
  service: Streams,
  requestStream: false,
  responseStream: true,
  requestType: streams_pb.StreamPortfolioDataRequest,
  responseType: streams_pb.StreamPortfolioDataResponse,
}

exports.Streams = Streams

function StreamsClient(serviceHost, options) {
  this.serviceHost = serviceHost
  this.options = options || {}
}

StreamsClient.prototype.streamPortfolioData = function streamPortfolioData(
  requestMessage,
  metadata
) {
  var listeners = {
    data: [],
    end: [],
    status: [],
  }
  var client = grpc.invoke(Streams.StreamPortfolioData, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage)
      })
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers })
      })
      listeners = null
    },
  })
  return {
    on: function (type, handler) {
      listeners[type].push(handler)
      return this
    },
    cancel: function () {
      listeners = null
      client.close()
    },
  }
}

exports.StreamsClient = StreamsClient
