import {
  DISPATCH_ROLE,
  EVENT_TYPES,
  PARTICIPATION_STATUS,
} from '../../constants'
export { PARTICIPATION_STATUS, DISPATCH_ROLE, EVENT_TYPES }

export const MOBILE_BREAKPOINT = 970

export const CALL_STATUSES = {
  DIALING: 'dialing',
  CONNECTED: 'connected',
  ENDED: 'ended',
  FAILED: 'failed',
}

export const DISPATCH_DETAIL_TABS = {
  PARTICIPATION: 'participation',
  PERFORMANCE: 'performance',
  ADMIN: 'admin',
} as const
export type DispatchDetailTabs =
  (typeof DISPATCH_DETAIL_TABS)[keyof typeof DISPATCH_DETAIL_TABS]

export const DISPATCH_TYPES = {
  COMMUNICATIONS_TEST: 'voltus_communications_test',
  ACCEPTANCE_TEST: 'voltus_acceptance_test',
  CURTAILMENT_TEST: 'voltus_curtailment_test',
  VOLUNTARY: 'market_voluntary_dispatch',
}

export enum DISPATCH_STATUS {
  ACTIVE = 'active',
  UPCOMING = 'upcoming',
  PAST = 'past',
}

export const DISPATCH_STATUS_OPTIONS: Array<{
  label: string
  value: `${DISPATCH_STATUS}`
}> = [
  { value: DISPATCH_STATUS.ACTIVE, label: 'Active' },
  { value: DISPATCH_STATUS.UPCOMING, label: 'Upcoming' },
  { value: DISPATCH_STATUS.PAST, label: 'Recently Ended' },
]

export const ACK_STATUS_MAP = {
  [PARTICIPATION_STATUS.CONFIRMED]: 'optin',
  [PARTICIPATION_STATUS.REJECTED]: 'optout',
  [PARTICIPATION_STATUS.NOT_RESPONDED]: 'not_responded',
}

export const PERFORMANCE_STATUS = {
  ON_TRACK: 'on-track',
  LAGGING: 'lagging',
  AT_RISK: 'at-risk',
  NO_DATA: 'no-data',
}

export const PERFORMANCE_THRESHOLD = {
  ON_TRACK: 100,
  LAGGING: 80,
}

export const ACK_FILTER_OPTIONS = [
  { label: 'All', value: 'all' },
  { label: 'Participating', value: PARTICIPATION_STATUS.CONFIRMED },
  { label: 'Declined', value: PARTICIPATION_STATUS.REJECTED },
  { label: 'No Response', value: PARTICIPATION_STATUS.NOT_RESPONDED },
]

export const ADMIN_LIST_VIEW_FILTER = {
  ACCOUNTS: 'accounts',
  ACCOUNTS_EXPANDED: 'accountsExpanded',
  SITES: 'sites',
} as const

export const ADMIN_ASSIGNMENT_FILTER = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
}

export const ADMIN_CONTROLS_FILTER = {
  ALL_TYPES: 'All',
  MANUAL: 'Manual',
  AUTOMATIC: 'Automatic',
  SCRIPTED: 'Scripted',
  ENG_INTEGRATION: 'Engineering Integration',
} as const

export const ADMIN_STATUS_FILTER = {
  ALL: 'all',
  PARTICIPATING: PARTICIPATION_STATUS.CONFIRMED,
  DECLINED: PARTICIPATION_STATUS.REJECTED,
  NOT_RESPONDED: PARTICIPATION_STATUS.NOT_RESPONDED,
}

export const ADMIN_SORT = {
  ASSIGNMENT: 'assignment',
  ACK_STATUS: 'ackStatus',
  MEGAWATTS_MISSING: 'megawattsMissing',
} as const

export const ADMIN_LIST_VIEW_FILTER_OPTIONS = [
  { label: 'Accounts', value: ADMIN_LIST_VIEW_FILTER.ACCOUNTS },
  {
    label: 'Accounts Expanded',
    value: ADMIN_LIST_VIEW_FILTER.ACCOUNTS_EXPANDED,
  },
  { label: 'Sites', value: ADMIN_LIST_VIEW_FILTER.SITES },
]

export const ADMIN_ASSIGNMENT_FILTER_OPTIONS = [
  { label: 'All Sites', value: ADMIN_ASSIGNMENT_FILTER.ALL },
  { label: 'Assigned to Me', value: ADMIN_ASSIGNMENT_FILTER.ASSIGNED },
  { label: 'Unassigned', value: ADMIN_ASSIGNMENT_FILTER.UNASSIGNED },
] as const

export const ADMIN_CONTROLS_FILTER_OPTIONS = [
  { label: 'All Types', value: ADMIN_CONTROLS_FILTER.ALL_TYPES },
  { label: 'Manual', value: ADMIN_CONTROLS_FILTER.MANUAL },
  { label: 'Automatic', value: ADMIN_CONTROLS_FILTER.AUTOMATIC },
  { label: 'Scripted', value: ADMIN_CONTROLS_FILTER.SCRIPTED },
  { label: 'Eng Integration', value: ADMIN_CONTROLS_FILTER.ENG_INTEGRATION },
]

export const ADMIN_STATUS_FILTER_OPTIONS = [
  { label: 'All ACKs', value: ADMIN_STATUS_FILTER.ALL },
  { label: 'Participating', value: ADMIN_STATUS_FILTER.PARTICIPATING },
  { label: 'Declined', value: ADMIN_STATUS_FILTER.DECLINED },
  { label: 'No Response', value: ADMIN_STATUS_FILTER.NOT_RESPONDED },
]

export const ADMIN_SORT_OPTIONS = [
  { label: 'Assignments', value: ADMIN_SORT.ASSIGNMENT },
  // This is a special option that sorts by both Acknowledgement Status and
  // Commitment value. It's a legacy behavior that's been carried forward
  // because it's critical to DispatchOps
  { label: 'ACK & Commitment', value: ADMIN_SORT.ACK_STATUS },
  { label: 'MW Missing', value: ADMIN_SORT.MEGAWATTS_MISSING },
]

export const WS_EVENTS = {
  cumulativeFacilityPerformance: 'cumulativeFacilityPerformance',
  cumulativePortfolioPerformance: 'cumulativePortfolioPerformance',
  facilityDemand: 'facilityDemand',
  facilityPerformance: 'facilityPerformance',
  portfolioPerformance: 'portfolioPerformance',
  facilityTarget: 'facilityTarget',
  userAcknowledgement: 'userAcknowledgement',
  streamingDispatch: 'streamingDispatch',
}

export const LIST_MODE = {
  CURRENT: 'current',
  DISPATCH: 'dispatch',
} as const

export const LIST_VIEW = {
  GRID: 'grid',
  LIST: 'list',
} as const

export const LOADING_STATES = {
  INITIAL: 'initial',
  LOADING: 'loading',
  FETCHING: 'fetching',
  READY: 'ready',
  ERROR: 'error',
} as const

export enum PatchTypes {
  CALL = 'callNote',
  ASSIGNMENT = 'assignment',
  TEXT_NOTE = 'textNote',
  USER_DISPATCH_STATE = 'userDispatchState',
}

export const ENABLEMENT_STATUS = {
  NOT_ENABLED: 'not_enabled',
  PRE_VALIDATION: 'pre-validation',
  VALIDATED: 'validated',
} as const

export const NUM_HISTORICAL_DISPATCHES_PER_PAGE = 40

export const NUM_DISPATCH_NOTES_PER_PAGE = 100

export enum DISPATCH_TIMES {
  FIFTEEN_MIN = 15,
  THIRTY_MIN = 30,
  ONE_HOUR = 60,
  TWO_HOUR = 120,
  THREE_HOUR = 180,
}

export const DISPATCH_TIME_OPTIONS = [
  { value: DISPATCH_TIMES.FIFTEEN_MIN, label: '15 min' },
  { value: DISPATCH_TIMES.THIRTY_MIN, label: '30 min' },
  { value: DISPATCH_TIMES.ONE_HOUR, label: '1 hour' },
  { value: DISPATCH_TIMES.TWO_HOUR, label: '2 hours' },
  { value: DISPATCH_TIMES.THREE_HOUR, label: '3 hours' },
]

export enum AssignSitesModalTypes {
  CLAIM = 'claim',
  UNASSIGN = 'unassign',
}
