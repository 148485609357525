import { useQuery } from '@tanstack/react-query'
import { network, queryKeys, endpoints } from '@voltus/network'
import { Any } from '@voltus/types'
import {
  DispatchSnapshotWithPortfolios,
  DispatchOverviewSnapshot,
} from '../types/dispatch'
import { transformDispatchData } from './transforms'

const getActiveDispatchSnapshots = (
  page = 1,
  perPage = 100
  // TODO: Better return typings
): Promise<Any> => {
  return network.get(
    endpoints.voc.snapshots.listDispatchSnapshots({
      historical: false,
      per_page: perPage,
      page,
    })
  )
}

export const useActiveDispatchesQuery = ({
  page,
  perPage = 500,
  enabled = true,
}) => {
  return useQuery<
    { snapshots: Array<DispatchOverviewSnapshot> },
    unknown,
    Array<DispatchSnapshotWithPortfolios>
  >({
    // Refetch this data every 30 seconds
    refetchInterval: 30 * 1000,
    queryKey: queryKeys.activeDispatches({ page, perPage }),
    enabled,
    queryFn: () => getActiveDispatchSnapshots(page, perPage),

    select: (data) => {
      const transformed = transformDispatchData(data?.snapshots).sort(
        (a, b) => {
          return (
            new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
          )
        }
      )
      return transformed
    },
  })
}
