import * as yup from 'yup'

yup.addMethod(
  yup.string,
  'minMaxDigits',
  function (
    args = {},
    message = '${path} must be at least ${min} and max ${max} digits'
  ) {
    const defaultArgs = {
      min: 0,
      max: Number.POSITIVE_INFINITY,
    }
    const params = { ...defaultArgs, ...args }

    return this.test('minMaxDigits', message, function (value) {
      if (!value) {
        return true
      }
      const { path, createError } = this
      const digits = (value.match(/\d/g) || []).length
      if (digits >= params.min && digits <= params.max) {
        return true
      }

      return createError({ ...params, params, path, value, message })
    })
  }
)
