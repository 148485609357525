import * as React from 'react'
import { AnyObject } from '@voltus/types'

const prefixMap: AnyObject<number> = {}
const getNextName = (prefix: string): string => {
  if (prefixMap[prefix]) {
    prefixMap[prefix]++
  } else {
    prefixMap[prefix] = 1
  }
  return `${prefix}-${prefixMap[prefix]}`
}

export const useUniqueName = (prefix: string, initialName?: string): string => {
  const localName = React.useRef(initialName)

  if (!localName.current) {
    localName.current = getNextName(prefix)
  }

  return localName.current
}
