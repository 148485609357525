/**
 * Check if a given user has an active call.
 * We define an active call as one that has no endedAt,
 * and one of the participants matches the passed in user id
 *
 * @param {[]Call} callList - array of all call objects, flattened
 * @param {*} userId - the user id to check for active calls
 * @returns {boolean} if the user is on a call or not
 */
export const isUserOnACall = (callList, userId) => {
  return callList.find((call) => {
    // ignore all calls that have ended
    if (call.endedAt) {
      return false
    }

    // If the call hasnt' ended, and we found ourselves as one of the participants
    // in a call, then, well, we're on a call
    return call.participantsList.find(
      (participant) => participant.voltappId === userId
    )
  })
}
