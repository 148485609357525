import {
  ThemeProvider,
  MessageList,
  Message,
  Bubble,
  MessageText,
  TextComposer,
  Row,
  TextInput,
  SendButton,
} from '@livechat/ui-kit'
import CSS from 'csstype'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import React from 'react'
import { DATE_FORMAT } from '@voltus/constants'
import { Icons } from '../../icons'
import { ActivityIndicator } from '../ActivityIndicator'
import { Box } from '../Box'
import { Button } from '../Button'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { WithTooltip } from '../Tooltip'

import stylesheet from './ChatWindow.scss'
import theme from './theme'

export interface IMessage {
  /**
   * Unique id for this message
   */
  id: number
  /**
   * Unique id for the user who this conversation is keyed by
   */
  userId: number
  /**
   * Unique id for the user who sent this message
   */
  senderUserId: number
  /**
   * Display name for the user who sent this message
   */
  senderUserFullName: string
  /**
   * UTC time string indicating when this message was sent
   */
  timestamp: string
  /**
   * Message body text
   */
  body: string
}

interface Props {
  /**
   * Messages that will be displayed
   */
  messages: Array<IMessage>
  /**
   * Function that will called when the send button is clicked
   * or when the user submits the TextInput by pressing enter while it is focused.
   * @param messageBody - The current value of the message TextInput
   */
  onSend: (messageBody: string) => void
  /**
   * onClick event handler for the Message component.
   * @param event - The click event
   * @param message - The message that was clicked on
   */
  onClickMessage?: (
    message: IMessage,
    event: React.MouseEvent<HTMLInputElement>
  ) => void
  /**
   * onSeen event handler for the Message component.
   * @param message - The message that was seen
   */
  onSeenMessage?: (message: IMessage) => void
  /**
   * onSeen event handler for the "Mark as Unread" button.
   * @param message - The message to mark as unread
   */
  onMarkMessageAsUnread?: (message: IMessage) => void
  /**
   * UserId of the user sending messages.
   * Used to determine which messages should receive isOwn styles and be positioned on the right side
   */
  senderUserId?: number
  /**
   * Error message that will be displayed in red above the TextComposer.
   * If no error message is present, the red box will not be displayed.
   */
  errorMessage?: string
  /**
   * Whether to display a loading state.
   */
  isLoading?: boolean
  /**
   * Whether the other participant in the text conversation is typing a message.
   * When true, will display a typing indicator below the messages.
   */
  isTyping?: boolean
  /**
   * Name of the user who is currently typing.
   */
  nameOfUserTyping?: string
  /**
   * Function to call when the user is currently typing a message.
   */
  onTypingMessage?: () => void
  /**
   * Whether the button at the top of the chat window will be displayed.
   */
  shouldDisplayTopButton?: boolean
  /**
   * Whether the button at the top of the chat window should display as loading.
   */
  isTopButtonLoading?: boolean
  /**
   * The text that will appear inside the top button
   */
  topButtonText?: string
  /**
   * onClick event handler for top button
   * @param event - The click event
   */
  onClickTopButton?: (event: React.MouseEvent<HTMLInputElement>) => void
  /**
   * className for container div
   */
  className?: string
  /**
   * CSS styles that will be applied to the message container.
   * Use this prop to style the timestamp and sender text.
   */
  messageStyle?: CSS.Properties
  /**
   * CSS styles that will be applied to message body text.
   */
  messageTextStyle?: CSS.Properties
  /**
   * CSS styles that will be applied to the message bubble.
   */
  messageBubbleStyle?: CSS.Properties
  /**
   * CSS styles that will be applied to the TextComposer component.
   * The TextComposer component is the container for the TextInput and SendButton.
   */
  textComposerStyle?: CSS.Properties
  /**
   * CSS styles that will be applied to the TextInput component.
   */
  textInputStyle?: CSS.Properties
}

export const ChatWindow = ({
  messages,
  onSend,
  onClickMessage,
  onSeenMessage,
  onMarkMessageAsUnread,
  senderUserId,
  errorMessage,
  isTyping,
  isLoading,
  nameOfUserTyping,
  shouldDisplayTopButton,
  isTopButtonLoading,
  topButtonText,
  onClickTopButton,
  onTypingMessage,
  className,
  messageStyle,
  messageTextStyle,
  messageBubbleStyle,
  textComposerStyle,
  textInputStyle,
}: Props): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <Flex.Column height="100%" className={className}>
        {isLoading ? (
          <Flex.FullyCentered width="100%" height="100%" flex={1}>
            <ActivityIndicator.Large />
          </Flex.FullyCentered>
        ) : (
          <>
            {shouldDisplayTopButton && (
              <Button.Inline
                className={stylesheet.topButton}
                onClick={onClickTopButton}
                isLoading={isTopButtonLoading}
              >
                {topButtonText}
              </Button.Inline>
            )}
            <Box flex="1" overflowY="scroll">
              <MessageList>
                {messages.map((message, index) => (
                  <Message
                    key={message.id}
                    date={format(
                      new Date(message.timestamp),
                      DATE_FORMAT.MONTHNAME_DAY_YEAR_TIME_DATE_FNS
                    )}
                    deliveryStatus={`Sent by ${message.senderUserFullName}`}
                    isOwn={message.senderUserId === senderUserId}
                    onClick={
                      onClickMessage
                        ? (e) => onClickMessage(message, e)
                        : undefined
                    }
                    onSeen={
                      onSeenMessage ? () => onSeenMessage(message) : undefined
                    }
                    style={{ marginBottom: 16, ...messageStyle }}
                  >
                    <Flex.Row
                      alignItems="center"
                      className={stylesheet.messageHoverContainer}
                    >
                      <Bubble style={messageBubbleStyle}>
                        <MessageText style={messageTextStyle}>
                          {message.body}
                        </MessageText>
                      </Bubble>
                      {/* Only allow user to mark last message as unread */}
                      {onMarkMessageAsUnread &&
                        index === messages.length - 1 && (
                          <Box ml={1} className={stylesheet.messageOptions}>
                            <WithTooltip
                              anchorPoint="top-center"
                              contentAlign="center"
                              content={() => <Box p={1}>Mark as Unread</Box>}
                            >
                              <Icons.Notification
                                onClick={() => onMarkMessageAsUnread(message)}
                              />
                            </WithTooltip>
                          </Box>
                        )}
                    </Flex.Row>
                  </Message>
                ))}
                {isTyping && (
                  <Message>
                    <Bubble style={messageBubbleStyle}>
                      <MessageText style={messageTextStyle}>
                        <Flex.Row
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {!isEmpty(nameOfUserTyping) && (
                            <Text m={0} mr={4} maxWidth="80%">
                              {nameOfUserTyping} is typing
                            </Text>
                          )}
                          <div className={stylesheet.typingIndicator} />
                        </Flex.Row>
                      </MessageText>
                    </Bubble>
                  </Message>
                )}
              </MessageList>
            </Box>
            {!isEmpty(errorMessage) && (
              <Box bg="reds.20" borderRadius={5} m={3} mb={0} p={10}>
                <Text fontSize={14} fontWeight="700" m={0}>
                  {errorMessage}
                </Text>
              </Box>
            )}
          </>
        )}
        <Box m={16} className={stylesheet.textComposerContainer}>
          <TextComposer
            onChange={onTypingMessage}
            onSend={onSend}
            style={textComposerStyle}
          >
            <Row align="center">
              <TextInput style={textInputStyle} placeholder="" />
              <SendButton data-testid="send-button" fit />
            </Row>
          </TextComposer>
        </Box>
      </Flex.Column>
    </ThemeProvider>
  )
}
