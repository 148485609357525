import { css } from '@emotion/css'
import { get, noop } from 'lodash'
import * as React from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Any } from '@voltus/types'
import { isTestEnv, bindKeyboardClickHandlers } from '@voltus/utils'
import { StyledIcons } from '../../icons'
import { StyledPropsCss } from '../../utils/styledSystem'
import { ActivityIndicator } from '../ActivityIndicator'
import { Box } from '../Box'
import { AccordionContent, AccordionContentProps } from './AccordionContent'

const defaultIsOpenOnMount = () => false

export type AccordionProps<Item> = {
  /**
   * Sets the html element type of the root container element
   */
  Wrapper?: string | React.FC<Any>
  /**
   * Should we allow multiple items to be open at once?
   */
  allowMultiple?: boolean
  /**
   * Sets the styles of the chevron icon
   */
  chevronCss?: StyledPropsCss
  /**
   * Sets the classname of the root div
   */
  containerCss?: StyledPropsCss
  /**
   * Sets the styles of the div surrounding the content
   */
  contentCss?: StyledPropsCss
  /**
   * Sets the styles of the inner content div
   */
  contentInnerCss?: StyledPropsCss
  /**
   * A list of data of any shape.
   * Data will be passed back to each render function so individual
   * items can be used to construct headers and body content
   */
  data: Array<Item>
  /**
   * Enable/Disable expanding items
   */
  expandable?: boolean
  /**
   * Sets the styles of the div surrounding the header
   */
  headerCss?: StyledPropsCss
  /**
   * Show the chevron as a loading spinner instead
   */
  isLoading?: boolean
  /**
   * Configuration function to set specific rows to be open
   * on component mount.
   * Signature of (data, index) =\> bool
   * e.g. isOpenOnMount=\{(data, index) =\> index === 1\}
   * will cause the second item to be open on the accordion mount
   */
  isOpenOnMount?: (data: Item, index: number) => boolean
  /**
   * Keypath to identify unique property on each data object
   * Used to set react element keys
   */
  keyPath?: string
  /**
   * When using the Accordion in controlled mode,
   * the onChange callback is required, and will be
   * called with the following signature
   * (itemData, index) =\> void
   *
   */
  onChange?: (itemData: Item, index: number) => void
  /**
   * Callback when an item is opened
   */
  onOpen?: (itemData: Item, index: number) => void
  /**
   * If you passed "opened" you are using the Accordion
   * in "controlled" mode, meaning you are now fully
   * responsible for managing the open/close states
   * of the items.
   *
   * Opened is an object with the following shape:
   * \{ 0: true, 1: false, 2: false \}
   * where the key is the index of the item, and the
   * values are if the item is open or not
   */
  opened?: { [key: number]: boolean }
  /**
   * Content renderer
   * Receives the data object, open status, the index, and an optional ref setter
   * to be used if the content can grow/shrink once the row is opened
   * e.g. renderContent=\{(data, isOpen, index, setRef) =\> \<div ref=\{setRef\}\>\{index\}</div>\}
   */
  renderContent: AccordionContentProps<Item>['renderContent']
  /**
   * Header renderer
   * Receives the data object, open status, and the index,
   * e.g. renderHeader=\{(data, isOpen, index) =\> <div>\{index\}</div>\}
   */
  renderHeader: (
    item: Item,
    isOpened: boolean,
    index: number
  ) => React.ReactNode
}

/**
 * A generic Accordion component that provides animating the opening/closing
 * of child content.
 *
 * It can be configured to allow only one item to be open at a time, or multiple
 */
function Accordion<Item>({
  opened,
  onChange = noop,
  data,
  containerCss,
  headerCss,
  contentCss,
  contentInnerCss,
  chevronCss,
  renderHeader,
  renderContent,
  expandable = true,
  isOpenOnMount = defaultIsOpenOnMount,
  isLoading = false,
  Wrapper = React.Fragment,
  keyPath,
  allowMultiple = false,
  onOpen,
}: AccordionProps<Item>): JSX.Element {
  const [openedInternal, setOpened] = React.useState(
    data.reduce((res, data, index) => {
      res[index] = isOpenOnMount(data, index)
      return res
    }, {})
  )
  const [heights, setHeights] = React.useState({})
  const toggleItem = (index) => {
    if (!expandable) {
      return
    }
    onOpen?.(data[index], index)
    if (opened) {
      onChange(data[index], index)
      return
    }

    if (allowMultiple) {
      setOpened((state) => ({
        ...state,
        [index]: !state[index],
      }))
    } else {
      setOpened((state) => ({ [index]: !state[index] }))
    }
  }

  const nodeRef = React.useRef<HTMLDivElement>(null)

  return (
    <Box css={containerCss}>
      {data.map((item, index) => {
        const isOpened = opened ? opened[index] : openedInternal[index]
        const wrapperProps =
          Wrapper === React.Fragment
            ? {}
            : {
                item: item,
                index: index,
              }
        return (
          <Wrapper key={keyPath ? get(item, keyPath) : index} {...wrapperProps}>
            <Box
              onClick={() => toggleItem(index)}
              {...bindKeyboardClickHandlers(() => toggleItem(index))}
              tabIndex={0}
              py={2}
              px={3}
              display="flex"
              borderTopColor="grays.20"
              borderTopStyle="solid"
              borderTopWidth={1}
              cursor="pointer"
              alignItems="flex-start"
              css={headerCss}
            >
              <Box flex="1 1 auto">{renderHeader(item, isOpened, index)}</Box>
              {isLoading ? (
                <ActivityIndicator.ExtraSmall width={20} height={20} />
              ) : null}
              {!isLoading ? (
                <Box
                  as="span"
                  css={{
                    transition: 'transform 0.2s ease-in-out',
                    transformOrigin: 'center',
                    ...(isOpened ? { transform: 'rotate(180deg)' } : {}),
                    ...chevronCss,
                  }}
                >
                  {expandable && !isLoading ? <StyledIcons.Chevron /> : null}
                </Box>
              ) : null}
            </Box>
            <Box overflow="hidden" css={contentCss}>
              <TransitionGroup appear>
                {isOpened ? (
                  <CSSTransition
                    key="content"
                    nodeRef={nodeRef}
                    classNames={{
                      appear: css`
                        height: 0;
                      `,
                      enter: css`
                        height: 0;
                      `,
                    }}
                    onExiting={() => {
                      setHeights((state) => ({
                        ...state,
                        [index]: 0,
                      }))
                    }}
                    onEntering={() => {
                      setHeights((state) => ({
                        ...state,
                        [index]: nodeRef?.current?.scrollHeight,
                      }))
                    }}
                    appear
                    in={isOpened}
                    timeout={isTestEnv() ? 0 : 200}
                  >
                    {(transitionState) => {
                      return (
                        <AccordionContent
                          nodeRef={nodeRef}
                          contentInnerCss={contentInnerCss}
                          transitionState={transitionState}
                          renderContent={renderContent}
                          item={item}
                          index={index}
                          heights={heights}
                          isOpened={isOpened}
                        />
                      )
                    }}
                  </CSSTransition>
                ) : null}
              </TransitionGroup>
            </Box>
          </Wrapper>
        )
      })}
    </Box>
  )
}

export { Accordion }
