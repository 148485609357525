/*global EMAIL, PASSWORD*/
import 'core-js/stable'
import {
  matchQuery,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { setupAuth } from '@voltus/auth'

import { testSelectors as SELECTORS } from '@voltus/constants'
import { RefreshKeyProvider, QualtricsProvider } from '@voltus/context'
import {
  Forbidden,
  Card,
  Box,
  ResponsiveContainer,
  Navbar,
  Modal,
  Text,
  ThemeProvider,
  Skeleton,
  theme,
  colors,
  PointerContextProvider,
  Toast,
  TimeZoneContextProvider,
  ErrorFallback,
} from '@voltus/core-components'
import { initDD, ddErrorHandler } from '@voltus/datadog'
import { FeatureFlagProvider } from '@voltus/feature-flags'
import logger from '@voltus/logger'
import { ENVIRONMENTS, isTestEnv, isIE, isNotNull } from '@voltus/utils'
import App from './App'
import { GlobalSidebarProvider } from './components/GlobalSidebar'

import { HelpMenuContext } from './components/HelpMenu/HelpMenuContext'
import DOMAIN from './constants/domain'
import INJECTION from './constants/injection'
import { PATHS } from './constants/routes'
import { MobileContext } from './context/MobileContext'

import 'normalize.css'
import './index.scss'
import './utils/additionalYupValidators'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import configureStore from './modules/store'
import { routes } from './routes/routes'

// This is dumb - but we're coming from a _really_ old version of immer,
// that didn't, by default, freeze produced objects, so after updating
// immer we started seeing an error related to trying to extend non-extensible
// objects. This sets an immer default to get it back to how it used to behave
// TODO: @jcharry update downstream usages so we can remove this hack, eventually
// eslint-disable-next-line import/order
import { setAutoFreeze, enableAllPlugins } from 'immer'
setAutoFreeze(false)
enableAllPlugins()

const NODE_ENV = process.env.NODE_ENV
const MSW = process.env.MSW

initDD('voltapp-react')

function initApp() {
  const store = configureStore()
  routes.initRoutes(store)
  if (__DEV__) {
    /* eslint-disable-next-line */
    /* @ts-ignore */
    window.reduxStore = store
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: isTestEnv() ? false : 3,
      },
    },
    queryCache: new QueryCache({
      // This callback will only be invoked once per Query and is a good place
      // to display Toast errors to ensure they don't stack up.
      // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
      onError: (error, query) => {
        if (
          query?.meta?.errorMessage &&
          typeof query.meta.errorMessage === 'string'
        ) {
          Toast.error(query.meta.errorMessage)
        }
        // If an error is throw inside a query, it's caught here. We have to explicitly log it
        // otherwise the error is swallowed and not logged to the console.
        // If this happens in prod we probably want to know about it in datadog, so report it
        logger.report.error(error, {
          from: 'voltapp-react-query-cache',
          queryKey: query.queryKey,
        })
      },
    }),
    mutationCache: new MutationCache({
      onSuccess: (_data, _variables, _context, mutation) => {
        queryClient.invalidateQueries({
          predicate: (query) => {
            // we can't know the type of `mutation.meta.invalidates`, so write
            // some type guards to guarantee it looks like it should before actually
            // invalidating a query
            if (
              mutation.meta?.invalidates &&
              Array.isArray(mutation.meta?.invalidates)
            ) {
              return mutation.meta.invalidates
                .filter(isNotNull)
                .some((queryKey) => {
                  return matchQuery({ queryKey }, query)
                })
            }

            return false
          },
        })
      },
    }),
  })

  if (INJECTION) {
    Modal.setAppElement(INJECTION)

    const root = createRoot(INJECTION)
    root.render(
      <React.StrictMode>
        <ErrorBoundary
          onError={ddErrorHandler}
          FallbackComponent={ErrorFallback}
        >
          <TimeZoneContextProvider>
            <QueryClientProvider client={queryClient}>
              <FeatureFlagProvider>
                <PointerContextProvider>
                  <BrowserRouter basename={process.env.BASE_PATH}>
                    <Skeleton.Theme
                      color={colors.grays['15']}
                      highlightColor={colors.grays['10']}
                    >
                      <ThemeProvider theme={theme}>
                        <QualtricsProvider>
                          <RefreshKeyProvider>
                            {/* eslint-disable-next-line */}
                            {/* @ts-ignore */}
                            <MobileContext.Provider>
                              <Provider store={store}>
                                <HelpMenuContext.Provider>
                                  <GlobalSidebarProvider>
                                    <App />
                                  </GlobalSidebarProvider>
                                </HelpMenuContext.Provider>
                              </Provider>
                            </MobileContext.Provider>
                          </RefreshKeyProvider>
                        </QualtricsProvider>
                      </ThemeProvider>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </Skeleton.Theme>
                  </BrowserRouter>
                </PointerContextProvider>
              </FeatureFlagProvider>
            </QueryClientProvider>
          </TimeZoneContextProvider>
        </ErrorBoundary>
      </React.StrictMode>
    )
  }
}

function initUnAuthenticatedView() {
  const next =
    PATHS.LOGIN + `?next=${window.encodeURIComponent(window.location.pathname)}`
  if (INJECTION) {
    const root = createRoot(INJECTION)
    root.render(
      <BrowserRouter basename={process.env.BASE_PATH}>
        <Navbar>
          <Navbar.Item
            data-testid={SELECTORS.FORBIDDEN_PAGE_LOGIN_LINK}
            active={false}
            is="a"
            to={next}
          >
            Login
          </Navbar.Item>
        </Navbar>
        <Forbidden
          LinkComponent={(linkProps) => {
            return (
              <a
                {...linkProps}
                data-testid={SELECTORS.FORBIDDEN_PAGE_LOGIN_TEXT_LINK}
              />
            )
          }}
          fallbackUrl={next}
          fallbackMessage="Click here to return to the login page"
        />
      </BrowserRouter>
    )
  }
}

function bootstrapApp() {
  if (NODE_ENV === ENVIRONMENTS.DEV) {
    /* eslint-disable-next-line */
    /* @ts-ignore */
    setupAuth(DOMAIN, ENVIRONMENTS.WDS, EMAIL, PASSWORD)
      .then(initApp)
      // If the user is not authenticated - render a
      // Simple page with a redirect link to the login page
      .catch(initUnAuthenticatedView)
  } else {
    setupAuth(DOMAIN, NODE_ENV).then(initApp).catch(initUnAuthenticatedView)
  }
}

const renderIEFallback = () => {
  if (INJECTION) {
    const root = createRoot(INJECTION)
    root.render(
      <ThemeProvider theme={theme}>
        <ResponsiveContainer>
          <Card as={Box} mt={4}>
            <Card.Content>
              <Text.Headline>Internet Explorer is not supported</Text.Headline>
              <Text.P>
                Please switch to one of the following supported browsers:
              </Text.P>
              <Box as="ul" ml={4}>
                <Text mb={1} as="li">
                  Microsoft Edge
                </Text>
                <Text mb={1} as="li">
                  Google Chrome
                </Text>
                <Text mb={1} as="li">
                  Mozilla Firefox
                </Text>
                <Text mb={1} as="li">
                  Safari
                </Text>
              </Box>
            </Card.Content>
          </Card>
        </ResponsiveContainer>
      </ThemeProvider>
    )
  }
}

async function main() {
  if (MSW) {
    const { setupWorker } = await import('msw/browser')
    const { handlers } = await import('@voltus/mocks/handlers')
    const { onUnhandledRequest } = await import(
      '@voltus/mocks/utils/onUnhandledRequest'
    )
    const worker = setupWorker(...handlers)
    await worker.start({ onUnhandledRequest })
  }

  bootstrapApp()
}

if (isIE()) {
  renderIEFallback()
} else {
  main()
}
