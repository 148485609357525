import { Any, Permissions } from '@voltus/types'

interface PartialState {
  [key: string]: Any
  permissions: Permissions
}

// We can't know the full state but we do want to enforce
// that the permissions key exists and has the right shape
export const selectPermissions = <TState>(
  state: TState extends PartialState ? TState : never
): Permissions => state.permissions
