import { network, endpoints } from '@voltus/network'
import { AnyObject, ObjectValues } from '@voltus/types'
import { APPLICATIONS, EVENTS, PROPERTIES } from './constants'
import createMixpanel, { Mixpanel } from './createMixpanel'

/**
 * Wrapper around mixpanel allowing us to do a few extra things:
 * 1. Stub out mixpanel under different circumstances, i.e. we can toggle
 *    a feature flag to disable tracking. A future feature should be to allow
 *    users to opt out of tracking, in which case here we'd disable mixpanel
 *    globally for that user
 * 2. Provide some convenience functions like init that handle commonly
 *    grouped mixpanel calls
 * 3. Allow for setting global properties that are sent along with every event
 */
class EventTracker {
  CONSTANTS = {
    APPLICATIONS,
    EVENTS,
  }
  mixpanel: Mixpanel

  constructor() {
    /**
     * Rather than use mixpanel directly from window, this
     * allows us to conditionally stub out mixpanel for different
     * environments - abstracting out the rules that decide
     * if and when we should call actual mixpanel methods
     */
    this.mixpanel = createMixpanel()
  }

  /**
   * Setup a user session, identify the user based on their
   * salesforceId or email, then set a
   *
   * @param user - User data, must have an `id` property
   * @param superProperties - data sent along with all events
   */
  init = ({ id, ...user }, superProperties = {}) => {
    this.setPeople(id, user)
    this.identifyUser(id)
    this.setSuperProperties({
      ...superProperties,
      [PROPERTIES.HOST]: window.location.host,
    })
  }

  /**
   * Super properties are properties that get sent along with all events
   */
  setSuperProperties = (props) => {
    this.mixpanel.register(props)
  }

  /**
   * Clear a super property sent along with all events
   * Keep the dev events around
   */
  clearSuperProperties = (propertyName) => {
    this.mixpanel.unregister(propertyName)
  }

  /**
   * Creates and/or updates a user properties
   * Mixpanel user properties are mutable, so if a user's name
   * ever changes, this call will keep it up to date on their user profile
   *
   * This is typically called after `indentifyUser` to ensure
   *
   * @param id - should be (salesforceId || email)
   * @param userProperties - people properties
   */
  setPeople = (id: string, userProperties: AnyObject) => {
    this.mixpanel.people.set({
      [PROPERTIES.EMAIL]: userProperties.email,
      [PROPERTIES.NAME]: userProperties.fullName,
      [PROPERTIES.CREATED]: userProperties.dateCreated,
      [PROPERTIES.ROLE]: userProperties.role,
      [PROPERTIES.ROLE_ID]: userProperties.roleId,
      [PROPERTIES.ORGANIZATION_ID]: userProperties.organizationId,
      [PROPERTIES.ORGANIZATION_NAME]: userProperties.organization.name,
      [PROPERTIES.LAST_LOAD]: new Date().toISOString(),
      [PROPERTIES.USER_TITLE]: userProperties.title,
      [PROPERTIES.ACCOUNT_MANAGER]:
        userProperties.accountManagers?.[0]?.fullName || '',
    })
    // Count the number of times a user visits voltapp.
    // Useful for separating out "power users"
    this.mixpanel.people.increment(PROPERTIES.VISITED)
  }

  /**
   * An alias to mixpanel identify
   */
  identifyUser = (id: string) => {
    this.mixpanel.identify(id)
  }

  trackOnServer = (
    eventName: ObjectValues<typeof EVENTS>,
    properties: AnyObject = {}
  ) => {
    network
      .post(endpoints.v0_1.trackEvent(), {
        body: {
          event_name: eventName,
          properties,
        },
      })
      .catch(() => {
        // pass
      })
  }

  /**
   * The heart of this module. Track event!
   * @param event - name of event sent to mixpanel
   * @param properties - any properties to send with the event
   */
  track = (
    eventName: ObjectValues<typeof EVENTS>,
    properties: AnyObject = {}
  ) => {
    this.mixpanel.track(eventName, properties)
    this.mixpanel.people.set({
      [PROPERTIES.LAST_EVENT]: new Date().toISOString(),
    })
  }

  reset = () => {
    this.mixpanel.reset()
  }
}

const eventTracker = new EventTracker()
export { eventTracker }
