import { grpc } from '@improbable-eng/grpc-web'
import * as jspb from 'google-protobuf'
import noop from 'lodash/noop'
import auth from '@voltus/auth'
import Proto, {
  GetRecentConversationsResponse,
  GetUserMessagesRequest,
  GetUserMessagesResponse,
  IndicateTypingEventRequest,
  IndicateTypingEventResponse,
  SearchMessagesRequest,
  SearchMessagesResponse,
  SendMessageRequest,
  SendMessageResponse,
  UpdateMessageResponse,
} from '@voltus/grpc-clients/voc-sms/sms_pb'
import { Sms } from '@voltus/grpc-clients/voc-sms/sms_pb_service'
import logger, { debugLogger } from '@voltus/logger'
import { Any, AnyObject } from '@voltus/types'
import { sanitizeMessageForDemo } from './gRPCUtils'

const makeHost = () => document.location.origin + '/admin/voc-admin/sms/api'

interface initializeConversationEventStreamParams {
  onEnd: (...args: Array<Any>) => void
  onMessage: (message: AnyObject) => void
  userId: number
  lastSeenMessageId: number
}

export const initializeConversationEventStream = ({
  onEnd = noop,
  onMessage,
  userId,
  lastSeenMessageId,
}: initializeConversationEventStreamParams): grpc.Client<
  grpc.ProtobufMessage,
  grpc.ProtobufMessage
> => {
  debugLogger.log(`Initializing SMS Conversation event stream ${userId}`)

  const transport = grpc.WebsocketTransport()
  const grpcClient = grpc.client(Sms.ConversationEventStream, {
    host: makeHost(),
    transport,
  })

  grpcClient.onMessage((message) => {
    if (message.toObject) {
      logger.info('grpcMessage', { message: message.toObject() })
      onMessage(message.toObject())
    }
  })

  grpcClient.onEnd((...args) => {
    logger.warn('Conversation event stream disconnected')
    onEnd(...args)
  })

  grpcClient.start()

  const conversationEventStreamRequest =
    new Proto.ConversationEventStreamRequest()
  conversationEventStreamRequest.setAccessToken(auth.accessToken as string)
  conversationEventStreamRequest.setUserId(userId)
  conversationEventStreamRequest.setLastSeenMessage(lastSeenMessageId)
  grpcClient.send(conversationEventStreamRequest)

  return grpcClient
}

interface initializeMessageNotificationEventStreamParams {
  onEnd: (...args: Array<Any>) => void
  onMessage: (message: AnyObject) => void
}

export const initializeMessageNotificationEventStream = ({
  onEnd = noop,
  onMessage,
}: initializeMessageNotificationEventStreamParams): grpc.Client<
  grpc.ProtobufMessage,
  grpc.ProtobufMessage
> => {
  debugLogger.log('Initializing Message Notification event stream')

  const transport = grpc.WebsocketTransport()
  const grpcClient = grpc.client(Sms.MessageNotificationEventStream, {
    host: makeHost(),
    transport,
  })

  grpcClient.onMessage((message) => {
    if (message.toObject) {
      logger.info('grpcMessage', { message: message.toObject() })
      onMessage(message.toObject())
    }
  })

  grpcClient.onEnd((...args) => {
    logger.warn('Message Notification event stream disconnected')
    onEnd(...args)
  })

  grpcClient.start()

  const messageNotificationEventStreamRequest =
    new Proto.MessageNotificationEventStreamRequest()
  messageNotificationEventStreamRequest.setAccessToken(
    auth.accessToken as string
  )
  grpcClient.send(messageNotificationEventStreamRequest)

  return grpcClient
}

// This transport is configured to send Browser cookies along with cross-origin requests.
const crossBrowserTransport = grpc.CrossBrowserHttpTransport({
  withCredentials: true,
})

const unary = (request: jspb.Message, service): Promise<jspb.Message> => {
  if (request.setAccessToken) {
    request.setAccessToken(auth.accessToken)
  }
  return new Promise((resolve, reject) => {
    const host = makeHost()
    grpc.unary(service, {
      request,
      host: host,
      transport: crossBrowserTransport,
      metadata: {
        Authorization: `Bearer ${auth.accessToken}`,
      },
      onEnd: (res) => {
        const { status, message, statusMessage } = res
        if (status === grpc.Code.OK && message) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          logger.info('GRPC:', service.methodName, message.toObject())
          sanitizeMessageForDemo(service, host, message).then((m) => resolve(m))
          return
        }

        logger.warn(`GRPC: ${service.methodName} failed`)
        reject(statusMessage)
      },
    })
  })
}

export const getUserMessages = ({
  userId,
  limit,
  afterId,
  beforeId,
}: GetUserMessagesRequest.AsObject): Promise<GetUserMessagesResponse.AsObject> => {
  const request = new Proto.GetUserMessagesRequest()
  request.setUserId(userId)
  request.setLimit(limit)
  request.setAfterId(afterId)
  request.setBeforeId(beforeId)
  return unary(request, Sms.GetUserMessages)
}

export const sendMessage = ({
  userId,
  senderUserId,
  body,
}: Omit<
  SendMessageRequest.AsObject,
  'accessToken'
>): Promise<SendMessageResponse.AsObject> => {
  const request = new Proto.SendMessageRequest()
  request.setUserId(userId)
  request.setSenderUserId(senderUserId)
  request.setBody(body)
  return unary(request, Sms.SendMessage)
}

export interface UpdateMessageParams {
  id: number
  metadata: [string, string]
}

export const updateMessage = ({
  id,
  metadata,
}: UpdateMessageParams): Promise<UpdateMessageResponse.AsObject> => {
  const request = new Proto.UpdateMessageRequest()
  request.setId(id)
  request.getMetadataMap().set(metadata[0], metadata[1])
  return unary(request, Sms.UpdateMessage)
}

export const searchMessages = ({
  text,
}: SearchMessagesRequest.AsObject): Promise<SearchMessagesResponse.AsObject> => {
  const request = new Proto.SearchMessagesRequest()
  request.setText(text)
  return unary(request, Sms.SearchMessages)
}

export const indicateTypingEvent = ({
  userId,
  senderUserId,
}: Omit<
  IndicateTypingEventRequest.AsObject,
  'accessToken'
>): Promise<IndicateTypingEventResponse.AsObject> => {
  const request = new Proto.IndicateTypingEventRequest()
  request.setUserId(userId)
  request.setSenderUserId(senderUserId)
  return unary(request, Sms.IndicateTypingEvent)
}

export const getRecentConversations =
  (): Promise<GetRecentConversationsResponse.AsObject> => {
    const request = new Proto.GetRecentConversationsRequest()
    return unary(request, Sms.GetRecentConversations)
  }
