import { useRefreshState } from '@voltus/context'
import { OrderBy } from '@voltus/core-components'
import { AnyObject } from '@voltus/types'
import { isNotNull } from '@voltus/utils'

const refreshable =
  (fn) =>
  (additionalKeys: AnyObject | null = {}, shouldRefresh = false) => {
    const refreshKey = useRefreshState()

    if (shouldRefresh) {
      return [...fn(additionalKeys ?? {}), refreshKey]
    }

    return [...fn(additionalKeys ?? {})]
  }

export const applications = () => ['applications']
export const applicationsList = () => ['applications', 'list']
export const applicationsSearch = (
  params: {
    name?: string
    page?: number
    perPage?: number
    orderBy?: OrderBy
    orderDir?: string
    search?: string
  } = {}
) => [...applicationsList(), params]

export const applicationDetail = ({
  applicationId,
}: {
  applicationId?: number
}) => [...applications(), 'detail', applicationId]

export const portfolios = () => ['portfolios']

export const applicationPortfolios = ({
  applicationId,
}: {
  applicationId: number
}) => [...applications(), ...portfolios(), 'sites', applicationId]

export const portfolioList = refreshable(() => [...portfolios(), 'list', 'all'])

export const portfolioUsers = refreshable(
  ({ portfolioId }: { portfolioId: number | string }) => [
    ...portfolios(),
    'users',
    portfolioId,
  ]
)
export const portfolioApplications = refreshable(
  ({ portfolioId }: { portfolioId: number | string }) => [
    ...portfolios(),
    'applications',
    portfolioId,
  ]
)

export const portfolioSites = refreshable(
  ({ portfolioId }: { portfolioId: number | string }) => [
    ...portfolios(),
    'sites',
    portfolioId,
  ]
)

export const carbonReduction = ({
  startDate,
  endDate,
  portfolioId,
  aggregateBy,
}: {
  startDate: string
  endDate: string
  portfolioId: number
  aggregateBy: string
}) => ['carbon-reporting', startDate, endDate, portfolioId, aggregateBy]

export const carbonPermissions = ({ portfolioId }: { portfolioId: number }) => [
  'carbon-permissions',
  portfolioId,
]

export const telemetryAlerts = () => ['telemetry-alerts']

export const earliestPerformance = ({
  portfolioId,
}: {
  portfolioId: number
}) => ['earliest-performance', portfolioId]

export const allUserSearch = refreshable(
  (
    params: {
      name?: string
      page?: number
      perPage?: number
      orderBy?: string
      orderDir?: string
    } = {}
  ) => ['users', 'all', 'search', params]
)

export const allSiteSearch = (
  params: {
    name?: string
    page?: number
    perPage?: number
    orderBy?: string
    orderDir?: string
  } = {}
) => ['sites', 'all', 'search', params]

export const organization = (filters?: {
  search?: string
  page?: number
  perPage?: number
  orderBy?: OrderBy
}) => ['organization', 'all', filters].filter(isNotNull)

export const activeDispatches = ({
  page,
  perPage,
}: {
  page: number
  perPage: number
}) => ['active', 'dispatches', page, perPage]

export const siteNotes = ({ siteId }: { siteId: number }) => [
  'site-notes',
  siteId,
]

export const siteContactNotes = ({
  dispatchId,
  userId,
}: {
  dispatchId: number
  userId: number
}) => ['site-contact-notes', dispatchId, userId]

export const portfolioSnapshot = ['portfolio-snapshot']
export const vocAdminAssignments = ['voc-admin-assignments']
export const vocAdminAssignmentsByDispatch = (did: number) => [
  ...vocAdminAssignments,
  did,
]
export const accountManagementUserPage = (portfolioId) => [
  'account-management',
  'users-page-data',
  portfolioId,
]
export const accountManagementAccounts = () => [
  'account-management',
  'accounts',
]
export const userProfile = () => ['user', 'profile']
export const user = (userId?: number) => ['user', userId]

export const listSnapshots = ({ portfolioId, dispatchIds }) => [
  'list-snapshots',
  portfolioId,
  dispatchIds,
]

export const pastPerformanceDispatchDetail = ({ portfolioId, dispatchId }) => [
  'past-performance',
  'dispatch',
  portfolioId,
  dispatchId,
]

export const rtDashDataSourcesKey = () => ['rt-dash-data-sources']
export const rtEnergyDataStreamKey = ['rt-dash-data-stream']
export const rtEnergyDataStream = ({
  startDate,
  endDate,
  interval,
  dataSourceKey,
}) => [...rtEnergyDataStreamKey, startDate, endDate, interval, dataSourceKey]
export const rtEnergyDataStreamZoom = ({
  startDate,
  endDate,
  interval,
  dataSourceKey,
}) => [
  'rt-zoom',
  ...rtEnergyDataStreamKey,
  startDate,
  endDate,
  interval,
  dataSourceKey,
]

export const rtEnergyISOPeaksToDateKey = ['rt-dash-historical-peaks']
export const rtEnergyISOPeaksToDate = ({ isoId }) => [
  ...rtEnergyISOPeaksToDateKey,
  isoId,
]

export const rtEnergyISOPeaksHistoricalKey = ['rt-dash-peaks-to-date']
export const rtEnergyISOPeaksHistorical = ({ isoId }) => [
  ...rtEnergyISOPeaksHistoricalKey,
  isoId,
]

export const rtEnergyBaselinesKey = ['rt-dash-baselines']
export const rtEnergyBaselines = ({ facilityId, eventId }) => [
  ...rtEnergyBaselinesKey,
  facilityId,
  eventId,
]
export const rtActiveEventsKey = ['rt-dash-events']
export const rtActiveEvents = ({
  startDate,
  endDate = '',
}: {
  startDate: string
  endDate?: string
}) => [...rtActiveEventsKey, startDate, endDate].filter(Boolean)

type PaginationKeys = {
  perPage: number
  orderBy: OrderBy
  search?: string
}
export const facilitiesInfiniteListKey = ['facilities-infinite-list']
export const facilitiesInfiniteList = ({
  perPage,
  orderBy,
  search,
}: PaginationKeys) => [...facilitiesInfiniteListKey, perPage, orderBy, search]

export const marketResourcesList = (
  market: string,
  orderBy: OrderBy,
  search?: string
) => ['market-resources', market, orderBy, search]
export const marketResourceDetail = (market: string, resourceName: string) => [
  'market-resource',
  market,
  resourceName,
]
export const marketResourceFacilityAssociations = ({
  market,
  resourceName,
  perPage,
  orderBy,
  search,
}: { market: string; resourceName: string } & Partial<PaginationKeys>) => [
  'market-resource-facility-associations',
  market,
  resourceName,
  perPage,
  orderBy,
  search,
]

export const utilityFacilties = ({ utilitySalesforceId }) => [
  'market-utility-facilities',
  utilitySalesforceId,
]

export const unmatchedCogs = ['unmatched-cogs']
export const availablePayments = ['available-payments']
export const paymentsKey = ['payments']
export const payments = ({
  status,
  prevStatusPaymentProposed,
  organizationSalesforceId,
  parentProgramSalesforceId,
  search,
}) => [
  ...paymentsKey,
  status,
  prevStatusPaymentProposed,
  organizationSalesforceId,
  parentProgramSalesforceId,
  search,
]

export const dispatchQueueKey = ['dispatch-queue']

export const dispatchQueueGetDispatches = (
  programSalesforceId: Array<string>,
  states: Array<string>,
  updatedSince?: Date
) => [...dispatchQueueKey, states, programSalesforceId, updatedSince]

export const dispatchQueueDispatch = (dispatchId) => [
  ...dispatchQueueKey,
  dispatchId,
]
export const qualifyingRegistrations = (
  programSalesforceId: string,
  dispatchId?: number
) => [programSalesforceId, dispatchId]
export const dispatchQueueGetPrograms = () => 'dispatch-queue-programs'

export const voltletsKey = 'voltlets'

export const voltletIoTManagerKey = ({ clientId }) => [
  voltletsKey,
  'iot-manager',
  'voltlet',
  clientId,
]
export const voltletIoTManagerOutputStatusSpansKey = (
  clientId: string,
  startTime: string,
  endTime?: string
) => [
  voltletsKey,
  'iot-manager',
  'output-status-spans',
  clientId,
  startTime,
  endTime,
]
export const voltletIoTManagerMetricsExpectedMsgsRecvRatioKey = (
  clientId: string,
  startTime: string,
  intervalWidthSeconds: number,
  endTime?: string
) => [
  voltletsKey,
  'iot-manager',
  'expected-msgs-recv-ratio',
  clientId,
  startTime,
  endTime,
  intervalWidthSeconds,
]
export const voltletIoTManagerConfigKey = ({ clientId }) => [
  voltletsKey,
  'iot-manager',
  'config',
  clientId,
]
export const voltletDetailsKey = ({ clientId }) => [
  voltletsKey,
  'voltlet-details',
  clientId,
]
export const voltletConfigDetailsKey = ({ configId }) => [
  voltletsKey,
  'voltlet-config-details',
  configId,
]
export const voltletsEventKey = ({ startDate, endDate, facilityId }) => [
  voltletsKey,
  'voltlet-scheduled-events',
  startDate,
  endDate,
  facilityId,
]
export const voltletMarketsProgramKey = ({ programId }) => [
  voltletsKey,
  'voltlet-markets-program',
  programId,
]
export const voltletsPaginatedKey = ({ page, perPage, orderBy, search }) => [
  'voltlet-list',
  page,
  perPage,
  orderBy,
  search,
]

export const allFeatureFlagsQueryKey = () => ['all-flags']

export const allBills = () => ['bills']
export const bills = (
  organizationId?: number,
  filters: {
    labels?: Array<string>
    types?: Array<string>
    search?: string
  } = {}
) => [...allBills(), { organizationId }, { filters }]
export const bill = (
  organizationId?: number,
  billId?: number,
  filters: {
    labels?: Array<string>
    types?: Array<string>
    search?: string
  } = {}
) => [...allBills(), { organizationId, billId }, { filters }]

export const schemas = (organizationId?: number) => [
  ...allBills(),
  'schemas',
  organizationId,
]
export const schema = (schemaId?: number) => [
  ...allBills(),
  'schemas',
  schemaId,
]
export const billComments = (billId?: number) => [
  ...allBills(),
  billId,
  'comments',
]
export const billsLabels = () => [...allBills(), 'labels']

export const allFiles = () => ['files']
export const filesMetadata = (
  organizationId?: number,
  filters: {
    type?: Array<string>
  } = {}
) => [...allFiles(), { organizationId }, { filters }]
export const fileMetadata = (id?: number) => [...allFiles(), 'detail', id]
export const fileDownloadLink = (id?: number) => ['file-download-link', id]

export const combinedLoads = (search?: string) => ['combined-loads', search]

export const loadStreams = (
  dataSourceKeys: Array<string>,
  startTime: string,
  endTime: string,
  smoothing?: number,
  interval?: number
) => [
  'loadStreams',
  {
    dataSourceKeys: dataSourceKeys.sort(),
    startTime,
    endTime,
    smoothing,
    interval,
  },
]

export const loadStreamsBatched = (
  dataSourceKeys: Array<string>,
  startTime: string,
  endTime: string,
  smoothing?: number,
  interval?: number
) => [
  'loadStreams',
  'batched',
  {
    dataSourceKeys: dataSourceKeys.sort(),
    startTime,
    endTime,
    smoothing,
    interval,
  },
]

export const compareStreams = (
  dataSourceKey: string,
  startTime: string,
  endTime: string,
  interval
) => ['compareStreams', { dataSourceKey, startTime, endTime, interval }]

export const systemLoadSearch = ({ search }: { search?: string }) => [
  'system-load-search',
  search,
]
export const siteSearch = ({
  category,
  search,
  filters,
  includeIds,
  ...rest
}) => ['sites-search', category, search, filters, includeIds, rest]

export const getDwollaCustomerId = () => ['bank-account', 'dwolla-customer-id']

export const utilityAccountsByOrganization = (organizationId?: number) => [
  'utility-account',
  'organization',
  organizationId,
]

export const utilityAccountsByFaciliy = (facilityId?: number) => [
  'utility-accounts',
  facilityId,
]

export const scheduledEvents = () => ['events-list']

export const scheduledEventsFiltered = (
  page: number,
  perPage: number,
  orderBy: OrderBy,
  programFilterString: string,
  eventType?: string,
  refreshKey?: number
) => [
  ...scheduledEvents(),
  page,
  perPage,
  orderBy.property,
  orderBy.dir,
  programFilterString,
  eventType,
  refreshKey,
]

export const eventDetail = (eventId?: string) => ['event-details', eventId]

export const allPrograms = () => ['all-programs']

export const siteAvailability = ({
  start,
  end,
  siteIds,
}: {
  start: string
  end: string
  siteIds?: Array<number>
}) => [
  'block',
  {
    dashboard: 'block',
    info: 'site-availability',
    start,
    end,
    siteIds,
  },
]

export const programTree = () => ['program-tree']
