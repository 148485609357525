import { takeLatest, put, all, call } from '@redux-saga/core/effects'
import { decamelizeKeys } from 'humps'
import { Toast } from '@voltus/core-components'
import { eventTracker, EVENTS } from '@voltus/event-tracking'
import logger from '@voltus/logger'
import { network, endpoints } from '@voltus/network'
import { nullify } from '@voltus/utils'

import { ProfileTypes, ProfileActions } from './profile.redux'

export function* fetchProfileData() {
  try {
    const data = yield call(network.get, endpoints.profile.user())
    yield put(
      ProfileActions.receiveProfile({
        data,
        loading: false,
        error: null,
      })
    )
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    yield put(
      ProfileActions.receiveProfile({
        loading: false,
        error: new Error(e),
      })
    )
  }
}

function* updateProfile({ data, onSuccess, onFailure }) {
  try {
    const response = yield call(network.put, endpoints.user.user(data.id), {
      body: decamelizeKeys(nullify(data)),
    })

    const mergedData = {
      // we need to merge API response with form fields values because:
      // - phone numbers are re-formatted by API
      // - some fields (firstName, lastName) are missing in PUT response
      ...data,
      ...response,
    }

    eventTracker.track(EVENTS.UPDATE_PROFILE)

    yield put(
      ProfileActions.updateProfileSuccess({
        data: mergedData,
        loading: false,
        error: null,
      })
    )

    Toast.success('Profile updated')
    onSuccess?.(mergedData)
  } catch (e) {
    if (e.message) {
      logger.report.error(e.message)
    }
    onFailure?.(e.message ? e.message : e)
    yield put(
      ProfileActions.updateProfileFailure({
        loading: false,
        error: new Error(e),
      })
    )
  }
}

export function* watchProfile() {
  yield all([
    takeLatest(ProfileTypes.FETCH_PROFILE, fetchProfileData),
    takeLatest(ProfileTypes.UPDATE_PROFILE, updateProfile),
  ])
}
