import fuzzy from 'fuzzy'
import { isProdHost } from '@voltus/utils'
import { hashStringToUInt } from '@voltus/utils'
import { genericSanitize } from '../../parseAndSanitizeData'

/**
 * Custom response sanitizer for the facility/search endpoint
 * The request handler strips the search param and fetches _all_ facilities.
 * This handler sanitizes then filters facilities based on the search param.
 * We filter on the sanitized names, not the original facility names.
 */
export const handleFacilitySearchRes = (uri, method, json, ctx) => {
  const { search } = ctx
  const sanitized = genericSanitize(JSON.parse(json), {
    '^facilities$': {
      '^results$': {
        '^meters$': {
          _swap: [
            {
              field: 'name',
              type: 'meter',
            },
          ],
        },
        _swap: [
          {
            field: 'city',
            type: 'city',
          },
          {
            field: 'state',
            type: 'state_short',
          },
          {
            field: 'street',
            type: 'street',
          },
          {
            field: 'utility_name',
            type: 'organization_id',
            key: 'utility_id',
          },
          {
            field: 'organization_name',
            type: 'organization_id',
            key: 'organization_id',
          },
          {
            field: 'curtailment_plan',
            type: 'sentence',
          },
          {
            field: 'name',
            type: 'facility_id',
            key: 'id',
          },
          {
            field: 'salesforce_id',
            type: 'facility_salesforce_id',
          },
        ],
      },
    },
  })

  const extract = (facility) => facility.name
  return {
    ...sanitized,
    facilities: {
      ...sanitized.facilities,
      results: search
        ? fuzzy
            .filter(search, sanitized.facilities.results, {
              extract,
            })
            .map((el) => el.original)
        : sanitized.facilities.results,
    },
  }
}

/**
 * This method is a custom sanitizer for the powerbox/filters endpoint
 * The response from this endpoint looks like:
 * ```
 * {
 *  "filter": [
 *     { filter: 'program_ids', options: [{ label: 'thing', value: 1 }], ...moreStuff },
 *     { filter: 'portfolio_ids', options: [{ label: 'Portfolio', value: 2 }], ...moreStuff },
 *   ]
 * }
 * ```
 * We need to sanitize the options differently depending on the value of the "filter" property
 * The demo config doesn't allow for this kind of customization
 */
export const handlePowerboxFilters = async (uri, method, data) => {
  const config = (await import('../../demo-cfg')).config

  const replacement = isProdHost()
    ? config.replacement.production
    : config.replacement.development
  const generic = config.replacement.generic

  const sanitizePortfolioName = (name, id) => {
    if (replacement.portfolio.id[id]) {
      return replacement.portfolio.id[id]
    }
    return hashStringToUInt(name, generic.portfolio)
  }
  const sanitizeUtilityName = (name, id) => {
    if (replacement.organization.id[id]) {
      return replacement.organization.id[id]
    }
    return hashStringToUInt(name, generic.utility)
  }
  const newFilters: Array<{ label: string; value: string | number }> = []
  const parsed = JSON.parse(data)
  for (const filter of parsed.filters) {
    switch (filter.filter) {
      case 'portfolio_ids': {
        const opts = filter.options.map(({ label, value }) => ({
          label: sanitizePortfolioName(label, value),
          value,
        }))
        newFilters.push({ ...filter, options: opts })
        break
      }
      case 'utility_ids': {
        const opts = filter.options.map(({ label, value }) => ({
          label: sanitizeUtilityName(label, value),
          value,
        }))
        newFilters.push({ ...filter, options: opts })
        break
      }
      case 'meter_provider_ids':
        newFilters.push({
          ...filter,
          options: filter.options.map(({ label, value }) => ({
            label: hashStringToUInt(label, 'Demo Meter Provider'),
            value,
          })),
        })
        break
      // Don't use default case to push non-sanitized filters, so we don't
      // accidentally add a new filter that needs sanitizing
      case 'program_ids':
      case 'dispatch_status':
      case 'enablement_status':
      case 'has_generator':
      case 'states':
        newFilters.push(filter)
        break
      default:
        break
    }
  }
  return { filters: newFilters }
}
