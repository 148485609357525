import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { useFeatureFlagContext } from './FeatureFlagContext'
import { FlagType } from './types'

type FlagEnabledRouteProps = {
  /**
   * Return the route when the flag is either true or false
   * This allows for having routes that satisfy either
   * state (true or false) of the feature flag
   */
  whenFlagIs: boolean
  /**
   * The flag object to compare against
   */
  flag: FlagType
  /**
   * Either a fallback URL to redirect to if the flag is not enabled,
   * or a React component to render in place of the original component.
   * Otherwise this route just renders null
   */
  fallback?: string | React.ElementType
  children: React.ReactElement
}

/**
 * Use this route to hide a screen behind a feature flag
 */
export function FlagEnabledRoute({
  whenFlagIs = true,
  flag,
  fallback,
  children,
}: FlagEnabledRouteProps) {
  const { variation, ready } = useFeatureFlagContext()
  const enabled = variation(flag.value)
  if (ready) {
    if (
      (enabled && whenFlagIs === true) ||
      (!enabled && whenFlagIs === false)
    ) {
      return children
    }

    if (typeof fallback === 'string') {
      return <Navigate replace to={fallback} />
    }

    if (typeof fallback === 'object') {
      // We need to use createElement() here because React components must
      // be capitalized. If we do <fallback /> then it thinks we are trying
      // to render an HTML tag called "fallback".
      const Comp = React.createElement(fallback)
      if (React.isValidElement(Comp)) {
        return Comp
      }
    }

    return null
  }

  return null
}
