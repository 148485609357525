export const TOUR_IDS = {
  PORTFOLIO_DASH: 'RcTwcIP4A6v',
  CASH_DASH: 'xbbyJkHab1a',
}

export const TOUR_NAMES = {
  [TOUR_IDS.PORTFOLIO_DASH]: 'Portfolio Dash Tour',
  [TOUR_IDS.CASH_DASH]: 'CashDash Tour',
}

export const DEFAULT_TOURS_READY_CONTEXT = {
  [TOUR_IDS.PORTFOLIO_DASH]: false,
  [TOUR_IDS.CASH_DASH]: false,
}

export const PORTFOLIO_DASH_TOUR_SELECTORS = {
  SITES_DASH_FILTER: 'SITES_DASH_FILTER',
  PORTFOLIO_DASH_DATE_DROPDOWN: 'PORTFOLIO_DASH_DATE_DROPDOWN',
  SITE_DETAILS_HEADING: 'SITE_DETAILS_HEADING',
  SITE_CONTACTS_HEADING: 'SITE_CONTACTS_HEADING',
  NOMINATION_TABLE_ROW: 'NOMINATION_TABLE_ROW',
}

export const CASH_DASH_TOUR_SELECTORS = {
  CASH_DASH_NAV: 'CASH_DASH_NAV',
  LAST_PAYMENT_BOX: 'LAST_PAYMENT_BOX',
  LINE_ITEMS_FILTER: 'LINE_ITEMS_FILTER',
}
