import { PATHS } from '@voltus/constants'

export { PATHS }
export const ROUTES = {
  ADMIN: {
    url: PATHS.ADMIN,
    name: 'Admin',
  },
  EARNINGS_V1: {
    PAYMENTS: {
      url: (portfolioId) => `/earnings/?portfolioId=${portfolioId}#/payments`,
    },
    PERFORMANCE: {
      url: (portfolioId) =>
        `/earnings/?portfolioId=${portfolioId}#/performance/dispatches`,
    },
  },
  EARNINGS: {
    url: PATHS.CASHDASH_HOME,
    name: 'CashDash',
  },
  DISPATCHES: {
    url: PATHS.DISPATCHES,
    name: 'Dispatches',
  },
  ACTIVE_DISPATCHES: {
    url: PATHS.ACTIVE_DISPATCHES,
    name: 'Active Dispatches',
  },
  PAST_PERFORMANCE: {
    url: PATHS.PAST_PERFORMANCE,
    name: 'Past Performance',
  },
  PORTFOLIO: {
    url: PATHS.PORTFOLIO_HOME,
    name: 'Portfolio',
  },
}
