import * as React from 'react'

const context = React.createContext<DOMRectReadOnly | undefined>(undefined)

export const NavbarDimensionsProvider = ({ children, value }) => {
  return <context.Provider value={value}>{children}</context.Provider>
}

export const useNavbarDimensions = () => {
  return React.useContext(context)
}
