import { createActions, createReducer } from 'reduxsauce'

export const { Types: EventTrackingTypes, Creators: EventTrackingActions } =
  createActions(
    {
      initializeEventTracking: ['navigate', 'application'],
      receiveTrackingData: ['payload'],
    },
    { prefix: 'EVENTS_TRACKING/' }
  )

const INITIAL_STATE = null

const setEventTracking = (state, { payload }) => {
  const { profile, events } = payload
  return {
    profile,
    events,
  }
}

export const eventTrackingReducer = createReducer(INITIAL_STATE, {
  [EventTrackingTypes.RECEIVE_TRACKING_DATA]: setEventTracking,
})
