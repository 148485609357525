import {
  eventTrackingReducer,
  permissionsReducer,
  profileReducer,
  globals,
} from '@voltus/modules'

import { reducer as dispatchesReducer } from '../routes/dispatches/routes/ActiveDispatches/modules/reducers'

export default {
  permissions: permissionsReducer,
  eventTracking: eventTrackingReducer,
  profile: profileReducer,
  globals: globals.globalsReducer,
  dispatches: dispatchesReducer,
}
