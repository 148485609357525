import { debugLogger } from '@voltus/logger'
import { AnyObject } from '@voltus/types'
import { hashStringToUInt, isProdHost } from '@voltus/utils'
import { config } from './demo-cfg'
import { FetchMethods } from './types'

interface SwapItem {
  /**
   * The field name in the API response that needs to be replaced. This is
   * always expected to be snake case. APIs that return a top-level array
   * will not have a field name, so this value is optional.
   */
  field?: string
  /**
   * The PII type of the field. This is always expected to be snake case.
   */
  type: string
  /**
   *  The key informs what field to look up a pseudonym by. It is often
   *  different from the portfolio/facility/org name itself and is instead an id
   *  or salesforce id, which is returned with the API response in a different
   *  field.
   */
  key?: string
  /**
   * If your API response contains an optional field - i.e. a field that is sometimes missing
   * on the response object - then you can set optional true to avoid the sanitizer throwing
   * an error and stopping parsing altogether.
   */
  optional?: boolean
}

export const swapDirective = '_swap'
export const keyDirective = '_key'

async function parseAndSanitizeData(
  uri: string,
  method: FetchMethods,
  data: string,
  handlerCtx?: AnyObject
) {
  for (const [api, urlConfig] of Object.entries(config.api)) {
    const re = new RegExp(api)
    if (re.test(uri)) {
      const methodConfig = urlConfig[method]
      if (methodConfig === 'block') {
        debugLogger.warn(`${method} ${uri} is not allowed in demo mode`)
        return null
      }

      // For passthroughs, don't mess with the data at all
      if (methodConfig === 'pass') {
        return data
      }

      const handlerConfig = methodConfig?.res
      if (!handlerConfig) {
        debugLogger.warn(
          `${method} ${uri} is blocked because it has not been audited for sanitization`
        )
        return null
      }
      // The handler config can also be 'pass' or 'block'
      if (handlerConfig === 'pass') {
        return data
      }
      if (typeof handlerConfig === 'function') {
        try {
          return JSON.stringify(
            await handlerConfig(uri, method, data, handlerCtx)
          )
        } catch (e) {
          debugLogger.error(
            `Encountered error sanitizing response for ${method} ${uri}: ${e.message}`
          )
          throw e
        }
      }

      // at this point, handlerConfig is an object, and we can proceed with sanitization via config
      try {
        return JSON.stringify(genericSanitize(JSON.parse(data), handlerConfig))
      } catch (e) {
        debugLogger.error(`Encountered error on ${uri}: ${e.message}`)
        throw e
      }
    }
  }
}

// this method mutates data objects in place
// data has been cloned ahead of time and may be large, so
// creating many immutable objects is burdensome
export function genericSanitize(data, nodes, key = '') {
  for (const [node, next] of Object.entries(nodes)) {
    if (node === swapDirective) {
      // Base case

      // If data is an array, swap every member
      if (Array.isArray(data)) {
        data.forEach(
          (item, i, self) =>
            (self[i] = swapData(item, key, next as Array<SwapItem>))
        )
        continue
      }

      // If data is a single member, just swap it
      // But cannot swap null data, so don't try
      data = data ? swapData(data, key, next as Array<SwapItem>) : data
      continue
    }

    // Visit next node
    // This is a regex check in case keys are some kind of ids
    const reNode = new RegExp(node)
    const childrenKeys = Object.keys(data).filter((key) => reNode.test(key))
    for (const childKey of childrenKeys) {
      const child = data[childKey]
      if (Array.isArray(child)) {
        child.forEach(
          (item, i, self) => (self[i] = genericSanitize(item, next, childKey))
        )
      } else {
        genericSanitize(child, next, childKey)
      }
    }
  }
  return data
}

function swapData(data, key: string, swapItems: Array<SwapItem>) {
  const replacements = isProdHost()
    ? config.replacement.production
    : config.replacement.development

  const genericReplacements = config.replacement.generic

  swapItems.forEach((swapItem) => {
    const { field, type, optional } = swapItem
    if (!field) {
      // this is only for lists of strings (non-objects)
      // without any fields to indicate an ID,
      // only generic name replacement is supported
      data = config.replacement.generic[swapItem.type] ?? 'DEMO'
      return data
    }

    // must check for the property to exist specifically
    // in case the value of the field is null, still proceed to replace it
    // if the property does not exist, this could indicate a payload
    // diverged from our expectations, so throw an error
    if (!optional && !(field in data)) {
      throw new Error(`Unable to find field: '${field}' in data`)
    }

    switch (type) {
      case 'email':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.user.email,
          genericReplacements.email
        )
        break
      case 'full_name':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.user.id,
          genericReplacements.full_name
        )
        break
      case 'facility_salesforce_id':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.facility.salesforceId,
          genericReplacements.facility
        )
        break
      case 'facility_id':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.facility.id,
          genericReplacements.facility
        )
        break
      case 'portfolio_id':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.portfolio.id,
          genericReplacements.portfolio
        )
        break
      case 'organization_salesforce_id':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.organization.salesforceId,
          genericReplacements.organization
        )
        break
      case 'organization_id':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.organization.id,
          genericReplacements.organization
        )
        break
      case 'cohort':
        data[field] = swapMappedItem(
          data,
          key,
          swapItem,
          replacements.cohort.id,
          genericReplacements.cohort
        )
        break
      default:
        // if the property is undefined or if the value is null, will return 'DEMO'
        data[field] = genericReplacements[type] ?? 'DEMO'
        break
    }
  })
  return data
}

function swapMappedItem(
  data,
  key: string,
  swapItem: SwapItem,
  map: { [key: string]: string },
  fallback: string
) {
  const idField = swapItem.key ?? ''
  const fieldName = swapItem.field ?? ''

  /**
   * If the idField is the keyDirective, use the key to look up a replacement in
   * the map.
   *
   * Example:
   * ```
   * key = 1234
   * map = {
   *  "1234": "DEMO Nice Facility",
   *  "5678": "DEMO Pleasant Facility"
   * }
   * // Returns: "DEMO Nice Facility"
   * ```
   * If there is no matching object in the map, attempt to generate a pseudonym,
   * or use a fallback.
   */
  if (idField === keyDirective) {
    return map[key] ?? getPseudonym(data, fieldName, fallback)
  }

  /**
   * Use the idField to look up a replacement in the map.
   *
   * Example:
   * ```
   * data = { organization_id: "74" }
   * idField = "organization_id"
   * map = { 74: "DEMO All" }
   * // Returns: "DEMO All"
   * ```
   *
   * If there is no matching object in the map, attempt to generate a pseudonym,
   * or use a fallback.
   */
  return map[data[idField]] ?? getPseudonym(data, fieldName, fallback)
}

/**
 * Generate a pseudonym using the field from the data object. The fallback will
 * be used to prefix the pseudonym.
 *
 * Example:
 * ```
 * field = 'name'
 * data = {name: 'Voltus'}
 * fallback = 'DEMO'
 * // Returns: DEMO 1036415
 * ```
 *
 * If the value of the field on the data object is null, use a fallback instead.
 *
 * Example:
 * ```
 * field = 'name'
 * data = {name: null}
 * fallback = 'COOL'
 * // Returns: 'COOL'
 * ```
 */
function getPseudonym(data, fieldName: string, fallback: string) {
  const stringToReplace = data[fieldName]
  if (!stringToReplace) {
    return fallback
  }
  return hashStringToUInt(stringToReplace, fallback)
}

export { parseAndSanitizeData }
