/**
 * Helper for firing a callback on each portfolio dispatch
 * within the snapshot object
 *
 * @param {Object} state - redux state
 * @param {function} cb - callback fired with each snapshot
 */
export const forEachSnapshot = (state, cb) => {
  Object.entries(state.snapshots).forEach(([dispatchId, portfolios = {}]) => {
    Object.entries(portfolios).forEach(([portfolioId, snapshot]) => {
      cb({ portfolioId, dispatchId, snapshot })
    })
  })
}

/**
 * Path getters to be called with lodash `set` or `get`
 * Get's the path for snapshot data
 */
export const getSnapshotDataPath = (portfolioId, dispatchId) => [
  'snapshots',
  dispatchId,
  portfolioId,
  'data',
]

/**
 * Get's the path for `facilityDispatch` within a given snapshot
 */
export const getFacilityDispatchPath = (portfolioId, dispatchId) => [
  ...getSnapshotDataPath(portfolioId, dispatchId),
  'facilityDispatch',
]

/**
 * Get's the path for `portfolioDispatch` within a given snapshot
 */
export const getPortfolioDispatchPath = (portfolioId, dispatchId) => [
  ...getSnapshotDataPath(portfolioId, dispatchId),
  'portfolioDispatch',
]

/**
 * Get's the path for `facilitySparklines` within a given snapshot
 */
export const getFacilitySparklinesPath = (portfolioId, dispatchId) => [
  ...getSnapshotDataPath(portfolioId, dispatchId),
  'facilitySparklines',
]

/**
 * Get's the path for `facilityTargets` within a given snapshot
 */
export const getFacilityTargetsPath = (portfolioId, dispatchId) => [
  ...getSnapshotDataPath(portfolioId, dispatchId),
  'facilityTargets',
]

export const getUserAckSummaryStatusPath = (
  portfolioId,
  dispatchId,
  status
) => [
  ...getPortfolioDispatchPath(portfolioId, dispatchId),
  'userAckSummary',
  status,
]
