import { useQuery } from '@tanstack/react-query'
import Cookies from 'cookies-js'
import jwtDecode from 'jwt-decode'
import * as LDClient from 'launchdarkly-js-client-sdk'
import * as React from 'react'
import { HOST_TARGET, LAUNCH_DARKLY_TOKENS } from '@voltus/constants'
import { datadogRum } from '@voltus/datadog'
import logger from '@voltus/logger'
import { network, endpoints, queryKeys } from '@voltus/network'
import { ProfileQueryResult } from '@voltus/queries'
import { isTestEnv, ENVIRONMENTS } from '@voltus/utils'

type Variation = boolean | string
type RestrictedLDClient = {
  variation: LDClient.LDClient['variation']
  getContext: () => LDClient.LDMultiKindContext
}

type FeatureFlagProviderProps = {
  ctxOverride?: FeatureFlagContextValue
  children: React.ReactNode
}

const clientStub: RestrictedLDClient = {
  variation: () => false,
  getContext: () => ({
    kind: 'multi',
    user: { key: '', kind: 'user' },
    organization: { key: '', kind: 'organization' },
  }),
}

type FeatureFlagContextValue = {
  variation: RestrictedLDClient['variation']
  getContext: RestrictedLDClient['getContext']
  allFlags: { [key: string]: Variation }
  ready: boolean
}

const ctxStub: FeatureFlagContextValue = {
  ...clientStub,
  allFlags: {},
  ready: false,
}

const fakeLDClient = {
  variation: (key, defaultValue = false) => {
    // eslint-disable-next-line
    // @ts-ignore
    return window.__featureFlagManualOverrides?.[key] ?? defaultValue
  },
  getContext: (): LDClient.LDMultiKindContext => ({
    kind: 'multi',
    user: {
      kind: 'user',
      key: 'fakie-fake-user',
    },
    organization: {
      kind: 'organization',
      key: 'fakie-fake-org',
    },
  }),
  waitUntilReady: () => Promise.resolve(),
}

const context = React.createContext<FeatureFlagContextValue>(ctxStub)
export let ldclient: LDClient.LDClient

// We could fetch the user's profile data to get their email and use
// that as their key, but we have their JWT in a cookie, so it's faster
// to use that, and since we're going to delay the page while we initialize
// launch darkly, we want it to be fast
function initializeLD({
  email,
  organization,
}: {
  email: string
  organization: string
}) {
  return new Promise<LDClient.LDClient>((resolve, reject) => {
    if (ldclient) {
      resolve(ldclient)
      return
    }

    const jwt = Cookies.get('access_token')
    if (!jwt) {
      reject('No JWT found')
      return
    }

    const getClientToken = () => {
      if (isTestEnv()) {
        return LAUNCH_DARKLY_TOKENS.TEST
      }

      // HOST_TARGET is injected into the build during a deploy. We do this so we can know for sure
      // if we're on dev.voltus.co vs voltapp.voltus.co
      // The reason we can't just use NODE_ENV is becuase we deploy production builds to both dev.voltus.co
      // and voltapp.voltus.co, which means NODE_ENV = 'production' for both environments. Instead
      // we can know at deploy time what our target is, and inject that as HOST_TARGET
      if (process.env.HOST_TARGET === HOST_TARGET.PRODUCTION) {
        return LAUNCH_DARKLY_TOKENS.PRODUCTION
      }

      return LAUNCH_DARKLY_TOKENS.DEVELOPMENT
    }

    try {
      const userEmail = jwtDecode<{ identity?: { email: string } }>(jwt)
        ?.identity?.email
      if (userEmail) {
        const token = getClientToken()
        if (!token) {
          reject('no ld token found')
          return
        }
        const userContext = {
          kind: 'user',
          key: email,
        }
        const organizationContext = {
          kind: 'organization',
          key: organization,
        }
        const multiContext = {
          kind: 'multi',
          user: userContext,
          organization: organizationContext,
        }
        const options: LDClient.LDOptions = {
          inspectors: [
            {
              type: 'flag-used',
              name: 'dd-inspector',
              method: (key: string, detail: LDClient.LDEvaluationDetail) => {
                datadogRum.addFeatureFlagEvaluation(key, detail.value)
              },
            },
          ],
        }
        const client = LDClient.initialize(token, multiContext, options)
        client.on('ready', () => {
          ldclient = client
          if (isTestEnv()) {
            ldclient = fakeLDClient as LDClient.LDClient
          }
          resolve(client)
        })
      } else {
        reject('No email found from jwt, cannot use feature flags')
      }
    } catch (e) {
      reject(e)
    }
  })
}

export function FeatureFlagProvider({
  /**
   * Dependency injection to allow for overriding the context and avoiding
   * hitting launch darkly during tests.
   * ONLY use during tests
   */
  ctxOverride,
  children,
}: FeatureFlagProviderProps): JSX.Element {
  const [ctx, setCtx] = React.useState<FeatureFlagContextValue>(
    ctxOverride ?? {
      ...clientStub,
      allFlags: {},
      ready: false,
    }
  )
  // If a ctxOverride has been passed then we don't need to make the profile
  // query because we're overriding the permissions anyway.
  // This also prevents issues in our tests where this query can finish after
  // the tests are finished and we get warnings in the console.
  const { data: profile } = useQuery({
    // Only use different key for MSW bypass
    queryKey: process.env.MSW
      ? ['feature-flags', 'profile']
      : queryKeys.userProfile(),
    queryFn: () =>
      network.get<ProfileQueryResult>(endpoints.profile.user(), {
        headers: process.env.MSW
          ? {
              'Bypass-MSW': 'true',
            }
          : undefined,
      }),
    enabled: !ctxOverride,
  })
  const email = profile?.email
  const organization = profile?.organization.name

  React.useEffect(() => {
    if (ctxOverride) {
      // If we injected the context,
      // no need to talk to launch darkly
      return
    }

    if (email && organization) {
      initializeLD({ email, organization })
        .then((c) => {
          c.on('change', (settings) => {
            setCtx((state) => {
              const updatedFlags = {}
              for (const key in settings) {
                if (Object.prototype.hasOwnProperty.call(settings, key)) {
                  updatedFlags[key] = settings[key].current
                }
              }
              return {
                ...state,
                allFlags: {
                  ...state.allFlags,
                  ...updatedFlags,
                },
              }
            })
          })
          setCtx({
            variation: c.variation,
            // We restrict the context to only support multi context here, but the LD client is more permissive,
            // allowing for LDSingleKindContext, which has a different type structure. So we cast since the return
            // value from getContext is not a generic, and thus not configuratble
            getContext:
              c.getContext as unknown as () => LDClient.LDMultiKindContext,
            ready: true,
            // The LD Client has an allFlags method, but we don't need to pass the method,
            // We can just pass, well, all the flags as an object
            allFlags: c.allFlags(),
          })
        })
        .catch((e) => {
          // In test environments, we end up here, so just fail silently.
          // TODO: We might want to alert on this at some point, if this happens in production
          // it's a problem. It means we're not properly decoding the user email from the JWT which
          // will make it impossible to use feature flags
          logger.once.error(e)
        })
    }
  }, [ctxOverride, email, organization])

  // A gross way to intercept the client during tests.
  // Pull a value from the window that ONLY exists during test runs,
  // and use those instead of a real launch darkly client
  if (
    // eslint-disable-next-line
    // @ts-ignore
    window.__featureFlagManualOverrides ||
    process.env.TEST_ENV === ENVIRONMENTS.PLAYWRIGHT
  ) {
    return (
      <context.Provider
        value={{
          variation: fakeLDClient.variation,
          getContext: fakeLDClient.getContext,
          // eslint-disable-next-line
          // @ts-ignore
          allFlags: window.__featureFlagManualOverrides,
          ready: true,
        }}
      >
        {children}
      </context.Provider>
    )
  }

  return <context.Provider value={ctx}>{children}</context.Provider>
}

export const useFeatureFlagContext = (): FeatureFlagContextValue =>
  React.useContext(context)
