import noop from 'lodash/noop'
import React from 'react'
import { Link } from 'react-router-dom'
import { eventTracker } from './EventTracker'
import { PROPERTIES } from './constants'

const TrackedLink = ({
  eventName,
  eventProps,
  to,
  onClick = noop,
  ...props
}) => {
  return (
    <Link
      to={to}
      onClick={(e) => {
        onClick(e)
        eventTracker.track(eventName, {
          [PROPERTIES.NAVIGATE_FROM]: to?.state?.from,
          ...eventProps,
        })
      }}
      {...props}
    />
  )
}

export { TrackedLink }
