import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DEFAULT_TOURS_READY_CONTEXT } from './constants'

const context = createContext()

const HelpMenuContext = {}
function HelpMenuContextProvider({ children, ...props }) {
  const [toursReady, setToursReady] = useState(DEFAULT_TOURS_READY_CONTEXT)

  const location = useLocation()
  useEffect(() => {
    setToursReady(DEFAULT_TOURS_READY_CONTEXT)
  }, [location.pathname])

  const setTourReady = (tourId) => {
    setToursReady((state) => ({ ...state, [tourId]: true }))
  }

  return (
    <context.Provider value={{ toursReady, setTourReady }} {...props}>
      {children}
    </context.Provider>
  )
}
HelpMenuContext.Provider = HelpMenuContextProvider
HelpMenuContext.Consumer = context.Consumer

const useHelpMenuContext = () => useContext(context)

export { HelpMenuContext, useHelpMenuContext, context as HelpMenuContextType }
